import { Component, OnInit } from '@angular/core';
import { UtilService } from '../services';

@Component({
	selector: 'app-spinner',
	template: `
		<div *ngIf="_show">
			<p-blockUI blocked="true">
				<p-progressSpinner strokeWidth="4"></p-progressSpinner>
			</p-blockUI>
		</div>
	`,
})
export class SpinnerComponent implements OnInit {
	_show: boolean;
	_tShow = false;
	_ttShow = '';
	_setShowTimer: any = null;
	constructor(private util: UtilService) {}

	ngOnInit(): void {
		this.util.registerSpinner(this);
	}
	get show(): boolean {
		return this._show;
	}
	set show(value: boolean) {
		// eslint-disable-next-line
		const that = this;
		this._tShow = value;
		const tShow = value;
		this._ttShow = JSON.stringify(tShow);
		if (this._setShowTimer) {
			clearTimeout(this._setShowTimer);
		}
		this._setShowTimer = setTimeout(function () {
			const r = JSON.parse(that._ttShow);
			that._show = r;
			//that.renderShowSpinner();
		}, 100);
	}
	renderShowSpinner() {
		this._show = this._tShow;
	}
}
