import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ActionResult } from 'src/app/shared/models/api';
import { BusinessEntityInsurance } from 'src/app/shared/models/business-entities';
import { ApiService } from 'src/app/core/services';

@Injectable({
	providedIn: 'root',
})
export class EntityInsuranceApiService {
	constructor(private apiService: ApiService) {
		//
	}

	getBusinessEntityInsurancesForBusinessEntity(businessEntityId: string): Observable<BusinessEntityInsurance[]> {
		return this.apiService.get<BusinessEntityInsurance[]>(`/BusinessEntity/GetBusinessEntityInsurancesForBusinessEntity/${businessEntityId}`);
	}

	saveBusinessEntityInsurance(item: BusinessEntityInsurance) {
		return this.apiService.post<ActionResult>('/BusinessEntity/SaveBusinessEntityInsurance', item);
	}

	disableBusinessEntityInsurance(businessEntityInsuranceId: string) {
		return this.apiService.get<ActionResult>(`/BusinessEntity/DisableBusinessEntityInsurance/${businessEntityInsuranceId}`);
	}
}
