<div class="confirm-dialog-title" data-testid="paidInvoiceConfirmationContentTitle">
	<i [class]="getIcon()" class="p-confirm-dialog-icon"></i>
	<span class="p-confirm-dialog-message">{{ getTitle() }}</span>
</div>
<form [formGroup]="confirmationForm" #formRef="ngForm">
	<!-- TO-DO: Timezone change -->
	<app-form-item
		[visible]="[formModes.Create].includes(formMode)"
		[editable]="[formModes.Create].includes(formMode)"
		[controlType]="formControls.Calendar"
		[control]="confirmationForm.get('datePaid')"
		label="Paid"
		data-testid="paidInvoiceConfirmationDatePaid">
	</app-form-item>
	<app-form-item
		[visible]="[formModes.Create].includes(formMode)"
		[editable]="[formModes.Create].includes(formMode)"
		[controlType]="formControls.Textbox"
		[control]="confirmationForm.get('referenceNumber')"
		label="Reference #"
		data-testid="paidInvoiceConfirmationReferenceNumber">
	</app-form-item>
	<app-form-item
		[visible]="[formModes.Create].includes(formMode)"
		[editable]="[formModes.Create].includes(formMode)"
		[controlType]="formControls.Textarea"
		[control]="confirmationForm.get('comments')"
		label="Comments"
		data-testid="paidInvoiceConfirmationComments">
	</app-form-item>
</form>
<app-dialog-footer dataTestIdComponentName="PaidInvoiceConfirmation" (closeDialog)="closeDialog()" (submitDialog)="submitDialog()"></app-dialog-footer>
