export class Map {
	MapId: string;

	ReferenceTypeCode: string | null;
	ReferenceId: string;
	ReferenceName: string | null;

	Name: string | null;
	Position: number | null;

	TimeZoneCode: string | null;
	TimeZoneName: string | null;

	NewFileId: string | null;
	FileName: string | null;

	ImagePreview: string | null;

	constructor(properties?: any) {
		if (properties) {
			for (const key in properties) {
				this[key] = properties[key];
			}
		}
	}
}
