<div class="container">
	<div class="p-grid">
		<div class="p-mt-0">
			<div class="lock p-mt-5 p-pt-5"></div>
			<div class="message">
				<h1>Access to this page is restricted</h1>
				<p class="p-d-flex p-jc-center">We are sorry, but you do not have access to this page or resource</p>
			</div>
		</div>
	</div>
</div>
