import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
	selector: 'app-dialog-footer',
	templateUrl: './dialog-footer.component.html',
})
export class DialogFooterComponent {
	@Input() dataTestIdComponentName = '';
	@Input() isAcceptButtonVisible = true;
	@Input() isAcceptButtonDisabled = false;
	@Input() acceptButtonLabel = 'Yes';
	@Input() acceptButtonIcon = 'pi pi-check';
	@Input() acceptButtonTooltip = 'Yes';
	@Input() isPartialAcceptButtonVisible = false;
	@Input() partialAcceptButtonLabel = 'Yes';
	@Input() partialAcceptButtonIcon = 'pi pi-check';
	@Input() partialAcceptButtonTooltip = 'Yes';
	@Input() isRejectButtonVisible = true;
	@Input() rejectButtonLabel = 'No';
	@Input() rejectButtonIcon = 'pi pi-times';
	@Input() rejectButtonTooltip = 'No';
	@Input() isClearButtonVisible = false;
	@Input() clearButtonLabel = 'Clear';
	@Input() clearButtonIcon = 'pi pi-times';
	@Input() clearButtonTooltip = 'Clear';
	@Input() isDeleteButtonVisible = false;
	@Input() deleteButtonLabel = 'Delete';
	@Input() deleteButtonIcon = 'pi pi-trash';
	@Input() deleteButtonTooltip = 'Delete';

	@Output() submitDialog = new EventEmitter<boolean>();
	@Output() closeDialog = new EventEmitter<void>();
	@Output() clearDialog = new EventEmitter<void>();
	@Output() deleteDialog = new EventEmitter<void>();

	showAcceptButton(): boolean {
		if (this.isAcceptButtonVisible == true) return true;

		return false;
	}
	showAcceptButtonDisabled(): boolean {
		if (this.isAcceptButtonDisabled == true) return true;

		return false;
	}

	showPartialAcceptButton(): boolean {
		if (this.isPartialAcceptButtonVisible == true) return true;

		return false;
	}

	showRejectButton(): boolean {
		if (this.isRejectButtonVisible == true) return true;

		return false;
	}

	showClearButton(): boolean {
		if (this.isClearButtonVisible == true) return true;

		return false;
	}

	showDeleteButton(): boolean {
		if (this.isDeleteButtonVisible == true) return true;

		return false;
	}

	onSubmitDialog(fullAccept: boolean) {
		this.submitDialog.emit(fullAccept);
	}

	onCloseDialog() {
		this.closeDialog.emit();
	}

	onClearDialog() {
		this.clearDialog.emit();
	}

	onDeleteDialog() {
		this.deleteDialog.emit();
	}
}
