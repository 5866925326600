<div class="panel-overlay" *ngIf="showProgressBar()">
	<p-panel #pnl header="" class="panel-body progressbar-panel" data-testid="progressbarPanel">
		<div class="p-px-6 p-pb-3">
			<div data-testid="progressbarHeader" class="panel-title p-text-light">Updating Progress</div>
			<div class="p-pb-2">
				<div class="p-d-flex p-jc-end desc-title p-pb-2" id="processingCount" data-testid="processingCount">{{ getProcessingCount() }}</div>
				<div>
					<p-progressBar [value]="getProgressValue()" [showValue]="false" data-testid="progressValue"> </p-progressBar>
				</div>
				<div class="p-d-flex p-jc-start p-pt-2 desc-title" id="timeRemaining" data-testid="timeRemaining">{{ getTimeRemaining() }}</div>
				<div class="p-d-flex p-jc-end">
					<p-button data-testid="progressbarCancel" label="Cancel" (onClick)="cancelProgress()" icon="" pTooltip="Cancel" tooltipPosition="bottom"></p-button>
				</div>
			</div>
		</div>
	</p-panel>
</div>
