import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ActionResult } from 'src/app/shared/models/api';
import { ApiFile } from 'src/app/shared/models/shared';
import { ApiService } from 'src/app/core/services';
import { MapMarker } from '../models/shared/map-marker.model';
import { Map } from '../models/shared/map.model';

@Injectable({
	providedIn: 'root',
})
export class MapService {
	constructor(private apiService: ApiService) {
		//
	}

	getMap(mapId: string): Observable<Map> {
		return this.apiService.get<Map>(`/Map/GetMap/${mapId}`);
	}

	saveMap(map: Map) {
		return this.apiService.post<ActionResult>('/Map/SaveMap', map);
	}

	disableMap(mapId: string) {
		return this.apiService.get<ActionResult>(`/Map/DisableMap/${mapId}`);
	}

	getMapMarkersForMap(mapId: string): Observable<MapMarker[]> {
		return this.apiService.get<MapMarker[]>(`/Map/GetMapMarkersForMap/${mapId}`);
	}

	saveMapMarker(mapMarker: MapMarker) {
		return this.apiService.post<ActionResult>('/Map/SaveMapMarker', mapMarker);
	}

	disableMapMarker(mapMakerId: string) {
		return this.apiService.get<ActionResult>(`/Map/DisableMapMarker/${mapMakerId}`);
	}

	getMapImage(mapId: string): Observable<ApiFile> {
		return this.apiService.get<ApiFile>(`/Map/GetMapImage/${mapId}`);
	}

	uploadFile(fileId: string, file: any) {
		const fileDto = new ApiFile();
		fileDto.NewFileId = fileId;
		return this.apiService.postFile('/Map/UploadFile', file, fileDto);
	}
	getMapMarker(markerId: string): Observable<MapMarker> {
		return this.apiService.get<MapMarker>(`/Map/GetMapMarker/${markerId}`);
	}
}
