import { Directive } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';

@Directive({
	selector: '[validateDay]',
	providers: [
		{
			provide: NG_VALIDATORS,
			useExisting: DayValidatorDirective,
			multi: true,
		},
	],
})
export class DayValidatorDirective implements Validator {
	validate(control: AbstractControl): ValidationErrors | null {
		if (control.value && control.value > 0 && control.value <= 31) {
			return null;
		} else {
			return { day: 'Invalid Input.' };
		}
	}
}
31;
