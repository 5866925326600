import { Component, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TabView } from 'primeng/tabview';
import { Globals } from 'src/app/globals';
import { AuditHistoryComponent } from 'src/app/shared/components/audit-history/audit-history.component';
import { Constants } from 'src/app/shared/constants/constants.constant';
import { BaseComponent } from 'src/app/core/components/base.component';
import { AppContextService, SignalRService } from 'src/app/core/services';
import { UserProfile } from '../../../shared/models';
import { UserProfileDetailsComponent } from '../../components/user-profile-details/user-profile-details.component';

@Component({
	selector: 'app-user-profile',
	templateUrl: './user-profile.component.html',
	encapsulation: ViewEncapsulation.None,
})
export class UserProfileComponent extends BaseComponent implements OnInit {
	referenceTypeCode: string = Constants.ReferenceType.UserProfile;

	@Input() item: UserProfile = new UserProfile();
	@ViewChild('auditHistoryList') auditHistoryList: AuditHistoryComponent;
	@ViewChild('detail') detail: UserProfileDetailsComponent;
	@ViewChild('tabs') tabs: TabView;
	activeIndex = 0;

	myProfile: boolean;

	constructor(
		appContext: AppContextService,
		public globals: Globals,
		private activatedRoute: ActivatedRoute,
		private route: ActivatedRoute,
		private router: Router,
		private signalRService: SignalRService // including here to handle any user updates automatically
	) {
		super(appContext);
		this.appContext.ViewTitle = 'User';
		this.appContext.BasePageCSSClass = Constants.BasePageCSSClass.Default;
		this.appContext.HasTableBasedData = false;
	}

	ngOnInit(): void {
		this.util.clearMessages();
		this.activatedRoute.data.subscribe(data => {
			this.item = data.pageData.user;
			this.myProfile = data.pageData.myProfile;
			this.formMode = this.formModes.View;

			this.refreshBreadcrumbs();
			if (this.myProfile) {
				this.appContext.ViewTitle = 'My Profile';
			}
		});
	}

	isPage(): boolean {
		return true;
	}

	goBackToListPage() {
		this.router.navigate(['../'], { relativeTo: this.route });
	}

	refreshBreadcrumbs() {
		if (!this.myProfile) this.setBreadcrumb([this.item.DisplayName]);
	}

	detailsUpdated(event: UserProfile) {
		this.item = event;
		this.formMode = this.formModes.View;

		this.refreshBreadcrumbs();
	}

	editCancelled() {
		if (this.formMode == this.formModes.Create) {
			this.goBackToListPage();
		}
	}

	tabOnChange(event: any) {
		this.util.clearMessages();
		this.activeIndex = event.index;
		this.refreshData();
	}

	refreshData() {
		if (this.activeIndex >= 0) {
			const name = this.tabs.tabs[this.activeIndex].header;
			this.appContext.HasTableBasedData = false;

			switch (name) {
				case 'Details': {
					this.detail.refreshData();
					break;
				}
				case 'Activity': {
					this.auditHistoryList.refreshData();
					this.appContext.HasTableBasedData = true;
					break;
				}
				default:
					throw Error('Unhandled refresh for header ' + name);
			}
		}
	}

	showActivity(): boolean {
		return this.appContext.hasPermission(Constants.UserPermission.CommonActivityView);
	}
}
