import { LookupValue } from 'src/app/shared/models/lookups';
import { FormControl } from './form-control.model';
import { FormMode } from './form-mode.model';

export class FormItem {
	name: string;
	label: string;
	control: FormControl;
	editableModes: FormMode[];
	visibleModes: FormMode[];
	lookupValues: LookupValue[] = [];
}
