import { formatNumber } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { FORM_ITEM_DISPLAY_OPTION_LABEL, FormItemDisplayOption } from '../enums';

@Pipe({ name: 'formatNumber', pure: false })
export class FormatNumberPipe implements PipeTransform {
	transform(value: string | number, format: string, locale: string) {
		if (value === FORM_ITEM_DISPLAY_OPTION_LABEL[FormItemDisplayOption.MISSING_PERMISSION]) return value;

		let formattedNumber = '';
		if (value || value === 0) {
			value = Number(value);
			if (isNaN(value)) {
				throw new Error(`${value} is not a acceptable number`);
			}

			if (value || value === 0) {
				formattedNumber = formatNumber(value, locale, format);
			}
		}
		return formattedNumber;
	}
}
