import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Table } from 'primeng/table';
import { BusinessEntityDetails } from 'src/app/features/contact/shared/models';
import { Constants } from 'src/app/shared/constants/constants.constant';
import { EntityLicenceConstants } from 'src/app/shared/constants/entity-licence.constant';
import { BusinessEntityLicence } from 'src/app/shared/models/business-entities';
import { RegionSettings } from 'src/app/shared/models/settings';
import { EntityLicenceApiService } from 'src/app/shared/services';
import { BaseComponent } from 'src/app/core/components/base.component';
import { AppContextService, TooltipMessageService } from 'src/app/core/services';

@Component({
	selector: 'app-entity-licence-table',
	templateUrl: './entity-licence-table.component.html',
})
export class EntityLicenceTableComponent extends BaseComponent implements OnInit {
	@Input() businessEntityDetails: BusinessEntityDetails;

	@ViewChild('recordsTable') recordsTable: Table;
	selectedRecord: BusinessEntityLicence;
	showList = true;
	records: BusinessEntityLicence[] = [];
	refreshRequired = false;
	region: RegionSettings;

	constructor(
		appContext: AppContextService,
		private entityLicenceService: EntityLicenceApiService,
		private route: ActivatedRoute,
		private tooltipMessageService: TooltipMessageService
	) {
		super(appContext);
	}

	ngOnInit() {
		this.region = this.appContext.getRegion();
	}

	public refreshData() {
		if (this.businessEntityDetails.BusinessEntityId) {
			try {
				this.entityLicenceService.getBusinessEntityLicencesForBusinessEntity(this.businessEntityDetails.BusinessEntityId).subscribe(result => {
					this.records = result;
					this.showList = true;
					this.appContext.HasTableBasedData = true;
				});
			} catch (ex) {
				this.util.hideWait();
				console.error(ex);
			}
		}
	}

	addClick() {
		this.showList = false;
		this.appContext.HasTableBasedData = false;
		this.selectedRecord = new BusinessEntityLicence();
		this.selectedRecord.BusinessEntityId = this.businessEntityDetails.BusinessEntityId;
	}

	expandClick(item: BusinessEntityLicence) {
		this.selectedRecord = item;
		this.showList = false;
		this.appContext.HasTableBasedData = false;
	}

	editFinished() {
		this.refreshRequired = true;
	}

	detailsClosed() {
		this.showList = true;
		this.appContext.HasTableBasedData = true;
		if (this.refreshRequired) this.refreshData();
	}

	showAddRecord(): boolean {
		if (this.businessEntityDetails.DateAdded == undefined) return false;
		return this.businessEntityDetails?.ObjectAccess?.AllowAddLicence?.Hide != true;
	}

	allowAddRecord(): boolean {
		if (this.businessEntityDetails.DateAdded == undefined) return false;
		return this.businessEntityDetails?.ObjectAccess?.AllowEdit?.IsAllowed;
	}

	getEditTooltip(): string {
		if (this.businessEntityDetails.DateAdded != undefined) {
			return (
				this.tooltipMessageService.getTooltipMessage(
					'C_BUSINESS_ENTITY',
					'AllowEdit',
					this.businessEntityDetails?.ObjectAccess?.AllowEdit,
					this.businessEntityDetails
				) ?? EntityLicenceConstants.Tab_detail_AddLicence
			);
		}
	}
	getFixedLicenceHolderName(): string {
		if (
			this.businessEntityDetails.DateAdded != undefined &&
			this.businessEntityDetails.BusinessEntityRelationTypeCode == Constants.BusinessEntityRelationType.Employee
		)
			return this.businessEntityDetails.BusinessEntityName;

		return null;
	}
}
