export class MapMarker {
	MapMarkerId: string;
	MapId: string;

	Title: string | null;
	ColourCode: string;
	ColourName: string | null;
	MapMarkerStyleCode: string | null;
	MapMarkerStyleName: string | null;

	ParentRecordTypeCode: string | null;
	ParentRecordId: string | null;
	ParentRecordName: string | null;

	RecordTypeCode: string | null;
	RecordId: string | null;
	RecordName: string | null;

	Comments: string | null;

	OffsetX: number | null;
	OffsetY: number | null;

	MarkerSize: number | null;

	constructor(properties?: any) {
		if (properties) {
			for (const key in properties) {
				this[key] = properties[key];
			}
		}
	}
}
