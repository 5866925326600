import { LookupValue } from '.';

export class SubscriberLookupValue extends LookupValue {
	IsDemoSubscription: boolean;
	IsTestSubscription: boolean;
	IsDefaultSubscription: boolean;
	SubscriptionAccessTypeCode: string;
	StatusCode: string;
	StatusName: string;
}
