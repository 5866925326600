export class GlobalSearchRequest {
	IncludeServiceRequests?: boolean;
	IncludePurchaseOrders?: boolean;
	IncludeInvoices?: boolean;
	IncludeClients?: boolean;
	IncludeBusinessEntities?: boolean;

	AssetNumber: string;
	ClientId: string;
}
