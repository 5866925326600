import { Constants } from 'src/app/shared/constants/constants.constant';

export class NavigationConstants {
	static readonly MESSAGE_MISSINGPERMISSION: string = 'You do not have access to this page or resource.';

	static readonly RECORD_WO = {
		path: '/work-order/',
		permission: Constants.UserPermission.OrderView,
	};

	static readonly RECORD_QUOTE = {
		path: '/quote/',
		permission: Constants.UserPermission.QuoteView,
	};

	static readonly RECORD_SCOPE = {
		path: '/scope/',
		permission: Constants.UserPermission.ScopeView,
	};

	static readonly RECORD_SRQ = {
		path: '/service-request/',
		permission: Constants.UserPermission.ServiceRequestView,
	};

	static readonly RECORD_ASSET = {
		path: '/asset/',
		permission: Constants.UserPermission.AssetView,
	};

	static readonly RECORD_PROPERTY = {
		path: '/property/',
		permission: Constants.UserPermission.AssetView,
	};

	static readonly RECORD_CLIENT = {
		path: '/contact/client/',
		permission: Constants.UserPermission.ClientView,
	};

	static readonly RECORD_CONTRACTOR = {
		path: '/contact/contractor/',
		permission: Constants.UserPermission.BusinessEntityView,
	};

	static readonly RECORD_SUPPLIER = {
		path: '/contact/supplier/',
		permission: Constants.UserPermission.BusinessEntityView,
	};

	static readonly RECORD_EMPLOYEE = {
		path: '/contact/employee/',
		permission: Constants.UserPermission.BusinessEntityView,
	};
}
