import { HttpEvent, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ActionResult, LazyLoadArgs, PagedResponse } from 'src/app/shared/models/api';
import { Attachment, AttachmentFile } from 'src/app/shared/models/shared';
import { ApiService } from 'src/app/core/services';

@Injectable({
	providedIn: 'root',
})
export class AttachmentService {
	constructor(private apiService: ApiService) {}

	getPagedListAttachmentsForParent(
		searchData: LazyLoadArgs,
		parentReferenceTypeCode: string,
		parentReferenceId: string,
		includeRelated: boolean
	): Observable<PagedResponse<Attachment>> {
		if (!searchData.sortField) {
			searchData.sortField = 'DateAdded';
			searchData.sortDesc = true;
		}

		const httpParams: HttpParams = new HttpParams()
			.append('parentReferenceTypeCode', parentReferenceTypeCode)
			.append('parentReferenceId', parentReferenceId)
			.append('includeRelated', includeRelated)
			.append('includeFiles', true);

		return this.apiService.post('/Attachment/GetPagedListAttachmentsForParent', searchData, httpParams) as Observable<PagedResponse<Attachment>>;
	}

	getAttachmentsForReference(referenceTypeCode: string, referenceId: string): Observable<Attachment[]> {
		return this.apiService.get<Attachment[]>(`/Attachment/GetAttachmentsForReference/${referenceTypeCode}/${referenceId}`);
	}

	saveAttachment(attachment: Attachment, updateFilesMissingDataOnly = true): Observable<ActionResult> {
		const httpParams: HttpParams = new HttpParams().append('updateFilesMissingDataOnly', updateFilesMissingDataOnly);

		return this.apiService.post('/Attachment/SaveAttachment', attachment, httpParams);
	}

	uploadFile(fileId: string, files: any): Observable<ActionResult> {
		const fileDto = new AttachmentFile();
		fileDto.AttachmentFileId = fileId;
		return this.apiService.postFile('/Attachment/UploadFile', files, fileDto);
	}

	uploadFileWithProgress(fileId: string, files: any): Observable<HttpEvent<ActionResult>> {
		const fileDto = new AttachmentFile();
		fileDto.AttachmentFileId = fileId;
		return this.apiService.postFileWithProgress('/Attachment/UploadFile', files, fileDto);
	}

	disableAttachment(attachmentId: string, calledFromList = false): Observable<ActionResult> {
		return this.apiService.get<ActionResult>(`/Attachment/DisableAttachment/${attachmentId}/${calledFromList}`);
	}

	disableAttachmentFile(attachmentFileId: string): Observable<ActionResult> {
		return this.apiService.get<ActionResult>(`/Attachment/DisableAttachmentFile/${attachmentFileId}`);
	}

	getAttachmentFileData(attachmentFileId: string): Observable<any> {
		return this.apiService.getFile(`/Attachment/GetAttachmentFileData/${attachmentFileId}`);
	}

	getAttachment(attachmentId: string): Observable<Attachment> {
		return this.apiService.get<Attachment>(`/Attachment/GetAttachment/${attachmentId}`);
	}

	getAttachmentFile(attachmentFileId: string): Observable<AttachmentFile> {
		return this.apiService.get<AttachmentFile>(`/Attachment/GetAttachmentFile/${attachmentFileId}`);
	}
}
