import { LookupValue } from './lookup-value.model';

export class ContactLookupValue extends LookupValue {
	ContactTypeName: string;
	Name: string;
	PhoneNumber: string;
	MobileNumber: string;
	EmailAddress: string;
	AccessDetails: string;
	DateLastUpdated: Date;
}
