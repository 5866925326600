import { Directive } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';

@Directive({
	selector: '[validateNumber]',
	providers: [
		{
			provide: NG_VALIDATORS,
			useExisting: NumberValidatorDirective,
			multi: true,
		},
	],
})
export class NumberValidatorDirective implements Validator {
	validate(control: AbstractControl): ValidationErrors | null {
		if (control.value && control.value > 0) {
			return null;
		} else {
			return { number: 'Invalid Input.' };
		}
	}
}
