<div [ngClass]="appContext.BasePageCSSClass">
	<div class="confirm-dialog-title" data-testid="multiSelectTitle">
		<i [class]="getIcon()" class="p-confirm-dialog-icon"></i>
		<span class="p-confirm-dialog-message">{{ getTitle() }}</span>
	</div>
	<div class="p-pb-2 p-m-1">
		<p-listbox
			[options]="listItems"
			[(ngModel)]="selectedListItems"
			[metaKeySelection]="false"
			[checkbox]="true"
			[filter]="false"
			[multiple]="true"
			optionLabel="NameValue"
			styleClass="custom-multiselect-listbox">
			<ng-template let-listItem pTemplate="item">
				<div class="list-item">
					<div>{{ listItem.NameValue }}</div>
				</div>
			</ng-template>
		</p-listbox>
	</div>
	<app-dialog-footer
		dataTestIdComponentName="MultiSelectFooter"
		acceptButtonLabel="{{ acceptButtonLabel }}"
		acceptButtonIcon="{{ acceptButtonIcon }}"
		acceptButtonTooltip="{{ acceptButtonTooltip }}"
		rejectButtonLabel="{{ rejectButtonLabel }}"
		rejectButtonIcon="{{ rejectButtonIcon }}"
		rejectButtonTooltip="{{ rejectButtonTooltip }}"
		(closeDialog)="closeDialog()"
		(submitDialog)="submitDialog()"></app-dialog-footer>
</div>
