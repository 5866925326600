import { ViewChild } from '@angular/core';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { OverlayPanel } from 'primeng/overlaypanel';
import { FilterConfig } from 'src/app/shared/models/ui';
import { BaseComponent } from 'src/app/core/components/base.component';
import { AppContextService } from 'src/app/core/services';

@Component({
	selector: 'app-advanced-filter',
	templateUrl: './advanced-filter.component.html',
})
export class AdvancedFilterComponent extends BaseComponent {
	public isFilterAvailable = false;
	@Input() filterConfigs: FilterConfig[];
	@Output() filterUpdated = new EventEmitter<FilterConfig[]>();

	@ViewChild('filterOP') filterOp: OverlayPanel;

	constructor(appContext: AppContextService) {
		super(appContext);
	}

	clear() {
		this.filterConfigs.forEach(x => {
			if (!x.IsHidden) {
				x.Values = [];
				x.TextValue = null;
				x.DateValue = null;
				x.NumberValue = null;
				x.StartDate = null;
				x.EndDate = null;
				x.StartNumber = null;
				x.EndNumber = null;
				x.BoolValue = false;
			}
		});
	}

	advancedSearchUpdated() {
		this.filterOp.hide();
		this.filterUpdated.next(this.filterConfigs);
	}

	onHideFilter() {
		if (this.filterOp.overlayVisible) {
			this.filterUpdated.next(this.filterConfigs);
		}
	}
	toggle(event: any) {
		if (this.filterConfigs) {
			const visibleFilters = this.filterConfigs.filter(x => !x.IsHidden);
			if (visibleFilters.length !== 0) {
				this.filterOp.toggle(event);
			}
		}
	}
}
