import { Directive } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';

@Directive({
	selector: '[validateCannotBeZero]',
	providers: [
		{
			provide: NG_VALIDATORS,
			useExisting: CannotBeZeroValidatorDirective,
			multi: true,
		},
	],
})
export class CannotBeZeroValidatorDirective implements Validator {
	validate(control: AbstractControl): ValidationErrors | null {
		if (control.value == 0) {
			return { cannotBeZero: true };
		}

		return null;
	}
}
