import { Component, OnDestroy, OnInit } from '@angular/core';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { EventMessage, EventType, InteractionStatus } from '@azure/msal-browser';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { b2cPolicies, loginRequest } from 'src/app/auth-config';
import { AppContextService, NavigationService, SecurityService } from '../../services';

@Component({
	selector: 'app-auth',
	templateUrl: `./auth.component.html`,
})
export class AuthComponent implements OnInit, OnDestroy {
	private readonly _destroying$ = new Subject<void>();
	constructor(
		private msalService: MsalService,
		private msalBroadcastService: MsalBroadcastService,
		private securityService: SecurityService,
		private navigationService: NavigationService,
		private appContext: AppContextService
	) {}

	ngOnInit() {
		/**
		 * You can subscribe to MSAL events as shown below. For more info,
		 * visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/events.md
		 */
		this.msalBroadcastService.msalSubject$.pipe(filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_FAILURE)).subscribe((msg: EventMessage) => {
			const request: any = {
				scopes: loginRequest.scopes,
				authority: b2cPolicies.authorities.signIn.authority,
			};

			if (msg.error && msg.error.message) {
				// Check for forgot password error
				// Learn more about AAD error codes at https://docs.microsoft.com/en-us/azure/active-directory-b2c/error-codes
				if (msg.error.message.indexOf('AADB2C90118') > -1) {
					request.authority = b2cPolicies.authorities.passwReset.authority;
				} else if (msg.error.message.indexOf('AADB2C90091') === -1) {
					// Check policy is not cancelled by user
					request.extraQueryParameters = {
						error_message: msg.error.message,
					};
				}
			}
			this.msalService.loginRedirect(request);
		});

		this.msalBroadcastService.inProgress$
			.pipe(
				filter((status: InteractionStatus) => status === InteractionStatus.None),
				takeUntil(this._destroying$)
			)
			.subscribe(() => {
				const authService = this.msalService as any;
				if (authService.redirectHash) {
					const error = this.parseError(authService.redirectHash);
					if (error) {
						this.msalService.loginRedirect({
							scopes: loginRequest.scopes,
							authority: b2cPolicies.authorities.signIn.authority,
							extraQueryParameters: {
								error_message: error,
							},
						});
						return;
					}
				}

				if (this.isAuthenticated) {
					this.securityService.loginCurrentUser().subscribe(x => {
						if (x) {
							this.navigationService.navigateToDashboard();
						} else {
							this.navigationService.navigateToCurrentSubscriptions();
						}
					});
				} else {
					this.navigationService.navigateToDashboard();
				}
			});
	}

	get isAuthenticated() {
		return this.msalService.instance.getAllAccounts().length > 0;
	}

	parseError(response: string) {
		const matches = response.match(/error_description=([^&]*)/);
		return matches && matches.length > 0 ? matches[1] : null;
	}

	logoutAndRedirectUserToSubscription() {
		this.appContext.clearUser();
		const logoutRequest = { postLogoutRedirectUri: 'https://spydertech.net.au/spydertech-contact-us' };
		this.msalService.logoutRedirect(logoutRequest);
	}

	ngOnDestroy(): void {
		this._destroying$.next(undefined);
		this._destroying$.complete();
	}
}
