import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';

/**
 * Example
 * <span fontSizeAdjustment [defaultFontSize]="1.4" [containerHeight]='47'>Hello world</span>
 */
@Directive({
	selector: '[fontSizeAdjustment]',
})
export class FontSizeAdjustmentDirective implements AfterViewInit {
	@Input() defaultFontSize = 1;
	@Input() containerHeight = 47;

	constructor(private elRef: ElementRef) {}

	ngAfterViewInit() {
		const element = this.elRef.nativeElement;
		let fontSize = this.defaultFontSize;
		while (element.offsetHeight > this.containerHeight) {
			element.style.fontSize = fontSize + 'rem';
			fontSize -= 0.1;
		}
	}
}
