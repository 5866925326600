import { Directive, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator, ValidatorFn } from '@angular/forms';

@Directive({
	selector: '[validateDateFromTo]',
	providers: [
		{
			provide: NG_VALIDATORS,
			useExisting: DateFromToValidatorDirective,
			multi: true,
		},
	],
})
export class DateFromToValidatorDirective implements Validator {
	static calledByDateFromValidator = false;
	static calledByDateToValidator = false;

	@Input() isDateFrom: boolean;
	@Input() appDateFromToValidator: string;

	static validateToDate(dateFrom: string): ValidatorFn {
		return (control: AbstractControl): ValidationErrors | null => {
			if (control.value == null || control.value === '') {
				return null;
			}

			if (control.parent) {
				const dateFromControl = control.parent.get(dateFrom);
				if (dateFromControl.value == null || dateFromControl.value === '') {
					return null;
				}
				if (control.value < dateFromControl.value) {
					return { dateTo: 'date to is greater than date from' };
				}
				DateFromToValidatorDirective.calledByDateToValidator = true;
				if (!DateFromToValidatorDirective.calledByDateFromValidator) {
					dateFromControl.setValue(dateFromControl.value);
				} else {
					DateFromToValidatorDirective.calledByDateToValidator = false;
					DateFromToValidatorDirective.calledByDateFromValidator = false;
				}
				// dateFromControl.markAsDirty();
				// dateFromControl.markAsTouched();
			}
			return null;
		};
	}
	static validateFromDate(dateTo: string): ValidatorFn {
		return (control: AbstractControl): ValidationErrors | null => {
			if (control.value == null || control.value === '') {
				return null;
			}

			if (control.parent) {
				const dateToControl = control.parent.get(dateTo);
				if (dateToControl.value == null || dateToControl.value === '') {
					return null;
				}
				if (control.value > dateToControl.value) {
					return { dateTo: 'date to is greater than date from' };
				}
				DateFromToValidatorDirective.calledByDateFromValidator = true;
				if (!DateFromToValidatorDirective.calledByDateToValidator) {
					dateToControl.setValue(dateToControl.value);
				} else {
					DateFromToValidatorDirective.calledByDateToValidator = false;
					DateFromToValidatorDirective.calledByDateFromValidator = false;
				}
				// dateToControl.setValue(dateToControl.value);
				// dateToControl.markAsDirty();
				// dateToControl.markAsTouched();
			}
			return null;
		};
	}

	validate(control: AbstractControl): ValidationErrors | null {
		if (this.isDateFrom) {
			return DateFromToValidatorDirective.validateFromDate(this.appDateFromToValidator);
		} else {
			return DateFromToValidatorDirective.validateToDate(this.appDateFromToValidator);
		}
	}
}
