import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TargetDateCalculationDetail } from 'src/app/shared/models/details';
import { ApiService, UtilService } from 'src/app/core/services';

@Injectable({
	providedIn: 'root',
})
export class TargetDateService {
	constructor(private apiService: ApiService, private util: UtilService) {}

	calculateTargetDate(calc: TargetDateCalculationDetail, timezoneCode: string): Observable<Date> {
		const httpParams: HttpParams = new HttpParams().append('timezoneCode', timezoneCode);

		const apiCall = this.apiService.post('/TargetDate/CalculateTargetDate', calc, httpParams) as Observable<Date>;
		return apiCall.pipe(
			map(result => {
				const localTime = this.util.fixTimeZones(result, timezoneCode, true);
				return localTime;
			})
		);
	}

	calculateDefaultTargetDateForPurchaseOrder(workOrderId: string, purchaseOrderPriorityCode: string, timezoneCode: string): Observable<Date> {
		const apiCall = this.apiService.get<Date>(`/TargetDate/CalculateDefaultTargetDateForPurchaseOrder/${workOrderId}/${purchaseOrderPriorityCode}`);
		return apiCall.pipe(
			map(result => {
				const localTime = this.util.fixTimeZones(result, timezoneCode, true);
				return localTime;
			})
		);
	}
}
