import { Injectable } from '@angular/core';
import { FilterMetadata, TableState } from 'primeng/api';
import { BehaviorSubject } from 'rxjs';
import { AppContextService, UtilService } from 'src/app/core/services';
import { Column } from '../models/shared/column';

@Injectable({
	providedIn: 'root',
})
export abstract class DynamicColumnService {
	public util: UtilService;

	columns: Column[] = [];

	columnsSelectionStorageKey: string;
	columnsOrderStorageKey: string;
	//columnsResizeStorageKey: string;
	listStorageKey: string;

	constructor(public appContext: AppContextService) {
		this.appContext = appContext;
		this.util = appContext.util;
	}

	private selectedColumnsSubject = new BehaviorSubject<Column[]>(this.columns);
	selectedColumns$ = this.selectedColumnsSubject.asObservable();

	getColumnsSelectionStorageKey(): string {
		return this.columnsSelectionStorageKey;
	}

	getColumnsOrderStorageKey(): string {
		return this.columnsOrderStorageKey;
	}

	// getColumnsResizeStorageKey(): string {
	// 	return this.columnsResizeStorageKey;
	// }

	getColumns(): Column[] {
		return this.columns;
	}

	getListStorageKey(): string {
		return this.listStorageKey;
	}

	getColumnsSortedByHeader() {
		if (this.columns) return this.columns.sort((a, b) => a.header.localeCompare(b.header));
	}

	getColumnsSortedByOrder() {
		if (this.columns) return this.columns.sort((n1, n2) => n2.order - n1.order);
	}

	setSelectedColumns(columns: Column[]): void {
		const orderColumns = this.hasStoredColumnOrder() ? columns : columns.sort((n1, n2) => n1.order - n2.order);
		this.selectedColumnsSubject.next(orderColumns);
	}

	saveSelectedColumnsToStorage(selectedColumns: Column[]) {
		const preferredColumnsSelection = selectedColumns.map(column => column.field);
		this.appContext.setSavedValueAsJson(this.getColumnsSelectionStorageKey(), JSON.stringify(preferredColumnsSelection));
	}

	loadSelectedColumnsFromStorage(defaultColumns: Column[]) {
		let selectedColumns: Column[] = [];
		const preferredColumnsSelection = JSON.parse(this.appContext.getSavedValueFromJson(this.getColumnsSelectionStorageKey()));
		if (!preferredColumnsSelection || preferredColumnsSelection.length === 0) {
			selectedColumns = [...defaultColumns];
			this.saveSelectedColumnsToStorage(selectedColumns);
		} else {
			selectedColumns = defaultColumns.filter(column => preferredColumnsSelection.includes(column.field));
		}

		const preferredColumnsOrder = this.loadColumnOrderFromStorage();

		if (preferredColumnsOrder && preferredColumnsOrder.length !== 0) {
			selectedColumns = preferredColumnsOrder.map(x => selectedColumns.find(col => col.field === x)).filter(col => col !== undefined);
		}

		return selectedColumns;
	}

	saveColumnOrderToStorage(event) {
		if (event && event.columns) {
			const orderedColumns = event.columns.map(col => col.field);
			this.appContext.setSavedValueAsJson(this.getColumnsOrderStorageKey(), JSON.stringify(orderedColumns));

			this.refreshTable();
		}
	}

	refreshTable() {
		const selectedColumns = this.loadSelectedColumnsFromStorage(this.columns);
		this.setSelectedColumns(selectedColumns);
	}

	hasStoredColumnOrder(): boolean {
		const preferredColumnsOrder = this.loadColumnOrderFromStorage();
		return preferredColumnsOrder && preferredColumnsOrder.length !== 0;
	}

	loadColumnOrderFromStorage(): string[] {
		const preferredColumnsOrder = JSON.parse(this.appContext.getSavedValueFromJson(this.getColumnsOrderStorageKey()));
		return preferredColumnsOrder ? preferredColumnsOrder : [];
	}

	/* For this approach need to define default widths also for reset*/
	/*saveColumnResizeToStorage(event) {
		const resizeColumns: Column[] = [];
		const field = event.element.getAttribute('ng-reflect-field');
		const resizeWidth = event.delta;
		const column = this.columns.find(col => col.field === field);
		if (column) {
			column.resizeWidth = resizeWidth + 'px';
			resizeColumns.push(column);
		}
		//this.setLocalStorage(this.getColumnsResizeStorageKey(), JSON.stringify(resizeColumns));
	}*/

	/*loadColumnResizeFromStorage(): { field: string; width: number }[] {
		const preferredColumnsResize = JSON.parse(this.getLocalStorage(this.getColumnsResizeStorageKey()));
		return preferredColumnsResize ? preferredColumnsResize : [];
	}*/

	resetLocalStorage() {
		this.appContext.clearSavedValue(this.getColumnsSelectionStorageKey());
		this.appContext.clearSavedValue(this.getColumnsOrderStorageKey());
		//this.removeLocalStorage(this.getColumnsResizeStorageKey());
	}

	getColumnWithActiveFilters(): Column[] {
		let filters: { [key: string]: FilterMetadata | FilterMetadata[] } = {};
		let sortField: string;

		const columnsWithActiveFilters: Column[] = [];
		const tableState = this.getTableState();
		if (tableState) {
			filters = tableState.filters;
			sortField = tableState.sortField;
			if (filters) {
				for (const [key, filter] of Object.entries(filters)) {
					if (filter && filter[0] != null && filter[0].value != null) {
						const column = this.columns.find(x => x.filterField === key);
						if (column) {
							columnsWithActiveFilters.push(column);
						}
					}
				}
			}

			if (sortField && sortField.length > 0) {
				let addedInActiveFilter: boolean;
				const sortFieldColumn = this.columns.find(x => x.sortField === sortField);
				if (columnsWithActiveFilters.length > 0) {
					addedInActiveFilter = columnsWithActiveFilters.some(x => x.sortField === sortField);
				}
				if (!addedInActiveFilter && sortFieldColumn) {
					columnsWithActiveFilters.push(sortFieldColumn);
				}
			}
		}
		return columnsWithActiveFilters;
	}

	getTableState(): TableState {
		let tableState: TableState = null;
		const savedState = this.appContext.getSavedValue(this.getListStorageKey()) || '{}';
		if (savedState) {
			tableState = JSON.parse(savedState);
		}
		return tableState;
	}
}
