import { Injectable } from '@angular/core';
import { Constants } from 'src/app/shared/constants/constants.constant';
import { InvoiceLookupValue } from 'src/app/shared/models/lookups';
import { AppContextService } from 'src/app/core/services';
import { InvoiceFieldNames } from '../enums';
import { InvoiceableOrderItem, NewInvoice } from '../models';

@Injectable({
	providedIn: 'root',
})
export class InvoiceAccessService {
	visibleFieldForInvoiceCreate(invoice: NewInvoice, fieldName: InvoiceFieldNames, selectedParentInvoice?: InvoiceLookupValue) {
		switch (fieldName) {
			case InvoiceFieldNames.company:
				return true;
			case InvoiceFieldNames.category:
				return true;
			case InvoiceFieldNames.taxCode:
				if (invoice.InvoiceCreationRuleCode == Constants.InvoiceCreationRule.AdhocAmount) return true;
				else return false;
			case InvoiceFieldNames.taxRate:
				if (invoice.InvoiceCreationRuleCode == Constants.InvoiceCreationRule.AdhocAmount) return true;
				else return false;
			case InvoiceFieldNames.invoiceType:
				if (
					invoice.InvoiceCreationRuleCode == Constants.InvoiceCreationRule.AdhocAmount ||
					invoice.InvoiceCreationRuleCode == Constants.InvoiceCreationRule.SingleAmount
				)
					return true;
				else return false;
			case InvoiceFieldNames.previousTotal:
				if (
					invoice.InvoiceCreationRuleCode == Constants.InvoiceCreationRule.SingleAmount ||
					((invoice.ReferenceTypeCode == Constants.ReferenceType.WorkOrder || invoice.ReferenceTypeCode == Constants.ReferenceType.PurchaseOrder) &&
						invoice.InvoiceCreationRuleCode == Constants.InvoiceCreationRule.SingleAmount) ||
					invoice.InvoiceCreationRuleCode == Constants.InvoiceCreationRule.RemainingAmount ||
					invoice.InvoiceCreationRuleCode == Constants.InvoiceCreationRule.RemainingItems ||
					invoice.InvoiceCreationRuleCode == Constants.InvoiceCreationRule.Items
				)
					return true;
				else return false;

			case InvoiceFieldNames.invoicePercentage:
			case InvoiceFieldNames.remainingPercentage:
				if (
					((invoice.ReferenceTypeCode == Constants.ReferenceType.WorkOrder || invoice.ReferenceTypeCode == Constants.ReferenceType.PurchaseOrder) &&
						invoice.InvoiceCreationRuleCode == Constants.InvoiceCreationRule.SingleAmount) ||
					invoice.InvoiceCreationRuleCode == Constants.InvoiceCreationRule.RemainingAmount ||
					invoice.InvoiceCreationRuleCode == Constants.InvoiceCreationRule.RemainingItems ||
					invoice.InvoiceCreationRuleCode == Constants.InvoiceCreationRule.Items
				) {
					return true;
				} else return false;

			case InvoiceFieldNames.orderAmount:
				if (
					((invoice.ReferenceTypeCode == Constants.ReferenceType.WorkOrder || invoice.ReferenceTypeCode == Constants.ReferenceType.PurchaseOrder) &&
						invoice.InvoiceCreationRuleCode == Constants.InvoiceCreationRule.SingleAmount) ||
					invoice.InvoiceCreationRuleCode == Constants.InvoiceCreationRule.RemainingAmount ||
					invoice.InvoiceCreationRuleCode == Constants.InvoiceCreationRule.RemainingItems ||
					invoice.InvoiceCreationRuleCode == Constants.InvoiceCreationRule.Items ||
					invoice.InvoiceCreationRuleCode == Constants.InvoiceCreationRule.FullPayment
				)
					return true;
				else return false;
			case InvoiceFieldNames.remainingTotal:
				if (
					((invoice.ReferenceTypeCode == Constants.ReferenceType.WorkOrder || invoice.ReferenceTypeCode == Constants.ReferenceType.PurchaseOrder) &&
						invoice.InvoiceCreationRuleCode == Constants.InvoiceCreationRule.SingleAmount) ||
					invoice.InvoiceCreationRuleCode == Constants.InvoiceCreationRule.RemainingAmount ||
					invoice.InvoiceCreationRuleCode == Constants.InvoiceCreationRule.RemainingItems ||
					invoice.InvoiceCreationRuleCode == Constants.InvoiceCreationRule.Items ||
					invoice.InvoiceCreationRuleCode == Constants.InvoiceCreationRule.FullPayment
				) {
					return true;
				} else return false;

			case InvoiceFieldNames.remainingCredit:
				if (invoice.InvoiceCreationRuleCode == Constants.InvoiceCreationRule.CreditNote) {
					return true;
				} else return false;

			case InvoiceFieldNames.parentInvoice:
				if (invoice.InvoiceCreationRuleCode == Constants.InvoiceCreationRule.CreditNote) return true;
				else return false;
			case InvoiceFieldNames.itemsTab:
				if (
					invoice.InvoiceCreationRuleCode == Constants.InvoiceCreationRule.Items ||
					invoice.InvoiceCreationRuleCode == Constants.InvoiceCreationRule.RemainingItems ||
					(invoice.InvoiceCreationRuleCode == Constants.InvoiceCreationRule.CreditNote &&
						(invoice.ReferenceTypeCode == Constants.ReferenceType.WorkOrder || invoice.ReferenceTypeCode == Constants.ReferenceType.PurchaseOrder) &&
						(selectedParentInvoice?.InvoiceSubtypeCode == Constants.InvoiceCreationRule.Items ||
							selectedParentInvoice?.InvoiceSubtypeCode == Constants.InvoiceCreationRule.RemainingItems)) ||
					(invoice.InvoiceCreationRuleCode == Constants.InvoiceCreationRule.FullPayment &&
						(invoice.ReferenceTypeCode == Constants.ReferenceType.WorkOrder || invoice.ReferenceTypeCode == Constants.ReferenceType.PurchaseOrder))
				)
					return true;
				else return false;
			case InvoiceFieldNames.relatedTab:
				if (!invoice.InvoiceCreationRuleCode || invoice.InvoiceCreationRuleCode == Constants.InvoiceCreationRule.AdhocAmount) return false;
				else return true;
			case InvoiceFieldNames.targetDate:
				if (invoice.InvoiceCreationRuleCode == Constants.InvoiceCreationRule.CreditNote) return false;
				else return true;
			case InvoiceFieldNames.propertyAddress:
				if (invoice.InvoiceCreationRuleCode == Constants.InvoiceCreationRule.CreditNote) return true;
				else return false;
			default:
				return false;
		}
	}

	readOnlyFieldForInvoiceCreate(
		invoice: NewInvoice,
		appContext: AppContextService,
		fieldName: InvoiceFieldNames,
		isFixedParentInvoice: boolean,
		selectedParentInvoice?: InvoiceLookupValue,
		selectedItems?: InvoiceableOrderItem[]
	) {
		switch (fieldName) {
			case InvoiceFieldNames.contact:
				if (!invoice.AllowContactChanges || (invoice.InvoiceCreationRuleCode == Constants.InvoiceCreationRule.CreditNote && selectedParentInvoice))
					return false;
				else return true;
			case InvoiceFieldNames.company:
				if (invoice.InvoiceCreationRuleCode == Constants.InvoiceCreationRule.AdhocAmount) return true;
				else return false;
			case InvoiceFieldNames.category:
				return true;
			case InvoiceFieldNames.taxCode:
				if (invoice.AllowTaxCodeChanges == true && invoice.InvoiceCreationRuleCode !== Constants.InvoiceCreationRule.CreditNote) return true;
				else return false;
			case InvoiceFieldNames.taxRate:
				if (invoice.AllowTaxCodeChanges == true && invoice.InvoiceCreationRuleCode !== Constants.InvoiceCreationRule.CreditNote) return true;
				else return false;
			case InvoiceFieldNames.invoiceType:
				if (isFixedParentInvoice) return false;
				else return true;
			case InvoiceFieldNames.invoiceTotal:
				if (
					invoice.InvoiceCreationRuleCode == Constants.InvoiceCreationRule.AdhocAmount ||
					invoice.InvoiceCreationRuleCode == Constants.InvoiceCreationRule.SingleAmount ||
					(invoice.InvoiceCreationRuleCode == Constants.InvoiceCreationRule.CreditNote && invoice.ParentInvoiceId && !getItemSelected(selectedItems))
				)
					return true;
				else return false;
			case InvoiceFieldNames.invoicePercentage:
				if (
					(invoice.ReferenceTypeCode == Constants.ReferenceType.WorkOrder || invoice.ReferenceTypeCode == Constants.ReferenceType.PurchaseOrder) &&
					invoice.InvoiceCreationRuleCode == Constants.InvoiceCreationRule.SingleAmount
				) {
					const amt =
						(invoice.ReferenceTypeCode == Constants.ReferenceType.PurchaseOrder && invoice.IsReceivable) ||
						(invoice.ReferenceTypeCode == Constants.ReferenceType.WorkOrder && !invoice.IsReceivable)
							? -1 * invoice.OrderAmountInclTax
							: invoice.OrderAmountInclTax;
					return amt >= 0 ? true : false;
				} else return false;
			case InvoiceFieldNames.previousTotal:
				return false;
			case InvoiceFieldNames.remainingPercentage:
				if (
					(invoice.ReferenceTypeCode == Constants.ReferenceType.ServiceRequest ||
						invoice.ReferenceTypeCode == Constants.ReferenceType.Scope ||
						invoice.ReferenceTypeCode == Constants.ReferenceType.QuoteRequest) &&
					invoice.InvoiceCreationRuleCode == Constants.InvoiceCreationRule.CreditNote
				)
					return true;
				else return false;
			case InvoiceFieldNames.remainingTotal:
				if (invoice.InvoiceCreationRuleCode == Constants.InvoiceCreationRule.CreditNote) return true;
				return false;

			case InvoiceFieldNames.remainingCredit:
				if (invoice.InvoiceCreationRuleCode == Constants.InvoiceCreationRule.CreditNote) return false;
				return true;

			case InvoiceFieldNames.orderAmount:
				return false;
			case InvoiceFieldNames.parentInvoice:
				if (isFixedParentInvoice) return false;
				if (invoice.InvoiceCreationRuleCode == Constants.InvoiceCreationRule.CreditNote) return true;
				else return false;

			default:
				return false;
		}
	}
}

function getItemSelected(selectedItems: InvoiceableOrderItem[]) {
	if (selectedItems?.length > 0) return true;
	else return false;
}
