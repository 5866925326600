export class FinancialBillingSettings {
	BusinessRegistrationNumber: string;

	BankAccountName: string;
	BankAccountDetails: BankAccountDetailsValue[];

	BillingName: string;
	BillingAddress: string;
	BillingSuburb: string;
	BillingState: string;
	BillingPostcode: string;
	BillingCountry: string;

	PleaseNoteMessage: string;
	CreditAdviceMessage: string;
	constructor(properties?: any) {
		if (properties) {
			for (const key in properties) {
				this[key] = properties[key];
			}
		}
	}
}

export class BankAccountDetailsValue {
	Id: string; // Required only for UI purpose
	Header: string;
	Value: string;

	constructor(properties?: any) {
		if (properties) {
			for (const key in properties) {
				this[key] = properties[key];
			}
		}
	}
}
