import { Injectable } from '@angular/core';
import { LazyLoadArgs } from 'src/app/shared/models/api';

@Injectable()
export class Globals {
	//ViewTitle: string;
	//BasePageCSSClass: string;
	listViewFilters: { id: string; componentGuid: string; listViewPath: string; filter: LazyLoadArgs }[] = [];

	getListViewFilter(componentGuid: string): LazyLoadArgs {
		const suffix = location.pathname.substring(location.pathname.lastIndexOf('/') + 1, location.pathname.length);
		let id = '';
		if (suffix.indexOf('-') >= 0 || suffix.toLowerCase() === 'new') {
			id = suffix;
		}
		const currentPath = location.pathname;
		const currentFilter = this.listViewFilters.find(x => {
			return x.listViewPath === currentPath && x.id === id && (x.componentGuid === componentGuid || id === '');
		});
		return currentFilter ? currentFilter.filter : new LazyLoadArgs();
	}

	saveListViewFilter(componentGuid: string, filter: LazyLoadArgs) {
		const path = location.pathname.substring(0, location.pathname.lastIndexOf('/'));
		const suffix = location.pathname.substring(location.pathname.lastIndexOf('/') + 1, location.pathname.length);
		let id = '';
		if (suffix.indexOf('-') >= 0 || suffix.toLowerCase() === 'new') {
			id = suffix;
		}
		this.listViewFilters = this.listViewFilters.filter(
			x =>
				x.listViewPath.substring(0, x.listViewPath.lastIndexOf('/')) !== path ||
				(x.listViewPath.substring(0, x.listViewPath.lastIndexOf('/')) === path && x.id === id)
		);
		const currentPath = location.pathname;
		let currentFilter = this.listViewFilters.find(x => {
			return x.listViewPath === currentPath && x.id === id && (x.componentGuid === componentGuid || id === '');
		});
		if (!currentFilter) {
			currentFilter = {
				id: id,
				componentGuid: componentGuid,
				listViewPath: currentPath,
				filter: filter,
			};
			this.listViewFilters.push(currentFilter);
		} else {
			currentFilter.filter = filter;
		}
	}
}
