import { Component, Input, ViewEncapsulation } from '@angular/core';
import { AbstractControl, UntypedFormControl } from '@angular/forms';
import { FormControl as FrmCntl } from 'src/app/shared/models/forms';
import { LookupValue } from '../../models/lookups';

@Component({
	selector: 'app-custom-chips-multiselect',
	templateUrl: './custom-chips-multiselect.component.html',
	styleUrls: ['./custom-chips-multiselect.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class ChipsMultiselectComponent {
	@Input() control: AbstractControl = new UntypedFormControl();
	@Input() label: string;
	@Input() lookupValues: LookupValue[] = [];
	formControls = FrmCntl;
	selectedItems: string[] = [];

	showMultiselectOptions = false;
	isRemoveIconClicked = false;
	isAllSelected = false;

	get filteredOptions(): LookupValue[] {
		const multiselectSearch = document.getElementById('multiselect-search') as HTMLInputElement;
		return this.lookupValues.filter(option => option.NameValue.toLowerCase().includes(multiselectSearch.value.toLowerCase()));
	}

	get allSelected(): boolean {
		return this.filteredOptions.length > 0 && this.filteredOptions.every(option => this.selectedItems.includes(option.NameValue));
	}

	removeItem(item: any) {
		if (this.showMultiselectOptions) {
			this.isRemoveIconClicked = true;
		}

		if (this.control.value && this.control.value.length > 0) {
			this.selectedItems = [];
			this.selectedItems = this.control.value;
		}
		if (this.selectedItems.includes(item.value)) {
			this.selectedItems = this.selectedItems.filter(option => option !== item.value);
		}
		this.control.patchValue(this.selectedItems);
	}

	clearSearch() {
		const multiselectSearch = document.getElementById('multiselect-search') as HTMLInputElement;
		if (multiselectSearch) {
			multiselectSearch.value = '';
		}
	}

	toggleMultiselectDropdown() {
		const multiselectSearch = document.getElementById('multiselect-search') as HTMLInputElement;
		if (this.isRemoveIconClicked && this.showMultiselectOptions) {
			this.showMultiselectOptions = true;
			this.isRemoveIconClicked = false;
		} else {
			this.showMultiselectOptions = !this.showMultiselectOptions;
		}

		if (multiselectSearch) {
			multiselectSearch.value = '';
		}
	}

	toggleSelectAll() {
		if (this.control.value && this.control.value.length > 0) {
			this.selectedItems = [];
			this.selectedItems = this.control.value;
		}
		this.isAllSelected = !this.isAllSelected;
		if (this.isAllSelected) {
			this.filteredOptions.forEach(option => {
				if (!this.selectedItems.includes(option.NameValue)) {
					this.selectedItems.push(option.NameValue);
				}
			});
		} else {
			this.filteredOptions.forEach(option => {
				this.selectedItems = this.selectedItems.filter(item => item !== option.NameValue);
			});
		}
		this.control.patchValue(this.selectedItems);
	}

	toggleItemSelection(option: LookupValue) {
		if (this.control.value && this.control.value.length > 0) {
			this.selectedItems = [];
			this.selectedItems = this.control.value;
		}
		if (this.selectedItems.includes(option.NameValue)) {
			this.selectedItems = this.selectedItems.filter(item => item !== option.NameValue);
		} else {
			this.selectedItems.push(option.NameValue);
		}
		this.control.patchValue(this.selectedItems);
	}
}
