<p-toolbar>
	<ng-template pTemplate="left"></ng-template>
	<ng-template pTemplate="right">
		<div class="button-with-tooltip-holder" *ngIf="showDelete() && [formModes.View].includes(formMode)" pTooltip="Delete Note" tooltipPosition="bottom">
			<p-button icon="pi pi-trash" (onClick)="deleteItem()" label="Delete" data-testid="notesDetailDeleteButton"> </p-button>
		</div>
		<div class="button-with-tooltip-holder" *ngIf="showEdit() && [formModes.View].includes(formMode)" pTooltip="Edit Note" tooltipPosition="bottom">
			<p-button label="Edit" (onClick)="editItem()" icon="pi pi-pencil" data-testid="notesDetailEditButton"> </p-button>
		</div>
		<div class="button-with-tooltip-holder" *ngIf="[formModes.Edit, formModes.Create].includes(formMode)" pTooltip="Save Note" tooltipPosition="bottom">
			<p-button label="Save" (onClick)="saveItem()" icon="pi pi-save" data-testid="notesDetailSaveButton"> </p-button>
		</div>
		<div
			class="button-with-tooltip-holder"
			*ngIf="[formModes.Edit, formModes.Create].includes(formMode) && openedInEdit == false"
			pTooltip="Cancel Edit"
			tooltipPosition="bottom">
			<p-button label="Cancel" (onClick)="cancelItemEdit()" icon="pi pi-ban"> </p-button>
		</div>
		<div
			class="button-with-tooltip-holder"
			*ngIf="[formModes.Create].includes(formMode) || openedInEdit"
			pTooltip="Cancel Edit and Back To Notes List"
			tooltipPosition="bottom">
			<p-button label="Cancel" (onClick)="backToList()" icon="pi pi-ban"> </p-button>
		</div>
		<div
			class="button-with-tooltip-holder"
			*ngIf="[formModes.View].includes(formMode) && openedInEdit == false"
			pTooltip="Back To Notes List"
			tooltipPosition="bottom">
			<p-button label="Back" (onClick)="backToList()" icon="pi pi-arrow-left" data-testid="notesDetailBackToNoteListButton"> </p-button>
		</div>
	</ng-template>
</p-toolbar>
<form [formGroup]="noteDetailForm">
	<div class="p-grid p-formgrid p-m-0 p-p-0">
		<div class="p-col-3 p-md-0 form-element">
			<app-form-item
				[visible]="[formModes.View, formModes.Edit, formModes.Create].includes(formMode)"
				[editable]="[formModes.Edit, formModes.Create].includes(formMode)"
				[controlType]="formControls.Autocomplete"
				[control]="noteDetailForm.get('noteCategory')"
				[lookupValues]="noteCategories"
				label="Category">
			</app-form-item>
		</div>
		<div class="p-col-3 p-md-0 form-element">
			<app-form-item
				[visible]="[formModes.View, formModes.Edit, formModes.Create].includes(formMode)"
				[editable]="[formModes.Edit, formModes.Create].includes(formMode)"
				[controlType]="formControls.Autocomplete"
				[control]="noteDetailForm.get('noteAlertType')"
				[lookupValues]="noteAlertTypes"
				label="Alert Type">
			</app-form-item>
		</div>
		<div class="p-col-3 p-md-0 form-element"></div>
		<div class="p-col-3 p-md-0 form-element"></div>

		<div class="p-col-6 p-md-0 form-element">
			<app-form-item
				[visible]="[formModes.View, formModes.Edit, formModes.Create].includes(formMode)"
				[editable]="[formModes.Edit, formModes.Create].includes(formMode)"
				[controlType]="formControls.Textarea"
				[control]="noteDetailForm.get('comments')"
				label="Comments"
				data-testid="notesDetailComments">
			</app-form-item>
		</div>
		<div class="p-col-3 p-md-0 form-element"></div>
		<div class="p-col-3 p-md-0 form-element"></div>

		<div class="p-col-3 p-md-0 form-element">
			<app-form-item
				[visible]="[formModes.View, formModes.Edit].includes(formMode)"
				[editable]="false"
				[controlType]="formControls.Textbox"
				[control]="noteDetailForm.get('addedBy')"
				[iconAfterTextboxReadOnly]="getUserRelationTypeIcon(item?.AddedByExternalUserRelationTypeCode)"
				[iconAfterTextboxTooltip]="getUserRelationTypeTooltip(item?.AddedByExternalUserRelationTypeCode)"
				label="Created by">
			</app-form-item>
		</div>
		<div class="p-col-3 p-md-0 form-element">
			<app-form-item
				[visible]="[formModes.View, formModes.Edit].includes(formMode)"
				[editable]="false"
				[controlType]="formControls.Textbox"
				[control]="noteDetailForm.get('updatedBy')"
				[iconAfterTextboxReadOnly]="getUserRelationTypeIcon(item?.UpdatedByExternalUserRelationTypeCode)"
				[iconAfterTextboxTooltip]="getUserRelationTypeTooltip(item?.UpdatedByExternalUserRelationTypeCode)"
				label="Last Updated by">
			</app-form-item>
		</div>
		<div class="p-col-3 p-md-0 form-element"></div>
		<div class="p-col-3 p-md-0 form-element"></div>

		<div class="p-col-3 p-md-0 form-element">
			<app-form-item
				[visible]="[formModes.View, formModes.Edit].includes(formMode)"
				[editable]="false"
				[controlType]="formControls.Calendar"
				[control]="noteDetailForm.get('dateAdded')"
				label="Created">
			</app-form-item>
		</div>
		<div class="p-col-3 p-md-0 form-element">
			<app-form-item
				[visible]="[formModes.View, formModes.Edit].includes(formMode)"
				[editable]="false"
				[controlType]="formControls.Calendar"
				[control]="noteDetailForm.get('dateUpdated')"
				label="Last Updated">
			</app-form-item>
		</div>
		<div class="p-col-3 p-md-0 form-element"></div>
		<div class="p-col-3 p-md-0 form-element"></div>
	</div>
</form>
