import { HttpClientModule } from '@angular/common/http';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { LOCALE_ID } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
	MSAL_GUARD_CONFIG,
	MSAL_INSTANCE,
	MSAL_INTERCEPTOR_CONFIG,
	MsalBroadcastService,
	MsalGuard,
	MsalGuardConfiguration,
	MsalInterceptor,
	MsalInterceptorConfiguration,
	MsalModule,
	MsalRedirectComponent,
	MsalService,
} from '@azure/msal-angular';
import { IPublicClientApplication, InteractionType, PublicClientApplication } from '@azure/msal-browser';
import { QRCodeModule } from 'angularx-qrcode';
import { CookieService } from 'ngx-cookie-service';
import { MarkdownModule } from 'ngx-markdown';
import { MessageService } from 'primeng/api';
import { ConfirmationService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { loginRequest, msalConfig, protectedResources } from './auth-config';
import { AuthComponent } from './core/components/auth/auth.component';
import { PRIMENG_ROOT_MODULE } from './core/constants/primeng-root-import-modules.constant';
import { CoreModule } from './core/core.module';
import { RequestInterceptor } from './core/interceptors';
import { ApiService, AppContextService, CacheService, LocaleService, NavigationService, SignalRService, UtilService } from './core/services';
import { AccessDeniedComponent } from './features/access-denied/access-denied.component';
import { Globals } from './globals';
import { AppFooterComponent } from './layout/footer/app.footer.component';
import { AppBreadcrumbComponent } from './layout/header/breadcrumb/app.breadcrumb.component';
import { AppBreadcrumbService } from './layout/header/breadcrumb/app.breadcrumb.service';
import { AppTopBarComponent } from './layout/header/toolbar/app.topbar.component';
import { AppMainComponent } from './layout/main/app.main.component';
import { AppMenuComponent } from './layout/sidebar/app.menu.component';
import { MenuService } from './layout/sidebar/app.menu.service';
import { SubMenuComponent } from './layout/sub-menu/sub-menu.component';
import { SharedModule } from './shared/shared.module';

/**
 * Here we pass the configuration parameters to create an MSAL instance.
 * For more info, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/configuration.md
 */
export function MSALInstanceFactory(): IPublicClientApplication {
	return new PublicClientApplication(msalConfig);
}

/**
 * MSAL Angular will automatically retrieve tokens for resources
 * added to protectedResourceMap. For more info, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/initialization.md#get-tokens-for-web-api-calls
 */
export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
	const protectedResourceMap = new Map<string, Array<string>>();

	protectedResourceMap.set(protectedResources.spyderFlowApi.endpoint, protectedResources.spyderFlowApi.scopes);

	return {
		interactionType: InteractionType.Redirect,
		protectedResourceMap,
	};
}

/**
 * Set your default interaction type for MSALGuard here. If you have any
 * additional scopes you want the user to consent upon login, add them here as well.
 */
export function MSALGuardConfigFactory(): MsalGuardConfiguration {
	return {
		interactionType: InteractionType.Redirect,
		authRequest: loginRequest,
	};
}

@NgModule({
	imports: [
		BrowserModule,
		FormsModule,
		ReactiveFormsModule,
		...PRIMENG_ROOT_MODULE,
		AppRoutingModule,
		HttpClientModule,
		BrowserAnimationsModule,
		CoreModule,
		SharedModule,
		MsalModule,
		MarkdownModule.forRoot(),
		QRCodeModule,
	],
	declarations: [
		AuthComponent,
		AppComponent,
		SubMenuComponent,
		AppMainComponent,
		AppMenuComponent,
		AppBreadcrumbComponent,
		AppTopBarComponent,
		AppFooterComponent,
		AccessDeniedComponent,
	],
	providers: [
		AppBreadcrumbService,
		MenuService,
		AppContextService,
		Globals,
		ApiService,
		LocaleService,
		SignalRService,
		CacheService,
		UtilService,
		MessageService,
		CookieService,
		ConfirmationService,
		DialogService,
		NavigationService,
		{
			provide: HTTP_INTERCEPTORS,
			useClass: RequestInterceptor,
			multi: true,
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: MsalInterceptor,
			multi: true,
		},
		{
			provide: MSAL_INSTANCE,
			useFactory: MSALInstanceFactory,
		},
		{
			provide: MSAL_GUARD_CONFIG,
			useFactory: MSALGuardConfigFactory,
		},
		{
			provide: MSAL_INTERCEPTOR_CONFIG,
			useFactory: MSALInterceptorConfigFactory,
		},
		MsalService,
		MsalGuard,
		MsalBroadcastService,
		{
			provide: LOCALE_ID,
			useFactory: (localeService: LocaleService) => {
				localeService.registerCulture();
			},
			deps: [LocaleService],
		},
	],
	bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule {}
