import { AbstractControl, ValidatorFn } from '@angular/forms';
import {
	BusinessEntityRequiredValidatorDirective,
	CannotBeZeroValidatorDirective,
	DateFromValidatorDirective,
	DateToValidatorDirective,
	DayValidatorDirective,
	EmailValidatorDirective,
	EventRequiredValidatorDirective,
	ExpiryDateOrNotApplicableRequiredValidatorDirective,
	LatitudeValidatorDirective,
	LiabilityPercentageRequiredValidatorDirective,
	LiabilityTypeRequiredValidatorDirective,
	LongitudeValidatorDirective,
	MaxLengthValidatorDirective,
	MinAmountValidatorDirective,
	NumberValidatorDirective,
	OptionalDateToValidatorDirective,
	PercentageValidatorDirective,
	PhonenumberValidatorDirective,
	PurchaseOrderTargetDateRequiredValidatorDirective,
	QuantityMaxValueValidatorDirective,
	QuantityMinValueValidatorDirective,
	RecurEndAfterStartValidatorDirective,
	WorkActivityRequiredValidatorDirective,
} from '../directives';

export class CustomValidators {
	static email(control: AbstractControl) {
		const validator = new EmailValidatorDirective();
		return validator.validate(control);
	}

	static phone(control: AbstractControl) {
		const validator = new PhonenumberValidatorDirective();
		return validator.validate(control);
	}

	static liabilityTypeRequired(control: AbstractControl) {
		const validator = new LiabilityTypeRequiredValidatorDirective();
		return validator.validate(control);
	}

	static liabilityPercentageRequired(control: AbstractControl) {
		const validator = new LiabilityPercentageRequiredValidatorDirective();
		return validator.validate(control);
	}

	static purchaseOrderTargetDateRequired(control: AbstractControl) {
		const validator = new PurchaseOrderTargetDateRequiredValidatorDirective();
		return validator.validate(control);
	}

	static businessEntityRequired(control: AbstractControl) {
		const validator = new BusinessEntityRequiredValidatorDirective();
		return validator.validate(control);
	}

	static dateFromBeforeDateTo(control: AbstractControl) {
		const validator = new DateFromValidatorDirective();
		return validator.validate(control);
	}

	static dateToAfterDateFrom(control: AbstractControl) {
		const validator = new DateToValidatorDirective();
		return validator.validate(control);
	}

	static optionalDateToAfterDateFrom(control: AbstractControl) {
		const validator = new OptionalDateToValidatorDirective();
		return validator.validate(control);
	}

	static cannotBeZero(control: AbstractControl) {
		const validator = new CannotBeZeroValidatorDirective();
		return validator.validate(control);
	}

	static expiryDateOrNotApplicableRequired(control: AbstractControl) {
		const validator = new ExpiryDateOrNotApplicableRequiredValidatorDirective();
		return validator.validate(control);
	}

	static conditionalValidator(predicate: () => boolean, validator: ValidatorFn) {
		return (control: AbstractControl) => {
			if (!control.parent) {
				return null;
			}
			if (predicate()) {
				return validator(control);
			}
			return null;
		};
	}
	static latitude(control: AbstractControl) {
		const validator = new LatitudeValidatorDirective();
		return validator.validate(control);
	}
	static longitude(control: AbstractControl) {
		const validator = new LongitudeValidatorDirective();
		return validator.validate(control);
	}

	static quantityMaxValue(control: AbstractControl) {
		const validator = new QuantityMaxValueValidatorDirective();
		return validator.validate(control);
	}

	static quantityMinValue(control: AbstractControl) {
		const validator = new QuantityMinValueValidatorDirective();
		return validator.validate(control);
	}
	static percentage(control: AbstractControl) {
		const validator = new PercentageValidatorDirective();
		return validator.validate(control);
	}

	static minAmount(control: AbstractControl) {
		const validator = new MinAmountValidatorDirective();
		return validator.validate(control);
	}

	static eventRequired(control: AbstractControl) {
		const validator = new EventRequiredValidatorDirective();
		return validator.validate(control);
	}

	static workActivityRequired(control: AbstractControl) {
		const validator = new WorkActivityRequiredValidatorDirective();
		return validator.validate(control);
	}

	static maxlength(control: AbstractControl) {
		const validator = new MaxLengthValidatorDirective();
		return validator.validate(control);
	}

	static recurEndAfterStart(control: AbstractControl) {
		const validator = new RecurEndAfterStartValidatorDirective();
		return validator.validate(control);
	}

	static number(control: AbstractControl) {
		const validator = new NumberValidatorDirective();
		return validator.validate(control);
	}

	static day(control: AbstractControl) {
		const validator = new DayValidatorDirective();
		return validator.validate(control);
	}
}
