<div class="options-container p-field p-fluid p-float-label">
	<div (click)="toggleMultiselectDropdown()" class="p-float-label">
		<p-chips id="customchips" [formControl]="control" addOnBlur="true" (onRemove)="removeItem($event)" autocomplete="off"> </p-chips>
		<label for="customchips">{{ label }}</label>
	</div>
	<div class="p-overlay multiselect-container" style="margin-top: 0px" *ngIf="showMultiselectOptions">
		<div class="p-multiselect-panel">
			<div class="p-multiselect-header">
				<div class="p-checkbox p-component">
					<div
						[ngClass]="{
							'p-checkbox-box': true,
							'p-highlight': allSelected
						}"
						(click)="toggleSelectAll()">
						<i *ngIf="allSelected" class="p-checkbox-icon pi pi-check"></i>
					</div>
				</div>
				<div class="p-multiselect-filter-container">
					<input type="text" id="multiselect-search" pInputText class="p-multiselect-filter" autocomplete="off" />
					<i class="p-multiselect-filter-icon pi pi-search"></i>
				</div>
				<button type="button" pButton icon="pi pi-times" class="p-multiselect-close p-button-plain" (click)="clearSearch()"></button>
			</div>
			<div class="p-multiselect-items-wrapper" style="max-height: 200px">
				<ul role="listbox" class="p-multiselect-items">
					<li
						*ngFor="let option of filteredOptions; let i = index"
						[ngClass]="{
							'p-ripple p-element p-multiselect-item': true,
							'p-highlight': selectedItems.includes(option.NameValue)
						}"
						(click)="toggleItemSelection(option)">
						<div class="p-checkbox p-component">
							<div
								[ngClass]="{
									'p-checkbox-box': true,
									'p-highlight': selectedItems.includes(option.NameValue)
								}">
								<i *ngIf="selectedItems.includes(option.NameValue)" class="p-checkbox-icon pi pi-check"></i>
							</div>
						</div>
						<div class="option-item option-item-value">
							<div class="option-chip" [ngClass]="option.Style">{{ option.NameValue }}</div>
						</div>
					</li>
				</ul>
			</div>
		</div>
	</div>
</div>
