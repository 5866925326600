export class BulkActionResult {
	Details: any;
	IsSuccess: boolean;
	IsCancelled: boolean;
	Messages: any[];
	Action: string;
	constructor(properties?: any) {
		if (properties) {
			for (const key in properties) {
				this[key] = properties[key];
			}
		}
	}
}
