import { SearchFilter } from 'src/app/shared/models/search-filters';

export class LazyLoadArgs {
	first = 0;
	rows = 10;
	globalFilter?: string;
	sortField?: string;
	sortDesc?: boolean = false;
	filters?: PropertyFilter[] = [];
	timezoneCode: string;
	searchFilter?: SearchFilter;
	data: any;

	get sortKey(): string {
		if (this.sortField) {
			return `${this.sortField} ${this.sortDesc ? 'DESC' : ''}`;
		}
		return '';
	}
}

export class PropertyFilter {
	FieldName: string;
	MatchMode: string;
	Operator: string;
	Value: string;
	Values: string[];
	constructor() {
		this.Values = [];
	}
}
