import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import * as moment from 'moment';
import { FilterService } from 'primeng/api';
import { FormControl as FrmCtrl, SelectButtonOption } from 'src/app/shared/models/forms';
import { LookupValue } from 'src/app/shared/models/lookups';
import { RegionSettings } from 'src/app/shared/models/settings';
import { BaseComponent } from 'src/app/core/components/base.component';
import { GlobalConstants } from 'src/app/core/constants/global-constants.constants';
import { AppContextService } from 'src/app/core/services';
import { LookupPredicate } from 'src/app/core/utils';
import { FORM_ITEM_DISPLAY_OPTION_LABEL, FormItemDisplayOption } from '../../enums';
import { IconService } from '../../services/icon.service';

@Component({
	selector: 'app-form-item',
	templateUrl: './form-item.component.html',
	styleUrls: ['./form-item.component.scss'],
})
export class FormItemComponent extends BaseComponent implements OnInit, OnChanges {
	@Input() visible = true;
	@Input() editable = false;
	@Input() tabindex = '0';
	@Input() controlType = FrmCtrl.Textbox;
	@Input() control: AbstractControl = new UntypedFormControl();
	@Input() label: string;
	@Input() innerHtml: string;
	@Input() lookupValues: LookupValue[];
	@Input() minFractionDigits = GlobalConstants.defaultMinFractionDigits;
	@Input() maxFractionDigits = GlobalConstants.defaultMaxFractionDigits;
	@Input() minDate: Date;
	@Input() maxDate: Date;
	@Input() minAmountValue: number;
	@Input() minValue: number;
	@Input() showTime = true;
	@Input() dateFormat = 'd/mm/yy';
	@Input() customSearch: LookupPredicate;
	@Input() autoCompleteMinLength = 1;
	@Input() placeholder: string;
	@Input() values: string[];
	@Input() autoCompleteShowCodes = false;
	@Input() displayWithStyle = false;
	@Input() hasAdditionalDisplayValue = false;
	@Input() autoCompleteForceSelection = true;
	@Output() inputChange: EventEmitter<any> = new EventEmitter();
	@Input() formSubmitAttempt = false;
	@Input() selectBtnOptions: SelectButtonOption[];
	@Input() iconAfterTextboxReadOnly: string;
	@Input() iconAfterTextboxTooltip: string;
	@Input() groupLookupValues: any[];
	@Input() filteredGroupLookupValues: any[];
	@Input() numericRightAlign: boolean;
	@Input() timeZoneCode = moment.tz.guess();
	@Input() multiple = false;
	@Input() labelMarginWidth = 4;
	@Input() smallLabelMarginWidth = 2;
	@Input() maxlength: number;
	@Input() formItemDisplayOption: FormItemDisplayOption;
	@Input() inputId: string;
	@Input() isIconAutoComplete = true;
	@Input() appendTo: HTMLElement | ElementRef | string | null | undefined;
	@Output() colorChange: EventEmitter<any> = new EventEmitter();

	//Only for AutoCompleteWithIcon
	defaultIconClass = 'fa fa-circle';
	defaultIconFontSize = '16px';
	defaultIconColor = '#ffffff';
	defaultDateBasedOnTimeZone: Date;
	filteredValues: LookupValue[];
	formControls = FrmCtrl;
	region: RegionSettings;
	@Input() errDef: any = {
		required: 'This field is required.',
		email: 'Invalid email format.',
		phonenumber: 'Invalid contact #.',
		liabilityTypeRequired: 'This field is required if the Liability Percentage has been entered.',
		liabilitypcRequired: 'This field is required if the Liability Type has been selected.',
		purchaseOrderTargetDateRequired: 'This field is required if Allocated To has been selected.',
		businessEntityRequired: 'This field is required if Due Date has been selected.',
		comments: 'Comment is required.',
		dateFromGreaterThanDateTo: 'This date must be before the Date To.',
		dateToSmallerThanDateFrom: 'This date must be after than the Date From.',
		cannotBeZero: 'This field cannot be zero.',
		expiryDateOrNotApplicableRequired: 'This field is required.',
		latitude: 'Latitude must be between -90 and 90. Allows upto 7 decimals.',
		longitude: 'Longitude must be between -180 and 180. Allows upto 7 decimals.',
		quantityMaximumValue: 'Invalid quantity.',
		quantityMinimumValue: 'Invalid quantity',
		percentage: 'Percentage should be within the range of 0 to 100.',
		eventOrWorkActivityRequired: 'Either checkbox must be selected.',
		maxlength: 'Maximum length exceeded.',
		day: 'Must be between 1 to 31.',
		number: 'Must be 1 or more.',
		endAfterStartDate: 'This date must be equal or after than the Date From.',
	};
	@Input() hideError = false;

	constructor(appContext: AppContextService, private filterService: FilterService, private iconService: IconService) {
		super(appContext);
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (this.control == null) throw new TypeError("Control '" + this.label + "' is null.");

		if (this.control.value instanceof LookupValue) {
			if (!this.control.value.CodeValue && !this.control.value.LookupValueId) {
				this.control.setValue(null);
			}
		}
	}

	onCalendarFocus(event): void {
		if (!this.timeZoneCode) {
			this.timeZoneCode = moment.tz.guess();
		}
		this.defaultDateBasedOnTimeZone = this.control.value ?? moment(moment.tz(new Date(), this.timeZoneCode).format('YYYY-MM-DD HH:mm:ss.SSS')).toDate();
	}

	ngOnInit(): void {
		this.region = this.appContext.getRegion();
	}

	get valueMarginWidth() {
		return 12 - this.labelMarginWidth;
	}

	get largeValueMarginWidth() {
		return 12 - this.smallLabelMarginWidth;
	}

	get value() {
		if (this.control.value) {
			if (this.control.value.NameValue) return this.control.value.NameValue;

			if (Array.isArray(this.control.value))
				return this.control.value
					.map(x => x?.NameValue ?? x)
					.filter(x => x != null)
					.join(', ');
		}

		return this.control.value;
	}

	get codeValueForReadOnly() {
		if (this.control.value) {
			if (this.control.value.NameValue) return this.control.value.CodeValue;

			if (Array.isArray(this.control.value))
				return this.control.value
					.map(x => x?.CodeValue ?? x)
					.filter(x => x != null)
					.join(', ');
		}
		return this.control.value;
	}

	getIconClass(codeValue: string): string {
		return this.iconService.getIcons(codeValue);
	}

	get valueMask() {
		return FORM_ITEM_DISPLAY_OPTION_LABEL[this.formItemDisplayOption ?? 0];
	}

	get selectedLookupValues() {
		if (this.control.value) {
			if (this.control.value.NameValue) return this.control.value.NameValue;
		}

		return this.control.value;
	}

	get name() {
		return this.getName(this.control);
	}

	get displayError(): boolean {
		if (this.hideError) {
			return false;
		}

		return this.control.errors && (this.control.touched || this.control.dirty);
	}
	/*
    get displayError() : boolean {
      return !this.control.valid && (this.control.touched || (this.control.untouched && this.formSubmitAttempt));
    }
  */
	get error() {
		let error = !this.control.valid ? 'Unknown validation error' : null;
		for (const key of Object.keys(this.errDef)) {
			if (this.control.errors && this.control.errors[key]) {
				error = this.errDef[key];
				break;
			}
		}
		return error;
	}

	search(event: any) {
		let query = event?.query ?? '';

		if (this.customSearch) {
			if (query == '' || (query == null && this.control?.value?.NameValue)) query = this.control?.value?.NameValue ?? '';

			this.customSearch(query)?.subscribe(items => {
				this.filteredValues = items;
			});
		} else if (this.autoCompleteShowCodes) {
			const filtered = [];
			this.lookupValues.forEach(x => {
				if (x.ExtendedCodeValue && x.ExtendedCodeValue.toLowerCase().indexOf(query.toLowerCase()) !== -1) {
					filtered.push(x);
				}
			});
			this.filteredValues = filtered;
		} else {
			const filtered = [];
			this.lookupValues.forEach(x => {
				if (x.ExtendedNameValue && x.ExtendedNameValue.toLowerCase().indexOf(query.toLowerCase()) !== -1) {
					filtered.push(x);
				}
			});
			this.filteredValues = filtered;
		}
	}

	searchGrouped(event: any) {
		let query = event?.query ?? '';
		if (query == '' || (query == null && this.control?.value?.label)) {
			query = this.control?.value?.label ?? '';
		}

		const filteredGroups = [];

		for (const optgroup of this.groupLookupValues) {
			const filteredSubOptions = this.filterService.filter(optgroup.items, ['label'], query, 'contains');
			if (filteredSubOptions && filteredSubOptions.length) {
				filteredGroups.push({
					label: optgroup.label,
					value: optgroup.value,
					icon: optgroup.icon,
					items: filteredSubOptions,
				});
			}
		}

		this.filteredGroupLookupValues = filteredGroups;
	}

	private getName(control: AbstractControl): string | null {
		const group = <UntypedFormGroup>control.parent;
		if (!group) {
			return null;
		}

		let name: string;
		Object.keys(group.controls).forEach(key => {
			const childControl = group.get(key);
			if (childControl !== control) {
				return;
			}
			name = key;
		});

		return name;
	}

	onInput(event: any) {
		this.inputChange.emit(event);
	}

	onBlur(event: any) {
		this.control.markAsDirty();
	}

	onDateInput(event: any) {
		this.inputChange.emit(null);
	}

	onDateChange(event: Date) {
		this.inputChange.emit(event);
	}

	onColorChange(event: any) {
		this.colorChange.emit(event);
	}
}
