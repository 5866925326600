export class Record {
	RecordTypeCode: string;
	RecordId: string;
	RecordName: string;
	//for ui
	StyleClass: string;

	constructor(properties?: any) {
		if (properties) {
			for (const key in properties) {
				this[key] = properties[key];
			}
		}
	}
}
