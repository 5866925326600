<ng-template ngFor let-child let-i="index" [ngForOf]="root ? item : item.items">
	<li
		[class]="child.styleClass"
		[ngClass]="{ 'menu-item-link-focused': isChildMenuLinkActive(i), 'menu-item-link-active': isMenuLinkFocused(i) }"
		routerLinkActive="menu-item-link-active"
		[routerLinkActiveOptions]="{ exact: false }">
		<a
			[attr.data-testid]="'main-' + child.label"
			pRipple
			[href]="child.url || '#'"
			(click)="itemClick($event, child, i)"
			*ngIf="!child.routerLink"
			[attr.tabindex]="!visible ? '-1' : null"
			[attr.target]="child.target"
			(mouseenter)="onMouseEnter(i)">
			<div class="layout-menuitem-icon-holder">
				<i [ngClass]="child.icon" class="layout-menuitem-icon"></i>
			</div>
			<span class="menuitem-text">{{ child.label }}</span>
			<i class="pi pi-fw pi-angle-down layout-submenu-toggler" *ngIf="child.items"></i>
			<p-chip *ngIf="child.badge" class="sidebar-beta-badge sm" [label]="child.badge"></p-chip>
		</a>

		<a
			[attr.data-testid]="'sub-' + child.label"
			pRipple
			(click)="itemClick($event, child, i)"
			*ngIf="child.routerLink && !child.queryParams"
			[attr.tabindex]="!visible ? '-1' : null"
			[attr.target]="child.target"
			(mouseenter)="onMouseEnter(i)"
			[routerLink]="child.routerLink">
			<div class="layout-menuitem-icon-holder">
				<i [ngClass]="child.icon" class="layout-menuitem-icon"></i>
			</div>
			<span class="menuitem-text">{{ child.label }} {{ child?.isBeta }}</span>
			<p-chip *ngIf="child.badge" class="sidebar-beta-badge sm" [label]="child.badge"></p-chip>

			<i class="pi pi-fw pi-angle-down layout-submenu-toggler" *ngIf="child.items"></i>
		</a>

		<ul
			app-submenu
			[item]="child"
			*ngIf="child.items && (isMenuLinkFocused(i) || isChildMenuLinkActive(i))"
			[visible]="isMenuLinkFocused(i) || isChildMenuLinkActive(i)"
			[reset]="reset"
			[parentActive]="isMenuLinkFocused(i) || isChildMenuLinkActive(i)"
			[@children]="
				app.isHorizontal() && root
					? isMenuLinkFocused(i) || isChildMenuLinkActive(i)
						? 'visible'
						: 'hidden'
					: isMenuLinkFocused(i) || isChildMenuLinkActive(i)
					? 'visibleAnimated'
					: 'hiddenAnimated'
			"></ul>
	</li>
</ng-template>
