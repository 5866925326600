export class BulkActionResultSummary {
	Success: string;
	Error: string;
	Info: string;
	constructor(properties?: any) {
		if (properties) {
			for (const key in properties) {
				this[key] = properties[key];
			}
		}
	}
}
