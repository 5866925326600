import { StatusLookupValue } from './status-lookup-value.model';

export class BusinessEntityLookupValue extends StatusLookupValue {
	BusinessEntityName: string;
	BusinessRegistrationNumber: string;
	InternalReferenceNumber: string;
	BusinessEntityRelationTypeCode: string;
	BusinessEntityRelationTypeName: string;
	StatusName: string;
	TaxCode: string;
	ContactPhone: string;
	ContactMobile: string;
	ContactEmail: string;
	EarliestLicenceOrInsuranceExpiryDate: Date;
}
