import { formatDate } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'formatTime',
})
export class FormatTimePipe implements PipeTransform {
	transform(value: string, format: string, locale: string): string {
		let formattedDate = '';
		if (value) {
			formattedDate = formatDate(value, format, locale);
		}
		return formattedDate;
	}
}
