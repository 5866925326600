import { AttachmentDetail } from './attachment-detail.model';
import { AttachmentFile } from './attachment-file.model';

export class Attachment {
	Details: AttachmentDetail;
	Files: AttachmentFile[];

	constructor(properties?: any) {
		if (properties) {
			for (const key in properties) {
				this[key] = properties[key];
			}
		}
	}
}
