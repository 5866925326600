export const DefaultRegionSettings = {
	RegionCode: 'en-AU',
	RegionName: 'English (Australia)',

	CurrencySymbol: '$',
	CurrencyPrecision: 2,
	CurrencyCode: 'AUD',
	DateFormat: 'd/MM/yyyy',
	DateTimeFormat: 'd/MM/yyyy h:mm a',
	TimeFormat: 'h:mm a',
	NumberFormat: '',
	CurrencyFormat: '#,##0.00',
	CurrencySymbolAtEnd: false,
	Use24Hours: false,
	QuantityPrecision: 2,

	GoogleAddressOptions: null,
	DefaultMapLocation: null,
};
