import { Directive, Input } from '@angular/core';
import { AutoComplete } from 'primeng/autocomplete';

@Directive({
	selector: '[appAutoComplete]',
	exportAs: 'appAutoCompleteDirective',
})
export class AppAutoCompleteDirective {
	list: any[];
	@Input() filterBy: string;

	constructor(private host: AutoComplete) {
		this.list = this.host.suggestions;

		this.host.onClear.subscribe(e => {
			this.setValue(null);
		});

		this.host.completeMethod.subscribe(event => {
			if (!this.list) {
				this.list = this.host.suggestions;
				if (!this.filterBy) {
					this.filterBy = this.host.field;
				}
			}
			this.filterList(event);
		});
	}

	reset() {
		this.list = null;
	}

	setValue(value) {
		this.host.value = value;
		this.host.onModelChange(value);
		this.host.onSelect.emit(value);
	}

	filterList(event) {
		const query = event.query;
		if (query) {
			const filterProps = this.filterBy.split(',').map(p => p.trim());
			this.host.suggestions = this.list.filter(p => {
				for (let i = 0; i < filterProps.length; i++) {
					const prop = filterProps[i];
					if (p[prop]) {
						if (p[prop].toLowerCase().indexOf(query.toLowerCase()) >= 0) {
							return true;
						}
					} else {
						//console.error(`Property: ${prop} does not exist in the autoComplete source`);
					}
				}

				return false;
			});
		} else {
			this.host.suggestions = this.list;
		}
		//console.log(this.host.suggestions);
	}
}
