import { ObjectAccess } from 'src/app/shared/models/api';
import { GeolocationDto } from 'src/app/shared/models/shared';

export class AssetDetails {
	AssetId: string;

	AssetNumber: string;
	InternalAssetReference: string;
	ExternalAssetReference: string;
	AssetTypeCode: string;
	AssetTypeName: string;
	AssetReference: string;
	AssetCategoryCode: string;
	AssetCategoryName: string;

	ServiceAreaId: string;
	ServiceAreaName: string;

	Street: string;
	Suburb: string;
	Postcode: string;
	State: string;
	Country: string;

	GeolocationDto: GeolocationDto;

	TimeZoneCode: string;
	TimeZoneName: string;

	AddedById: string;
	AddedBy: string;
	UpdatedById: string;
	UpdatedBy: string;
	DateAdded: Date;
	DateUpdated: Date;
	DateRemoved: Date;

	ClientId: string;
	ClientName: string;

	ParentAssetId: string;
	ParentAssetNumber: string;

	QrCode: string;
	Barcode: string;

	ObjectAccess: ObjectAccess;

	constructor(properties?: any) {
		if (properties) {
			for (const key in properties) {
				this[key] = properties[key];
			}
		}
	}
}
