import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'truncated',
})
export class TruncatedPipe implements PipeTransform {
	transform(value: string, args: any[]): string {
		if (value == null) return null;

		const limit = args?.length > 0 ? parseInt(args[0], 10) : 100;
		return value.length > limit ? value : null;
	}
}
