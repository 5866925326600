import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FlowHistory } from 'src/app/shared/models/shared';
import { ApiService } from 'src/app/core/services';

@Injectable({
	providedIn: 'root',
})
export class FlowHistoryService {
	constructor(private apiService: ApiService) {}
	getFlowHistory(referenceTypeCode: string, referenceId: string, includeCurrent: boolean): Observable<FlowHistory[]> {
		return this.apiService.get<FlowHistory[]>(`/FlowHistory/GetFlowHistory/${referenceTypeCode}/${referenceId}/${includeCurrent}`);
	}
}
