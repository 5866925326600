import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ActionResult, LazyLoadArgs, PagedResponse } from 'src/app/shared/models/api';
import { ExternalNotification } from 'src/app/shared/models/shared';
import { ExportOptions } from 'src/app/shared/models/ui';
import { ApiRequestArgs } from 'src/app/core/models/api-request-args.model';
import { ApiService } from 'src/app/core/services';
import {
	InvoiceDetails,
	InvoiceIssue,
	InvoiceItem,
	InvoiceList,
	InvoicePayment,
	InvoiceRelatedEntry,
	InvoiceSummary,
	InvoiceableOrderItem,
	NewInvoice,
} from '../models';

@Injectable({
	providedIn: 'root',
})
export class InvoiceApiService {
	constructor(private apiService: ApiService) {
		//
	}

	getPagedList(searchData: LazyLoadArgs, isReceivables: boolean): Observable<PagedResponse<InvoiceList>> {
		return this.apiService.post<PagedResponse<InvoiceList>>(`/Invoice/GetPagedList/${isReceivables}`, searchData);
	}

	getPagedListExcelFile(exportOptions: ExportOptions, isReceivables: boolean): Observable<ActionResult> {
		return this.apiService.post<ActionResult>(`/Invoice/GetPagedListExcelFile/${isReceivables}`, exportOptions);
	}

	getInvoiceSummary(invoiceId: string): Observable<InvoiceSummary> {
		return this.apiService.get<InvoiceSummary>(`/Invoice/GetInvoiceSummary/${invoiceId}`);
	}

	getInvoiceDetails(invoiceId: string): Observable<InvoiceDetails> {
		return this.apiService.get<InvoiceDetails>(`/Invoice/GetInvoiceDetails/${invoiceId}`);
	}

	saveInvoiceDetails(invoice: InvoiceDetails): Observable<ActionResult> {
		return this.apiService.post<ActionResult>('/Invoice/SaveInvoiceDetails', invoice);
	}

	createNewInvoiceDetailsForAdhoc(companyId: string, isReceivable: boolean, alwaysShowResult = false): Observable<ActionResult<NewInvoice>> {
		return this.apiService.getArgs<ActionResult<NewInvoice>>(
			`/Invoice/CreateNewInvoiceDetailsForAdhoc/${companyId}/${isReceivable}`,
			new ApiRequestArgs({ AlwaysShowResult: alwaysShowResult })
		);
	}

	createNewInvoiceDetailsForScope(scopeId: string, isReceivable: boolean, alwaysShowResult = false): Observable<ActionResult<NewInvoice>> {
		return this.apiService.getArgs<ActionResult<NewInvoice>>(
			`/Invoice/CreateNewInvoiceDetailsForScope/${scopeId}/${isReceivable}`,
			new ApiRequestArgs({ AlwaysShowResult: alwaysShowResult })
		);
	}

	createNewInvoiceDetailsForQuoteRequest(quoteId: string, isReceivable: boolean, alwaysShowResult = false): Observable<ActionResult<NewInvoice>> {
		return this.apiService.getArgs<ActionResult<NewInvoice>>(
			`/Invoice/CreateNewInvoiceDetailsForQuoteRequest/${quoteId}/${isReceivable}`,
			new ApiRequestArgs({ AlwaysShowResult: alwaysShowResult })
		);
	}

	createNewInvoiceDetailsForWorkOrder(workOrderId: string, isReceivable: boolean, alwaysShowResult = false): Observable<ActionResult<NewInvoice>> {
		return this.apiService.getArgs<ActionResult<NewInvoice>>(
			`/Invoice/CreateNewInvoiceDetailsForWorkOrder/${workOrderId}/${isReceivable}`,
			new ApiRequestArgs({ AlwaysShowResult: alwaysShowResult })
		);
	}

	createNewInvoiceDetailsForServiceRequest(serviceRequestId: string, isReceivable: boolean, alwaysShowResult = false): Observable<ActionResult<NewInvoice>> {
		return this.apiService.getArgs<ActionResult<NewInvoice>>(
			`/Invoice/CreateNewInvoiceDetailsForServiceRequest/${serviceRequestId}/${isReceivable}`,
			new ApiRequestArgs({ AlwaysShowResult: alwaysShowResult })
		);
	}

	createNewInvoiceDetailsForPurchaseOrder(purchaseOrderId: string, isReceivable: boolean, alwaysShowResult = false): Observable<ActionResult<NewInvoice>> {
		return this.apiService.getArgs<ActionResult<NewInvoice>>(
			`/Invoice/CreateNewInvoiceDetailsForPurchaseOrder/${purchaseOrderId}/${isReceivable}`,
			new ApiRequestArgs({ AlwaysShowResult: alwaysShowResult })
		);
	}

	createNewCreditNoteDetailsForInvoice(invoiceId: string, isReceivable: boolean, alwaysShowResult = false): Observable<ActionResult<NewInvoice>> {
		return this.apiService.getArgs<ActionResult<NewInvoice>>(
			`/Invoice/CreateNewCreditNoteDetailsForInvoice/${invoiceId}/${isReceivable}`,
			new ApiRequestArgs({ AlwaysShowResult: alwaysShowResult })
		);
	}

	calculatePaymentDate(invoice: NewInvoice): Observable<string> {
		return this.apiService.post<string>('/Invoice/CalculatePaymentDate', invoice);
	}

	saveNewInvoiceDetails(invoice: NewInvoice): Observable<ActionResult> {
		return this.apiService.postArgs<ActionResult>('/Invoice/SaveNewInvoiceDetails', invoice, new ApiRequestArgs({ AlwaysShowResult: false }));
	}

	approveNewInvoiceDetails(invoice: NewInvoice): Observable<ActionResult> {
		return this.apiService.post<ActionResult>('/Invoice/ApproveNewInvoiceDetails', invoice);
	}

	getOrderItemsAvailableForInvoicing(refType: string, refId: string, parentInvoiceId: string): Observable<InvoiceableOrderItem[]> {
		if (!refType || !refId) return of([]);
		return this.apiService.get<InvoiceableOrderItem[]>(`/Invoice/GetOrderItemsAvailableForInvoicing/${refType}/${refId}/${parentInvoiceId}`);
	}

	saveInvoiceIssue(invoiceIssue: InvoiceIssue): Observable<ActionResult> {
		return this.apiService.post<ActionResult>('/Invoice/SaveInvoiceIssue', invoiceIssue);
	}

	saveInvoicePayment(invoicePayment: InvoicePayment): Observable<ActionResult<InvoicePayment>> {
		return this.apiService.post<ActionResult>('/Invoice/SaveInvoicePayment', invoicePayment);
	}

	approveInvoice(invoiceId: string, calledFromList = false): Observable<ActionResult> {
		return this.apiService.get<ActionResult>(`/Invoice/ApproveInvoice/${invoiceId}/${calledFromList}`);
	}

	issueInvoice(invoiceId: string): Observable<ActionResult> {
		return this.apiService.get<ActionResult>(`/Invoice/IssueInvoice/${invoiceId}`);
	}

	holdInvoice(invoiceId: string): Observable<ActionResult> {
		return this.apiService.get<ActionResult>(`/Invoice/HoldInvoice/${invoiceId}`);
	}

	markInvoiceAsPaid(invoiceId: string, datePaid: Date, referenceNumber: string, comments: string, calledFromList = false): Observable<ActionResult> {
		const obj = {
			invoiceId: invoiceId,
			datePaid: datePaid,
			referenceNumber: referenceNumber,
			comments: comments,
		};
		return this.apiService.post<ActionResult>(`/Invoice/MarkInvoiceAsPaid/${calledFromList}`, obj);
	}

	cancelInvoice(invoiceId: string, calledFromList = false): Observable<ActionResult> {
		return this.apiService.get<ActionResult>(`/Invoice/CancelInvoice/${invoiceId}/${calledFromList}`);
	}

	voidInvoice(invoiceId: string, calledFromList = false): Observable<ActionResult> {
		return this.apiService.get<ActionResult>(`/Invoice/VoidInvoice/${invoiceId}/${calledFromList}`);
	}

	getInvoiceItemsForInvoice(invoiceId: string): Observable<InvoiceItem[]> {
		return this.apiService.get<InvoiceItem[]>(`/Invoice/GetInvoiceItemsForInvoice/${invoiceId}`);
	}

	getInvoiceIssuesForInvoice(invoiceId: string): Observable<InvoiceIssue[]> {
		return this.apiService.get<InvoiceIssue[]>(`/Invoice/GetInvoiceIssuesForInvoice/${invoiceId}`);
	}

	getInvoicePaymentsForInvoice(invoiceId: string): Observable<InvoicePayment[]> {
		return this.apiService.get<InvoicePayment[]>(`/Invoice/GetInvoicePaymentsForInvoice/${invoiceId}`);
	}

	getInvoiceItem(invoiceItemId: string): Observable<InvoiceItem> {
		return this.apiService.get<InvoiceItem>(`/Invoice/GetInvoiceItem/${invoiceItemId}`);
	}

	saveInvoiceItem(invoiceItem: InvoiceItem): Observable<ActionResult> {
		return this.apiService.post<ActionResult>('/Invoice/SaveInvoiceItem', invoiceItem);
	}

	disableInvoiceItem(invoiceItemId: string): Observable<ActionResult> {
		return this.apiService.get<ActionResult>(`/Invoice/DisableInvoiceItem/${invoiceItemId}`);
	}

	getEmailNotificationsForInvoice(invoiceId: string, includePreviouslySent: boolean): Observable<ExternalNotification[]> {
		return this.apiService.get<ExternalNotification[]>(`/Invoice/GetEmailNotificationsForInvoice/${invoiceId}/${includePreviouslySent}`);
	}

	sendEmailNotification(invoiceId: string, notifications: ExternalNotification[]): Observable<ActionResult> {
		return this.apiService.post(`/Invoice/sendEmailNotification?invoiceId=${invoiceId}`, notifications);
	}

	getRelatedInvoiceEntries(
		parentReferenceTypeCode: string,
		parentReferenceId: string,
		referenceTypeCode: string,
		referenceId: string,
		invoiceId: string,
		workAssetId: string,
		includeRemoved = false
	): Observable<InvoiceRelatedEntry[]> {
		if (parentReferenceTypeCode != null && parentReferenceTypeCode != undefined)
			return this.getRelatedInvoiceEntriesForParent(parentReferenceTypeCode, parentReferenceId, includeRemoved);
		if (referenceTypeCode != null && referenceTypeCode != undefined)
			return this.getRelatedInvoiceEntriesForReference(referenceTypeCode, referenceId, includeRemoved);
		if (workAssetId != null && workAssetId != undefined) return this.getRelatedInvoiceEntriesForWorkAsset(workAssetId, includeRemoved);

		return this.getRelatedInvoiceEntriesForInvoice(invoiceId, includeRemoved);
	}

	getRelatedInvoiceEntriesForParent(parentReferenceTypeCode: string, parentReferenceId: string, includeRemoved = false): Observable<InvoiceRelatedEntry[]> {
		return this.apiService.get<InvoiceRelatedEntry[]>(
			`/Invoice/GetRelatedInvoiceEntriesForParent/${parentReferenceTypeCode}/${parentReferenceId}/${includeRemoved}`
		);
	}

	getRelatedInvoiceEntriesForReference(referenceTypeCode: string, referenceId: string, includeRemoved = false): Observable<InvoiceRelatedEntry[]> {
		return this.apiService.get<InvoiceRelatedEntry[]>(`/Invoice/GetRelatedInvoiceEntriesForReference/${referenceTypeCode}/${referenceId}/${includeRemoved}`);
	}

	getRelatedInvoiceEntriesForWorkAsset(workAssetId: string, includeRemoved = false): Observable<InvoiceRelatedEntry[]> {
		return this.apiService.get<InvoiceRelatedEntry[]>(`/Invoice/GetRelatedInvoiceEntriesForWorkAsset/${workAssetId}/${includeRemoved}`);
	}

	getRelatedInvoiceEntriesForInvoice(invoiceId: string, includeRemoved = false): Observable<InvoiceRelatedEntry[]> {
		return this.apiService.get<InvoiceRelatedEntry[]>(`/Invoice/GetRelatedInvoiceEntriesForInvoice/${invoiceId}/${includeRemoved}`);
	}

	validateNewCreditNoteCreationForInvoice(invoiceId: string, isReceivable: boolean): Observable<ActionResult<NewInvoice>> {
		return this.apiService.get<ActionResult<NewInvoice>>(`/Invoice/ValidateNewCreditNoteCreationForInvoice/${invoiceId}/${isReceivable}`);
	}
}
