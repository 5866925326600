import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Table } from 'primeng/table';
import { Constants } from 'src/app/shared/constants/constants.constant';
import { FormMode } from 'src/app/shared/models/forms';
import { LookupValue } from 'src/app/shared/models/lookups';
import { RegionSettings } from 'src/app/shared/models/settings';
import { Note } from 'src/app/shared/models/shared';
import { NoteService } from 'src/app/shared/services';
import { BaseComponent } from 'src/app/core/components/base.component';
import { AppContextService, LoaderService, LookupService } from 'src/app/core/services';

@Component({
	selector: 'app-note-table',
	templateUrl: './note-table.component.html',
})
export class NoteTableComponent extends BaseComponent implements OnInit {
	@Input() referenceTypeCode: string;
	@Input() referenceId: string;
	@ViewChild('notesTable') notesTable: Table;
	@ViewChild('noteForm') noteForm: NgForm;
	@Output() notesUpdated = new EventEmitter();
	currentItem: Note;
	currentItemBackup: Note;
	showList = true;
	refreshRequired = false;
	formMode = FormMode.View;
	items: Note[] = [];
	noteCategories: LookupValue[];
	filteredNoteCategories: LookupValue[];
	selectedNoteCategory: LookupValue;
	noteAlertTypes: LookupValue[];
	filteredNoteAlertTypes: LookupValue[];
	selectedNoteAlertType: LookupValue;
	specificNoteCategories: LookupValue[] = [];
	specificNoteAlertTypes: LookupValue[] = [];
	region: RegionSettings;

	constructor(appContext: AppContextService, private noteService: NoteService, private lookupService: LookupService, private loaderService: LoaderService) {
		super(appContext);
	}

	ngOnInit(): void {
		try {
			this.initLookups();
		} catch (ex) {
			this.util.hideWait();
			console.error(ex);
		}
	}

	initLookups(): void {
		this.region = this.appContext.getRegion();
		this.lookupService.getNoteCategories(this.referenceTypeCode).subscribe(noteCategories => {
			this.noteCategories = noteCategories;
		});
		this.lookupService.getNoteAlertTypes().subscribe(noteAlertTypes => {
			this.noteAlertTypes = noteAlertTypes;
		});
	}

	public refreshData() {
		try {
			this.currentItem = null;
			this.currentItemBackup = null;

			if (this.notesTable) this.notesTable.editingRowKeys = {};

			if (this.referenceTypeCode && this.referenceId) {
				this.noteService.getNotesForReference(this.referenceTypeCode, this.referenceId).subscribe(notes => {
					this.items = notes;
					this.showList = true;
					this.appContext.HasTableBasedData = true;
				});
			}
		} catch (ex) {
			this.util.hideWait();
			console.error(ex);
		}
	}

	searchNoteCategory(event: any) {
		const filtered = [];
		this.specificNoteCategories.forEach(x => {
			if (x.NameValue && x.NameValue.toLowerCase().indexOf(event.query.toLowerCase()) !== -1) {
				filtered.push(x);
			}
		});
		this.filteredNoteCategories = filtered;
	}

	searchNoteAlertType(event: any) {
		const filtered = [];
		this.specificNoteAlertTypes.forEach(x => {
			if (x.NameValue && x.NameValue.toLowerCase().indexOf(event.query.toLowerCase()) !== -1) {
				filtered.push(x);
			}
		});
		this.filteredNoteAlertTypes = filtered;
	}

	noteCategorySelected(event: LookupValue) {
		this.selectedNoteCategory = event == null ? null : this.specificNoteCategories.find(x => x.CodeValue === event.CodeValue);

		this.currentItem.NoteCategoryCode = this.selectedNoteCategory?.CodeValue;
		this.currentItem.NoteCategoryName = this.selectedNoteCategory?.NameValue;
	}

	noteAlertTypeSelected(event: LookupValue) {
		this.selectedNoteAlertType = event == null ? null : this.specificNoteAlertTypes.find(x => x.CodeValue === event.CodeValue);

		this.currentItem.NoteAlertTypeCode = this.selectedNoteAlertType?.CodeValue;
		this.currentItem.NoteAlertTypeName = this.selectedNoteAlertType?.NameValue;
	}

	addClick(event: any, noteForm: NgForm) {
		this.specificNoteCategories = this.noteCategories.slice();
		this.specificNoteAlertTypes = this.noteAlertTypes.slice();

		this.selectedNoteCategory = this.specificNoteCategories.find(x => x.Selected);
		this.selectedNoteAlertType = this.specificNoteAlertTypes.find(x => x.Selected);
		this.formMode = this.formModes.Create;

		this.currentItem = new Note();
		this.currentItem.ReferenceId = this.referenceId;
		this.currentItem.ReferenceTypeCode = this.referenceTypeCode;
		this.currentItem.NoteCategoryCode = this.selectedNoteCategory?.CodeValue;
		this.currentItem.NoteCategoryName = this.selectedNoteCategory?.NameValue;
		this.currentItem.NoteAlertTypeCode = this.selectedNoteAlertType?.CodeValue;
		this.currentItem.NoteAlertTypeName = this.selectedNoteAlertType?.NameValue;
		this.currentItemBackup = null;

		this.items.unshift(this.currentItem);

		this.notesTable.editingRowKeys[this.currentItem.NoteId] = true;
	}

	expandClick(item: Note) {
		this.showList = false;
		this.appContext.HasTableBasedData = false;
		if (this.currentItem == item) {
			if (this.currentItem?.NoteId) this.formMode = FormMode.Edit;
			else this.formMode = FormMode.Create;
		} else {
			this.currentItem = item;
			this.formMode = FormMode.View;
		}
	}

	onRowEditInit(item: Note) {
		this.currentItem = item;
		this.currentItemBackup = this.util.deepClone(item);

		this.specificNoteAlertTypes = this.noteAlertTypes.slice();
		this.specificNoteCategories = this.noteCategories.slice();

		this.util.addRecordValueIntoLookup(this.currentItem.NoteAlertTypeCode, this.currentItem.NoteAlertTypeName, this.specificNoteAlertTypes);
		this.util.addRecordValueIntoLookup(this.currentItem.NoteCategoryCode, this.currentItem.NoteCategoryName, this.specificNoteCategories);

		this.selectedNoteCategory = this.specificNoteCategories.find(x => x.CodeValue === item.NoteCategoryCode);
		this.selectedNoteAlertType = this.specificNoteAlertTypes.find(x => x.CodeValue == item.NoteAlertTypeCode);
	}

	onRowEditSave(item: Note) {
		this.util.clearMessages();
		item.ReferenceTypeCode = this.referenceTypeCode;
		item.ReferenceId = this.referenceId;
		this.loaderService.block();
		this.noteService.saveNote(item).subscribe(result => {
			this.loaderService.unblock();
			if (result.IsSuccess) {
				this.notesTable.editingRowKeys[item.NoteId] = false;
				this.currentItem = null;
				this.currentItemBackup = null;
				this.notesUpdated.emit();
				this.refreshData();
			} else {
				this.expandClick(item);
			}
		});
	}

	onRowEditCancel() {
		this.util.clearMessages();
		const idx = this.items.indexOf(this.currentItem);
		if (idx >= 0) {
			if (this.currentItemBackup == null) this.items.splice(idx, 1);
			else this.items[idx] = this.currentItemBackup;
		}

		this.currentItem = null;
		this.currentItemBackup = null;
	}

	editFinished() {
		this.refreshRequired = true;
		this.notesUpdated.emit();
	}

	editCancelled() {
		const idx = this.items.indexOf(this.currentItem);
		if (idx >= 0) {
			if (this.currentItemBackup == null) this.items.splice(idx, 1);
			else this.items[idx] = this.currentItemBackup;
		}

		this.currentItem = this.currentItemBackup;
		this.currentItemBackup = null;
	}

	isValid() {
		return (
			this.currentItem.NoteCategoryCode != null &&
			this.currentItem.NoteCategoryCode != '' &&
			this.currentItem.Comments != null &&
			this.currentItem.Comments != ''
		);
	}

	detailsClosed() {
		this.currentItem = null;
		this.currentItemBackup = null;
		this.showList = true;
		this.appContext.HasTableBasedData = true;

		if (this.refreshRequired) this.refreshData();
	}

	showAddItem(): boolean {
		return this.appContext.hasPermission(Constants.UserPermission.CommonNotesCreate);
	}

	showEditItem(): boolean {
		return this.appContext.hasPermission(Constants.UserPermission.CommonNotesEdit);
	}
}
