import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { Observable, of, switchMap } from 'rxjs';
import { Constants } from 'src/app/shared/constants/constants.constant';
import { ActionResult } from 'src/app/shared/models/api';
import { AppContextService, SecurityApiService } from 'src/app/core/services';
import { UserClientSecurity } from '../models';

@Injectable({
	providedIn: 'root',
})
export class SecurityService {
	constructor(private securityApiService: SecurityApiService, private appContext: AppContextService, private msalService: MsalService) {
		//
	}

	// returns true if valid account + subscription
	private setCurrentUser(user: UserClientSecurity, logoutIfInvalid: boolean): boolean {
		this.appContext.setUser(user);

		if (
			user?.IsDemoSubscription ||
			user?.SubscriptionAccessTypeCode === Constants.SubscriptionAccessType.Full ||
			user?.SubscriptionAccessTypeCode === Constants.SubscriptionAccessType.Web
		) {
			return true;
		}

		if (logoutIfInvalid) {
			this.appContext.clearUser();
			this.msalService.logout();
		}

		return false;
	}

	loginCurrentUser(): Observable<boolean> {
		return this.updateCurrentUser(false).pipe(
			switchMap(x => {
				if (!x) return of(x);

				if (this.appContext.user.IsDefaultSubscription) return of(x);

				const subscriberId = this.appContext.getLastSubscriberId();
				if (!subscriberId || subscriberId == this.appContext.user.SubscriberId) return of(x);

				return this.changeSubscriber(subscriberId);
			})
		);
	}

	updateCurrentUser(logoutIfInvalid: boolean, subscriberId: string = null): Observable<boolean> {
		return this.securityApiService.getCurrentUser(subscriberId).pipe(
			switchMap(x => {
				return of(this.setCurrentUser(x, logoutIfInvalid));
			})
		);
	}

	changeSubscriber(subscriberId: string): Observable<boolean> {
		return this.updateCurrentUser(false, subscriberId);
	}

	setDefaultSubscriberForCurrentUser(subscriberId: string): Observable<ActionResult> {
		return this.securityApiService.setDefaultSubscriberForCurrentUser(subscriberId);
	}
}
