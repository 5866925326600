import { TaxLookupValue } from './tax-lookup-value.model';

export class OrderLookupValue extends TaxLookupValue {
	StatusName: string;
	ScheduledStartDate: Date | null;
	TargetDate: Date | null;
	OrderTypeCode: string;
	OrderPriorityCode: string;
	WorkOrderId: string;
	PropertyNumber: string;
	Street: string;
	Suburb: string;
	State: string;
	ItemTradeTypes: string;
}
