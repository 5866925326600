import { LazyLoadArgs } from 'src/app/shared/models/api';

export class FilterConfig {
	Name: string;
	DisplayName: string;
	DisplayType:
		| 'guidValue'
		| 'textValue'
		| 'multiValue'
		| 'selectBtn'
		| 'dropdown'
		| 'numberValue'
		| 'startNumberValue'
		| 'endNumberValue'
		| 'numberRange'
		| 'dateValue'
		| 'dateValue'
		| 'startDateValue'
		| 'endDateValue'
		| 'dateRange'
		| 'boolValue'
		| 'checkbox';
	Values: any[] = [];
	Options: any[];
	OptionValueField: string;
	TextValue: string;
	DateValue: Date;
	NumberValue: number;
	StartDate: Date;
	EndDate: Date;
	StartNumber: number;
	EndNumber: number;
	BoolValue: boolean;
	IsHidden: boolean;

	get IsFilterActive(): boolean {
		return (
			(this.Values && this.Values.length !== 0) ||
			(this.TextValue !== null && this.TextValue !== undefined) ||
			(this.DateValue !== null && this.DateValue !== undefined) ||
			(this.NumberValue !== null && this.NumberValue !== undefined) ||
			(this.StartDate !== null && this.StartDate !== undefined) ||
			(this.EndDate !== null && this.EndDate !== undefined) ||
			(this.StartNumber !== null && this.StartNumber !== undefined) ||
			(this.EndNumber !== null && this.EndNumber !== undefined) ||
			(this.BoolValue !== null && this.BoolValue !== undefined)
		);
	}

	constructor(
		name: string,
		displayName?: string,
		type?:
			| 'guidValue'
			| 'textValue'
			| 'multiValue'
			| 'selectBtn'
			| 'dropdown'
			| 'numberValue'
			| 'startNumberValue'
			| 'endNumberValue'
			| 'numberRange'
			| 'dateValue'
			| 'dateValue'
			| 'startDateValue'
			| 'endDateValue'
			| 'dateRange'
			| 'boolValue'
			| 'checkbox',
		isHidden = false,
		lazyLoadArgs: LazyLoadArgs = null
	) {
		this.Name = name;
		this.DisplayName = displayName ? displayName : this.Name;
		if (type) {
			this.DisplayType = type;
		} else {
			this.DisplayType = 'selectBtn';
		}
		this.IsHidden = isHidden;
		if (lazyLoadArgs) {
			lazyLoadArgs.filters = lazyLoadArgs && lazyLoadArgs.filters ? lazyLoadArgs.filters.filter(x => x.FieldName !== this.Name) : lazyLoadArgs.filters;
		}
	}
}
