import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { ObjectAccessReason } from 'src/app/shared/models/api';
import {
	IROAccessObject,
	IROAccessObjectList,
	IReplaceableList,
	TOOLTIP_MESSAGES,
	TOOLTIP_MESSAGES_COMPONENT,
	tooltipMessagesComponentType,
	ttPermissionType,
	ttReasonType,
} from 'src/app/core/constants/tooltip-messages.constant';

@Injectable({
	providedIn: 'root',
})
export class TooltipMessageService {
	getTooltipMessage = (
		component: tooltipMessagesComponentType,
		permission: ttPermissionType,
		reasonDTO: ObjectAccessReason | undefined,
		model: any,
		replaceableList?: IReplaceableList
	): string => {
		const DataObject = TOOLTIP_MESSAGES_COMPONENT[component].object;
		const sheet = TOOLTIP_MESSAGES_COMPONENT[component].sheet as keyof typeof TOOLTIP_MESSAGES;
		const reasonList: ttReasonType[] = [];
		if (!reasonDTO) {
			return null;
		}
		Object.entries(reasonDTO).forEach(([key, value]) => {
			if (value) {
				const val = key as ttReasonType;
				reasonList.push(val);
			}
		});

		const accessObjectList: IROAccessObjectList[] = [];
		TOOLTIP_MESSAGES[sheet].map(accessObject => {
			const tempAccessObject = accessObject as IROAccessObject;
			const reason = tempAccessObject.Reason as ttReasonType;
			if (
				tempAccessObject.Object === DataObject &&
				permission === tempAccessObject.Field &&
				reasonList.includes(reason) &&
				tempAccessObject.System !== 'Mobile'
			) {
				accessObjectList.push({
					order: tempAccessObject.Order ?? 0,
					message: tempAccessObject.Message,
				});
			}
		});
		if (!accessObjectList.length) {
			if (reasonDTO.DisableOverride) return 'DevTools: disable override.';
			return null;
		}
		const sortedAccessObjectList = _.sortBy(accessObjectList, 'order');
		let message = sortedAccessObjectList[0].message;

		if (message.includes('${')) {
			// get the fields
			const regex = /(?:\${)([^}]+)(?:})/;

			// eslint-disable-next-line
			while (true) {
				const nextMatch = message.match(regex);
				if (nextMatch == null) break;

				const replace = nextMatch[0];
				const key = nextMatch[1];

				// first check against the model, then the replaceable list, otherwise use placeholder default (to allow regex to proceed)
				const val = (model ? this.getValue(model, key) : null) ?? (replaceableList ? replaceableList[key] : null) ?? `{${key}}`;
				message = message.replace(replace, val);
			}
		}
		return message;
	};

	private readonly getValue = (model: any, key: string) => {
		const kv = key.toUpperCase();
		for (const prop in model) {
			if (prop.toUpperCase() == kv) return model[prop];
		}

		return null;
	};
}
