import {
	AfterViewChecked,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	DoCheck,
	ElementRef,
	EventEmitter,
	HostBinding,
	Input,
	IterableDiffers,
	NgZone,
	Output,
	ViewEncapsulation,
} from '@angular/core';

declare const google: any;

@Component({
	selector: 'p-gmap',
	template: `<div [ngStyle]="style" [class]="styleClass"></div>`,
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
})
export class GMapComponent implements AfterViewChecked, DoCheck {
	@HostBinding('class') elementClass = 'p-element';

	@Input() style: any;

	@Input() styleClass: string;

	@Input() options: any;

	@Input() overlays: any[];

	@Output() mapClick: EventEmitter<any> = new EventEmitter();

	@Output() overlayClick: EventEmitter<any> = new EventEmitter();

	@Output() overlayDblClick: EventEmitter<any> = new EventEmitter();

	@Output() overlayDragStart: EventEmitter<any> = new EventEmitter();

	@Output() overlayDrag: EventEmitter<any> = new EventEmitter();

	@Output() overlayDragEnd: EventEmitter<any> = new EventEmitter();

	@Output() mapReady: EventEmitter<any> = new EventEmitter();

	@Output() mapDragEnd: EventEmitter<any> = new EventEmitter();

	@Output() zoomChanged: EventEmitter<any> = new EventEmitter();

	differ: any;

	map: any;

	constructor(public el: ElementRef, differs: IterableDiffers, public cd: ChangeDetectorRef, public zone: NgZone) {
		this.differ = differs.find([]).create(null);
	}

	ngAfterViewChecked() {
		if (!this.map && this.el.nativeElement.offsetParent) {
			this.initialize();
		}
	}

	initialize() {
		this.map = new google.maps.Map(this.el.nativeElement.children[0], this.options);
		this.mapReady.emit({
			map: this.map,
		});

		if (this.overlays) {
			for (const overlay of this.overlays) {
				overlay.setMap(this.map);
				this.bindOverlayEvents(overlay);
			}
		}

		this.map.addListener('click', event => {
			this.zone.run(() => {
				this.mapClick.emit(event);
			});
		});

		this.map.addListener('dragend', event => {
			this.zone.run(() => {
				this.mapDragEnd.emit(event);
			});
		});

		this.map.addListener('zoom_changed', event => {
			this.zone.run(() => {
				this.zoomChanged.emit(event);
			});
		});
	}

	bindOverlayEvents(overlay: any) {
		overlay.addListener('click', event => {
			this.zone.run(() => {
				this.overlayClick.emit({
					originalEvent: event,
					overlay: overlay,
					map: this.map,
				});
			});
		});

		overlay.addListener('dblclick', event => {
			this.zone.run(() => {
				this.overlayDblClick.emit({
					originalEvent: event,
					overlay: overlay,
					map: this.map,
				});
			});
		});

		if (overlay.getDraggable()) {
			this.bindDragEvents(overlay);
		}
	}

	ngDoCheck() {
		const changes = this.differ.diff(this.overlays);

		if (changes && this.map) {
			changes.forEachRemovedItem(record => {
				google.maps.event.clearInstanceListeners(record.item);
				record.item.setMap(null);
			});

			changes.forEachAddedItem(record => {
				record.item.setMap(this.map);
				record.item.addListener('click', event => {
					this.zone.run(() => {
						this.overlayClick.emit({
							originalEvent: event,
							overlay: record.item,
							map: this.map,
						});
					});
				});

				if (record.item.getDraggable()) {
					this.bindDragEvents(record.item);
				}
			});
		}
	}

	bindDragEvents(overlay) {
		overlay.addListener('dragstart', event => {
			this.zone.run(() => {
				this.overlayDragStart.emit({
					originalEvent: event,
					overlay: overlay,
					map: this.map,
				});
			});
		});

		overlay.addListener('drag', event => {
			this.zone.run(() => {
				this.overlayDrag.emit({
					originalEvent: event,
					overlay: overlay,
					map: this.map,
				});
			});
		});

		overlay.addListener('dragend', event => {
			this.zone.run(() => {
				this.overlayDragEnd.emit({
					originalEvent: event,
					overlay: overlay,
					map: this.map,
				});
			});
		});
	}

	getMap() {
		return this.map;
	}
}
