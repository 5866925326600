import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Table } from 'primeng/table';
import { BusinessEntityDetails } from 'src/app/features/contact/shared/models';
import { EntityInsuranceConstants } from 'src/app/shared/constants/entity-insurance.constant';
import { BusinessEntityInsurance } from 'src/app/shared/models/business-entities';
import { RegionSettings } from 'src/app/shared/models/settings';
import { EntityInsuranceApiService } from 'src/app/shared/services';
import { BaseComponent } from 'src/app/core/components/base.component';
import { AppContextService, TooltipMessageService } from 'src/app/core/services';

@Component({
	selector: 'app-entity-insurance-table',
	templateUrl: './entity-insurance-table.component.html',
})
export class EntityInsuranceTableComponent extends BaseComponent implements OnInit {
	@Input() businessEntityDetails: BusinessEntityDetails;

	@ViewChild('recordsTable') recordsTable: Table;
	selectedRecord: BusinessEntityInsurance;
	showList = true;
	records: BusinessEntityInsurance[] = [];
	refreshRequired = false;
	region: RegionSettings;
	constructor(
		appContext: AppContextService,
		private entityInsuranceService: EntityInsuranceApiService,
		private route: ActivatedRoute,
		private tooltipMessageService: TooltipMessageService
	) {
		super(appContext);
	}

	ngOnInit() {
		this.region = this.appContext.getRegion();
	}

	public refreshData() {
		if (this.businessEntityDetails.BusinessEntityId) {
			try {
				this.entityInsuranceService.getBusinessEntityInsurancesForBusinessEntity(this.businessEntityDetails.BusinessEntityId).subscribe(result => {
					this.records = result;
					this.showList = true;
					this.appContext.HasTableBasedData = true;
				});
			} catch (ex) {
				this.util.hideWait();
				console.error(ex);
			}
		}
	}

	addClick() {
		this.showList = false;
		this.appContext.HasTableBasedData = false;

		this.selectedRecord = new BusinessEntityInsurance();
		this.selectedRecord.BusinessEntityId = this.businessEntityDetails.BusinessEntityId;
	}

	expandClick(item: BusinessEntityInsurance) {
		this.selectedRecord = item;
		this.showList = false;
		this.appContext.HasTableBasedData = false;
	}

	editFinished() {
		this.refreshRequired = true;
	}

	detailsClosed() {
		this.showList = true;
		this.appContext.HasTableBasedData = true;

		if (this.refreshRequired) this.refreshData();
	}

	showAddRecord(): boolean {
		if (this.businessEntityDetails.DateAdded == undefined) return false;
		return this.businessEntityDetails?.ObjectAccess?.AllowAddInsurance?.Hide != true;
	}

	allowAddRecord(): boolean {
		if (this.businessEntityDetails.DateAdded == undefined) return false;
		return this.businessEntityDetails?.ObjectAccess?.AllowEdit?.IsAllowed;
	}

	getAddTooltip(): string {
		if (this.businessEntityDetails.DateAdded != undefined) {
			return (
				this.tooltipMessageService.getTooltipMessage(
					'C_BUSINESS_ENTITY',
					'AllowEdit',
					this.businessEntityDetails?.ObjectAccess?.AllowEdit,
					this.businessEntityDetails
				) ?? EntityInsuranceConstants.Tab_detail_AddInsurance
			);
		}
	}
}
