export class ObjectAccess {
	AllowEdit: ObjectAccessReason;
	AllowRemove: ObjectAccessReason;
	AllowFlagEdit: ObjectAccessReason;

	// generic
	AllowReview: ObjectAccessReason;
	AllowSend: ObjectAccessReason;

	AllowAddItem: ObjectAccessReason;
	NewItemAccess: ObjectAccess;

	AllowFlowToScope: ObjectAccessReason;
	AllowFlowToQuote: ObjectAccessReason;
	AllowFlowToWorkOrder: ObjectAccessReason;
	AllowFlowToInvoice: ObjectAccessReason;
	AllowFlowToServiceRequest: ObjectAccessReason;
	AllowViewPlan: ObjectAccessReason;
	AllowPrinting: ObjectAccessReason;

	// SRQ
	AllowAmend: ObjectAccessReason;

	// order
	AllowBulkAllocateOrderItems: ObjectAccessReason;
	AllowDefectWithdraw: ObjectAccessReason;

	NewItemReviewAccess: ObjectAccess;

	// quote
	AllowAddRecipient: ObjectAccessReason;
	NewRecipientAccess: ObjectAccess;

	// invoice
	AllowApprove: ObjectAccessReason;
	AllowPaid: ObjectAccessReason;
	AllowHold: ObjectAccessReason;
	AllowRelease: ObjectAccessReason;
	AllowVoid: ObjectAccessReason;
	AllowAddCreditNote: ObjectAccessReason;

	AllowAddPayment: ObjectAccessReason;
	NewPaymentAccess: ObjectAccess;

	// config
	AllowAddInsurance: ObjectAccessReason;
	NewInsuranceAccess: ObjectAccess;

	AllowAddLicence: ObjectAccessReason;
	NewLicenceAccess: ObjectAccess;

	AllowAddCostPrice: ObjectAccessReason;
	NewCostPriceAccess: ObjectAccess;

	// accounts
	AllowAddPermission: ObjectAccessReason;
	NewPermissionAccess: ObjectAccess;

	//system interface
	AllowRecreate: ObjectAccessReason;
	AllowResend: ObjectAccessReason;
	AllowReprocess: ObjectAccessReason;
}

export class ObjectAccessReason {
	IsNew: boolean;
	IsRemoved: boolean;

	MissingPermission: boolean;
	IncorrectType: boolean;
	IncorrectStatus: boolean;
	IncorrectEntityType: boolean;
	MissingDates: boolean;
	MissingCodes: boolean;

	IsNotAllocated: boolean;
	IsDraft: boolean;
	IsInvoiced: boolean;
	IsDefected: boolean;
	IsReviewed: boolean;
	IsQuoted: boolean;
	HasOverriddenPrice: boolean;

	IsSystem: boolean;
	IncorrectConfig: boolean;

	DisableOverride: boolean;
	AlwaysHide: boolean;

	// combined check
	IsAllowed: boolean;
	Hide: boolean;

	// flags
	BlockDefectedItemChanges: boolean;
	BlockQuotedItemChanges: boolean;
	BlockQrBarcodeEditing: boolean;
	BlockDetailEditing: boolean;

	ShowOrderPriceOverride: boolean;
	AllowTaxCodeChanges: boolean;
}
