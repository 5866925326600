import { Directive } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';
import { ValidationConstants } from 'src/app/core/constants/validation.constant';

@Directive({
	selector: '[validateLatitude]',
	providers: [
		{
			provide: NG_VALIDATORS,
			useExisting: LatitudeValidatorDirective,
			multi: true,
		},
	],
})
export class LatitudeValidatorDirective implements Validator {
	validate(control: AbstractControl): ValidationErrors | null {
		if (control.value == null || control.value === '') {
			return null;
		}
		const validator = new RegExp(ValidationConstants.latitudeRegExp);
		return validator.test(control.value) ? null : { latitude: 'not matched' };
	}
}
