import { ObjectAccess } from 'src/app/shared/models/api';

export class UserProfileList {
	UserProfileId: string;
	Username: string;
	DisplayName: string;
	ContactPhone: string;
	ContactMobile: string;
	ContactEmail: string;
	StatusName: string;
	UserRoleName: string;
	ClientId: string;
	ClientName: string;
	ObjectAccess: ObjectAccess;
}
