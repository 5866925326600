import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';

@Component({
	selector: 'app-progress-bar',
	templateUrl: './progress-bar.component.html',
	styleUrls: ['./progress-bar.component.scss'],
})
export class ProgressBarComponent implements OnDestroy {
	@Input() isProcessingRecords = false;
	@Output() runBulkActionMethod = new EventEmitter<void>();
	@Output() cancelBulkActionMethod = new EventEmitter<void>();

	progressValue = 0;
	timeRemaining = '';
	processingCount = '';

	runAction() {
		this.runBulkActionMethod.emit();
	}

	calculateProcessingTimeAndCount(processingCount: number, durationToProcessFirstRecordms: number, totalRecords = 0) {
		const totalRecordsLeftToProcess: number = totalRecords - processingCount;
		const durationToProcessFirstRecordsec = durationToProcessFirstRecordms / 1000;
		const durationToProcessFirstRecordmin = durationToProcessFirstRecordms / 60000;

		const resttotalTimeRemaininginSeconds = Math.round((totalRecordsLeftToProcess - 1) * durationToProcessFirstRecordsec);
		const resttotalTimeRemaininginMinutes = Math.round((totalRecordsLeftToProcess - 1) * durationToProcessFirstRecordmin);
		if (resttotalTimeRemaininginSeconds > 30) {
			this.timeRemaining = `${resttotalTimeRemaininginMinutes} mins time remaining`;
		} else if (resttotalTimeRemaininginSeconds < 30 && resttotalTimeRemaininginSeconds >= 0) {
			this.timeRemaining = `${resttotalTimeRemaininginSeconds} secs time remaining`;
		} else {
			this.timeRemaining = '0 secs time remaining';
		}

		this.processingCount = `${processingCount}/${totalRecords}`;

		this.progressValue = (100 / totalRecords) * processingCount;

		if (processingCount === totalRecords) {
			this.finishProcess();
		}
	}

	cancelProgress() {
		this.cancelBulkActionMethod.emit();
		this.resetToDefaultValues();
	}

	finishProcess() {
		this.resetToDefaultValues();
	}

	ngOnDestroy() {
		this.resetToDefaultValues();
	}

	getTimeRemaining(): string {
		if (this.timeRemaining === '') return '0 secs time remaining';
		return this.timeRemaining;
	}

	getProcessingCount(): string {
		if (this.processingCount === '') return '0/0';

		return this.processingCount;
	}

	getProgressValue(): number {
		return this.progressValue;
	}

	getCurrentTime(): number {
		return new Date().getTime();
	}

	showProgressBar(): boolean {
		return this.isProcessingRecords;
	}

	resetToDefaultValues() {
		this.isProcessingRecords = false;
		this.timeRemaining = '';
		this.processingCount = '';
		this.progressValue = 0;
	}
}
