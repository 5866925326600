<p-table
	#dynamicTable
	[attr.data-testid]="dataTestIdTable"
	[dataKey]="dataKey"
	[rowHover]="true"
	[paginator]="true"
	[showCurrentPageReport]="true"
	[rows]="50"
	[lazy]="true"
	[stateKey]="stateKey"
	[value]="data"
	[loading]="loading"
	currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
	[totalRecords]="totalRecords"
	[(first)]="first"
	[filterDelay]="0"
	[rowsPerPageOptions]="[10, 25, 50]"
	selectionMode="multiple"
	stateStorage="local"
	[(selection)]="bulkActionService.selectedItems"
	[reorderableColumns]="true"
	[columns]="selectedColumns"
	[resizableColumns]="true"
	columnResizeMode="fit"
	(onLazyLoad)="onLazyLoad($event)"
	(selectionChange)="onSelectionChange($event)"
	(onColReorder)="onSaveColumnOrderToStorage($event)"
	(onPage)="onPageChange($event)">
	<ng-template pTemplate="header">
		<tr>
			<th *ngIf="bulkActionService && bulkActionService.isBulkActionView()" class="p-text-center">
				<p-tableHeaderCheckbox></p-tableHeaderCheckbox>
			</th>
			<th *ngIf="bulkActionService && bulkActionService.isBulkActionView()" class="bulkaction-icon-container"></th>

			<th *ngFor="let col of selectedColumns" [pSortableColumn]="col.sortField" [style]="{ width: col.width + 'px' }" pReorderableColumn pResizableColumn>
				<div class="table-header-label">
					{{ col.header }} <span *ngIf="col.isPrice">&nbsp;{{ region.CurrencySymbol }}</span>
					<p-sortIcon [attr.data-testid]="col.dataTestId" [field]="col.sortField" *ngIf="col.isSortable"></p-sortIcon>
					<p-columnFilter
						*ngIf="col.isFilterable && !col.isFilterCallback"
						[type]="col.type"
						[attr.data-testid]="col.filterdataTestId"
						[field]="col.filterField"
						[matchModeOptions]="col.matchModeOptions"
						display="menu"
						class="p-ml-auto">
					</p-columnFilter>
					<p-columnFilter *ngIf="col.isFilterable && col.isFilterCallback" [type]="col.filterType" [field]="col.filterField" display="menu" class="p-ml-auto">
						<ng-template
							*ngIf="(col.type === ColumnTypesEnum.DATE || ColumnTypesEnum.DATETIME) && col.hasFilterHeaderTemplate"
							pTemplate="filter"
							let-value
							let-filter="filterCallback">
							<app-inline-date-time-picker
								#calendar
								[ngModel]="value"
								[showIcon]="true"
								(onSelect)="dateFilter(calendar.value, col.filterField)"></app-inline-date-time-picker>
						</ng-template>
						<ng-template
							*ngIf="col.type === ColumnTypesEnum.NUMERIC && col.isPrice && col.hasFilterHeaderTemplate"
							pTemplate="filter"
							let-value
							let-filter="filterCallback">
							<p-inputNumber
								#number
								[ngModel]="value"
								[showButtons]="true"
								[locale]="region.RegionCode"
								(onBlur)="currencyFilter(number.value, col.filterField)"
								[minFractionDigits]="region.CurrencyPrecision"
								[maxFractionDigits]="region.CurrencyPrecision"
								autocomplete="off"></p-inputNumber>
						</ng-template>
						<ng-template *ngIf="col.type === ColumnTypesEnum.BOOLEAN && col.hasFilterHeaderTemplate" pTemplate="filter" let-value let-filter="filterCallback">
							<p-checkbox #checkbox [ngModel]="value" [binary]="true" (onChange)="checkboxFilter(checkbox.model, col.filterField)"></p-checkbox>
							{{ value == true ? 'yes' : value == false ? ' no' : ' any' }}
						</ng-template>
					</p-columnFilter>
				</div>
				<div *ngIf="col.type === ColumnTypesEnum.NUMERIC && col.isPrice && col.hasHeaderSmallLabel" class="title-label-small">(incl. tax)</div>
			</th>
		</tr>
	</ng-template>
	<ng-template pTemplate="body" let-item>
		<tr [attr.data-testid]="dataTestIdRow" class="p-selectable-row" (click)="onRowClick(item)">
			<td *ngIf="bulkActionService && bulkActionService.isBulkActionView()">
				<div class="p-text-center">
					<p-tableCheckbox
						data-testid="bulkActionCheckBox"
						*ngIf="bulkActionService.showBulkAction(item)"
						[disabled]="!bulkActionService.allowBulkAction(item)"
						[index]="rowIndex"
						class="bulkaction-checkbox-custom"
						[value]="item"></p-tableCheckbox>
					<button
						data-testid="bulkActionPadlock"
						*ngIf="!bulkActionService.showBulkAction(item)"
						pButton
						pRipple
						type="button"
						[pTooltip]="bulkActionService.getPadlockTooltip(item)"
						tooltipPosition="bottom"
						icon="pi pi-lock"
						class="p-button-rounded-md p-button-text"></button>
				</div>
			</td>
			<td *ngIf="bulkActionService && bulkActionService.isBulkActionView()" class="bulkaction-icon-container">
				<div>
					<button
						data-testid="bulkActionSuccessIcon"
						*ngIf="bulkActionService.showSuccessIcon(item)"
						pButton
						pRipple
						type="button"
						[pTooltip]=""
						tooltipPosition="bottom"
						icon="fa fa-check-circle"
						class="p-button-rounded-md p-button-text mark success"></button>
					<button
						data-testid="bulkActionErrorIcon"
						*ngIf="bulkActionService.showErrorIcon(item)"
						pButton
						pRipple
						type="button"
						(click)="bulkActionService.showErrorDialog(item)"
						[pTooltip]="bulkActionService.getActionErrorMessage(item)"
						tooltipPosition="bottom"
						icon="fas fa-exclamation-circle"
						class="p-button-rounded-md p-button-text mark error"></button>
				</div>
			</td>
			<td *ngFor="let col of selectedColumns" [style]="{ width: col.width + 'px' }" [attr.data-testid]="col.dataTestId + 'Data'">
				<ng-container [ngSwitch]="col.type">
					<ng-container *ngSwitchCase="ColumnTypesEnum.TEXT">
						<span class="wrap-text">
							{{ item[col.field] }}
						</span>
					</ng-container>
					<ng-container *ngSwitchCase="ColumnTypesEnum.NUMERIC">
						<span *ngIf="!col.isPrice">
							{{ item[col.field] }}
						</span>
						<span *ngIf="col.isPrice && viewPrices" class="text-right">
							{{ item[col.field] | formatNumber : '1.' + region.CurrencyPrecision + '-' + region.CurrencyPrecision : region.RegionCode }}
						</span>
					</ng-container>
					<ng-container *ngSwitchCase="ColumnTypesEnum.DATE">
						<span *ngIf="col.isInnerHtmlFormat">
							<span [innerHTML]="item[col.field] | formatDate : region.RegionCode : region.DateFormat"></span>
						</span>
					</ng-container>
					<ng-container *ngSwitchCase="ColumnTypesEnum.DATETIME">
						<span *ngIf="col.isInnerHtmlFormat">
							<span [innerHTML]="item[col.field] | formatDate : region.RegionCode : region.DateFormat : region.TimeFormat"></span>
						</span>
					</ng-container>
					<ng-container *ngSwitchCase="ColumnTypesEnum.BOOLEAN">
						<span>
							{{ item[col.field] ? 'yes' : 'no' }}
						</span>
					</ng-container>
				</ng-container>
			</td>
		</tr>
	</ng-template>
</p-table>
