<ng-template [ngIf]="visible">
	<ng-template [ngIf]="editable" [ngIfElse]="readMode" [ngSwitch]="controlType">
		<div *ngIf="controlType == formControls.ColorPicker">
			<p-inputGroup class="custom-color-picker" *ngSwitchCase="formControls.ColorPicker">
				<div class="p-field p-fluid p-float-label">
					<input
						[id]="name"
						[tabindex]="tabindex"
						[name]="name"
						[maxlength]="maxlength"
						[formControl]="control"
						type="text"
						#cpInput
						(keyup)="onInput($event)"
						pInputText
						(blur)="onBlur($event)"
						(onBlur)="onBlur($event)"
						autocomplete="off" />
					<label for="name">{{ label }}</label>
				</div>
				<p-inputGroupAddon class="p-inputgroup-addon">
					<p-colorPicker
						#cp
						[id]="name + 'Picker'"
						[tabindex]="tabindex"
						[name]="name + 'Picker'"
						[formControl]="control"
						(onChange)="onColorChange($event)"
						[inline]="false"
						[appendTo]="appendTo"></p-colorPicker>
				</p-inputGroupAddon>
			</p-inputGroup>
		</div>
		<div
			*ngIf="controlType != formControls.ColorPicker"
			class="p-field p-fluid"
			[ngClass]="{
				'p-float-label': controlType != formControls.CheckBox
			}">
			<input
				*ngSwitchCase="formControls.Textbox"
				[id]="name"
				[tabindex]="tabindex"
				[name]="name"
				[maxlength]="maxlength"
				[formControl]="control"
				type="text"
				#thisControl
				(keyup)="onInput($event)"
				pInputText
				(blur)="onBlur($event)"
				(onBlur)="onBlur($event)"
				autocomplete="off" />
			<p-inputNumber
				*ngSwitchCase="formControls.AmountTextbox"
				[id]="name"
				[tabindex]="tabindex"
				[name]="name"
				[formControl]="control"
				(onInput)="onInput($event)"
				[label]="label"
				[minFractionDigits]="region.CurrencyPrecision"
				[maxFractionDigits]="region.CurrencyPrecision"
				[min]="minAmountValue"
				(blur)="onBlur($event)"
				(onBlur)="onBlur($event)"
				autocomplete="off"></p-inputNumber>
			<textarea
				*ngSwitchCase="formControls.Textarea"
				[id]="name"
				[tabindex]="tabindex"
				[name]="name"
				[formControl]="control"
				[rows]="5"
				pInputTextarea
				(blur)="onBlur($event)"
				(onBlur)="onBlur($event)"
				autocomplete="off"></textarea>
			<div
				*ngSwitchCase="formControls.FormattedTextarea"
				[id]="name"
				[tabindex]="tabindex"
				[name]="name"
				[label]="label"
				[formControl]="control"
				(blur)="onBlur($event)"
				(onBlur)="onBlur($event)"
				autocomplete="off"></div>
			<p-calendar
				*ngSwitchCase="formControls.Calendar"
				[dateFormat]="dateFormat"
				[showTime]="showTime"
				[hourFormat]="region.Use24Hours ? '24' : '12'"
				[formControl]="control"
				[id]="name"
				[minDate]="minDate"
				[maxDate]="maxDate"
				[tabindex]="tabindex"
				[name]="name"
				[showIcon]="true"
				(onFocus)="onCalendarFocus()"
				(onInput)="onDateInput($event)"
				(onSelect)="onDateChange($event)"
				[styleClass]="displayError ? 'invalid' : null"
				(blur)="onBlur($event)"
				(onBlur)="onBlur($event)"
				[defaultDate]="defaultDateBasedOnTimeZone"
				[appendTo]="appendTo">
			</p-calendar>
			<p-calendar
				*ngSwitchCase="formControls.CalendarDate"
				[dateFormat]="dateFormat"
				[showTime]="false"
				[formControl]="control"
				[id]="name"
				[minDate]="minDate"
				[tabindex]="tabindex"
				[name]="name"
				[showIcon]="true"
				(onFocus)="onCalendarFocus($event)"
				(onInput)="onDateInput($event)"
				(onSelect)="onDateChange($event)"
				[styleClass]="displayError ? 'invalid' : null"
				(blur)="onBlur($event)"
				(onBlur)="onBlur($event)"
				[defaultDate]="defaultDateBasedOnTimeZone"
				[appendTo]="appendTo">
			</p-calendar>
			<p-calendar
				*ngSwitchCase="formControls.CalendarTime"
				[hourFormat]="region.Use24Hours ? '24' : '12'"
				[formControl]="control"
				[id]="name"
				[tabindex]="tabindex"
				[name]="name"
				[showIcon]="true"
				(onFocus)="onCalendarFocus()"
				(onInput)="onDateInput($event)"
				(onSelect)="onDateChange($event)"
				[styleClass]="displayError ? 'invalid' : null"
				(blur)="onBlur($event)"
				(onBlur)="onBlur($event)"
				[timeOnly]="true"
				[defaultDate]="defaultDateBasedOnTimeZone"
				[appendTo]="appendTo">
			</p-calendar>
			<p-autoComplete
				*ngSwitchCase="formControls.AutocompleteWithIcon"
				[name]="name"
				[suggestions]="filteredValues"
				[dropdown]="true"
				field="NameValue"
				(completeMethod)="search($event)"
				[id]="name"
				[tabindex]="tabindex"
				[name]="name"
				(onSelect)="onInput($event)"
				(onClear)="onInput()"
				[formControl]="control"
				[minLength]="autoCompleteMinLength"
				(blur)="onBlur($event)"
				(onBlur)="onBlur($event)"
				[placeholder]="placeholder"
				autocomplete="off"
				[forceSelection]="false"
				on>
				<ng-template let-lookupValue pTemplate="item">
					<div style="display: flex; flex-direction: row; align-items: center">
						<span [style.width]="isIconAutoComplete === false ? '2%' : '5%'" style="vertical-align: middle; height: 100%; text-align: center">
							<i
								[class]="isIconAutoComplete === false ? defaultIconClass : getIconClass(lookupValue.CodeValue) ?? defaultIconClass"
								[style.fontSize]="isIconAutoComplete === false ? defaultIconFontSize : lookupValue.AdditionalDetails.IntValue + 'px' ?? defaultIconFontSize"
								[style.color]="isIconAutoComplete === false ? lookupValue.CodeValue ?? defaultIconColor : defaultIconColor"></i></span
						><span style="padding-left: 10px"> {{ lookupValue.ExtendedNameValue ?? lookupValue.NameValue }}</span>
					</div>
				</ng-template>
			</p-autoComplete>
			<p-autoComplete
				*ngSwitchCase="formControls.Autocomplete"
				[name]="name"
				[suggestions]="filteredValues"
				[dropdown]="true"
				field="NameValue"
				(completeMethod)="search($event)"
				[id]="name"
				[tabindex]="tabindex"
				[name]="name"
				(onSelect)="onInput($event)"
				(onClear)="onInput()"
				[formControl]="control"
				[minLength]="autoCompleteMinLength"
				(blur)="onBlur($event)"
				(onBlur)="onBlur($event)"
				[placeholder]="placeholder"
				autocomplete="off"
				[multiple]="multiple"
				[forceSelection]="false"
				on>
				<ng-template let-lookupValue pTemplate="item">
					<div *ngIf="!autoCompleteShowCodes">
						<div *ngIf="hasAdditionalDisplayValue">
							<span class="is-completed-style"> <i class="fa fa-circle {{ lookupValue.AdditionalDisplayValue }}"></i></span>
							{{ lookupValue.ExtendedNameValue ?? lookupValue.NameValue }}
						</div>
						<span *ngIf="!hasAdditionalDisplayValue && displayWithStyle" class="custom-autoCompleteChip" [ngClass]="lookupValue.Style">
							{{ lookupValue.ExtendedNameValue ?? lookupValue.NameValue }}
						</span>
						<span *ngIf="!hasAdditionalDisplayValue && !displayWithStyle">{{ lookupValue.ExtendedNameValue ?? lookupValue.NameValue }}</span>
					</div>
					<div *ngIf="autoCompleteShowCodes">
						<div *ngIf="hasAdditionalDisplayValue">
							<span class="is-completed-style"> <i class="fa fa-circle {{ lookupValue.AdditionalDisplayValue }}"></i></span>
							{{ lookupValue.ExtendedCodeValue ?? lookupValue.CodeValue }}
						</div>
						<span *ngIf="!hasAdditionalDisplayValue && displayWithStyle" class="custom-autoCompleteChip" [ngClass]="lookupValue.Style">
							{{ lookupValue.ExtendedCodeValue ?? lookupValue.CodeValue }}
						</span>
						<span *ngIf="!hasAdditionalDisplayValue && !displayWithStyle">{{ lookupValue.ExtendedCodeValue ?? lookupValue.CodeValue }}</span>
					</div>
				</ng-template>
				<ng-template *ngIf="multiple" let-lookupValue pTemplate="selectedItem">
					<div *ngIf="!displayWithStyle">
						{{ lookupValue.NameValue }}
					</div>
					<div *ngIf="displayWithStyle">
						<span class="custom-autoCompleteChip" [ngClass]="lookupValue.Style">{{ lookupValue.NameValue }}</span>
					</div>
				</ng-template>
			</p-autoComplete>
			<p-checkbox
				*ngSwitchCase="formControls.CheckBox"
				[id]="name"
				[tabindex]="tabindex"
				[name]="name"
				[formControl]="control"
				[binary]="true"
				[label]="label"
				(blur)="onBlur($event)"
				(onBlur)="onBlur($event)"
				(onChange)="onInput($event)">
			</p-checkbox>
			<p-radioButton
				*ngSwitchCase="formControls.RadioButton"
				[value]="inputId"
				[id]="name"
				[name]="name"
				[inputId]="inputId"
				[tabindex]="tabindex"
				[formControl]="control"
				[label]="label"></p-radioButton>
			<p-inputNumber
				*ngSwitchCase="formControls.Numeric"
				[id]="name"
				[tabindex]="tabindex"
				[name]="name"
				[formControl]="control"
				(onInput)="onInput($event)"
				[label]="label"
				[minFractionDigits]="minFractionDigits"
				[maxFractionDigits]="maxFractionDigits"
				[min]="minValue"
				(blur)="onBlur($event)"
				(onBlur)="onBlur($event)"
				autocomplete="off">
			</p-inputNumber>
			<div *ngSwitchCase="formControls.SelectButton">
				<span>{{ label }}</span>
				<p-selectButton
					[options]="selectBtnOptions"
					[id]="name"
					[tabindex]="tabindex"
					[name]="name"
					[formControl]="control"
					optionLabel="name"
					optionValue="code">
					<ng-template let-item>
						<i [class]="item.icon"></i>
					</ng-template>
				</p-selectButton>
			</div>

			<p-multiSelect
				*ngSwitchCase="formControls.MultiSelect"
				display="chip"
				[id]="name"
				[name]="name"
				[options]="lookupValues"
				[tabindex]="tabindex"
				optionLabel="NameValue"
				[formControl]="control"
				(onChange)="onInput($event)"
				(blur)="onBlur($event)"
				(onBlur)="onBlur($event)"
				[appendTo]="appendTo"></p-multiSelect>

			<p-multiSelect
				*ngSwitchCase="formControls.MultiSelectCustom"
				display="chip"
				[id]="name"
				[name]="name"
				[options]="lookupValues"
				[tabindex]="tabindex"
				optionLabel="NameValue"
				[formControl]="control"
				(onChange)="onInput($event)"
				(blur)="onBlur($event)"
				(onBlur)="onBlur($event)"
				class="multiselect-custom"
				defaultLabel="Options ...">
				<ng-template let-value pTemplate="selectedItems">
					<div class="custom-multiselectChip-option-item custom-multiselectChip-option-item-value" *ngFor="let option of selectedLookupValues">
						<div *ngIf="option.Style && !option.AdditionalDetails" class="custom-multiselectChip" [ngClass]="option.Style">
							{{ option.NameValue }}
						</div>
						<div
							*ngIf="!option.Style && option.AdditionalDetails"
							class="custom-multiselectChip"
							[style.background-color]="option.AdditionalDetails.ColourCode">
							{{ option.NameValue }}
						</div>
					</div>
					<div class="options-placeholder">
						<span *ngIf="!selectedLookupValues || selectedLookupValues.length === 0" class="placeholder-text">Options ...</span>
						<span *ngIf="selectedLookupValues && selectedLookupValues.length > 0" class="placeholder-text"></span>
						<span class="placeholder-text pi pi-chevron-down"></span>
					</div>
				</ng-template>
				<ng-template let-option pTemplate="item">
					<div class="custom-multiselectChip-option-item">
						<div *ngIf="option.Style && !option.AdditionalDetails" class="custom-multiselectChip" [ngClass]="option.Style">
							{{ option.NameValue }}
						</div>
						<div
							*ngIf="!option.Style && option.AdditionalDetails"
							class="custom-multiselectChip"
							[style.background-color]="option.AdditionalDetails.ColourCode">
							{{ option.NameValue }}
						</div>
					</div>
				</ng-template>
			</p-multiSelect>

			<p-chips
				*ngSwitchCase="formControls.Chips"
				[inputId]="name"
				[name]="name"
				[tabindex]="tabindex"
				[formControl]="control"
				[addOnBlur]="true"
				[addOnTab]="true"
				autocomplete="off">
			</p-chips>
			<p *ngSwitchCase="formControls.InputInfoBox" [id]="name" [name]="name" [formControl]="control"></p>
			<p-autoComplete
				*ngSwitchCase="formControls.DropDownGroup"
				[id]="name"
				[name]="name"
				[dropdown]="true"
				field="label"
				(completeMethod)="searchGrouped($event)"
				[tabindex]="tabindex"
				[formControl]="control"
				[minLength]="autoCompleteMinLength"
				(blur)="onBlur($event)"
				(onBlur)="onBlur($event)"
				(onSelect)="onInput($event)"
				(onClear)="onInput()"
				[suggestions]="filteredGroupLookupValues"
				[placeholder]="placeholder"
				autocomplete="off"
				[forceSelection]="off"
				[group]="true">
				<ng-template let-item pTemplate="item">
					<div class="item-drop viewby item-option p-d-flex p-jc-between">
						<div class="viewby item-value">
							{{ item.label }}
						</div>
					</div>
				</ng-template>
				<ng-template let-group pTemplate="group">
					<div class="viewby headline p-d-flex">
						<i class="{{ group.icon }} viewby icon"></i>
						<div class="label viewby">{{ group.label }}</div>
					</div>
				</ng-template>
			</p-autoComplete>
			<label [attr.for]="name" *ngIf="![formControls.CheckBox, formControls.SelectButton, formControls.RadioButton].includes(controlType)">{{ label }}</label>
		</div>
		<div *ngIf="displayError && error" style="display: block; position: relative; margin-bottom: 2em">
			<small class="p-error block" style="position: absolute; margin-top: -1.2em">{{ error }}</small>
		</div>
	</ng-template>
	<ng-template #readMode [ngSwitch]="controlType">
		<div *ngSwitchCase="formControls.AmountTextbox">
			<div class="p-field p-grid">
				<div [class]="'p-col-12 p-md-' + labelMarginWidth">
					<div style="padding: 0px; white-space: nowrap" class="field-label-read-only">
						{{ label.split('(')[0] }}
					</div>
					<div style="padding: 0px" class="field-label-small" *ngIf="label.indexOf('(') >= 0">({{ label.split(' (')[1] }}</div>
				</div>
				<div [name]="name" [class]="'p-col-12 p-md-' + valueMarginWidth" style="text-align: right; padding-right: 30%">
					{{
						valueMask
							? valueMask
							: value && value != 'NaN'
							? (value | formatNumber : '1.' + region.CurrencyPrecision + '-' + region.CurrencyPrecision : region.RegionCode)
							: '-'
					}}
					<div class="icon-container" [pTooltip]="iconAfterTextboxTooltip"><i [ngClass]="iconAfterTextboxReadOnly"></i></div>
				</div>
			</div>
		</div>
		<div *ngSwitchCase="formControls.Calendar">
			<div class="p-field p-grid">
				<label for="name" [class]="'field-label-read-only p-col-12 p-md-' + labelMarginWidth">{{ label }}</label>
				<div [class]="'p-col-12 p-md-' + valueMarginWidth">
					<span [innerHTML]="value ? (value | formatDate : region.RegionCode : region.DateFormat : region.TimeFormat) : '-'"></span>
				</div>
			</div>
		</div>
		<div *ngSwitchCase="formControls.CalendarDate">
			<div class="p-field p-grid">
				<label for="name" [class]="'field-label-read-only p-col-12 p-md-' + labelMarginWidth">{{ label }}</label>
				<div [class]="'p-col-12 p-md-' + valueMarginWidth">
					<span [innerHTML]="value ? (value | formatDate : region.RegionCode : region.DateFormat) : '-'"></span>
				</div>
			</div>
		</div>
		<div *ngSwitchCase="formControls.CalendarTime">
			<div class="p-field p-grid">
				<label for="name" [class]="'field-label-read-only p-col-12 p-md-' + labelMarginWidth">{{ label }}</label>
				<div [class]="'p-col-12 p-md-' + valueMarginWidth">
					{{ value ? (value | formatTime : region.TimeFormat : region.RegionCode) : '-' }}
				</div>
			</div>
		</div>
		<div *ngSwitchCase="formControls.Textbox">
			<div class="p-field p-grid">
				<label for="name" [class]="'field-label-read-only p-col-12 p-md-' + labelMarginWidth">{{ label }}</label>
				<div [class]="'p-col-12 p-md-' + valueMarginWidth + ' overflow'">
					{{ value ? value : '-' }}
					<div class="icon-container" [pTooltip]="iconAfterTextboxTooltip"><i [ngClass]="iconAfterTextboxReadOnly"></i></div>
				</div>
			</div>
		</div>
		<div *ngSwitchCase="formControls.ColorPicker">
			<div class="p-field p-grid">
				<label for="name" [class]="'field-label-read-only p-col-12 p-md-' + labelMarginWidth">{{ label }}</label>
				<div [class]="'p-col-12 p-md-' + valueMarginWidth + ' overflow'">
					{{ value ? value : '-' }}
				</div>
			</div>
		</div>
		<div *ngSwitchCase="formControls.Autocomplete">
			<div class="p-field p-grid">
				<label for="name" [class]="'field-label-read-only p-col-12 p-md-' + labelMarginWidth">{{ label }}</label>
				<div [class]="'p-col-12 p-md-' + valueMarginWidth">
					{{ value ? value : '-' }}
				</div>
			</div>
		</div>
		<div *ngSwitchCase="formControls.AutocompleteWithIcon">
			<div class="p-field p-grid">
				<label for="name" [class]="'field-label-read-only p-col-12 p-md-' + labelMarginWidth">{{ label }}</label>
				<div [class]="'p-col-12 p-md-' + valueMarginWidth" style="flex-direction: column">
					<div>
						<span>
							<i class="fa fa-circle" [style.color]="isIconAutoComplete === false ? codeValueForReadOnly ?? defaultIconColor : defaultIconColor"></i
						></span>

						{{ value ? value : '-' }}
					</div>
				</div>
			</div>
		</div>
		<div *ngSwitchCase="formControls.Textarea">
			<div class="p-field p-grid">
				<label for="name" [class]="'field-label-read-only p-col-12 p-md-' + smallLabelMarginWidth">{{ label }}</label>
				<div [class]="'p-col-12 p-md-' + largeValueMarginWidth + ' formatted-text read-only-text-area'" [innerHTML]="value"></div>
			</div>
		</div>
		<div *ngSwitchCase="formControls.FormattedTextarea">
			<div class="p-field p-grid">
				<label for="name" [class]="'field-label-read-only p-col-12 p-md-' + labelMarginWidth">{{ label }}</label>
				<div [class]="'p-col-12 p-md-' + valueMarginWidth + ' formatted-text'" [innerHTML]="value ? value : '-'"></div>
			</div>
		</div>
		<div *ngSwitchCase="formControls.Numeric">
			<div class="p-field p-grid">
				<div [class]="'field-label-read-only p-col-12 p-md-' + labelMarginWidth">
					<div style="flex-shrink: 0; flex-grow: 1">
						{{ label }}
					</div>
				</div>
				<div
					[class]="'p-col-12 p-md-' + valueMarginWidth"
					[ngStyle]="{ 'text-align': numericRightAlign ? 'right' : '', 'padding-right': numericRightAlign ? '30%' : '' }">
					{{ value ? (value | formatNumber : '0.' + minFractionDigits + '-' + maxFractionDigits : region.RegionCode) : '-' }}
					<div class="icon-container" [pTooltip]="iconAfterTextboxTooltip"><i [ngClass]="iconAfterTextboxReadOnly"></i></div>
				</div>
			</div>
		</div>
		<div *ngSwitchCase="formControls.CheckBox">
			<div class="p-field p-grid">
				<p-checkbox
					[class]="'p-col-12 p-md-' + valueMarginWidth"
					*ngSwitchCase="formControls.CheckBox"
					[id]="name"
					[name]="name"
					[formControl]="control"
					[binary]="true"
					[label]="label"
					[readonly]="true"
					labelStyleClass="field-label-read-only">
				</p-checkbox>
			</div>
		</div>
		<div *ngSwitchCase="formControls.SelectButton">
			<div class="p-field p-grid">
				<label for="name" [class]="'field-label-read-only p-col-12 p-md-' + labelMarginWidth">{{ label }}</label>
				<div [class]="'p-col-12 p-md-' + valueMarginWidth">
					{{ value ? value : '-' }}
				</div>
			</div>
		</div>
		<div *ngSwitchCase="formControls.RadioButton">
			<div class="p-field p-grid">
				<p-radioButton
					[class]="'p-col-12 p-md-' + valueMarginWidth"
					*ngSwitchCase="formControls.RadioButton"
					[value]="inputId"
					[id]="name"
					[name]="name"
					[inputId]="inputId"
					[formControl]="control"
					[label]="label"
					[readonly]="true"
					labelStyleClass="field-label-read-only">
				</p-radioButton>
			</div>
		</div>
		<div *ngSwitchCase="formControls.MultiSelect">
			<div class="p-field p-grid">
				<label for="name" [class]="'field-label-read-only p-col-12 p-md-' + labelMarginWidth">{{ label }}</label>
				<div [class]="'p-col-12 p-md-' + valueMarginWidth">
					{{ value ? value : '-' }}
				</div>
			</div>
		</div>
		<div *ngSwitchCase="formControls.MultiSelectCustom">
			<div class="p-field p-grid">
				<label for="name" [class]="'field-label-read-only p-col-12 p-md-' + smallLabelMarginWidth">{{ label }}</label>
				<div class="custom-multiselectChip-option-item custom-multiselectChip-option-item-value" *ngFor="let option of selectedLookupValues">
					<div *ngIf="option.Style && !option.AdditionalDetails" class="custom-multiselectChip" [ngClass]="option.Style">
						{{ option.NameValue ? option.NameValue : '-' }}
					</div>
					<div *ngIf="!option.Style && option.AdditionalDetails" class="custom-multiselectChip" [style.background-color]="option.AdditionalDetails.ColourCode">
						{{ option.NameValue ? option.NameValue : '-' }}
					</div>
				</div>
				<span *ngIf="!selectedLookupValues || selectedLookupValues.length === 0">-</span>
			</div>
		</div>
		<div *ngSwitchCase="formControls.Chips">
			<div class="p-field p-grid">
				<label for="name" [class]="'field-label-read-only p-col-12 p-md-' + labelMarginWidth">{{ label }}</label>
				<div [class]="'p-col-12 p-md-' + valueMarginWidth">
					{{ value ? value : '-' }}
				</div>
			</div>
		</div>
		<div *ngSwitchCase="formControls.InputInfoBox">
			<div class="p-field p-grid">
				<div class="p-col-12 p-md-0">
					<p class="input-info-box">
						{{ value ? value : '-' }}
					</p>
				</div>
			</div>
		</div>
		<div *ngSwitchCase="formControls.DropDownGroup">
			<div class="p-field p-grid">
				<label for="name" [class]="'field-label-read-only p-col-12 p-md-' + labelMarginWidth">{{ label }}</label>
				<div [class]="'p-col-12 p-md-' + valueMarginWidth">
					{{ value ? value.label : '-' }}
				</div>
			</div>
		</div>
	</ng-template>
</ng-template>
