export class ApiFile {
	FileName: string;
	FileData: any;
	MalwareDetected: boolean;

	// local upload
	NewFileId: string;

	constructor(properties?: any) {
		if (properties) {
			for (const key in properties) {
				this[key] = properties[key];
			}
		}
	}
}
