import { Directive } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';

@Directive({
	selector: '[validateExpiryDateOrNotApplicableRequired]',
	providers: [
		{
			provide: NG_VALIDATORS,
			useExisting: ExpiryDateOrNotApplicableRequiredValidatorDirective,
			multi: true,
		},
	],
})
export class ExpiryDateOrNotApplicableRequiredValidatorDirective implements Validator {
	validate(control: AbstractControl): ValidationErrors | null {
		if (control.parent) {
			const expiryDateControl = control.parent.get('expiryDate');
			const expiryDateNotApplicableControl = control.parent.get('expiryDateNotApplicable');

			if (expiryDateControl && expiryDateNotApplicableControl) {
				if (!expiryDateControl.value && !expiryDateNotApplicableControl.value) {
					return { expiryDateOrNotApplicableRequired: 'This field is required.' };
				} else {
					return null;
				}
			} else {
				return null;
			}
		}
	}
}
