import { ObjectAccess } from 'src/app/shared/models/api';

export class UserProfile {
	UserProfileId: string;
	Username: string;

	DisplayName: string;
	ContactPhone: string;
	ContactMobile: string;
	ContactEmail: string;
	StatusCode: string;
	StatusName: string;
	UserRoleId: string;
	UserRoleName: string;

	ClientId: string;
	ClientName: string;

	IsCurrentUser: boolean;

	AddedById: string;
	AddedBy: string;
	UpdatedById: string;
	UpdatedBy: string;
	DateAdded: Date;
	DateUpdated: Date;

	ObjectAccess: ObjectAccess;
}
