import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Constants } from 'src/app/shared/constants/constants.constant';
import { ActionResult } from 'src/app/shared/models/api';
import { LookupValue } from 'src/app/shared/models/lookups';
import { FlagAlert } from 'src/app/shared/models/shared';
import { ApiService } from 'src/app/core/services';

@Injectable({
	providedIn: 'root',
})
export class FlagApiService {
	constructor(private apiService: ApiService) {}

	saveFlagAlert(flag: FlagAlert): Observable<ActionResult> {
		return this.apiService.post<ActionResult>(`/Flag/SaveFlagAlert`, flag);
	}

	disableFlagAlert(flagId: string): Observable<ActionResult> {
		return this.apiService.get<ActionResult>(`/Flag/DisableFlagAlert/${flagId}`);
	}

	constructFlags(lookupFlags: LookupValue[], selectedFlags: LookupValue[], refId: string, refName: string, refType: string): FlagAlert[] {
		const flagAlerts = selectedFlags.map(x => {
			const f = this.constructFlag(lookupFlags, x.CodeValue, refId, refName, refType);
			return f;
		});
		return flagAlerts;
	}

	constructFlag(lookupFlags: LookupValue[], code: string, refId: string, refName: string, refType: string): FlagAlert {
		const item = lookupFlags.find(x => x.CodeValue == code);
		const flag = new FlagAlert();
		flag.FlagAlertTypeCode = code;
		flag.ReferenceId = refId;
		flag.ReferenceName = refName;
		flag.ReferenceTypeCode = refType;
		if (item) flag.ColourCode = item.AdditionalDetails.ColourCode;
		return flag;
	}

	initialiseFlags(flagAlerts: FlagAlert[], lookupFlags: LookupValue[], addedDeleteLookupFlags = false): LookupValue[] {
		const selectedFlags = lookupFlags.filter(x => flagAlerts.find(y => y.FlagAlertTypeCode == x.CodeValue));
		const unMappedSavedFlagAlerts = flagAlerts.filter(s => {
			return !lookupFlags.some(k => {
				return s.FlagAlertTypeCode === k.CodeValue;
			});
		});

		if (unMappedSavedFlagAlerts && unMappedSavedFlagAlerts.length > 0) {
			unMappedSavedFlagAlerts.forEach(x => {
				selectedFlags.push(
					new LookupValue({
						AdditionalDetails: { ColourCode: x.ColourCode, ColourName: x.ColourName, DomainDetailTypeCode: Constants.DomainDetailType.FlagAlert },
						CodeValue: x.FlagAlertTypeCode,
						NameValue: x.FlagAlertTypeName,
						ExtendedNameValue: x.FlagAlertTypeName,
						ExtendedCodeValue: x.FlagAlertTypeCode,
					})
				);
			});
		}

		if (addedDeleteLookupFlags) {
			unMappedSavedFlagAlerts.forEach(x => {
				lookupFlags.push(
					new LookupValue({
						AdditionalDetails: { ColourCode: x.ColourCode, ColourName: x.ColourName, DomainDetailTypeCode: Constants.DomainDetailType.FlagAlert },
						CodeValue: x.FlagAlertTypeCode,
						NameValue: x.FlagAlertTypeName,
						ExtendedNameValue: x.FlagAlertTypeName,
						ExtendedCodeValue: x.FlagAlertTypeCode,
						WasDeleted: true,
					})
				);
			});
		}

		return selectedFlags;
	}
}
