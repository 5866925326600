import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-footer',
	template: `
		<div class="layout-footer clearfix">
			<a href="https://www.spydertech.net.au" target="_blank">
				<img src="images/spydertechlogo-footer.png" style="width: 165px;" class="img-no-highlight" />
			</a>
			<span class="footer-text-right">
				<div>All Rights Reserved</div>
				<div *ngIf="version">v{{ version }}</div>
			</span>
		</div>
	`,
})
export class AppFooterComponent {
	version = environment.appVersion;
}
