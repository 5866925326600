export class BulkActionConstants {
	//Summary Toast Mesages
	static readonly BulkAction_Success_Message = (successCount: string) => `${successCount} record(s) updated successfully.`;
	static readonly BulkAction_Error_Message = (errorCount: string) =>
		`${errorCount} record(s) failed to action, please review the error message for each record and try again.`;
	static readonly BulkAction_Info_Message = (skippedCount: string) => `${skippedCount} record(s) were skipped due to cancelling the action.`;

	//Messages
	static readonly BulkAction_Cancel_Message: string = 'Action cancelled';
	static readonly BulkAction_RecordsSelected_Message = (recordsCount: string) => `${recordsCount} records selected`;
}
