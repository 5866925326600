import { GeolocationDto } from 'src/app/shared/models/shared';

export class RegionSettings {
	RegionCode: string;
	RegionName: string;

	CurrencySymbol: string;
	CurrencyPrecision: number;
	CurrencyCode: string;
	DateFormat: string;
	DateTimeFormat: string;
	TimeFormat: string;
	NumberFormat: string;
	CurrencyFormat: string;
	CurrencySymbolAtEnd: boolean;
	Use24Hours: boolean;

	GoogleAddressOptions: GoogleAddressOptions;
	DefaultMapLocation: GeolocationDto;

	constructor(properties?: any) {
		if (properties) {
			for (const key in properties) {
				this[key] = properties[key];
			}
		}
	}
}

export class GoogleAddressOptions {
	CountryCodes: string[];
	AddressTypes: string[];
	AddressPrefixComponents: string[];
	AddressStreetNumberComponents: string[];
	AddressStreetNameComponents: string[];
	AddressSuburbComponents: string[];
	AddressPostcodeComponents: string[];
	AddressStateComponents: string[];
	AddressCountryComponents: string[];

	constructor(properties?: any) {
		if (properties) {
			for (const key in properties) {
				this[key] = properties[key];
			}
		}
	}
}
