import { ActionResult } from 'src/app/shared/models/api';
import { nameofFactory } from './nameof';

export function isActionResult(obj: any): obj is ActionResult {
	let result = true;
	const nameof = nameofFactory<ActionResult>();
	const props = [nameof('IsSuccess'), nameof('Messages')];
	props.forEach(x => {
		result = result && obj[x] !== undefined;
	});
	return result;
}
