import { Injectable } from '@angular/core';
import { Observable, Subject, catchError, concatMap, finalize, from, map, of } from 'rxjs';
import { LookupValue } from 'src/app/shared/models/lookups';
import { AppContextService, UtilService } from 'src/app/core/services';
import { ProgressBarComponent } from '../components/progress-bar/progress-bar.component';
import { BulkActionConstants } from '../constants/bulk-action.constants';
import { ActionResult, ActionResultMessage, ActionResultMessageType } from '../models/api/action-result.model';
import { IBulkActionBase } from '../models/bulk-action/bulk-action-base.model';
import { BulkActionResult } from '../models/bulk-action/bulk-action-result';
import { BulkActionResultSummary } from '../models/bulk-action/bulk-action-result-summary';

@Injectable({
	providedIn: 'root',
})
export abstract class BulkActionBaseService implements IBulkActionBase {
	progressBar: ProgressBarComponent;
	filterActions: LookupValue[] = [];
	filterActionSelected: LookupValue;
	defaultFilterActionSelected = true;
	selectedItems: any[];
	filteredSelectedItems: any[] = [];
	requestedActionName: string;
	totalSelectedRecords: number;
	isProcessingRecords = false;
	bulkActionResultSummary: BulkActionResultSummary;
	bulkActionResults: BulkActionResult[] = [];

	bulkActionHasFirstProcessingTime = false;
	bulkActionProcessingCount = 1;
	bulkActionDurationToProcessFirstms = 0;
	bulkActionCancelled = false;
	bulkActionArgs: any;
	actionStartTime: number;

	showMessage = false;
	messageObject: BulkActionResult;
	actionResultMessage: ActionResultMessage[] = [];
	reportIndividualMessages = false;
	showRefresh: boolean;

	private loadDataSubject = new Subject<void>();
	loadData$ = this.loadDataSubject.asObservable();

	public util: UtilService;

	abstract processApiRequest(actionName: string, sel: any, args: any): Observable<ActionResult<any>>;
	abstract refreshUpdatedValues(resp: ActionResult<any>);
	abstract allowBulkAction(item: any);

	constructor(public appContext: AppContextService) {
		this.appContext = appContext;
		this.util = appContext.util;
	}

	getBulkActionObjectPrimaryKeyName(): string {
		return null;
	}

	triggerLoadData() {
		this.loadDataSubject.next();
	}

	runBulkAction() {
		let cancelCount = 0;
		let successCount = 0;
		let errorCount = 0;
		let args: any;

		if (this.bulkActionArgs != null) {
			args = this.util.deepClone(this.bulkActionArgs);
		}
		this.bulkActionResultSummary = new BulkActionResultSummary();
		this.bulkActionResults = [];
		this.actionResultMessage = [];
		this.bulkActionCancelled = false;
		this.bulkActionArgs = null;

		const start = this.progressBar.getCurrentTime();
		from(this.filteredSelectedItems)
			.pipe(
				concatMap(record =>
					this.runAction(record, args).pipe(
						map(resp => ({ record, resp })),
						catchError(error => {
							console.error(`Error processing record ${record}: ${error}`);
							return of(null); // Return a null value to continue processing the remaining records
						})
					)
				),
				finalize(() => {
					if (this.reportIndividualMessages) {
						this.bulkActionResults.forEach(result => {
							result.Messages.forEach(message => {
								this.addActionResultMessage(this.bulkActionProcessingCount, message.Message, message.MessageType);
							});
						});
					} else {
						this.bulkActionResultSummary.Success = BulkActionConstants.BulkAction_Success_Message(successCount.toString());
						this.bulkActionResultSummary.Error = BulkActionConstants.BulkAction_Error_Message(errorCount.toString());
						this.bulkActionResultSummary.Info = BulkActionConstants.BulkAction_Info_Message(cancelCount.toString());
						this.addActionResultMessage(successCount, this.bulkActionResultSummary.Success, ActionResultMessageType.Success);
						this.addActionResultMessage(errorCount, this.bulkActionResultSummary.Error, ActionResultMessageType.Error);
						this.addActionResultMessage(cancelCount, this.bulkActionResultSummary.Info, ActionResultMessageType.Notification);
					}
					this.util.showActionResultMessages(this.actionResultMessage);
					this.resetBulkActionValues();
					this.showRefresh = true;

					//move the success item out of selected item list to prevent user re-select
					this.filteredSelectedItems = this.filteredSelectedItems.filter(x => {
						const index = this.bulkActionResults.findIndex(
							y => y.Details[this.getBulkActionObjectPrimaryKeyName()] === x[this.getBulkActionObjectPrimaryKeyName()] && (!y.IsSuccess || y.IsCancelled)
						);

						if (index > -1) {
							return true;
						}
					});

					//To have selected checkboxes in UI and filter, records count same
					this.selectedItems = this.filteredSelectedItems.slice();
				})
			)
			.subscribe(({ record, resp }): void => {
				this.bulkActionProcessingCount++;
				this.refreshProgressBar(start);
				this.bulkActionResults.push(
					new BulkActionResult({
						Details: record,
						IsCancelled: resp.IsCancelled,
						IsSuccess: resp.IsSuccess,

						Messages: this.reportIndividualMessages ? this.reportMessages(record, resp) : resp.Messages,

						Action: this.filterActionSelected,
					})
				);
				if (resp.IsCancelled) {
					cancelCount++;
				} else {
					if (resp.IsSuccess) {
						successCount++;
						this.callRefreshUpdatedValues(resp);
					} else {
						errorCount++;
					}
				}
				this.util.clearMessages();
			});
	}
	reportMessages(record: any, resp: ActionResult<any>): ActionResultMessage<any>[] {
		return [];
	}

	addActionResultMessage(count: number, message: string, messageType: ActionResultMessageType): void {
		if (count > 0) {
			this.actionResultMessage.push({
				Message: message,
				MessageType: messageType,
				Detail: '',
				OwnerId: '',
				FieldName: '',
				Source: '',
				Data: undefined,
			});
		}
	}

	callRefreshUpdatedValues(resp: ActionResult<any>) {
		return this.refreshUpdatedValues(resp);
	}

	startBulkAction(args: any) {
		setTimeout(() => {
			this.resetBulkActionValues();
			this.bulkActionArgs = args;
			this.isProcessingRecords = true;
			this.bulkActionProcessingCount = 0;
			this.progressBar.runAction();
		}, 600);
	}

	runAction(sel: any, args: any): Observable<ActionResult> {
		if (this.bulkActionCancelled) {
			return of({
				IsSuccess: false,
				IsCancelled: true,
				Messages: [
					{
						Message: BulkActionConstants.BulkAction_Cancel_Message,
						MessageType: ActionResultMessageType.Notification,
					},
				],
			} as ActionResult);
		}
		if (!sel) return;
		const actionName = this.filterActionSelected?.NameValue ?? this.filterActionSelected;

		return this.callApiMethod(actionName, sel, args);
	}

	callApiMethod(actionName: any, sel: any, args: any): Observable<ActionResult<any>> {
		return this.processApiRequest(actionName, sel, args);
	}

	refreshProgressBar(start: number) {
		const totalRecords: number = this.filteredSelectedItems.length;
		if (!this.bulkActionHasFirstProcessingTime) {
			const end = this.progressBar.getCurrentTime();
			this.bulkActionDurationToProcessFirstms = end - start;
			this.bulkActionHasFirstProcessingTime = true;
		}

		this.progressBar.calculateProcessingTimeAndCount(this.bulkActionProcessingCount, this.bulkActionDurationToProcessFirstms, totalRecords);
	}

	resetBulkActionValues() {
		this.refreshProgressBar(this.progressBar.getCurrentTime());
		this.bulkActionHasFirstProcessingTime = false;
		this.bulkActionProcessingCount = 0;
		this.bulkActionDurationToProcessFirstms = 0;
		this.isProcessingRecords = false;
		this.requestedActionName = '';
		this.totalSelectedRecords = 0;
	}

	cancelBulkAction() {
		this.bulkActionCancelled = true;
		this.showRefresh = true;
		this.resetBulkActionValues();
	}

	handleClearAllClick() {
		this.util.clearMessages();
		this.selectedItems = [];
		this.filteredSelectedItems = [];
		this.filterActionSelected = null;
		this.defaultFilterActionSelected = true;
		this.bulkActionResultSummary = null;
		this.bulkActionResults = [];
		this.showRefresh = false;
		this.requestedActionName = '';
		this.totalSelectedRecords = 0;

		this.triggerLoadData();
	}

	handleRefreshClick() {
		this.util.clearMessages();
		this.selectedItems = [];
		this.filteredSelectedItems = [];
		this.bulkActionResultSummary = null;
		this.bulkActionResults = [];

		this.showRefresh = false;

		this.triggerLoadData();
	}

	showClearAll(): boolean {
		return !this.defaultFilterActionSelected;
	}

	getSelectedItemCount() {
		if (!this.defaultFilterActionSelected) return BulkActionConstants.BulkAction_RecordsSelected_Message(this.filteredSelectedItems.length.toString());

		return '';
	}

	allowApply(): boolean {
		return this.hasSelectedBulkActionItems();
	}

	isBulkActionView(): boolean {
		return !this.defaultFilterActionSelected;
	}

	hasSelectedBulkActionItems(): boolean {
		if (!this.defaultFilterActionSelected) return this.filteredSelectedItems && this.filteredSelectedItems.length > 0;

		return false;
	}

	showErrorIcon(item: any): boolean {
		if (this.bulkActionResults.length === 0) return false;

		if (this.defaultFilterActionSelected) return false;
		const selItem = this.bulkActionResults.find(x => x.Details[this.getBulkActionObjectPrimaryKeyName()] === item[this.getBulkActionObjectPrimaryKeyName()]);
		if (selItem && selItem.IsCancelled) return false;
		return selItem && !selItem.IsSuccess;
	}

	showSuccessIcon(item: any): boolean {
		if (this.bulkActionResults.length === 0) return false;

		if (this.defaultFilterActionSelected) return false;
		const selItem = this.bulkActionResults.find(x => x.Details[this.getBulkActionObjectPrimaryKeyName()] === item[this.getBulkActionObjectPrimaryKeyName()]);
		if (selItem && selItem.IsCancelled) return false;
		return selItem && selItem.IsSuccess;
	}

	getActionErrorMessage(item: any): string {
		if (this.bulkActionResults.length === 0) return '';

		const selItem = this.bulkActionResults.find(x => x.Details[this.getBulkActionObjectPrimaryKeyName()] === item[this.getBulkActionObjectPrimaryKeyName()]);
		if (selItem && selItem.Messages) return selItem.Messages.map((x: { Message: any }) => x.Message).join(', ');

		return '';
	}

	onfilterActionChange(event: any) {
		this.defaultFilterActionSelected = !event.value;
		this.requestedActionName = event.value;

		this.util.clearMessages();
		this.selectedItems = [];
		this.filteredSelectedItems = [];
		this.bulkActionResultSummary = null;
		this.bulkActionResults = [];
		if (this.showRefresh) {
			this.handleRefreshClick();
		} else {
			this.showRefresh = false;
		}

		this.resetBulkActionValues();
	}

	onSelectionChange(items: any[]) {
		this.filteredSelectedItems = [];
		for (let i = items.length - 1; i >= 0; i--) {
			const item = items[i];
			if (this.callAllowBulkAction(item)) {
				this.filteredSelectedItems.push(item);
			}
		}
	}

	callAllowBulkAction(item: any) {
		return this.allowBulkAction(item);
	}

	addFilterOption(nameValue: string, codeValue: string): LookupValue {
		const option = new LookupValue();
		option.NameValue = nameValue;
		option.CodeValue = codeValue;
		return option;
	}

	onPageChange(event: any) {
		this.selectedItems = [];
		this.filteredSelectedItems = [];
		this.showRefresh = false;
		this.totalSelectedRecords = 0;
	}

	onComponentDestroy() {
		this.handleClearAllClick();
	}
}
