import { Directive } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';

@Directive({
	selector: '[validatePurchaseOrderTargetDateRequired]',
	providers: [
		{
			provide: NG_VALIDATORS,
			useExisting: PurchaseOrderTargetDateRequiredValidatorDirective,
			multi: true,
		},
	],
})
export class PurchaseOrderTargetDateRequiredValidatorDirective implements Validator {
	validate(control: AbstractControl): ValidationErrors | null {
		if (control.parent) {
			const businessEntityControl = control.parent.get('businessEntity');

			if (businessEntityControl) {
				if (businessEntityControl.value && !control.value) {
					return { purchaseOrderTargetDateRequired: 'This field is required if Allocated To has been selected' };
				} else {
					return null;
				}
			} else {
				return null;
			}
		}
	}
}
