import { animate, state, style, transition, trigger } from '@angular/animations';
import { Location } from '@angular/common';
import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { AppMainComponent } from 'src/app/layout/main/app.main.component';

@Component({
	selector: '[app-submenu]',

	templateUrl: 'sub-menu.component.html',
	styleUrls: ['sub-menu.component.scss'],
	animations: [
		trigger('children', [
			state(
				'void',
				style({
					height: '0px',
				})
			),
			state(
				'hiddenAnimated',
				style({
					height: '0px',
				})
			),
			state(
				'visibleAnimated',
				style({
					height: '*',
				})
			),
			state(
				'visible',
				style({
					height: '*',
					'z-index': 100,
				})
			),
			state(
				'hidden',
				style({
					height: '0px',
					'z-index': '*',
				})
			),
			transition('visibleAnimated => hiddenAnimated', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')),
			transition('hiddenAnimated => visibleAnimated', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')),
			transition('void => visibleAnimated, visibleAnimated => void', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')),
		]),
	],
})
export class SubMenuComponent {
	@Input() item: MenuItem;
	@Input() root: boolean;
	@Input() visible: boolean;
	_reset: boolean;
	_parentActive: boolean;
	activeIndex: number;

	constructor(public app: AppMainComponent, public router: Router, public location: Location) {}

	itemClick(event: Event, item: MenuItem, index: number) {
		if (this.root) {
			this.app.menuHoverActive = !this.app.menuHoverActive;
			event.preventDefault();
		}

		// avoid processing disabled items
		if (item.disabled) {
			event.preventDefault();
			return true;
		}

		// activate current item and deactivate active sibling if any
		if (item.items) {
			this.activeIndex = (this.activeIndex as number) === index ? -1 : index;
		} else {
			this.activeIndex = -1;
		}

		// execute command
		if (item.command) {
			item.command({ originalEvent: event, item: item });
		}

		// hide menu
		if (!item.items) {
			if (this.app.isMobile()) {
				this.app.sidebarActive = false;
				this.app.mobileMenuActive = false;
			}
			if (this.app.isHorizontal()) {
				this.app.resetMenu = true;
			} else {
				this.app.resetMenu = false;
			}
			this.app.menuHoverActive = !this.app.menuHoverActive;
		}
	}

	onMouseEnter(index: number) {
		if (this.root && this.app.menuHoverActive && this.app.isHorizontal() && !this.app.isMobile() && !this.app.isTablet()) {
			this.activeIndex = index;
		}
	}

	isMenuLinkFocused(index: number): boolean {
		return this.activeIndex === index;
	}

	isChildMenuLinkActive(index: number): boolean {
		let isChildActive = false;
		const tempItem = { ...this.item[index] };
		const sameURLValue = tempItem?.items?.filter(data => data.routerLink.findIndex(val => this.router.url.startsWith(val)) >= 0);

		if (!tempItem?.routerLink) {
			isChildActive = sameURLValue?.length ? sameURLValue.length > 0 : false;
		}
		return isChildActive;
	}

	@Input() get reset(): boolean {
		return this._reset;
	}

	set reset(val: boolean) {
		this._reset = val;
		if (this._reset && (this.app.isHorizontal() || this.app.isOverlay())) {
			this.activeIndex = null;
		}
	}

	@Input() get parentActive(): boolean {
		return this._parentActive;
	}

	set parentActive(val: boolean) {
		this._parentActive = val;
		if (!this._parentActive) {
			this.activeIndex = null;
		}
	}

	get dialogWidth(): number {
		return window.outerWidth * 0.8;
	}
}
