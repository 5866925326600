export class Contact {
	ContactId: string;
	ReferenceTypeCode: string;
	ReferenceId: string;
	ReferenceName: string;
	ContactTypeCode: string;
	ContactTypeName: string;
	Name: string;
	PhoneNumber: string;
	MobileNumber: string;
	EmailAddress: string;
	AccessDetails: string;
	LanguageCode: string;
	LanguageName: string;
	Description: string;
	IsPrimary: boolean;
	DateActiveFrom: Date;
	DateActiveTo: Date;
	TimeZoneCode: string;
	TimeZoneName: string;
	AddedById: string;
	AddedBy: string;
	UpdatedById: string;
	UpdatedBy: string;
	DateAdded: Date;
	DateUpdated: Date;
	DateRemoved: Date;
	AddedByExternalUserRelationTypeCode: string;
	UpdatedByExternalUserRelationTypeCode: string;

	constructor(properties?: any) {
		if (properties) {
			for (const key in properties) {
				this[key] = properties[key];
			}
		}
	}
}
