import { CodeValue } from './code-value.model';

export class LookupValue extends CodeValue {
	Description: string;
	AdditionalDetails: any;

	ParentLookupValueId: string;
	ParentNameValue: string;
	IsDraft: boolean;

	AdditionalDisplayValue: string;
	OrderIndex: number;
	ExtendedNameValue: string;
	ExtendedCodeValue: string;

	TimeZoneCode: string;
	IsInactive: boolean;

	Selected: boolean;
	RequiresParentLoad: boolean;

	Style: string;
	WasDeleted: boolean;

	constructor(properties?: any) {
		super(properties);
	}

	static getName(code: string, values: LookupValue[]) {
		return values.find(x => x.CodeValue == code)?.NameValue;
	}
}
