import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MenuItem } from 'primeng/api';

@Component({
	selector: 'app-split-button',
	templateUrl: './split-button.component.html',
	styleUrls: ['./split-button.component.scss'],
})
export class SplitButtonComponent {
	@Input() dataTestIdComponentName = '';
	@Input() buttonLabel = 'ADD';
	@Input() buttonIcon = 'pi pi-plus';
	@Input() isButtonDisabled = false;
	@Input() isButtonVisible = true;
	@Input() buttonTooltip: string;
	@Input() menuItems: MenuItem[] = [];

	@Output() buttonClick = new EventEmitter<void>();

	onButtonClick() {
		this.buttonClick.emit();
	}

	disableButton() {
		return this.isButtonDisabled;
	}

	showButton() {
		return this.isButtonVisible;
	}

	refreshMenuItemState(itemLabel: string, visible?: boolean, disabled?: boolean) {
		const menuItem = this.menuItems.find(item => item.label == itemLabel);
		if (!menuItem) return;
		if (visible != null) menuItem.visible = visible;
		if (disabled != null) menuItem.disabled = disabled;

		this.menuItems = [...this.menuItems];
	}

	addMenuItem(
		label: string,
		icon?: string,
		command?: (event?: any) => void,
		commandParams?: any,
		tooltipOptions?: {
			tooltipLabel: string;
			tooltipEvent: 'focus' | 'hover';
			tooltipPosition?: 'right' | 'left' | 'top' | 'bottom';
		},
		visible?: boolean,
		disabled?: boolean,
		styleClass?: string
	) {
		const menuItem: MenuItem = {
			label: label,
			icon: icon,
			command: event => {
				if (command) {
					command(commandParams);
				}
			},
			visible: visible,
			disabled: disabled,
			styleClass: styleClass,
			tooltip: tooltipOptions?.tooltipLabel,
			tooltipPosition: tooltipOptions?.tooltipPosition || 'bottom',
			tooltipOptions: tooltipOptions,
		};

		this.menuItems.push(menuItem);
	}
}
