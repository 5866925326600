import { Component, OnInit } from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
	layoutMode = 'static'; //'overlay';

	darkMenu = true;

	isRTL = false;

	compactMode = false;

	inputStyle = 'outlined';

	ripple = true;

	constructor(private primengConfig: PrimeNGConfig) {}

	ngOnInit() {
		this.primengConfig.ripple = true;
	}
}
