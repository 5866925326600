import { Component, Input, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import * as moment from 'moment';
import { Calendar } from 'primeng/calendar';
import { RegionSettings } from '../../models/settings';

@Component({
	selector: 'app-inline-date-time-picker',
	templateUrl: './inline-date-time-picker.component.html',
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => InlineDateTimePickerComponent),
			multi: true,
		},
	],
})
export class InlineDateTimePickerComponent extends Calendar {
	@Input() timeZoneCode = moment.tz.guess();
	defaultDateBasedOnTimeZone: Date;
	region: RegionSettings;

	onCalendarFocus(event): void {
		this.defaultDateBasedOnTimeZone = this.value ?? moment(moment.tz(new Date(), this.timeZoneCode).format('YYYY-MM-DD HH:mm:ss.SSS')).toDate();
		this.onFocus.emit(event);
	}

	onCalendarBlur(event): void {
		this.onBlur.emit(event);
	}

	onCalendarSelect(event): void {
		this.onModelChange(event);
		this.onSelect.emit(event);
	}
}
