export const environment = {
	production: false,
	// eslint-disable-next-line
	appVersion: require('../../package.json').version + '-stage',
	apiUrl: 'https://apim-sf-stg-aue.azure-api.net/',
	b2cTenant: 'spydertechiamstg',
	b2cClientId: 'c49a19fa-febf-446f-812e-73288bc2ae28',
	b2cRedirectUri: 'https://sandbox.spyderflow.com.au/auth',
	showDevHelpers: false,
	signalRNotificationHub: 'https://apim-sf-stg-aue.azure-api.net/notificationHub',
	signalRLogging: 0, // trace
};
