import { FinancialBillingSettings } from '.';

export class FinancialSettings {
	TaxDetails: TaxDetails;
	IntegrationDetails: IntegrationDetails;
	FinancialBillingSettings: FinancialBillingSettings;

	constructor(properties?: any) {
		if (properties) {
			for (const key in properties) {
				this[key] = properties[key];
			}
		}
	}
}

export class TaxDetails {
	TaxCode: string;
	TaxName: string;
	TaxRate: number;

	constructor(properties?: any) {
		if (properties) {
			for (const key in properties) {
				this[key] = properties[key];
			}
		}
	}
}

export class IntegrationDetails {
	FinancialIntegrationTypeCode: string;
	FinancialIntegrationTypeName: string;
	XeroSettings: XeroIntegrationSettings;

	constructor(properties?: any) {
		if (properties) {
			for (const key in properties) {
				this[key] = properties[key];
			}
		}
	}
}

export class XeroIntegrationSettings {
	XeroTenantId: string;
	ClientId: string;
	ClientSecret: string;
	BearerToken: string;
	Scope: string;
	WebhookKey: string;
	WebhookNotifications: string;
	WebhookDeliveryUrl: string;

	TaxCodeMappings: TaxCodeMappings[] = [];

	constructor(properties?: any) {
		if (properties) {
			for (const key in properties) {
				this[key] = properties[key];
			}
		}
	}
}

export class TaxCodeMappings {
	Id: string; // Required only for UI purpose
	SourceTaxCode: string;
	SourceTaxName: string;

	InvoiceTypeCode: string;
	InvoiceTypeName: string;
	DestinationAccountCode: string;
	DestinationTaxCode: string;

	constructor(properties?: any) {
		if (properties) {
			for (const key in properties) {
				this[key] = properties[key];
			}
		}
	}
}
