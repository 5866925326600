import { AccordionModule } from 'primeng/accordion';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { BlockUIModule } from 'primeng/blockui';
import { CalendarModule } from 'primeng/calendar';
import { CardModule } from 'primeng/card';
import { CarouselModule } from 'primeng/carousel';
import { CheckboxModule } from 'primeng/checkbox';
import { ColorPickerModule } from 'primeng/colorpicker';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ContextMenuModule } from 'primeng/contextmenu';
import { DataViewModule } from 'primeng/dataview';
import { DialogModule } from 'primeng/dialog';
import { DividerModule } from 'primeng/divider';
import { DragDropModule } from 'primeng/dragdrop';
import { DropdownModule } from 'primeng/dropdown';
import { EditorModule } from 'primeng/editor';
import { FieldsetModule } from 'primeng/fieldset';
import { FileUploadModule } from 'primeng/fileupload';
import { GalleriaModule } from 'primeng/galleria';
import { ImageModule } from 'primeng/image';
import { InplaceModule } from 'primeng/inplace';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { KeyFilterModule } from 'primeng/keyfilter';
import { ListboxModule } from 'primeng/listbox';
import { MegaMenuModule } from 'primeng/megamenu';
import { MenuModule } from 'primeng/menu';
import { MenubarModule } from 'primeng/menubar';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { PanelModule } from 'primeng/panel';
import { PanelMenuModule } from 'primeng/panelmenu';
import { PasswordModule } from 'primeng/password';
import { PickListModule } from 'primeng/picklist';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { RadioButtonModule } from 'primeng/radiobutton';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { SelectButtonModule } from 'primeng/selectbutton';
import { SidebarModule } from 'primeng/sidebar';
import { SplitButtonModule } from 'primeng/splitbutton';
import { StepsModule } from 'primeng/steps';
import { TableModule } from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';
import { TieredMenuModule } from 'primeng/tieredmenu';
import { ToastModule } from 'primeng/toast';
import { ToolbarModule } from 'primeng/toolbar';
import { TreeModule } from 'primeng/tree';

export const PRIMENG_COMMON_MODULE = [
	DropdownModule,
	DataViewModule,
	TableModule,
	ToastModule,
	PanelModule,
	StepsModule,
	EditorModule,
	CardModule,
	ConfirmDialogModule,
	DialogModule,
	OverlayPanelModule,
	FileUploadModule,
	PasswordModule,
	TabViewModule,
	InputTextModule,
	AutoCompleteModule,
	InplaceModule,
	KeyFilterModule,
	MenuModule,
	MenubarModule,
	AccordionModule,
	ProgressSpinnerModule,
	BlockUIModule,
	InputTextareaModule,
	CheckboxModule,
	ListboxModule,
	SidebarModule,
	TieredMenuModule,
	InputSwitchModule,
	PanelMenuModule,
	SplitButtonModule,
	RadioButtonModule,
	SelectButtonModule,
	ContextMenuModule,
	MegaMenuModule,
	CarouselModule,
	TreeModule,
	PickListModule,
	ToolbarModule,
	DragDropModule,
	FieldsetModule,
	ImageModule,
	GalleriaModule,
	ScrollPanelModule,
	DividerModule,
	CalendarModule,
	InputNumberModule,
	ColorPickerModule,
];
