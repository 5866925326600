import { Component, Input, ViewEncapsulation } from '@angular/core';
import { FormControl as FrmCntl } from 'src/app/shared/models/forms';

@Component({
	selector: 'app-chips',
	templateUrl: './chips.component.html',
	styleUrls: ['./chips.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class ChipsComponent {
	@Input() chips: string[];
	@Input() defaultBackgroundColor = 'rgba(96, 125, 139, 0.16)';
	@Input() defaultColor = 'rgba(255,255,255)';
	formControls = FrmCntl;
	// @Input() allowEdit = true;
	// @Input() showEdit = true;
}
