import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LazyLoadArgs, PagedResponse } from 'src/app/shared/models/api';
import { SearchFilter } from 'src/app/shared/models/search-filters';
import { GlobalSearch, GlobalSearchRequest } from 'src/app/shared/models/shared';
import { ApiService } from 'src/app/core/services';

@Injectable({
	providedIn: 'root',
})
export class SearchService {
	constructor(private apiService: ApiService) {
		//
	}

	getSearchFilterFromCode(searchFilterTypeCode: string, argsJson: string): Observable<SearchFilter> {
		const httpParams: HttpParams = new HttpParams().append('searchFilterTypeCode', searchFilterTypeCode).append('argsJson', argsJson);

		return this.apiService.get<SearchFilter>(`/Search/GetSearchFilterFromCode`, httpParams);
	}

	getPagedGlobalList(searchData: LazyLoadArgs, assetNumber: string = null, clientId: string = null): Observable<PagedResponse<GlobalSearch>> {
		searchData.data = new GlobalSearchRequest();
		searchData.data.AssetNumber = assetNumber;
		searchData.data.ClientId = clientId;

		return this.apiService.post<PagedResponse<GlobalSearch>>('/Search/GetPagedGlobalList', searchData);
	}
}
