import { Constants } from './constants.constant';

export class ConstantExtensions {
	//Invoice Types
	static IsStandardInvoice(invoiceTypeCode: string): boolean {
		return invoiceTypeCode == Constants.InvoiceType.Receivable || invoiceTypeCode == Constants.InvoiceType.Payable;
	}

	static IsReceivable(invoiceTypeCode: string): boolean {
		return invoiceTypeCode == Constants.InvoiceType.Receivable || invoiceTypeCode == Constants.InvoiceType.SalesCreditNote;
	}

	static IsCreditNote(invoiceTypeCode: string): boolean {
		return invoiceTypeCode == Constants.InvoiceType.SalesCreditNote || invoiceTypeCode == Constants.InvoiceType.PurchasesCreditNote;
	}

	static IsPayable(invoiceTypeCode: string): boolean {
		return invoiceTypeCode == Constants.InvoiceType.Payable || invoiceTypeCode == Constants.InvoiceType.PurchasesCreditNote;
	}

	//Invoice Sub Types
	static IsInvoiceSubTypeItems(invoiceSubtypeCode: string): boolean {
		return invoiceSubtypeCode == Constants.InvoiceSubtype.Items;
	}

	static IsInvoiceSubTypeAmount(invoiceSubtypeCode: string): boolean {
		return invoiceSubtypeCode == Constants.InvoiceSubtype.Amount;
	}
}
