export class Note {
	NoteId: string;
	ExternalReferenceNumber: string;
	ReferenceTypeCode: string;
	ReferenceId: string;
	ReferenceName: string;
	NoteAlertTypeCode: string;
	NoteAlertTypeName: string;
	NoteCategoryCode: string;
	NoteCategoryName: string;
	Comments: string;

	TimeZoneCode: string;
	TimeZoneName: string;
	AddedById: string;
	AddedBy: string;
	UpdatedById: string;
	UpdatedBy: string;
	DateAdded: Date;
	DateUpdated: Date;
	DateRemoved: Date;
	AddedByExternalUserRelationTypeCode: string;
	UpdatedByExternalUserRelationTypeCode: string;

	constructor(properties?: any) {
		if (properties) {
			for (const key in properties) {
				this[key] = properties[key];
			}
		}
	}
}
