//This list will include only those field names that are editable for invoice detail and new invoice
export enum InvoiceFieldNames {
	'contact' = 'contact',
	'billingAddress' = 'billingAddress',
	'billingSuburb' = 'billingSuburb',
	'billingState' = 'billingState',
	'billingPostcode' = 'billingPostcode',
	'billingCountry' = 'billingCountry',
	'company' = 'company',
	'category' = 'category',
	'comments' = 'comments',
	'taxCode' = 'taxCode',
	'taxRate' = 'taxRate',
	'invoiceType' = 'invoiceType',
	'invoiceTotal' = 'invoiceTotal',
	'previousTotal' = 'previousTotal',
	'invoicePercentage' = 'invoicePercentage',
	'remainingTotal' = 'remainingTotal',
	'remainingCredit' = 'remainingCredit',
	'remainingPercentage' = 'remainingPercentage',
	'orderAmount' = 'orderAmount',
	'parentInvoice' = 'parentInvoice',
	'itemsTab' = 'itemsTab',
	'relatedTab' = 'relatedTab',
	'targetDate' = 'targetDate',
	'propertyAddress' = 'propertyAddress',
}
