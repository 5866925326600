import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { InvoiceItemHistory } from 'src/app/shared/models/invoices';
import { ApiService } from 'src/app/core/services';

@Injectable({
	providedIn: 'root',
})
export class RelatedInvoiceApiService {
	constructor(private apiService: ApiService) {
		//
	}

	getInvoiceItemHistoryForReference(referenceTypeCode: string, referenceId: string): Observable<InvoiceItemHistory[]> {
		return this.apiService.get<InvoiceItemHistory[]>(`/Invoice/GetInvoiceItemHistoryForReference/${referenceTypeCode}/${referenceId}`);
	}
}
