import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Constants } from 'src/app/shared/constants/constants.constant';
import { AppContextService, LoaderService, UtilService } from '.';
import { NavigationConstants } from '../constants/navigation.constants';

@Injectable({
	providedIn: 'root',
})
export class NavigationService {
	constructor(private loaderService: LoaderService, private router: Router, private util: UtilService, private app: AppContextService) {
		//
	}

	navigateToDashboard() {
		this.util.navigate(['/dashboard']);
	}

	navigateToCurrentUserProfile() {
		this.util.navigate(['/profile']);
	}

	navigateToCurrentSubscriptions() {
		this.util.navigate(['/subscription']);
	}

	private isPurchaseOrderRecordType(recordTypeCode: string): boolean {
		return (
			recordTypeCode == Constants.RecordType.PurchaseOrder ||
			recordTypeCode == Constants.RecordType.Defect ||
			recordTypeCode == Constants.RecordType.CreditPurchaseOrder ||
			recordTypeCode == Constants.RecordType.RecoveryPurchaseOrder
		);
	}

	navigateToRecord(recordTypeCode: string, recordId: string, parentRecordId: string): boolean {
		if (this.isPurchaseOrderRecordType(recordTypeCode)) {
			recordTypeCode = Constants.RecordType.WorkOrder;
			recordId = parentRecordId;
		}

		const module = NavigationConstants[`RECORD_${recordTypeCode}`];
		if (module == null) {
			return false;
		}

		if (!this.app.hasPermission(module.permission)) {
			this.util.clearMessages();
			this.util.showError(NavigationConstants.MESSAGE_MISSINGPERMISSION);
			return false;
		}

		try {
			// TO-DO Find alternative for same page reload
			this.router.routeReuseStrategy.shouldReuseRoute = () => false;
		} catch (err) {
			console.log(err);
		}

		this.loaderService.block();
		this.router.navigate([`/${module.path}/`, recordId]);
		return true;
	}
}
