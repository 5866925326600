import { Directive } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';
import { ValidationConstants } from 'src/app/core/constants/validation.constant';

@Directive({
	selector: '[validatePhoneNumber]',
	providers: [
		{
			provide: NG_VALIDATORS,
			useExisting: PhonenumberValidatorDirective,
			multi: true,
		},
	],
})
export class PhonenumberValidatorDirective implements Validator {
	validate(control: AbstractControl): ValidationErrors | null {
		if (control.value == null || control.value === '') {
			return null;
		}
		const validator = new RegExp(ValidationConstants.phoneNumberRegExp);
		return validator.test(control.value) ? null : { phonenumber: 'not matched' };
	}
}
