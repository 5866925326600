import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { LOCALE_ID, NgModule } from '@angular/core';
import { Injector } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FullCalendarModule } from '@fullcalendar/angular';
import { TreeDragDropService } from 'primeng/api';
import { BlockableDivComponent } from './components/blockable-div.component';
import { SpinnerComponent } from './components/spinner.component';
import { PRIMENG_COMMON_MODULE } from './constants/primeng-import-modules.constant';
import {
	AppAutoCompleteDirective,
	DateFromToValidatorDirective,
	EmailValidatorDirective,
	FontSizeAdjustmentDirective,
	LatitudeValidatorDirective,
	LongitudeValidatorDirective,
	PasswordValidatorDirective,
	PercentageValidatorDirective,
	PhonenumberValidatorDirective,
} from './directives';
import { LocaleService } from './services/locale.service';
import { LocatorService } from './services/locator.service';

@NgModule({
	imports: [CommonModule, ...PRIMENG_COMMON_MODULE],
	//HttpModule,
	declarations: [
		SpinnerComponent,
		BlockableDivComponent,
		AppAutoCompleteDirective,
		PhonenumberValidatorDirective,
		DateFromToValidatorDirective,
		EmailValidatorDirective,
		PasswordValidatorDirective,
		LatitudeValidatorDirective,
		LongitudeValidatorDirective,
		PercentageValidatorDirective,
		FontSizeAdjustmentDirective,
	],
	exports: [
		...PRIMENG_COMMON_MODULE,
		SpinnerComponent,
		BlockableDivComponent,
		AppAutoCompleteDirective,
		PhonenumberValidatorDirective,
		DateFromToValidatorDirective,
		EmailValidatorDirective,
		PasswordValidatorDirective,
		FontSizeAdjustmentDirective,
		FullCalendarModule,
		FormsModule,
		ReactiveFormsModule,
		CommonModule,
		HttpClientModule,
		RouterModule,
	],
	//HttpModule,
	providers: [
		TreeDragDropService,
		{
			provide: LOCALE_ID,
			useFactory: (localeService: LocaleService) => {
				localeService.registerCulture();
			},
			deps: [LocaleService],
		},
	],
})
export class CoreModule {
	constructor(private injector: Injector) {
		// Create global Service Injector.
		LocatorService.injector = this.injector;
	}
}
