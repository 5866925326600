import { formatDate } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'formatDate',
	pure: false,
})
export class FormatDatePipe implements PipeTransform {
	transform(value: string, locale: string, dateFormat: string, timeFormat?: string): string {
		let formattedDate = '';
		if (value) {
			const date = new Date(value);
			const datePart = formatDate(date, dateFormat, locale);
			formattedDate = `<span class="formatted-date-holder"><span>${datePart}</span>`;
			if (timeFormat) {
				const timePart = formatDate(date, timeFormat, locale);
				formattedDate += ` <span>${timePart}</span></span>`;
			} else {
				formattedDate += `</span>`;
			}
		}
		return formattedDate;
	}
}
