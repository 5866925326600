export enum FormControl {
	Textbox = 0,
	Textarea,
	Calendar,
	CalendarDate,
	CalendarTime,
	Autocomplete,
	CheckBox,
	Numeric,
	AmountTextbox,
	SelectButton,
	FormattedTextarea,
	MultiSelect,
	Chips,
	InputInfoBox,
	MultiSelectCustom,
	DropDownGroup,
	RadioButton,
	ColorPicker,
	AutocompleteWithIcon,
}
