import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { AssetDetails } from 'src/app/features/asset/models/asset-details.model';
import { Constants } from 'src/app/shared/constants/constants.constant';
import { FormMode } from 'src/app/shared/models/forms';
import { RegionSettings } from 'src/app/shared/models/settings';
import { AppContextService, LookupService, UtilService } from 'src/app/core/services';

@Component({
	selector: 'app-google-address',
	templateUrl: './google-address.component.html',
	styleUrls: ['./google-address.component.scss'],
})
export class GoogleAddressComponent implements OnInit, AfterViewInit {
	@Input() label = 'Search Address';
	@Input() visible = false;
	@Input() formMode: FormMode;
	@Input() showSelectedAddress = false;
	@Input() invalid = false;
	@Output() setAddress: EventEmitter<any> = new EventEmitter();
	@ViewChild('addresstext') addresstext: any;
	@ViewChildren('addressSearch') addressSearch: QueryList<ElementRef>;
	region: RegionSettings;
	appContext: AppContextService;
	formattedAddress = '';

	constructor(private util: UtilService, private lookupService: LookupService, appContext: AppContextService) {
		this.appContext = appContext;
	}

	ngOnInit() {
		this.region = this.appContext.getRegion();
	}

	ngAfterViewInit() {
		if (this.formMode === FormMode.Create) {
			this.getPlaceAutocomplete();
		}

		if (this.formMode === FormMode.Edit) {
			if (this.visible) {
				this.getPlaceAutocomplete();
			}
		}

		this.addressSearch.changes.subscribe(e => {
			if (this.visible) {
				this.getPlaceAutocomplete();
			}
		});
	}

	private getPlaceAutocomplete() {
		this.appContext.user.Region.GoogleAddressOptions.AddressTypes.map(currentAddressType => {
			const autocomplete = new google.maps.places.Autocomplete(this.addresstext.nativeElement, {
				componentRestrictions: { country: this.appContext.user.Region.GoogleAddressOptions.CountryCodes },
				types: [currentAddressType], // 'establishment' / 'address' / 'geocode'
			});

			google.maps.event.addListener(autocomplete, 'place_changed', () => {
				const place = autocomplete.getPlace();
				this.invokeEvent(place);
			});
		});
	}

	invokeEvent(place: google.maps.places.PlaceResult) {
		const asset = new AssetDetails();
		let subPremise = '';
		let streetNumber = '';
		let streetName = '';

		const allGoogleAddressOptions = this.appContext.user.Region.GoogleAddressOptions;

		for (const componentType in allGoogleAddressOptions) {
			switch (componentType) {
				case 'AddressStreetNumberComponents': {
					allGoogleAddressOptions[componentType].map(x => {
						const temp = place.address_components.filter((y: { types: any[] }) => y.types.filter((z: string) => z == x)?.[0])?.[0];
						if (temp) {
							streetNumber = temp.long_name;
						}
					});

					break;
				}
				case 'AddressPrefixComponents': {
					allGoogleAddressOptions[componentType].map(x => {
						const temp = place.address_components.filter((y: { types: any[] }) => y.types.filter(z => z == x)?.[0])?.[0];
						if (temp) {
							subPremise = temp.long_name + '/';
						}
					});

					break;
				}
				case 'AddressStateComponents': {
					allGoogleAddressOptions[componentType].map(x => {
						const temp = place.address_components.filter((y: { types: string[] }) => y.types.filter(z => z == x)?.[0])?.[0];
						if (temp) {
							asset.State = temp.short_name;
						}
					});

					break;
				}
				case 'AddressPostcodeComponents': {
					allGoogleAddressOptions[componentType].map(x => {
						const temp = place.address_components.filter((y: { types: string[] }) => y.types.filter(z => z == x)?.[0])?.[0];
						if (temp) {
							asset.Postcode = temp?.long_name;
						}
					});

					break;
				}
				case 'AddressStreetNameComponents': {
					allGoogleAddressOptions[componentType].map(x => {
						const temp = place.address_components.filter((y: { types: string[] }) => y.types.filter(z => z == x)?.[0])?.[0];
						if (temp) {
							streetName = temp.long_name;
						}
					});

					break;
				}

				case 'AddressSuburbComponents': {
					allGoogleAddressOptions[componentType].map(x => {
						const temp = place.address_components.filter((y: { types: string[] }) => y.types.filter(z => z == x)?.[0])?.[0];
						if (temp) {
							asset.Suburb = temp.long_name;
						}
					});

					break;
				}
				case 'AddressCountryComponents': {
					allGoogleAddressOptions[componentType].map(x => {
						const temp = place.address_components.filter((y: { types: string[] }) => y.types.filter(z => z == x)?.[0])?.[0];
						if (temp) {
							asset.Country = temp.long_name;
						}
					});

					break;
				}
			}
			asset.Street = (subPremise + streetNumber + ' ' + streetName).trimStart();
		}

		const latLng = place.geometry.location.toJSON();
		asset.GeolocationDto = {
			Latitude: latLng.lat,
			Longitude: latLng.lng,
		};

		this.lookupService.getTimezoneCodeFromLatLong(latLng.lat, latLng.lng).subscribe(x => {
			asset.TimeZoneCode = x ?? Constants.Timezone.EuropeLondonGMTBST;

			this.setInput(place.formatted_address);
			this.setAddress.emit(asset);

			// dirty hack to cause ngClass (error) to be rerendered. Otherwise user needs to do a mouse click to see changes
			this.addresstext.nativeElement.focus();
			this.addresstext.nativeElement.blur();
		});
	}

	inputChanged(value: string) {
		if (!value) {
			this.formattedAddress = null;
			this.setAddress.emit(null);
		}
	}

	setInput(address = '') {
		if (address) {
			this.formattedAddress = address;
		}

		if (this.showSelectedAddress) {
			if (this.addresstext.nativeElement.value && this.formattedAddress) {
				this.addresstext.nativeElement.value = this.formattedAddress;
			}
		} else {
			this.addresstext.nativeElement.value = '';
		}
	}
}
