import { Component, Input, ViewEncapsulation } from '@angular/core';
import { LookupValue } from 'src/app/shared/models/lookups';
import { FlagApiService } from 'src/app/shared/services';

@Component({
	selector: 'app-summary-flags',
	templateUrl: './summary-flags.component.html',
	styleUrls: ['./summary-flags.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class SummaryFlagsComponent {
	@Input() lookupFlags: LookupValue[];
	@Input() selectedFlags: LookupValue[];
	@Input() flagIds = new Map<string, string>();
	@Input() refId: string;
	@Input() refName: string;
	@Input() refType: string;
	@Input() isForCreateNew = false;
	@Input() allowEdit = true;
	@Input() showEdit = true;
	@Input() tooltipFlagEdit = '';

	constructor(private flagService: FlagApiService) {}

	onTagsChange(event) {
		const item = event.itemValue as LookupValue;
		if (this.selectedFlags.find(x => x.CodeValue == item.CodeValue)) {
			this.addFlag(item.CodeValue);
		} else {
			this.removeFlag(item.CodeValue);
		}
	}

	requestFlagRemoval(code: string) {
		this.removeFlag(code);
	}

	private removeFlagFromSelected(code: string) {
		const item = this.selectedFlags.find(x => x.CodeValue == code);
		if (item) {
			const index = this.selectedFlags.indexOf(item);
			this.selectedFlags.splice(index, 1);
		}
	}

	private removeFlag(code: string) {
		if (this.isForCreateNew === true) {
			this.removeFlagFromSelected(code);
		} else if (this.flagIds.has(code)) {
			const flagId = this.flagIds.get(code);
			this.flagService.disableFlagAlert(flagId).subscribe(response => {
				if (response.IsSuccess) {
					this.removeFlagFromSelected(code);
					if (this.flagIds.has(code)) {
						this.flagIds.delete(code);
					}
				}
			});
		}
	}

	private addFlag(code: string) {
		if (this.isForCreateNew === true) {
			return true;
		} else {
			const flag = this.flagService.constructFlag(this.lookupFlags, code, this.refId, this.refName, this.refType);
			this.flagService.saveFlagAlert(flag).subscribe(
				response => {
					if (response.IsSuccess) {
						if (response.Data && response.Data.FlagAlertId) {
							this.flagIds.set(code, response.Data.FlagAlertId);
						}
					} else {
						this.removeFlagFromSelected(code);
					}
				},
				err => this.removeFlagFromSelected(code)
			);
		}
	}

	showCreate(): boolean {
		if (this.isForCreateNew || this.showEdit == true) return true;

		return false;
	}

	allowCreate(): boolean {
		if (this.isForCreateNew) return true;

		if (!this.allowEdit && this.selectedFlags != null && this.selectedFlags.length == 0) return true;

		return this.allowEdit;
	}

	isCreateDisable(): boolean {
		if (this.isForCreateNew) return false;

		return !this.allowEdit && this.selectedFlags != null && this.selectedFlags.length == 0;
	}

	showDelete(): boolean {
		if (this.isForCreateNew || this.allowEdit == true) return true;

		return false;
	}

	getEditTooltip(): string {
		return this.tooltipFlagEdit;
	}
}
