import { Constants } from './constants.constant';

export class IconConstants {
	static ICONS_LIST = [
		{ value: Constants.MapMarkerStyle.SmallCircle, label: Constants.MapMarkerStyle.Names.SmallCircle, icon: 'fa fa-circle' },
		{ value: Constants.MapMarkerStyle.LargeCircle, label: Constants.MapMarkerStyle.Names.LargeCircle, icon: 'fa fa-circle' },
		{ value: Constants.MapMarkerStyle.SquareUp, label: Constants.MapMarkerStyle.Names.SquareUp, icon: 'fas fa-caret-square-up' },
		{ value: Constants.MapMarkerStyle.SquareLeft, label: Constants.MapMarkerStyle.Names.SquareLeft, icon: 'fas fa-caret-square-left' },
		{ value: Constants.MapMarkerStyle.SquareDown, label: Constants.MapMarkerStyle.Names.SquareDown, icon: 'fas fa-caret-square-down' },
		{ value: Constants.MapMarkerStyle.SquareRight, label: Constants.MapMarkerStyle.Names.SquareRight, icon: 'fas fa-caret-square-right' },
		{ value: Constants.MapMarkerStyle.MapMarker, label: Constants.MapMarkerStyle.Names.MapMarker, icon: 'fas fa-map-marker' },
		{ value: Constants.MapMarkerStyle.Wifi, label: Constants.MapMarkerStyle.Names.Wifi, icon: 'fas fa-wifi' },
		{ value: Constants.MapMarkerStyle.Wheelchair, label: Constants.MapMarkerStyle.Names.Wheelchair, icon: 'fas fa-wheelchair' },
		{ value: Constants.MapMarkerStyle.Tree, label: Constants.MapMarkerStyle.Names.Tree, icon: 'fas fa-tree' },
		{ value: Constants.MapMarkerStyle.SquarePlus, label: Constants.MapMarkerStyle.Names.SquarePlus, icon: 'fas fa-plus-square' },
		{ value: Constants.MapMarkerStyle.FireExtinguisher, label: Constants.MapMarkerStyle.Names.FireExtinguisher, icon: 'fas fa-fire-extinguisher' },
	];

	static defaultIcon = 'fa fa-circle';

	static getIconClass(code: string): string {
		return this.ICONS_LIST.find(x => x.value == code)?.icon ?? this.defaultIcon;
	}
}
