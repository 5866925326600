<div
	class="layout-sidebar"
	[ngClass]="{ 'layout-sidebar-active': appMain.sidebarActive, 'layout-sidebar-dark': app.darkMenu }"
	(click)="appMain.onSidebarClick($event)"
	(mouseover)="appMain.sidebarActive = !appMain.isMobile()"
	(mouseleave)="appMain.sidebarActive = false">
	<div class="sidebar-logo">
		<a routerLink="/">
			<img alt="logo" src="images/spydertechlogo-header.png" />
			<div style="display: inline-block; padding-left: 10px">
				<span class="app-name" style="font-size: 1.3em; font-family: 'Raleway'">Spyder<i>Flow</i></span>
			</div>
		</a>
		<a href="#" class="sidebar-anchor" pTooltip="Toggle Menu" tooltipPosition="bottom" (click)="appMain.onToggleMenuClick($event)"></a>
	</div>
	<ul app-submenu [item]="model" root="true" class="layout-menu" visible="true" (click)="onMenuClick()" parentActive="true"></ul>
</div>
