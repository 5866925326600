<form [formGroup]="bankDetailForm">
	<div class="p-grid p-formgrid p-m-0 p-p-0" style="margin: 0px">
		<div class="p-col-6 p-md-0 form-element-left">
			<app-form-item
				[visible]="[formModes.Edit, formModes.View].includes(formMode)"
				[controlType]="formControls.Textbox"
				[editable]="[formModes.Edit].includes(formMode)"
				[control]="bankDetailForm.get('bankAccountName')"
				data-testid="bankAccountName"
				label="Bank Name">
			</app-form-item>
		</div>
		<div class="p-col-6 p-md-0 form-element-right">
			<app-form-item
				[visible]="[formModes.View, formModes.Edit].includes(formMode)"
				[editable]="[formModes.Readonly].includes(formMode)"
				[controlType]="formControls.InputInfoBox"
				[control]="bankDetailForm.get('infoMesageBankAccountName')">
			</app-form-item>
		</div>
		<div class="p-col-3 p-md-0 sub-heading-2">Bank Details</div>
		<div class="p-col-3 p-md-0 form-element-left"></div>
		<div class="p-col-6 p-md-0 form-element-right">
			<app-form-item
				[visible]="[formModes.View, formModes.Edit].includes(formMode)"
				[editable]="[formModes.Readonly].includes(formMode)"
				[controlType]="formControls.InputInfoBox"
				[control]="bankDetailForm.get('infoMesageBankDetailsHeader')">
			</app-form-item>
		</div>
	</div>
</form>
<p-toolbar>
	<ng-template pTemplate="left"><div class="p-col-12 p-md-0 sub-heading-2 p-toolbar-header"></div> </ng-template>
	<ng-template pTemplate="right">
		<div class="button-with-tooltip-holder" *ngIf="showAddBankDetail()" tooltipPosition="bottom">
			<p-button label="Add" data-testid="bankDetailAdd" (onClick)="addItemInline()" icon="pi pi-plus"> </p-button>
		</div>
	</ng-template>
</p-toolbar>
<div class="p-grid p-formgrid p-m-0 p-p-0">
	<div class="p-col-12 p-md-0 table-form-element">
		<p-table
			#bankDetailTable
			dataKey="Id"
			[rowHover]="true"
			responsiveLayout="scroll"
			[paginator]="false"
			[value]="bankDetails"
			[rows]="50"
			editMode="row"
			[totalRecords]="bankDetails.length">
			<ng-template pTemplate="header">
				<tr>
					<th *ngIf="[formModes.Edit].includes(formMode) && this.bankDetailAddMode == false && this.bankDetailEditMode == false" style="width: 3rem"></th>
					<th pSortableColumn="Header">
						<div class="table-header-label">
							Header
							<p-sortIcon field="Header"></p-sortIcon>
							<p-columnFilter type="text" field="Header" display="menu" class="p-ml-auto"> </p-columnFilter>
						</div>
					</th>
					<th pSortableColumn="Value">
						<div class="table-header-label">
							Value
							<p-sortIcon field="Value"></p-sortIcon>
							<p-columnFilter type="text" field="Value" display="menu" class="p-ml-auto"> </p-columnFilter>
						</div>
					</th>
					<th style="width: 3rem" name="inline-edit-buttons"></th>
					<th style="width: 3rem" name="inline-edit-buttons"></th>
				</tr>
			</ng-template>
			<ng-template pTemplate="body" let-item let-editing="editing" let-ri="rowIndex">
				<tr class="p-selectable-row" data-testid="bankDetailRow" [pEditableRow]="item" [pReorderableRow]="ri">
					<td *ngIf="[formModes.Edit].includes(formMode) && this.bankDetailAddMode == false && this.bankDetailEditMode == false">
						<span class="pi pi-bars" pReorderableRowHandle></span>
					</td>
					<td>
						<p-cellEditor>
							<ng-template pTemplate="input">
								<input pInputText type="text" name="destHeader" autocomplete="off" id="destHeader" [(ngModel)]="item.Header" style="width: 100%" />
							</ng-template>
							<ng-template pTemplate="output">
								{{ item.Header }}
							</ng-template>
						</p-cellEditor>
					</td>
					<td>
						<p-cellEditor>
							<ng-template pTemplate="input">
								<input pInputText type="text" name="destValue" autocomplete="off" id="destValue" [(ngModel)]="item.Value" style="width: 100%" />
							</ng-template>
							<ng-template pTemplate="output">
								{{ item.Value }}
							</ng-template>
						</p-cellEditor>
					</td>
					<td style="text-align: center; padding-left: 0px; padding-right: 0px">
						<div *ngIf="[formModes.Edit].includes(formMode) && currentBankDetail == null">
							<button
								pButton
								pRipple
								type="button"
								data-testid="bankDetailEdit"
								pInitEditableRow
								tooltipPosition="bottom"
								icon="pi pi-pencil"
								(click)="onRowEditInit(item, ri)"
								class="p-button-rounded p-button-text"></button>
						</div>
						<div *ngIf="[formModes.Edit].includes(formMode) && currentBankDetail == item">
							<button
								pButton
								pRipple
								type="button"
								pTooltip="Save"
								tooltipPosition="bottom"
								data-testid="saveBankDetail"
								icon="pi pi-save"
								(click)="onRowEditSave()"
								class="p-button-rounded p-button-text p-button-success"></button>
							<button
								pButton
								pRipple
								type="button"
								pTooltip="Cancel"
								tooltipPosition="bottom"
								pCancelEditableRow
								icon="pi pi-ban"
								(click)="onRowEditCancel(item, ri)"
								class="p-button-rounded p-button-text p-button-danger"></button>
						</div>
					</td>
					<td>
						<div *ngIf="[formModes.Edit].includes(formMode) && this.bankDetailAddMode == false && this.bankDetailEditMode == false">
							<button
								pButton
								pRipple
								type="button"
								data-testid="bankDetailDelete"
								tooltipPosition="bottom"
								icon="pi pi-trash"
								class="p-button-rounded p-button-text"
								(click)="onRowDelete(item)"></button>
						</div>
					</td>
				</tr>
			</ng-template>
		</p-table>
	</div>
</div>
