import { Directive } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';

@Directive({
	selector: '[validateOptionalDateTo]',
	providers: [
		{
			provide: NG_VALIDATORS,
			useExisting: OptionalDateToValidatorDirective,
			multi: true,
		},
	],
})
export class OptionalDateToValidatorDirective implements Validator {
	validate(control: AbstractControl): ValidationErrors | null {
		if (control.parent) {
			const dateFrom = control.parent.get('dateFrom');

			if (dateFrom) {
				if (dateFrom.value && control.value < dateFrom.value && control.value) {
					return { dateToSmallerThanDateFrom: 'This date must be after than the Date From.' };
				} else {
					return null;
				}
			} else {
				return null;
			}
		}
	}
}
