import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { PrimeNGConfig } from 'primeng/api';
import { Table } from 'primeng/table';
import { Observable, of } from 'rxjs';
import { Globals } from 'src/app/globals';
import { Constants } from 'src/app/shared/constants/constants.constant';
import { LazyLoadArgs, PagedResponse } from 'src/app/shared/models/api';
import { FormMode } from 'src/app/shared/models/forms';
import { RegionSettings } from 'src/app/shared/models/settings';
import { GlobalSearch } from 'src/app/shared/models/shared';
import { SearchService } from 'src/app/shared/services';
import { DataTableBaseComponent } from 'src/app/core/components/data-table.base.component';
import { AppContextService } from 'src/app/core/services';
import { nameofFactory } from 'src/app/core/utils';

@Component({
	selector: 'app-asset-history',
	templateUrl: './asset-history.component.html',
	styleUrls: ['./asset-history.component.scss'],
})
export class AssetHistoryComponent extends DataTableBaseComponent<GlobalSearch> implements OnInit {
	@Input() assetNumber: string;
	@Input() clientId: string;
	@Input() workOrderId: string;
	@Input() scopeId: string;
	@Input() quoteRequestId: string;
	@Input() serviceRequestId: string;
	@Input() timezoneCode: string;
	@ViewChild('assetHistoryTable') assetHistoryTable: Table;
	list: GlobalSearch[];
	totalRecords = 0;
	formMode = FormMode.View;
	formModes = FormMode;
	region: RegionSettings;
	listEnabled = false; // this is used to prevent the list from being updated automatically before the parent tab is clicked
	constructor(appContext: AppContextService, private searchService: SearchService, private router: Router, globals: Globals, config: PrimeNGConfig) {
		super(globals, appContext, config);
	}

	ngOnInit() {
		this.region = this.appContext.getRegion();
	}

	readonly nameof = nameofFactory<GlobalSearch>();

	getDateFilterFields(): string[] {
		return [this.nameof('DateIssued'), this.nameof('TargetDate'), this.nameof('DateCompleted')];
	}

	getDateFilterTimezoneCode(): string {
		return this.timezoneCode;
	}

	refreshData() {
		this.listEnabled = true;
		this.loadData();
	}

	lazyLoad(event: LazyLoadArgs): Observable<PagedResponse<GlobalSearch>> {
		if (this.listEnabled == false) return of(new PagedResponse<GlobalSearch>());

		if (this.clientId != null) return this.searchService.getPagedGlobalList(event, null, this.clientId);
		else if (this.assetNumber != null) return this.searchService.getPagedGlobalList(event, this.assetNumber, null);

		return of(new PagedResponse<GlobalSearch>());
	}

	currencyFilter(value, id) {
		if (value) this.assetHistoryTable.filters[id][0].value = value.toString();
	}

	getRowTypeName(dto: GlobalSearch) {
		switch (dto.ReferenceTypeCode) {
			case Constants.ReferenceType.ServiceRequest:
				return dto.ReferenceId != this.serviceRequestId ? Constants.ReferenceType.Names.ServiceRequest : null;
			case Constants.ReferenceType.Scope:
				return dto.ReferenceId != this.scopeId ? Constants.ReferenceType.Names.Scope : null;
			case Constants.ReferenceType.QuoteRequest:
				return dto.ReferenceId != this.quoteRequestId ? Constants.ReferenceType.Names.QuoteRequest : null;
			case Constants.ReferenceType.WorkOrder:
				return dto.ReferenceId != this.workOrderId ? Constants.ReferenceType.Names.WorkOrder : null;
			default:
				return null;
		}
	}

	handleRowClick(dto: GlobalSearch) {
		try {
			// TO-DO Find alternative for same page reload
			this.router.routeReuseStrategy.shouldReuseRoute = () => false;
		} catch (err) {
			console.log(err);
		}

		switch (dto.ReferenceTypeCode) {
			case Constants.ReferenceType.ServiceRequest:
				this.router.navigate(['/service-request/', dto.ReferenceId]);
				return;
			case Constants.ReferenceType.Scope:
				this.router.navigate(['/scope/', dto.ReferenceId]);
				return;
			case Constants.ReferenceType.QuoteRequest:
				this.router.navigate(['/quote/', dto.ReferenceId]);
				return;
			case Constants.ReferenceType.WorkOrder:
				this.router.navigate(['/work-order/', dto.ReferenceId]);
				return;
		}
	}
}
