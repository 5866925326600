import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable, OnDestroy } from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { Constants } from 'src/app/shared/constants/constants.constant';
import { ActionResult } from 'src/app/shared/models/api';
import { DefaultRegionSettings, RegionSettings, WorkflowSettings } from 'src/app/shared/models/settings';
import { BreadcrumbItem } from 'src/app/shared/models/ui';
import { Globals } from '../../globals';
import { EnvironmentConfig, Guid, UserClientSecurity } from '../models';
import { CacheService } from './cache.service';
import { LoaderService } from './loader.service';
import { UtilService } from './util.service';

@Injectable({ providedIn: 'root' })
export class AppContextService implements OnDestroy {
	public config: EnvironmentConfig = new EnvironmentConfig();
	public contextNavigationSubscription: any;

	public ViewTitle: string;
	public BasePageCSSClass: string;
	public HasTableBasedData = false;
	public routeModuleName: string;
	private userProfile: UserClientSecurity;
	public breadcrumbItems: BreadcrumbItem[];
	public showBreadcrumb: boolean;
	public showFilter: boolean;
	public clientCompanyChanged = new EventEmitter();
	public previousClientCompanySelection: string;
	public userChanged = new EventEmitter();

	constructor(
		private router: Router,
		public util: UtilService,
		public location: Location,
		public httpClient: HttpClient,
		public cache: CacheService,
		public globals: Globals,
		public loaderService: LoaderService
	) {
		this.contextNavigationSubscription = this.router.events.subscribe((e: any) => {
			if (e instanceof NavigationEnd || e instanceof NavigationCancel || e instanceof NavigationError) {
				this.loaderService.unblock();
				return;
			}
			if (e instanceof NavigationStart) {
				this.loaderService.block();
				return;
			}
		});

		try {
			this.extractConfig();
		} catch (ex) {
			this.util.hideWait();
			console.error(ex);
		}
	}

	ngOnDestroy() {
		try {
			if (this.contextNavigationSubscription) {
				this.contextNavigationSubscription.unsubscribe();
			}
		} catch (ex) {
			this.util.hideWait();
			console.error(ex);
		}
	}

	private extractConfig(): void {
		this.config.production = environment.production;
		this.config.apiUrl = environment.apiUrl;
	}

	get user(): UserClientSecurity {
		if (this.userProfile == null) {
			const user = JSON.parse(sessionStorage.getItem('user') ?? localStorage.getItem('user'));
			this.userProfile = new UserClientSecurity(user);
		}

		return this.userProfile;
	}

	public setUser(user: UserClientSecurity) {
		const userSessionId = this.userProfile?.UserSessionId ?? Guid.newGuid();

		// store user details in local storage to keep user logged in between page refreshes
		this.userProfile = user;
		if (user) this.userProfile.UserSessionId = userSessionId;

		sessionStorage.setItem('user', JSON.stringify(this.userProfile));
		localStorage.setItem('user', JSON.stringify(this.userProfile));

		this.userChanged.emit();
		this.getClientUser();
	}

	clearUser() {
		// updates last subscriber for user
		localStorage.setItem(`${this.user.UserProfileId}_LastSubscriberId`, this.userProfile.SubscriberId);

		// remove user from local storage to log user out
		sessionStorage.removeItem('user');
		localStorage.removeItem('user');

		this.userProfile = null;
	}

	getLastSubscriberId(): string {
		return localStorage.getItem(`${this.user.UserProfileId}_LastSubscriberId`);
	}

	public hasPermission(permissionCode: string): boolean {
		if (permissionCode == null) return true;

		return this.user?.UserPermissionCodes?.includes(permissionCode);
	}

	public hasAnyPermission(permissionCodes: string[]): boolean {
		if (permissionCodes == null) return true;

		return permissionCodes.map(x => this.user?.UserPermissionCodes?.includes(x) ?? false)?.includes(true) ?? false;
	}

	public hasAllPermission(permissionCodes: string[]): boolean {
		if (permissionCodes == null) return true;

		const matches = permissionCodes.map(x => this.user?.UserPermissionCodes?.includes(x) ?? false);
		return matches != null && matches.length > 0 && matches.includes(false) == false;
	}

	public getRegion(): RegionSettings {
		let region = this.user?.Region;

		if (!region?.RegionCode) region = DefaultRegionSettings;

		if (!region?.DateFormat) region.DateFormat = DefaultRegionSettings.DateFormat;
		if (!region?.DateTimeFormat) region.DateTimeFormat = DefaultRegionSettings.DateTimeFormat;
		if (!region?.TimeFormat) region.TimeFormat = DefaultRegionSettings.TimeFormat;

		if (region?.DateTimeFormat) region.DateTimeFormat = region.DateTimeFormat.replace('tt', 'a');
		if (region?.TimeFormat) region.TimeFormat = region.TimeFormat.replace('tt', 'a');

		return region;
	}

	public getWorkflowSettings(): WorkflowSettings {
		const workflow = this.user?.Workflow;
		return workflow;
	}

	public getViewByFilter(): string {
		return this.getSavedValue('ViewBy-Dropdown');
	}

	onClientCompanyChanged(): void {
		const currentSelection = this.getViewByFilter();
		if (this.previousClientCompanySelection != currentSelection) {
			this.previousClientCompanySelection = currentSelection;
			this.clientCompanyChanged.emit();
		}
	}

	public getClientUser(): boolean {
		return this.user?.ExternalUserRelationTypeCode == Constants.UserRelationType.Client;
	}

	public getStorageKey(key: string) {
		return `${this.user?.UserProfileId}_${this.user?.SubscriberId}_${key}`;
	}

	public getSavedValue(key: string): any {
		return localStorage.getItem(this.getStorageKey(key));
	}

	public getSavedValueFromJson(key: string): any {
		const val = this.getSavedValue(key);
		return JSON.parse(val);
	}

	public setSavedValue(key: string, value: any) {
		if (value) {
			localStorage.setItem(this.getStorageKey(key), value);
		} else {
			localStorage.removeItem(this.getStorageKey(key));
		}
	}

	public setSavedValueAsJson(key: string, value: any) {
		const val = JSON.stringify(value);
		this.setSavedValue(key, val);
	}

	public clearSavedValue(key: string) {
		localStorage.removeItem(this.getStorageKey(key));
	}

	public clearSavedValueUsingOriginalKey(key: string) {
		localStorage.removeItem(key);
	}

	prepareMessages(referenceTypeCode: string, referenceId: string, actionResult: ActionResult) {
		this.setSavedValue(`preparedMessages_${referenceTypeCode}_${referenceId}`, JSON.stringify(actionResult));
	}

	displayPreparedMessages(referenceTypeCode: string, referenceId: string) {
		const actionResultJson = this.getSavedValue(`preparedMessages_${referenceTypeCode}_${referenceId}`);
		if (actionResultJson != null && actionResultJson != 'null') {
			this.clearSavedValue(`preparedMessages_${referenceTypeCode}_${referenceId}`);
			const actionResult = JSON.parse(actionResultJson);
			this.util.showActionResult(actionResult);
		}
	}
}
