import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';
import { Button } from 'primeng/button';
import { Table } from 'primeng/table';
import { Observable, of } from 'rxjs';
import { Globals } from 'src/app/globals';
import { Constants } from 'src/app/shared/constants/constants.constant';
import { LazyLoadArgs, PagedResponse } from 'src/app/shared/models/api';
import { FormMode } from 'src/app/shared/models/forms';
import { RegionSettings } from 'src/app/shared/models/settings';
import { AuditHistory } from 'src/app/shared/models/shared';
import { AuditHistoryService } from 'src/app/shared/services';
import { DataTableBaseComponent } from 'src/app/core/components/data-table.base.component';
import { AppContextService } from 'src/app/core/services';
import { nameofFactory } from 'src/app/core/utils';
import { AUDIT_HISTORY_DISPLAY_FILTER_LIST, AuditHistoryDisplayFilterType } from '../../enums';

@Component({
	selector: 'app-audit-history',
	templateUrl: './audit-history.component.html',
	styleUrls: ['./audit-history.component.scss'],
})
export class AuditHistoryComponent extends DataTableBaseComponent<AuditHistory> implements OnInit {
	@Input() parentReferenceTypeCode: string;
	@Input() parentReferenceId: string;
	@Input() includeChildHistory = false;
	@Input() isSystemAudit = false;
	@Input() showActionsToolbar = true;
	@Input() timezoneCode: string;
	@ViewChild('showAllButton') showAllButton: Button;
	@ViewChild('showActionsButton') showActionsButton: Button;
	@ViewChild('auditHistoryTable') auditHistoryTable: Table;

	actionsOnly = false;
	auditHistoryDetails: AuditHistory[] = [];
	totalRecords = 0;
	formMode = FormMode.View;
	formModes = FormMode;
	region: RegionSettings;
	listEnabled = false;

	displayFilterOptions = AUDIT_HISTORY_DISPLAY_FILTER_LIST;
	displayFilterTypes = AuditHistoryDisplayFilterType;
	selectedFilterOption = this.displayFilterTypes[this.displayFilterTypes.SHOW_ALL];

	constructor(appContext: AppContextService, globals: Globals, config: PrimeNGConfig, private auditHistoryService: AuditHistoryService) {
		super(globals, appContext, config);
	}

	ngOnInit(): void {
		this.region = this.appContext.getRegion();
		this.auditHistoryDetails = [];
		this.updateActionsOnly();
	}

	readonly nameof = nameofFactory<AuditHistory>();

	getDateFilterFields(): string[] {
		return [this.nameof('DateAdded')];
	}

	getDateFilterTimezoneCode(): string {
		return this.timezoneCode;
	}

	changeFilterOption() {
		this.updateActionsOnly();
	}

	updateActionsOnly() {
		this.actionsOnly = this.selectedFilterOption == this.displayFilterTypes[this.displayFilterTypes.SHOW_ACTIONS];
		if (this.auditHistoryTable != null) this.auditHistoryTable.onPageChange({ first: 0, rows: this.auditHistoryTable.rows });
	}

	lazyLoad(event: LazyLoadArgs): Observable<PagedResponse<AuditHistory>> {
		if (this.parentReferenceTypeCode && this.parentReferenceId && this.listEnabled)
			return this.auditHistoryService.getPagedListAuditHistory(
				event,
				this.region,
				this.parentReferenceTypeCode,
				this.parentReferenceId,
				this.includeChildHistory,
				this.actionsOnly,
				this.isSystemAudit
			);

		return of(new PagedResponse<AuditHistory>());
	}

	refreshData() {
		this.listEnabled = true;
		this.updateActionsOnly();
	}

	isPrice(history: AuditHistory) {
		return history.HistoryFieldTypeCode == Constants.HistoryFieldType.Price;
	}
}
