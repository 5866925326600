import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { InvoiceDetails } from 'src/app/features/invoice/models';
import { InvoiceApiService } from 'src/app/features/invoice/services';
import { MessageDialogComponent } from 'src/app/shared/components/bulk-action/message-dialog/message-dialog.component';
import { ActionResult, ObjectAccessReason } from 'src/app/shared/models/api';
import { BulkActionBaseService } from 'src/app/shared/services/bulk-action.base.service';
import { ttPermissionType } from 'src/app/core/constants/tooltip-messages.constant';
import { AppContextService, DynamicDialogService, TooltipMessageService } from 'src/app/core/services';
import { PaidInvoiceConfirmationDialogComponent } from '../components/paid-invoice-confirmation-dialog/paid-invoice-confirmation-dialog.component';
import { InvoiceConstants } from '../constants';

@Injectable({
	providedIn: 'root',
})
export class InvoiceBulkActionService extends BulkActionBaseService {
	constructor(
		public appContext: AppContextService,
		private invoiceService: InvoiceApiService,
		private tooltipMessageService: TooltipMessageService,
		private dialogService: DynamicDialogService
	) {
		super(appContext);
	}

	getBulkActionObjectPrimaryKeyName(): string {
		return 'InvoiceId';
	}

	setFilterActionOptions() {
		this.filterActions = [];
		InvoiceConstants.FilterActionsList.forEach(option => {
			if (option == InvoiceConstants.ActionName_Paid && !this.hasPaymentPermission()) return;

			this.filterActions.push(this.addFilterOption(option, option));
		});
	}

	processApiRequest(actionName: string, sel: any, args: any): Observable<ActionResult<any>> {
		switch (actionName) {
			case InvoiceConstants.ActionName_Approve:
				return this.invoiceService.approveInvoice(sel.InvoiceId);
			case InvoiceConstants.ActionName_Cancel:
				return this.invoiceService.cancelInvoice(sel.InvoiceId);
			case InvoiceConstants.ActionName_Paid:
				return this.invoiceService.markInvoiceAsPaid(sel.InvoiceId, args?.datePaid, args?.referenceNumber, args?.comments);
			case InvoiceConstants.ActionName_Hold:
				return this.invoiceService.holdInvoice(sel.InvoiceId);
			case InvoiceConstants.ActionName_Release:
				return this.invoiceService.issueInvoice(sel.InvoiceId);
			case InvoiceConstants.ActionName_Void:
				return this.invoiceService.voidInvoice(sel.InvoiceId);
			default:
				return null;
		}
	}

	refreshUpdatedValues(resp: ActionResult<any>) {
		const updatedInvoiceDet: InvoiceDetails = resp.Data;
		const filteredInvoice: InvoiceDetails = this.filteredSelectedItems.find(x => x.InvoiceId == updatedInvoiceDet.InvoiceId);
		const selInvoice: InvoiceDetails = this.selectedItems.find(x => x.InvoiceId == updatedInvoiceDet.InvoiceId);
		if (filteredInvoice && selInvoice) {
			filteredInvoice.StatusCode = updatedInvoiceDet.StatusCode;
			filteredInvoice.StatusName = updatedInvoiceDet.StatusName;
			filteredInvoice.ObjectAccess = updatedInvoiceDet.ObjectAccess;

			selInvoice.StatusCode = updatedInvoiceDet.StatusCode;
			selInvoice.StatusName = updatedInvoiceDet.StatusName;
			selInvoice.ObjectAccess = updatedInvoiceDet.ObjectAccess;
		}
		return;
	}

	hasPaymentPermission() {
		return this.appContext.user.Workflow?.Invoice?.AllowManualPayments;
	}

	showBulkAction(item: InvoiceDetails): boolean {
		if (!this.filterActionSelected) return false;
		const actionName = this.filterActionSelected?.NameValue ?? (this.filterActionSelected as unknown as string);

		const access = this.getActionAccess(item, actionName);
		return access?.IsAllowed;
	}

	allowBulkAction(item: InvoiceDetails): boolean {
		if (!this.filterActionSelected) return false;
		const actionName = this.filterActionSelected?.NameValue ?? (this.filterActionSelected as unknown as string);

		const access = this.getActionAccess(item, actionName);
		return access?.IsAllowed;
	}

	private getActionAccess(item: InvoiceDetails, actionName: string): ObjectAccessReason {
		const actionAccessName = InvoiceConstants.ActionPermissionNames.find(ap => ap.action == actionName)?.permission;
		if (actionAccessName) return item?.ObjectAccess?.[actionAccessName];

		return null;
	}

	getPadlockTooltip(item: InvoiceDetails): string {
		if (!this.filterActionSelected) return '';
		const actionName = this.filterActionSelected?.NameValue ?? this.filterActionSelected;

		const actionAccessName = InvoiceConstants.ActionPermissionNames.find(ap => ap.action == actionName)?.permission;
		return this.tooltipMessageService.getTooltipMessage('INVOICE', actionAccessName as ttPermissionType, item?.ObjectAccess?.[actionAccessName], item, {
			actionName: actionName,
		} as any);
	}

	showErrorDialog(item: InvoiceDetails) {
		const selItem = this.bulkActionResults.find(x => x.Details.InvoiceId == item.InvoiceId);
		if (!selItem) return;

		this.messageObject = selItem;
		this.dialogService.open({
			component: MessageDialogComponent,
			header: InvoiceConstants.MessageDialog_Header_Error,
			styleClass: 'error-message-dialog-custom',
			contentStyle: { overflow: 'auto' },
			data: {
				title: InvoiceConstants.MessageDialog_Title_Error,
				icon: InvoiceConstants.Message_Title_Icon_Error,
				messageObject: this.messageObject,
				ObjectName: InvoiceConstants.BulkAction_Object_Name,
				ObjectPropertyName_Number: InvoiceConstants.BulkAction_Object_PropertyName_Number,
			},
		});
	}

	actionIcons(actionName): string {
		return InvoiceConstants.ActionIconsNames.find(ai => ai.action == actionName)?.icon || '';
	}

	handleApplyClick(event: any) {
		if (this.isBulkActionView) {
			const actionName = this.filterActionSelected?.NameValue ?? this.filterActionSelected;
			if (actionName == InvoiceConstants.ActionName_Paid) {
				this.handleApplyPaidClick(actionName);
				return;
			}

			const confirmationHeader = InvoiceConstants.Confirmation_Header_Generic(actionName.toString());
			const confirmationMessage = InvoiceConstants.BulkAction_Confirmation_Message_Generic(this.filteredSelectedItems.length.toString(), actionName.toString());
			const confirmationIcon = this.actionIcons(actionName);

			this.util.clearMessages();
			this.util.confirmSaveCustomParams(confirmationMessage, confirmationHeader, confirmationIcon).then(result => {
				if (result) {
					this.startBulkAction(null);
					return true;
				}
			});
		}
	}

	handleApplyPaidClick(actionName: string) {
		this.dialogService
			.open({
				component: PaidInvoiceConfirmationDialogComponent,
				header: InvoiceConstants.Confirmation_Header_Generic(actionName),
				data: {
					title: InvoiceConstants.BulkAction_Confirmation_Message_Paid(this.filteredSelectedItems.length.toString(), actionName.toLowerCase()),
					icon: this.actionIcons(actionName),
				},
				contentStyle: { overflow: 'visible' },
			})
			.subscribe(value => {
				if (value) {
					this.startBulkAction(value);
				}
			});
	}

	//This is done to maintain UI checkbox select all checked
	onSelectionChange(items: any[]) {
		this.filteredSelectedItems = [];
		for (let i = items.length - 1; i >= 0; i--) {
			const item = items[i];
			if (this.allowBulkAction(item)) {
				this.filteredSelectedItems.push(item);
			}
		}
	}

	errorDialogClosed() {
		this.showMessage = false;
	}
}
