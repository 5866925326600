import { PaymentTermsDetail } from 'src/app/shared/models/pricing';
import { LookupValue } from './lookup-value.model';

export class FinancialEntityLookupValue extends LookupValue {
	FinancialEntityTypeCode: string;
	PaymentTermsCode: string;
	DisallowCustomPaymentDates: boolean;

	Street: string;
	Suburb: string;
	State: string;
	Postcode: string;
	Country: string;

	PaymentTerms: PaymentTermsDetail;
}
