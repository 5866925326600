export class FlagAlert {
	FlagAlertId: string;
	ReferenceTypeCode: string;
	ReferenceId: string;
	ReferenceName: string;
	FlagAlertTypeCode: string;
	FlagAlertTypeName: string;
	ColourCode: string;
	ColourName: string;

	TimeZoneCode: string;
	TimeZoneName: string;

	DateRemoved: Date;

	constructor(properties?: any) {
		if (properties) {
			for (const key in properties) {
				this[key] = properties[key];
			}
		}
	}
}
