import { COLUMNTYPES } from 'src/app/shared/constants/column.constants';
import { Column } from 'src/app/shared/models/shared/column';

export class AccountAdminConstants {
	//User Profile Detail
	static readonly Tab_detail_EditUserProfile: string = 'Edit User Profile';

	//External User Profile Detail
	static readonly Tab_detail_EditExternalUserProfile: string = 'Edit External User Profile';

	//User Role Detail
	static readonly Tab_detail_EditUserRole: string = 'Edit User Role';
	static readonly Tab_detail_DeleteUserRole: string = 'Delete User Role';

	//User Role Permission
	static readonly Tab_detail_AddRolePermission: string = 'Add Permission';
	static readonly Tab_detail_DeleteRolePermission: string = 'Delete Permission';

	//subscriber
	static readonly Tab_detail_EditSubscriber: string = 'Edit Subscriber';

	//Record Types
	//Added to keep consistent similar to Main record Types
	static readonly RecordType_ExternalUser: string = 'ExternalUser';
	static readonly RecordType_User: string = 'User';
	static readonly RecordType_UserRole: string = 'User Role';

	//Users List Columns
	static readonly COLUMNS_Users: Column[] = [
		{ order: 1, header: 'Username', field: 'Username', dataTestId: 'userName' },
		{ order: 2, header: 'Display Name', field: 'DisplayName' },
		{ order: 3, header: 'E-mail', field: 'ContactEmail' },
		{ order: 4, header: 'Phone #', field: 'ContactPhone' },
		{ order: 5, header: 'Mobile #', field: 'ContactMobile' },
		{ order: 6, header: 'User Role', field: 'UserRoleName' },
		{ order: 7, header: 'Status', field: 'StatusName', sortField: 'StatusCode', filterField: 'StatusName', filterdataTestId: 'userStatusFilter' },
	];

	//External Users List Columns
	static readonly COLUMNS_ExternalUsers: Column[] = [
		{ order: 1, header: 'Username', field: 'Username', dataTestId: 'externalUserListUserName' },
		{ order: 2, header: 'Display Name', field: 'DisplayName' },
		{ order: 3, header: 'E-mail', field: 'ContactEmail' },
		{ order: 4, header: 'Phone #', field: 'ContactPhone' },
		{ order: 5, header: 'Mobile #', field: 'ContactMobile' },
		{ order: 6, header: 'User Role', field: 'UserRoleName' },
		{ order: 7, header: 'Status', field: 'StatusName', filterdataTestId: 'externalUserListStatusFilter' },
		{ order: 8, header: 'Client', field: 'ClientName', filterdataTestId: 'clientNameFilter' },
	];

	//User Roles List Columns
	static readonly COLUMNS_UserRoles: Column[] = [
		{ order: 1, header: 'Name', field: 'Name', dataTestId: 'userName', filterdataTestId: 'nameFilter' },
		{ order: 2, header: 'Type', field: 'UserRelationTypeName', dataTestId: 'userRelationTypeName', filterdataTestId: 'userRelationTypeFilter' },
		{ order: 3, header: 'Description', field: 'Description', dataTestId: 'description', filterdataTestId: 'userRelationDescriptionFilter' },
		{
			order: 4,
			header: 'Is System Role?',
			field: 'CopiedFromSystem',
			type: COLUMNTYPES.BOOLEAN,
			isInnerHtmlFormat: true,
			hasFilterHeaderTemplate: true,
			isFilterCallback: true,
			filterType: COLUMNTYPES.BOOLEAN,
		},
	];
}
