import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Constants } from 'src/app/shared/constants/constants.constant';
import { LazyLoadArgs, PagedResponse } from 'src/app/shared/models/api';
import { RegionSettings } from 'src/app/shared/models/settings';
import { AuditHistory } from 'src/app/shared/models/shared';
import { ApiService, UtilService } from 'src/app/core/services';

@Injectable({
	providedIn: 'root',
})
export class AuditHistoryService {
	constructor(private apiService: ApiService, private util: UtilService) {}

	getPagedListAuditHistory(
		searchData: LazyLoadArgs,
		region: RegionSettings,
		parentReferenceTypeCode: string,
		parentReferenceId: string,
		includeChildHistory: boolean,
		actionsOnly: boolean,
		isSystemAudit: boolean
	): Observable<PagedResponse<AuditHistory>> {
		const httpParams: HttpParams = new HttpParams()
			.append('parentReferenceTypeCode', parentReferenceTypeCode)
			.append('parentReferenceId', parentReferenceId)
			.append('includeChildHistory', includeChildHistory)
			.append('actionsOnly', actionsOnly);

		let url = '/AuditHistory/GetPagedListAuditHistory';
		if (isSystemAudit) url = '/SystemAuditHistory/GetPagedListAuditHistory';

		const serviceCall = this.apiService.post(url, searchData, httpParams) as Observable<PagedResponse<AuditHistory>>;
		return serviceCall.pipe(
			map(result => {
				result.Data.forEach(item => {
					item.OldValue = this.getFormattedValue(item.OldValue, item, region);
					item.NewValue = this.getFormattedValue(item.NewValue, item, region);
				});
				return result;
			})
		);
	}

	getFormattedValue(value: string, item: AuditHistory, region: RegionSettings): string {
		if (value == null || value == '') return value;

		if (item.HistoryFieldTypeCode == Constants.HistoryFieldType.DateTime)
			return this.util.getFormattedDate(this.util.convertTextToDate(value, item.TimeZoneCode), region.DateTimeFormat, item.TimeZoneCode);
		if (item.HistoryFieldTypeCode == Constants.HistoryFieldType.Date)
			return this.util.getFormattedDate(this.util.convertTextToDate(value, item.TimeZoneCode), region.DateFormat, item.TimeZoneCode);

		if (item.HistoryFieldTypeCode == Constants.HistoryFieldType.LocalDateTime)
			return this.util.getFormattedDate(this.util.convertTextToDate(value, null), region.DateTimeFormat, null);
		if (item.HistoryFieldTypeCode == Constants.HistoryFieldType.LocalDate)
			return this.util.getFormattedDate(this.util.convertTextToDate(value, null), region.DateFormat, null);

		return value;
	}
}
