export class CommunicationSettings {
	NotificationReplyToEmails: NotificationReplyToEmails;
	NotificationDefaultSendToEmails: NotificationDefaultSendToEmails;

	constructor(properties?: any) {
		if (properties) {
			for (const key in properties) {
				this[key] = properties[key];
			}
		}
	}
}

export class NotificationReplyToEmails {
	System: string;
	ServiceRequests: string;
	Scopes: string;
	QuoteRequests: string;
	PurchaseOrders: string;
	DefectPurchaseOrders: string;
	CreditRecoveryPurchaseOrders: string;
	Invoices: string;

	constructor(properties?: any) {
		if (properties) {
			for (const key in properties) {
				this[key] = properties[key];
			}
		}
	}
}

export class NotificationDefaultSendToEmails {
	ServiceRequests: string;
	ReceivableInvoices: string[];
	PayableInvoices: string[];
	SalesCreditNotes: string[];
	PurchasesCreditNotes: string[];

	constructor(properties?: any) {
		if (properties) {
			for (const key in properties) {
				this[key] = properties[key];
			}
		}
	}
}
