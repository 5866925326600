export class GlobalSearch {
	GlobalSearchId: string;

	ReferenceTypeCode: string;
	ReferenceId: string;
	ReferenceName: string;

	RecordTypeCode: string;
	WorkActivityTypeName: string;

	ClientId: string;
	CompanyId: string;
	BusinessEntityId: string;

	AssetNumber: string;
	AssetTypeName: string;
	AssetReference: string;
	AssetCategoryName: string;
	Street: string;
	Suburb: string;

	OwnerName: string;
	EstimatorName: string;
	InspectorName: string;
	BusinessEntityName: string;
	ParentReferenceName: string;
	WorkOrderPriorityCode: string;

	IsCompleted: boolean;
	StatusName: string;

	DateIssued: Date;
	ScheduledStartDate: Date;
	TargetDate: Date;
	DateCompleted: Date;

	SellAmountInclTax: number;
	InvoicedSellAmountInclTax: number;

	TimeZoneCode: string;
	TimeZoneName: string;

	AddedBy: string;
	AddedByExternalUserRelationTypeCode: string;
	DateAdded: Date;

	DateRemoved: Date;
}
