import { Injectable } from '@angular/core';
import { BlockableUI } from 'primeng/api';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class LoaderService {
	private blockedSubject: BehaviorSubject<boolean>;
	blokedChange: Observable<boolean>;
	blockedElem: BlockableUI = null;

	constructor() {
		this.blockedSubject = new BehaviorSubject<boolean>(false);
		this.blokedChange = this.blockedSubject.asObservable();
	}

	get blocked() {
		return this.blockedSubject.value;
	}

	block(element: BlockableUI = null) {
		this.blockedElem = element && element.getBlockableElement() ? element : null;
		this.blockedSubject.next(true);
	}

	unblock() {
		this.blockedElem = null;
		this.blockedSubject.next(false);
	}
}
