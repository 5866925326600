import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
	name: 'arrayConcat',
})
export class ArrayConcatPipe implements PipeTransform {
	constructor(private sanitizer: DomSanitizer) {}
	transform(value: any[], propertyName?: string): string {
		let result: string = null;
		if (value && value.length > 0) {
			if (propertyName != null) {
				result = value.map(r => r[propertyName]).join(', ');
			} else {
				result = value.join(', ');
			}
		}
		return result;
	}
}
