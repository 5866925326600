import { Directive } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';

@Directive({
	selector: '[validateBusinessEntityRequired]',
	providers: [
		{
			provide: NG_VALIDATORS,
			useExisting: BusinessEntityRequiredValidatorDirective,
			multi: true,
		},
	],
})
export class BusinessEntityRequiredValidatorDirective implements Validator {
	validate(control: AbstractControl): ValidationErrors | null {
		if (control.parent) {
			const targetDateControl = control.parent.get('purchaseOrderTargetDate');

			if (targetDateControl) {
				if (targetDateControl.value && !control.value) {
					return { businessEntityRequired: 'This field is required if Due Date has been selected' };
				} else {
					return null;
				}
			} else {
				return null;
			}
		}
	}
}
