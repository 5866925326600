import { Directive } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';
import { ValidationConstants } from 'src/app/core/constants/validation.constant';

@Directive({
	selector: '[validatePassword]',
	providers: [
		{
			provide: NG_VALIDATORS,
			useExisting: PasswordValidatorDirective,
			multi: true,
		},
	],
})
export class PasswordValidatorDirective implements Validator {
	validate(control: AbstractControl): ValidationErrors | null {
		if (control.value == null || control.value === '') {
			return null;
		}
		const validator = new RegExp(ValidationConstants.passwordRegExp);
		return validator.test(control.value) ? null : { password: 'The length must be minimum of 8 characters including caps and symbols.' };
	}
}
