export class MultiselectListItem {
	NameValue: string;
	CodeValue: string;
	constructor(properties?: any) {
		if (properties) {
			for (const key in properties) {
				this[key] = properties[key];
			}
		}
	}
}
