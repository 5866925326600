import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { EntityLicenceConstants } from 'src/app/shared/constants/entity-licence.constant';
import { BusinessEntityLicence } from 'src/app/shared/models/business-entities';
import { FormMode, FormControl as FrmCntl } from 'src/app/shared/models/forms';
import { LookupValue } from 'src/app/shared/models/lookups';
import { RegionSettings } from 'src/app/shared/models/settings';
import { EntityLicenceApiService } from 'src/app/shared/services';
import { BaseComponent } from 'src/app/core/components/base.component';
import { AppContextService, TooltipMessageService } from 'src/app/core/services';
import { CustomValidators } from 'src/app/core/utils';

@Component({
	selector: 'app-entity-licence-detail',
	templateUrl: './entity-licence-detail.component.html',
})
export class EntityLicenceDetailComponent extends BaseComponent implements OnInit {
	@Input() formMode = FormMode.View;
	@Input() record: BusinessEntityLicence = new BusinessEntityLicence();
	@Input() fixedLicenceHolderName: string | null;
	@Output() editFinished = new EventEmitter();
	@Output() detailsClosed = new EventEmitter();

	formControls = FrmCntl;
	workServiceTypes: LookupValue[] = [];
	licenceCertificateTypes: LookupValue[] = [];
	initialValues: any;
	region: RegionSettings;

	recordForm = this.fb.group({
		licenceHolderName: [null, [Validators.required]],
		workServiceType: [null, [Validators.required]],
		licenceCertificateType: [null, [Validators.required]],
		licenceCertificateNumber: [null, [Validators.required]],
		expiryDate: [null, [CustomValidators.expiryDateOrNotApplicableRequired]],
		expiryDateNotApplicable: [null, [CustomValidators.expiryDateOrNotApplicableRequired]],
		dateAdded: [null],
		dateUpdated: [null],
		dateRemoved: [null],
		updatedBy: [null],
		addedBy: [null],
	});

	constructor(
		appContext: AppContextService,
		private entityLicenceService: EntityLicenceApiService,
		private fb: UntypedFormBuilder,
		private activatedRoute: ActivatedRoute,
		private tooltipMessageService: TooltipMessageService
	) {
		super(appContext);
	}

	ngOnInit(): void {
		this.activatedRoute.data.subscribe(data => {
			this.initLookups(data);

			if (this.record.BusinessEntityLicenceId) {
				this.patchForm();
				this.initialValues = this.recordForm.value;
			} else {
				this.formMode = this.formModes.Create;
				this.patchForm();
			}
		});
	}

	initLookups(data) {
		this.region = this.appContext.getRegion();
		this.workServiceTypes = data.pageData.workServiceTypes.slice();
		this.licenceCertificateTypes = data.pageData.licenceCertificateTypes.slice();

		this.util.addRecordValueIntoLookup(this.record.WorkServiceTypeCode, this.record.WorkServiceTypeName, this.workServiceTypes);
		this.util.addRecordValueIntoLookup(this.record.LicenceCertificateTypeCode, this.record.LicenceCertificateTypeName, this.licenceCertificateTypes);
	}

	patchForm() {
		const workServiceType = this.workServiceTypes.find(x => x.CodeValue === this.record.WorkServiceTypeCode);
		const licenceCertificateType = this.licenceCertificateTypes.find(x => x.CodeValue === this.record.LicenceCertificateTypeCode);

		this.recordForm.patchValue({
			licenceHolderName: this.fixedLicenceHolderName ?? this.record.LicenceHolderName,
			workServiceType: workServiceType,
			licenceCertificateType: licenceCertificateType,
			licenceCertificateNumber: this.record.LicenceCertificateNumber,
			expiryDate: this.record.ExpiryDate,
			expiryDateNotApplicable: this.record.ExpiryDateNotApplicable,
			dateAdded: this.record.DateAdded,
			dateUpdated: this.record.DateUpdated,
			dateRemoved: this.record.DateRemoved,
			updatedBy: this.record.UpdatedBy,
			addedBy: this.record.AddedBy,
		});

		if (this.formMode != this.formModes.Create) this.markGroupDirty(this.recordForm);
	}

	expiryChanged() {
		this.recordForm.controls['expiryDate'].updateValueAndValidity();
		this.recordForm.controls['expiryDateNotApplicable'].updateValueAndValidity();
	}

	editRecord() {
		this.recordForm.reset();
		this.patchForm();
		this.initialValues = this.recordForm.value;
		this.formMode = this.formModes.Edit;
	}

	cancelRecordEdit() {
		this.util.clearMessages();
		this.recordForm.reset(this.initialValues);
		this.formMode = this.formModes.View;
	}

	backToList() {
		if (this.formMode != this.formModes.View) {
			this.cancelRecordEdit();
			this.detailsClosed.emit();
		} else {
			this.detailsClosed.emit();
		}
	}

	saveRecord() {
		this.util.clearMessages();
		this.appContext.loaderService.block();

		if (this.recordForm.valid) {
			this.record.LicenceHolderName = this.recordForm.value.licenceHolderName;
			this.record.WorkServiceTypeCode = this.recordForm.value.workServiceType?.CodeValue;
			this.record.WorkServiceTypeName = this.recordForm.value.workServiceType?.NameValue;
			this.record.LicenceCertificateTypeCode = this.recordForm.value.licenceCertificateType?.CodeValue;
			this.record.LicenceCertificateTypeName = this.recordForm.value.licenceCertificateType?.NameValue;
			this.record.LicenceCertificateNumber = this.recordForm.value.licenceCertificateNumber;
			this.record.ExpiryDate = this.recordForm.value.expiryDate;
			this.record.ExpiryDateNotApplicable = this.recordForm.value.expiryDateNotApplicable === true ? true : null;

			this.record.ExpiryDateWithoutTimezone = this.util.formatAsDate(this.record.ExpiryDate); // workaround

			this.entityLicenceService.saveBusinessEntityLicence(this.record).subscribe(result => {
				if (result.IsSuccess) {
					this.record = this.util.deepClone(result.Data);

					this.recordForm.reset();
					this.patchForm();
					this.formMode = this.formModes.View;

					this.editFinished.emit(result.Data);
				}
				this.appContext.loaderService.unblock();
			});
		} else {
			this.appContext.loaderService.unblock();
			this.markGroupDirty(this.recordForm);
		}
	}

	deleteRecord() {
		this.util.confirmDelete('Do you want to delete this licence?').then(result => {
			if (result) {
				this.util.clearMessages();
				this.appContext.loaderService.block();

				this.entityLicenceService.disableBusinessEntityLicence(this.record.BusinessEntityLicenceId).subscribe(result => {
					this.appContext.loaderService.unblock();
					if (result.IsSuccess) {
						this.editFinished.emit(result.Data);
						this.backToList();
					}
				});
			}
		});
	}

	showEdit(): boolean {
		return this.record.ObjectAccess?.AllowEdit?.Hide != true;
	}

	allowEdit(): boolean {
		return this.record?.ObjectAccess?.AllowEdit?.IsAllowed;
	}

	getEditTooltip(): string {
		return (
			this.tooltipMessageService.getTooltipMessage('C_BUSINESS_ENTITY_LICENCE', 'AllowEdit', this.record.ObjectAccess?.AllowEdit, this.record) ??
			EntityLicenceConstants.Tab_detail_EditLicence
		);
	}

	showDelete(): boolean {
		return this.record.ObjectAccess?.AllowRemove?.Hide != true;
	}

	allowDelete(): boolean {
		return this.record?.ObjectAccess?.AllowRemove?.IsAllowed;
	}

	getDeleteTooltip(): string {
		return (
			this.tooltipMessageService.getTooltipMessage('C_BUSINESS_ENTITY_LICENCE', 'AllowRemove', this.record.ObjectAccess?.AllowRemove, this.record) ??
			EntityLicenceConstants.Tab_detail_DeleteLicence
		);
	}
}
