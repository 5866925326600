export class MapPosition {
	ClientX: number;
	ClientY: number;

	ClientWidth: number;
	ClientHeight: number;

	MarkerSize: number;

	constructor(properties?: any) {
		if (properties) {
			for (const key in properties) {
				this[key] = properties[key];
			}
		}
	}
}
