import { registerLocaleData } from '@angular/common';
import { Injectable } from '@angular/core';
import { AppContextService } from './app-context.service';

@Injectable({ providedIn: 'root' })
export class LocaleService {
	constructor(private appContext: AppContextService) {
		this.registerCulture();
	}
	private _locale: string;
	today = new Date();
	val = 123.45;
	set locale(value: string) {
		this._locale = value;
	}
	get locale(): string {
		return this._locale;
	}

	public registerCulture(): string {
		const region = this.appContext.getRegion();
		this.locale = region.RegionCode;
		this.localeInitializer(this.locale);

		return this.locale;
	}
	localeInitializer(localeId: string): Promise<any> {
		return import(`/node_modules/@angular/common/locales/${localeId}.mjs`).then(
			localeModule => {
				registerLocaleData(localeModule.default, localeId);
			},
			async () => {
				const fallbackLocale = localeId.split('-')[0];
				const fallbackLocaleModule = await import(`/node_modules/@angular/common/locales/${fallbackLocale}.mjs`);
				registerLocaleData(fallbackLocaleModule.default, fallbackLocale);
			}
		);
	}
}
