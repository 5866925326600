export class FlagAlertValue {
	FlagAlertTypeCode: string;
	FlagAlertTypeName: string;
	ColourCode: string;

	constructor(properties?: any) {
		if (properties) {
			for (const key in properties) {
				this[key] = properties[key];
			}
		}
	}
}
