import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ActionResult } from 'src/app/shared/models/api';
import { Contact } from 'src/app/shared/models/shared';
import { ApiService } from 'src/app/core/services';

@Injectable({
	providedIn: 'root',
})
export class ContactService {
	constructor(private apiService: ApiService) {
		//
	}

	getContactsForReference(referenceTypeCode: string, referenceId: string): Observable<Contact[]> {
		return this.apiService.get<Contact[]>(`/Contact/GetContactsForReference/${referenceTypeCode}/${referenceId}`);
	}

	saveContact(contact: Contact): Observable<ActionResult> {
		return this.apiService.post('/Contact/SaveContact', contact);
	}

	disableContact(contactId: string): Observable<ActionResult> {
		return this.apiService.get<ActionResult>(`/Contact/DisableContact/${contactId}`);
	}
}
