import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Constants } from 'src/app/shared/constants/constants.constant';
import { FormControl as FrmCntl } from 'src/app/shared/models/forms';
import { LookupValue } from 'src/app/shared/models/lookups';
import { BaseComponent } from 'src/app/core/components/base.component';
import { AppContextService, TooltipMessageService } from 'src/app/core/services';
import { CustomValidators } from 'src/app/core/utils';
import { AccountAdminConstants } from '../../../shared/constants';
import { UserProfile } from '../../../shared/models';
import { UserProfileApiService } from '../../../shared/services';

@Component({
	selector: 'app-user-profile-details',
	templateUrl: './user-profile-details.component.html',
})
export class UserProfileDetailsComponent extends BaseComponent implements OnInit {
	@Input() item: UserProfile = new UserProfile();
	@Output() editCancelled = new EventEmitter();
	@Output() detailsUpdated = new EventEmitter();

	formControls = FrmCntl;
	isCurrentUser = false;

	userRoles: LookupValue[] = [];
	statuses: LookupValue[] = [];

	initialValues: any;
	detailForm = this.fb.group({
		username: ['', [Validators.required, CustomValidators.email]],
		displayName: ['', [Validators.required]],
		contactPhone: ['', [CustomValidators.phone]],
		contactMobile: ['', [CustomValidators.phone]],
		contactEmail: ['', [Validators.required, CustomValidators.email]],
		userRole: [null, [Validators.required]],
		status: [null, [Validators.required]],
		dateAdded: [null],
		dateUpdated: [null],
		updatedBy: [null],
		addedBy: [null],
	});

	constructor(
		appContext: AppContextService,
		private userProfileApiService: UserProfileApiService,
		private fb: UntypedFormBuilder,
		private activatedRoute: ActivatedRoute,
		private router: Router,
		private tooltipMessageService: TooltipMessageService
	) {
		super(appContext);
	}

	ngOnInit(): void {
		this.activatedRoute.data.subscribe(data => {
			this.item = data.pageData.user;
			this.isCurrentUser = this.item.IsCurrentUser;
			this.initLookups(data);
			this.patchForm();
			this.initialValues = this.detailForm.value;
		});
	}

	initLookups(data) {
		this.userRoles = data.pageData.userRoles.slice();
		this.statuses = data.pageData.statuses.slice();

		this.util.addRecordValueIdIntoLookup(this.item.UserRoleId, this.item.UserRoleName, this.userRoles);
		this.util.addRecordValueIntoLookup(this.item.StatusCode, this.item.StatusName, this.statuses);
	}

	refreshData() {
		this.userProfileApiService.getUserProfile(this.item.UserProfileId).subscribe(resp => {
			this.item = resp;
			this.patchForm();

			this.detailsUpdated.emit(this.item);
		});
	}

	patchForm() {
		const userRole = this.userRoles.find(x => x.LookupValueId === this.item.UserRoleId);
		const status = this.statuses.find(x => x.CodeValue === this.item.StatusCode);

		this.detailForm.patchValue({
			username: this.item.Username,
			displayName: this.item.DisplayName,
			status: status,
			userRole: userRole,
			contactPhone: this.item.ContactPhone,
			contactMobile: this.item.ContactMobile,
			contactEmail: this.item.ContactEmail,
			dateAdded: this.item.DateAdded,
			dateUpdated: this.item.DateUpdated,
			updatedBy: this.item.UpdatedBy,
			addedBy: this.item.AddedBy,
		});
	}

	editDetails() {
		this.detailForm.reset();
		this.patchForm();
		this.initialValues = this.detailForm.value;
		this.formMode = this.formModes.Edit;
	}

	cancelDetailsEdit() {
		this.util.clearMessages();
		this.detailForm.reset(this.initialValues);

		if (this.formMode != this.formModes.Create) {
			this.formMode = this.formModes.View;
		}

		this.editCancelled.emit();
	}

	saveDetails() {
		this.util.clearMessages();
		this.appContext.loaderService.block();

		if (this.detailForm.valid) {
			const updatedObj = this.util.deepClone(this.item);
			updatedObj.DisplayName = this.detailForm.value.displayName;
			updatedObj.StatusCode = this.detailForm.value.status?.CodeValue;
			updatedObj.StatusName = this.detailForm.value.status?.NameValue;
			updatedObj.UserRoleId = this.detailForm.value.userRole?.LookupValueId;
			updatedObj.UserRoleName = this.detailForm.value.userRole?.NameValue;
			updatedObj.ContactPhone = this.detailForm.value.contactPhone;
			updatedObj.ContactMobile = this.detailForm.value.contactMobile;
			updatedObj.ContactEmail = this.detailForm.value.contactEmail;

			this.userProfileApiService.saveUserProfile(updatedObj).subscribe(result => {
				if (result.IsSuccess) {
					this.item = this.util.deepClone(result.Data);
					this.detailForm.reset();
					this.patchForm();
					this.initialValues = this.detailForm.value;
					this.formMode = this.formModes.View;

					this.detailsUpdated.emit(this.item);
				}
				this.appContext.loaderService.unblock();
			});
		} else {
			this.appContext.loaderService.unblock();
			this.markGroupDirty(this.detailForm);
		}
	}

	sendInvitation() {
		this.appContext.loaderService.block();
		this.userProfileApiService.sendInvitation(this.item.UserProfileId).subscribe(result => {
			this.appContext.loaderService.unblock();
			if (result.IsSuccess) {
				this.appContext.util.showSuccess('Sent successfully');
			}
		});
	}

	showEdit(): boolean {
		return this.item?.ObjectAccess?.AllowEdit?.Hide != true;
	}

	allowEdit(): boolean {
		return this.item?.ObjectAccess?.AllowEdit?.IsAllowed;
	}

	getEditTooltip(): string {
		return (
			this.tooltipMessageService.getTooltipMessage('C_USER', 'AllowEdit', this.item?.ObjectAccess?.AllowEdit, this.item) ??
			AccountAdminConstants.Tab_detail_EditUserProfile
		);
	}

	showSendInvitation(): boolean {
		return this.appContext.hasPermission(Constants.UserPermission.UserUpdate) && this.item.StatusCode === Constants.UserProfileStatus.Invited;
	}
}
