<div>
	<p-splitButton
		*ngIf="showButton()"
		icon="pi pi-plus"
		[attr.data-testid]="'splitbtn-' + dataTestIdComponentName"
		label="{{ buttonLabel }}"
		icon="{{ buttonIcon }}"
		(onClick)="onButtonClick($event)"
		[disabled]="disableButton()"
		[model]="menuItems"
		[pTooltip]="buttonTooltip"
		tooltipPosition="bottom"
		appendTo="body">
	</p-splitButton>
</div>
