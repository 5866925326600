import { Directive } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';

@Directive({
	selector: '[validateMaxlength]',
	providers: [
		{
			provide: NG_VALIDATORS,
			useExisting: MaxLengthValidatorDirective,
			multi: true,
		},
	],
})
export class MaxLengthValidatorDirective implements Validator {
	validate(control: AbstractControl): ValidationErrors | null {
		if (control.value && control.errors?.maxlength && control.value.length > control.errors?.maxlength.requiredLength) {
			return { maxlength: 'Invalid Input.' };
		} else {
			return null;
		}
	}
}
