<div class="p-dialog-footer app-dialog-footer">
	<div *ngIf="showClearButton()" class="button-with-tooltip-holder" pTooltip="{{ clearButtonTooltip }}" tooltipPosition="bottom">
		<p-button
			[attr.data-testid]="'btn-clracpt-' + dataTestIdComponentName"
			class="p-confirm-dialog-accept"
			label="{{ clearButtonLabel }}"
			(onClick)="onClearDialog()"
			icon="{{ clearButtonIcon }}"></p-button>
	</div>
	<div *ngIf="showDeleteButton()" class="button-with-tooltip-holder" pTooltip="{{ deleteButtonTooltip }}" tooltipPosition="bottom">
		<p-button
			[attr.data-testid]="'btn-delacpt-' + dataTestIdComponentName"
			class="p-confirm-dialog-accept"
			label="{{ deleteButtonLabel }}"
			(onClick)="onDeleteDialog(true)"
			icon="{{ deleteButtonIcon }}"></p-button>
	</div>
	<div *ngIf="showRejectButton()" class="button-with-tooltip-holder" pTooltip="{{ rejectButtonTooltip }}" tooltipPosition="bottom">
		<p-button
			[attr.data-testid]="'btn-rej-' + dataTestIdComponentName"
			class="p-confirm-dialog-reject"
			label="{{ rejectButtonLabel }}"
			(onClick)="onCloseDialog()"
			icon="{{ rejectButtonIcon }}"></p-button>
	</div>
	<div *ngIf="showPartialAcceptButton()" class="button-with-tooltip-holder" pTooltip="{{ partialAcceptButtonTooltip }}" tooltipPosition="bottom">
		<p-button
			[attr.data-testid]="'btn-partacpt-' + dataTestIdComponentName"
			class="p-confirm-dialog-accept"
			label="{{ partialAcceptButtonLabel }}"
			(onClick)="onSubmitDialog(false)"
			icon="{{ partialAcceptButtonIcon }}"></p-button>
	</div>
	<div *ngIf="showAcceptButton()" class="button-with-tooltip-holder" pTooltip="{{ acceptButtonTooltip }}" tooltipPosition="bottom">
		<p-button
			[attr.data-testid]="'btn-acpt-' + dataTestIdComponentName"
			class="p-confirm-dialog-accept"
			label="{{ acceptButtonLabel }}"
			[disabled]="showAcceptButtonDisabled()"
			(onClick)="onSubmitDialog(true)"
			icon="{{ acceptButtonIcon }}"></p-button>
	</div>
</div>
