<div class="dialog-error">
	<div class="container">
		<div class="confirm-dialog-title" data-testid="messageTitle">
			<i [class]="getIcon()" class="p-confirm-dialog-icon error-icon"></i>
			<span class="p-confirm-dialog-message">{{ getTitle() }}</span>
		</div>
		<div class="p-pb-2 p-m-1">
			<div class="row p-d-flex p-py-3 p-jc-between">
				<div class="p-col-6 p-col-nogutter">
					<div class="det label">
						<b>{{ getObjectName() }}</b>
					</div>
					<div class="det p-text-light">{{ getObjectNumber() }}</div>
				</div>
				<div class="p-col-6 p-col-nogutter">
					<div class="det label"><b>Action</b></div>
					<div class="det p-text-light">{{ getActionName() }}</div>
				</div>
			</div>

			<div *ngIf="messageObject" class="row p-p-1">
				<span class="det p-text-light" *ngFor="let obj of messageObject.Messages">
					{{ obj.Message }}
				</span>
			</div>
		</div>
	</div>
</div>
<div class="p-d-flex p-jc-center p-ai-center copy-link-container">
	<span class="pi pi-copy copy-link-icon"></span>
	<span class="p-jc-center"><a class="copy-link-label" href="javascript:void(0)" (click)="copyMessage()">Copy Message</a></span>
</div>
<div>
	<app-dialog-footer
		dataTestIdComponentName="BulkActionGenericErrorMessage"
		(closeDialog)="closeDialog()"
		rejectButtonLabel="Close"
		rejectButtonToolTip="Close"
		isAcceptButtonVisible="false"></app-dialog-footer>
</div>
