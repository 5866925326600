import { Directive } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';

@Directive({
	selector: '[validateWorkActivityRequired]',
	providers: [
		{
			provide: NG_VALIDATORS,
			useExisting: WorkActivityRequiredValidatorDirective,
			multi: true,
		},
	],
})
export class WorkActivityRequiredValidatorDirective implements Validator {
	validate(control: AbstractControl): ValidationErrors | null {
		if (control.parent) {
			const showEventsControl = control.parent.get('showEvents');

			if (showEventsControl) {
				if (!showEventsControl.value && !control.value) {
					return { eventOrWorkActivityRequired: 'Either events or work activities checkbox must be selected.' };
				} else {
					return null;
				}
			} else {
				return null;
			}
		}
	}
}
