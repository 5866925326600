export class UserNotificationEvent {
	NotificationQueueActionCode: string;
	SubscriberId: string;
	UserProfileId: string;
	UserSessionId: string;

	Data: any;

	constructor(properties?: any) {
		if (properties) {
			for (const key in properties) {
				this[key] = properties[key];
			}
		}
	}
}
