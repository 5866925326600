import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SubscriberLookupValue } from 'src/app/shared/models/lookups';
import { RegionSettings } from 'src/app/shared/models/settings';
import { ApiService, AppContextService } from 'src/app/core/services';

@Injectable({
	providedIn: 'root',
})
export class SystemLookupService {
	constructor(protected apiService: ApiService, protected appContext: AppContextService) {
		//
	}

	getDefaultRegionSettings(regionCode: string): Observable<RegionSettings> {
		return this.apiService.get<RegionSettings>(`/SystemLookup/GetDefaultRegionSettings/${regionCode}`);
	}

	getSubscribersForCurrentUser(): Observable<SubscriberLookupValue[]> {
		return this.apiService.get<SubscriberLookupValue[]>(`/SystemLookup/GetSubscribersForCurrentUser`);
	}
}
