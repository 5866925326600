import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AppContextService } from '../services/app-context.service';
import { UtilService } from '../services/util.service';

@Injectable()
export class RequestInterceptor implements HttpInterceptor {
	constructor(private util: UtilService, private appContext: AppContextService) {}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return next.handle(request).pipe(
			tap(
				(event: HttpEvent<any>) => {
					//
				},
				(err: any) => {
					if (err instanceof HttpErrorResponse) {
						let errorMessage = '';

						if (err.status === 0) {
							errorMessage = 'Could not connect to service.';
						} else if (!this.appContext.config.production) {
							if (err.message) {
								errorMessage = err.message;
							} else {
								errorMessage = err.error.FullExceptionMessage;
							}
						}

						if (err.status !== 404 && !this.appContext.config.suppressErrorGrowl) {
							if (err.error && err.error.Messages) {
								console.error(err.error.Messages);
								this.util.showActionResult(err.error);
							} else {
								this.util.showError(errorMessage);
							}
						}
					}
				}
			)
		);
	}
}
