export class ExternalNotification {
	ExternalNotificationId: string; // only used for row tracking on the UI

	ExternalNotificationTypeCode: string;
	DocumentDestinationTypeCode: string;

	BusinessEntityName: string;

	BusinessEntityId: string;
	ClientId: string;

	ReferenceDto: {
		ReferenceTypeCode: string;
		ReferenceId: string;
		ReferenceName: string;
	};
	IsSystem: boolean;

	EmailAddress: string;
	Comments: string;

	DateSent: Date;

	constructor(properties?: any) {
		if (properties) {
			for (const key in properties) {
				this[key] = properties[key];
			}
		}
	}
}
