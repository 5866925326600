import { StatusLookupValue } from './status-lookup-value.model';

export class ItemLookupValue extends StatusLookupValue {
	LineItemNumber: number;
	SorId: string;
	SorCode: string;
	UnitOfMeasureName: string;
	LocationName: string;
	Quantity: number;
	Comments: string;

	DefaultTaxCode: string;
	ItemTaxCode: string;

	WorkOrderItemId: string;

	CurrentInvoiceItemId: string;
	UnitSellAmountExclTax: number;
	UnitSellAmountInclTax: number;
	UnitSellAmountTax: number;
	UnitCostAmountExclTax: number;
	UnitCostAmountInclTax: number;
	UnitCostAmountTax: number;
}
