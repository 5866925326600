import { Directive } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';

@Directive({
	selector: '[validateEventRequired]',
	providers: [
		{
			provide: NG_VALIDATORS,
			useExisting: EventRequiredValidatorDirective,
			multi: true,
		},
	],
})
export class EventRequiredValidatorDirective implements Validator {
	validate(control: AbstractControl): ValidationErrors | null {
		if (control.parent) {
			const showWorkActivitiesControl = control.parent.get('showWorkActivities');

			if (showWorkActivitiesControl) {
				if (!showWorkActivitiesControl.value && !control.value) {
					return { eventOrWorkActivityRequired: 'Either checkbox must be selected.' };
				} else {
					return null;
				}
			} else {
				return null;
			}
		}
	}
}
