import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PriceAmount, TaxCodePriceRequest, TaxCodePriceResponse, TaxCodeRequest, TaxRequest, UnitCostAmountRequest } from 'src/app/shared/models/pricing';
import { ApiService } from 'src/app/core/services';

@Injectable({
	providedIn: 'root',
})
export class PriceService {
	constructor(private apiService: ApiService) {}

	calculateUnitCostAmountForOrderItem(priceRequest: UnitCostAmountRequest): Observable<TaxCodePriceResponse> {
		return this.apiService.post('/Price/CalculateUnitCostAmountForOrderItem', priceRequest) as Observable<TaxCodePriceResponse>;
	}

	calculateTaxRate(taxCodeRequest: TaxCodeRequest): Observable<TaxRequest> {
		return this.apiService.post('/Price/CalculateTaxRate', taxCodeRequest) as Observable<TaxRequest>;
	}

	calculateTax(priceRequest: TaxRequest): Observable<PriceAmount> {
		return this.apiService.post('/Price/CalculateTax', priceRequest) as Observable<PriceAmount>;
	}

	calculateTaxDetails(taxRequest: TaxCodePriceRequest): Observable<TaxCodePriceResponse> {
		return this.apiService.post<TaxCodePriceResponse>('/Price/CalculateTaxDetails', taxRequest);
	}
}
