import { Component, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import * as _ from 'lodash';
import { Table } from 'primeng/table';
import { FormMode, FormControl as FrmCntl } from 'src/app/shared/models/forms';
import { BankAccountDetailsValue, FinancialBillingSettings, RegionSettings } from 'src/app/shared/models/settings';
import { BaseComponent } from 'src/app/core/components/base.component';
import { INPUT_INFO_MESSAGE } from 'src/app/core/constants/input-info-messages.constant';
import { Guid } from 'src/app/core/models';
import { AppContextService } from 'src/app/core/services';

@Component({
	selector: 'app-bank-detail',
	templateUrl: './bank-detail.component.html',
	styleUrls: ['./bank-detail.component.scss'],
})
export class BankDetailComponent extends BaseComponent implements OnInit, OnChanges {
	@Input() formMode: FormMode;
	@Input() initialFinancialBillingSettings: FinancialBillingSettings;
	financialBillingSettings: FinancialBillingSettings;
	INFO_MESSAGE = INPUT_INFO_MESSAGE.FINANCIAL_BILLING_SETTINGS;
	formControls = FrmCntl;
	@ViewChild('bankDetailTable') bankDetailTable: Table;
	bankDetails: BankAccountDetailsValue[] = [];
	currentBankDetail: BankAccountDetailsValue;
	region: RegionSettings;
	constructor(appContext: AppContextService, private fb: UntypedFormBuilder) {
		super(appContext);
	}
	bankDetailAddMode = false;
	bankDetailEditMode = false;
	bankDetailBackup: BankAccountDetailsValue;
	bankDetailForm = this.fb.group({
		bankAccountName: [null],
		infoMesageBankAccountName: [null],
		infoMesageBankDetailsHeader: [null],
	});

	ngOnInit(): void {
		this.region = this.appContext.getRegion();
	}

	patchForm(newSettings: FinancialBillingSettings = null) {
		if (newSettings != null) this.financialBillingSettings = newSettings;
		if (!this.financialBillingSettings) this.financialBillingSettings = new FinancialBillingSettings();

		this.bankDetails = _.cloneDeep(this.financialBillingSettings.BankAccountDetails) ?? [];
		this.bankDetails.forEach(x => {
			x.Id = Guid.newGuid();
		});
		this.currentBankDetail = null;
		if (this.bankDetailTable) this.bankDetailTable.editingRowKeys = {};

		this.bankDetailForm.patchValue({
			bankAccountName: this.financialBillingSettings?.BankAccountName,
			infoMesageBankAccountName: this.INFO_MESSAGE.BankName.message,
			infoMesageBankDetailsHeader: this.INFO_MESSAGE.BankDetails.message,
		});
	}

	showAddBankDetail(): boolean {
		return this.formMode == this.formModes.Edit && this.currentBankDetail == null;
	}

	addItemInline() {
		this.currentBankDetail = new BankAccountDetailsValue();
		this.currentBankDetail.Id = Guid.newGuid();
		this.currentBankDetail.Header = null;
		this.currentBankDetail.Value = null;
		this.bankDetails.splice(0, 0, this.currentBankDetail);
		this.bankDetailAddMode = true;
		this.bankDetailTable.editingRowKeys[this.currentBankDetail.Id] = true;
	}

	onRowDelete(item: BankAccountDetailsValue) {
		const ind = this.bankDetails.indexOf(item);
		this.bankDetails.splice(ind, 1);
	}

	ngOnChanges() {
		this.financialBillingSettings = this.initialFinancialBillingSettings
			? this.util.deepClone(this.initialFinancialBillingSettings)
			: new FinancialBillingSettings();
		this.bankDetailForm.reset(this.financialBillingSettings);

		this.patchForm();
	}

	onRowEditInit(item: BankAccountDetailsValue) {
		this.currentBankDetail = item;
		this.bankDetailBackup = this.util.deepClone(item);
		this.bankDetailEditMode = true;
		this.bankDetailTable.editingRowKeys[item.Id] = true;
	}

	onRowEditCancel(item: BankAccountDetailsValue, ri: number) {
		if (this.bankDetailAddMode) {
			this.bankDetails.splice(ri, 1);
		} else {
			this.bankDetails[ri] = this.bankDetailBackup;
		}
		this.currentBankDetail = null;
		this.bankDetailAddMode = false;
		this.bankDetailEditMode = false;
	}

	onRowEditSave() {
		if (
			this.currentBankDetail.Header == null ||
			this.currentBankDetail.Header == '' ||
			this.currentBankDetail.Value == null ||
			this.currentBankDetail.Value == ''
		) {
			this.util.showError('Header and Value are required.');
			return;
		}

		Object.keys(this.bankDetailTable.editingRowKeys).forEach(x => (this.bankDetailTable.editingRowKeys[x] = false));
		this.currentBankDetail = null;
		this.bankDetailAddMode = false;
		this.bankDetailEditMode = false;
	}

	getBankDetails() {
		const updatedBankDetails = new FinancialBillingSettings();

		updatedBankDetails.BankAccountName = this.bankDetailForm.value.bankAccountName;
		updatedBankDetails.BankAccountDetails = this.bankDetails;

		return updatedBankDetails;
	}
}
