import { HttpParams } from '@angular/common/http';

export class ApiRequestArgs {
	Params: HttpParams = new HttpParams();
	AlwaysShowResult = true;
	SubscriberId: string;
	CustomHeaders: any;
	FormData = false;
	SkipContentType = false;

	constructor(properties?: any) {
		if (properties) {
			for (const key in properties) {
				this[key] = properties[key];
			}
		}
	}
}
