import { Directive } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';
import { ValidationConstants } from 'src/app/core/constants/validation.constant';

@Directive({
	selector: '[validateEmail]',
	providers: [
		{
			provide: NG_VALIDATORS,
			useExisting: EmailValidatorDirective,
			multi: true,
		},
	],
})
export class EmailValidatorDirective implements Validator {
	validate(control: AbstractControl): ValidationErrors | null {
		if (control.value == null || control.value === '') {
			return null;
		}
		const validator = new RegExp(ValidationConstants.emailRegExp);
		if (Array.isArray(control.value)) {
			for (const val of control.value) {
				if (!validator.test(val)) {
					return { email: 'not matched' };
				}
			}
		} else {
			return validator.test(control.value) ? null : { email: 'not matched' };
		}
		return null;
	}
}
