import { HttpEventType } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng/api';
import { FileUpload } from 'primeng/fileupload';
import { Table } from 'primeng/table';
import { Constants } from 'src/app/shared/constants/constants.constant';
import { FormMode, FormControl as FrmCntl } from 'src/app/shared/models/forms';
import { LookupValue } from 'src/app/shared/models/lookups';
import { RegionSettings } from 'src/app/shared/models/settings';
import { Attachment, AttachmentDetail, AttachmentFile } from 'src/app/shared/models/shared';
import { AttachmentService } from 'src/app/shared/services';
import { BaseComponent } from 'src/app/core/components/base.component';
import { Guid } from 'src/app/core/models';
import { AppContextService, LoaderService } from 'src/app/core/services';

@Component({
	selector: 'app-attachment-details',
	templateUrl: './attachment-details.component.html',
	styleUrls: ['../attachment.component.scss'],
})
export class AttachmentDetailsComponent extends BaseComponent implements OnInit {
	@Input() referenceName = '';
	@Input() formMode = FormMode.View;
	@Input() readonly = false;
	@Input() attachment: Attachment;
	@Input() images: any[];
	@Input() parentReferenceTypeCode: string;
	@Input() parentReferenceId: string;
	@Output() displayList = new EventEmitter();
	@Output() attachmentUpdated = new EventEmitter();
	@ViewChild('fileUpload') fileUpload: FileUpload;
	@ViewChild('attachmentFilesUI') grid: Table;

	attachmentTypes: LookupValue[] = [];
	formModes = FormMode;
	formControls = FrmCntl;
	attachmentBkp: AttachmentDetail;

	uploadingFiles: any[] = [];
	uploadingCount = 0;

	displayPreview: boolean;
	activeIndex = 0;
	region: RegionSettings;
	attachmentUploadCall = [];
	isUploadInProgress = false;

	attachmentForm = this.fb.group({
		externalReferenceNumber: [''],
		referenceName: [''],
		attachmentType: [null, [Validators.required]],
		attachmentTitle: [null],
		addedBy: [''],
		updatedBy: [''],
		dateAdded: [null],
		dateUpdated: [null],
		dateRemoved: [null],
	});

	constructor(
		appContext: AppContextService,
		private attachmentService: AttachmentService,
		private messageService: MessageService,
		private activatedRoute: ActivatedRoute,
		private fb: UntypedFormBuilder,
		private sanitizer: DomSanitizer,
		private loaderService: LoaderService
	) {
		super(appContext);
	}

	ngOnInit(): void {
		this.activatedRoute.data.subscribe(data => {
			if (this.formMode == this.formModes.Create) {
				this.attachment = new Attachment();
				this.attachment.Details = new AttachmentDetail();
				this.attachment.Files = [];
				this.images = [];
			} else {
				this.attachmentBkp = this.util.deepClone(this.attachment.Details);
			}

			this.initLookups(data);
			this.initForm();
		});
	}

	initLookups(data) {
		this.region = this.appContext.getRegion();
		this.attachmentTypes = [];

		if (data.pageData && data.pageData.attachmentTypes) {
			this.attachmentTypes = data.pageData.attachmentTypes.slice();
		}

		this.util.addRecordValueIntoLookup(this.attachment.Details.AttachmentTypeCode, this.attachment.Details.AttachmentTypeName, this.attachmentTypes);
	}

	private initForm() {
		let attachmentType = new LookupValue();
		attachmentType.CodeValue = this.attachment.Details.AttachmentTypeCode;
		attachmentType.NameValue = this.attachment.Details.AttachmentTypeName;

		if (attachmentType.CodeValue === undefined) {
			attachmentType = this.attachmentTypes.find(x => x.Selected);
		}

		this.attachmentForm.patchValue({
			externalReferenceNumber: this.attachment.Details.ExternalReferenceNumber,
			referenceName: this.attachment.Details.ReferenceName,
			attachmentType: attachmentType,
			attachmentTitle: this.attachment.Details.AttachmentTitle,
			dateAdded: this.attachment.Details.DateAdded,
			dateRemoved: this.attachment.Details.DateRemoved,
			dateUpdated: this.attachment.Details.DateUpdated,
			addedBy: this.attachment.Details.AddedBy,
			updatedBy: this.attachment.Details.UpdatedBy,
		});

		this.markGroupDirty(this.attachmentForm);
	}

	editAttachment() {
		this.attachmentBkp = this.util.deepClone(this.attachment.Details);
		this.attachmentForm.reset();
		this.initForm();
		this.formMode = this.formModes.Edit;
	}

	cancelAttachmentEdit() {
		this.util.clearMessages();
		this.attachmentForm.reset();

		if (this.formMode !== this.formModes.Create) {
			this.formMode = FormMode.View;
			this.attachment.Details = this.attachmentBkp;
			this.attachmentBkp = null;
			this.initForm();

			for (let i = 0; i < this.attachment.Files.length; i++) {
				if (this.attachment.Files[i].FileData == null) {
					this.attachment.Files.splice(i, 1);
					i--;
				}
			}
		} else {
			this.showList();
		}
	}

	showList() {
		this.displayList.emit();
	}

	deleteAttachment(attachment: Attachment) {
		this.util.clearMessages();
		this.util.confirmDelete('Do you want to delete this attachment?').then(result => {
			if (result) {
				this.loaderService.block();
				this.attachmentService.disableAttachment(attachment.Details.AttachmentId).subscribe(result => {
					this.loaderService.unblock();
					if (result.IsSuccess) {
						this.attachmentUpdated.emit();
						this.showList();
					}
				});
			}
		});
	}

	deleteAttachmentFile(attachmentFile: AttachmentFile) {
		this.util.clearMessages();
		let msg = 'Do you want to delete this file?';
		if (this.isExistingItem(attachmentFile)) msg += ' This action cannot be reverted.';

		this.util.confirmDelete(msg).then(result => {
			if (result) {
				if (this.isExistingItem(attachmentFile)) {
					this.loaderService.block();
					this.attachmentService.disableAttachmentFile(attachmentFile.AttachmentFileId).subscribe(result => {
						this.loaderService.unblock();
						if (result.IsSuccess) {
							const index = this.attachment.Files.indexOf(attachmentFile);
							this.attachment.Files.splice(index, 1);
							this.attachmentUpdated.emit();
						}
					});
				} else {
					const index = this.attachment.Files.indexOf(attachmentFile);
					this.attachment.Files.splice(index, 1);
				}
			}
		});
	}

	onClear() {
		this.isUploadInProgress = false;
		this.fileUpload.progress = 0;
		this.attachmentUploadCall.map(data => data?.unsubscribe());
		this.attachmentUploadCall = [];
	}

	progressReport($event: any) {
		this.fileUpload.progress = $event?.progress;
	}

	removeSelectedFile(index, event) {
		this.fileUpload.remove(event, index);
	}

	onUpload(event) {
		this.fileUpload.progress = 0;
		if (this.isUploadInProgress) {
			this.util.showInfo('Your file upload is already in progress');
			return;
		}
		let fileCnt = 0;
		if (this.attachment.Files) fileCnt = this.attachment.Files.length + event.files.length;
		if (fileCnt <= 10) {
			this.uploadingFiles = [];
			this.uploadingCount = 0;
			for (let i = 0; i < event.files.length; i++) {
				const newFileDto = new AttachmentFile();
				newFileDto.AttachmentFileId = Guid.newGuid();
				newFileDto.AttachmentFileName = event.files[i].name;
				const file = event.files[i];
				this.uploadingFiles.push({
					fileDto: newFileDto,
					file: file,
				});
				this.uploadingCount++;
				this.isUploadInProgress = true;
				this.attachmentUploadCall[i] = this.attachmentService.uploadFileWithProgress(newFileDto.AttachmentFileId, file).subscribe(result => {
					if (result?.type === HttpEventType.UploadProgress) {
						this.fileUpload.onProgress.emit({ originalEvent: event, progress: (result.loaded / result.total) * 100 });
					}

					if (result?.type === HttpEventType.Response) {
						if (result.body?.IsSuccess) {
							this.uploadingCount--;

							const uploadingFile = this.uploadingFiles.find(x => x.fileDto.AttachmentFileId == result.body?.Data);
							const uploadedFile = uploadingFile.file;
							const fileDto = uploadingFile.fileDto;
							const reader = new FileReader();
							reader.onloadend = e => {
								fileDto.ImagePreview = e.target.result as string;
								if (this.isValidImage(fileDto)) {
									this.images.push({
										AttachmentFileId: fileDto.AttachmentFileId,
										AttachmentId: fileDto.AttachmentId,
										PreviewImage: fileDto.ImagePreview,
										PreviewThumbnail: fileDto.ImagePreview,
										IsFullImage: true,
									});
								}
							};
							reader.readAsDataURL(uploadedFile);
							this.attachment.Files.push(fileDto);

							this.fileUpload.remove(event, i);
							if (this.uploadingCount == 0) {
								this.isUploadInProgress = false;
								this.util.showSuccess((event.files.length > 1 ? 'Files' : 'File') + ' uploaded.');
								this.fileUpload.clear();
							}
						}
					}
				});
			}
		} else {
			this.util.showError('Files limit exceeded. Limit is 10.', 'Invalid');
		}
	}

	validateForm(): boolean {
		if (this.attachmentForm.valid == false) return false;

		if (this.fileUpload.files.length > 0) {
			const messageText = this.fileUpload.files.length > 1 ? 'Files' : 'File';
			this.util.showError('Upload or clear the selected ' + messageText.toLowerCase() + ' before continuing.', messageText + ' not uploaded');
			return false;
		}

		return true;
	}

	saveAttachment() {
		this.util.clearMessages();

		const valid = this.validateForm();
		if (valid) {
			if (this.formMode == FormMode.Create) {
				this.attachment.Details.ParentReferenceTypeCode = this.parentReferenceTypeCode;
				this.attachment.Details.ReferenceTypeCode = this.parentReferenceTypeCode;
				this.attachment.Details.ReferenceId = this.parentReferenceId;
				this.attachment.Details.ParentReferenceId = this.parentReferenceId;
			}

			this.attachment.Details.AttachmentTitle = this.attachmentForm.value.attachmentTitle;
			this.attachment.Details.AttachmentTypeCode = this.attachmentForm.value.attachmentType.CodeValue;

			// only send new files & attachment details - no need to send existing files that aren't changing
			const attachToSend = new Attachment();
			attachToSend.Details = this.util.deepClone(this.attachment.Details);
			attachToSend.Files = [];

			this.attachment.Files.forEach(x => {
				if (x.FileData == null) {
					const fs = this.util.deepClone(x);
					fs.ImagePreview = null;
					attachToSend.Files.push(fs);
				}
			});

			this.loaderService.block();
			this.attachmentService.saveAttachment(attachToSend).subscribe(result => {
				this.loaderService.unblock();
				if (result.IsSuccess) {
					this.attachment.Details = this.util.deepClone(result.Data.Details);
					if (result.Data.Files) {
						this.attachment.Files.filter(x => x.FileData == null).forEach(x => {
							const fileDto = result.Data.Files.find(y => y.AttachmentFileId == x.AttachmentFileId);
							if (fileDto) {
								x.DateAdded = fileDto.DateAdded;
								x.AddedBy = fileDto.AddedBy;
								x.AttachmentClientPath = fileDto.AttachmentClientPath;
								x.AttachmentContainerName = fileDto.AttachmentContainerName;
								x.FileData = fileDto.FileData;
							}
						});
					}

					this.attachmentBkp = null;
					this.attachmentForm.reset();
					this.initForm();
					this.formMode = this.formModes.View;
					this.attachmentUpdated.emit();
				}
			});
		} else {
			this.markGroupDirty(this.attachmentForm);
		}
	}

	sanitize(url: string) {
		console.log(url);
		const x: string = this.sanitizer.bypassSecurityTrustResourceUrl(url) as string;
		console.log(x);
		return x;
	}

	downloadAllFiles(attachment: Attachment) {
		attachment.Files.forEach(attachmentFile => {
			if (attachmentFile.AttachmentFileId) this.downloadFile(attachmentFile);
		});
	}

	downloadFile(attachmentFile?: AttachmentFile) {
		if (this.isExistingItem(attachmentFile) == false) return;

		this.attachmentService.getAttachmentFileData(attachmentFile.AttachmentFileId).subscribe(response => {
			const newFile: AttachmentFile = new AttachmentFile();
			const fileURL = window.URL.createObjectURL(response);
			newFile.FileData = fileURL;
			this.util.downloadFileUrl(newFile.FileData, attachmentFile.AttachmentFileName);
		});
	}

	isValidImage(file: AttachmentFile): boolean {
		return this.util.isValidImage(file.AttachmentFileName);
	}

	isExistingItem(file: AttachmentFile): boolean {
		return file.AttachmentClientPath != null && file.AttachmentClientPath != '' && file.AttachmentContainerName != null && file.AttachmentContainerName != '';
	}

	clickEvent(attachmentFile: AttachmentFile) {
		if (this.isExistingItem(attachmentFile) == false) return;

		if (attachmentFile.AttachmentFileName.indexOf('.pdf') > 0) {
			this.attachmentService.getAttachmentFileData(attachmentFile.AttachmentFileId).subscribe(response => {
				const file = new File([response], attachmentFile.AttachmentFileName, { type: 'application/pdf' });
				const fileURL = window.URL.createObjectURL(file);
				window.open(fileURL);
			});
		} else {
			this.downloadFile(attachmentFile);
		}
	}

	thumbnailClick(file: AttachmentFile) {
		const index = this.images.findIndex(x => x.AttachmentFileId == file.AttachmentFileId);

		if (index !== -1) {
			const isFullImage = this.images[index].IsFullImage;
			this.activeIndex = index;

			if (isFullImage) {
				this.displayPreview = true;
				this.getAllImagesForAttachment();
			} else {
				this.loaderService.block();
				this.attachmentService.getAttachmentFile(file.AttachmentFileId).subscribe(resp => {
					this.images[index].PreviewImage = `data:image/png;base64,${resp.FileData}`;
					this.images[index].IsFullImage = true;
					this.loaderService.unblock();
					this.displayPreview = true;
					this.getAllImagesForAttachment();
				});
			}
		}
	}

	getAllImagesForAttachment() {
		this.images
			.filter(t => t.IsFullImage === false)
			.forEach(img => {
				this.attachmentService.getAttachmentFile(img.AttachmentFileId).subscribe(resp => {
					const index = this.images.findIndex(x => x.AttachmentFileId == img.AttachmentFileId);
					if (this.images[index].IsFullImage === false) {
						this.images[index].PreviewImage = `data:image/png;base64,${resp.FileData}`;
						this.images[index].IsFullImage = true;
					}
				});
			});
	}

	showEdit(): boolean {
		return this.appContext.hasPermission(Constants.UserPermission.CommonAttachmentsEdit);
	}

	showDelete(): boolean {
		return this.appContext.hasPermission(Constants.UserPermission.CommonAttachmentsRemove);
	}

	showDeleteItem(attachmentFile: AttachmentFile): boolean {
		if (this.formMode == this.formModes.Create) return true;
		if (attachmentFile?.FileData == null) return true;
		if (this.formMode == this.formModes.Edit) return false;

		return this.appContext.hasPermission(Constants.UserPermission.CommonAttachmentsRemove);
	}
}
