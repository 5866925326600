import { ObjectAccess } from 'src/app/shared/models/api';

export class BusinessEntityInsurance {
	BusinessEntityInsuranceId: string;
	BusinessEntityId: string;
	BusinessEntityName: string;

	InsuranceTypeCode: string;
	InsuranceTypeName: string;
	PolicyNumber: string;
	CoverAmount: string;
	ExpiryDate: Date;
	ExpiryDateNotApplicable: boolean;
	Comments: string;

	PolicyNumberFieldVisibilityCode: string;
	CoverAmountFieldVisibilityCode: string;
	ExpiryDateFieldVisibilityCode: string;
	IsRequiredInsurance: boolean;

	AddedById: string;
	AddedBy: string;
	UpdatedById: string;
	UpdatedBy: string;
	DateAdded: Date;
	DateUpdated: Date;
	DateRemoved: Date;

	// exclude from timezone
	ExcludeFromTimezone: string[];
	ExpiryDateWithoutTimezone: string;

	ObjectAccess: ObjectAccess;

	constructor(properties?: any) {
		if (properties) {
			for (const key in properties) {
				this[key] = properties[key];
			}
		}
	}
}
