import { Directive } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';

@Directive({
	selector: '[validateLiabilityPercentageRequired]',
	providers: [
		{
			provide: NG_VALIDATORS,
			useExisting: LiabilityPercentageRequiredValidatorDirective,
			multi: true,
		},
	],
})
export class LiabilityPercentageRequiredValidatorDirective implements Validator {
	validate(control: AbstractControl): ValidationErrors | null {
		if (control.parent) {
			const liabilityTypeControl = control.parent.get('liabilityType');

			if (liabilityTypeControl) {
				if (liabilityTypeControl.value && !control.value) {
					return { liabilitypcRequired: 'This field is required if the Liability Type has been selected.' };
				} else {
					return null;
				}
			} else {
				return null;
			}
		}
	}
}
