import { FlagAlertValue } from '../shared/flag-alert-value.model';
import { LookupValue } from './lookup-value.model';

export class RecordLookupValue extends LookupValue {
	RecordTypeCode: string;

	TargetDate: Date;
	ScheduledStartDate: Date;

	AssetReference: string;
	Street: string;
	Suburb: string;

	BusinessEntityName: string;

	// only for individual records
	ParentRecordTypeCode: string;
	ParentRecordId: string;
	ParentRecordName: string;

	BusinessEntityId: string;
	ResourceRecordTypeCode: string;

	OwnerName: string;
	InspectorName: string;
	EstimatorName: string;
	AssetNumber: string;
	CompanyId: string;
	ClientId: string;

	FlagAlerts: FlagAlertValue[];
}
