import { LookupValue } from './lookup-value.model';

export class SorLookupValue extends LookupValue {
	SorCode: string;
	Keywords: string;
	ShortDescription: string;
	LongDescription: string;
	TradeTypeCode: string;
	TradeTypeName: string;
	UnitOfMeasureName: string;
	TaxCode: string;
	IsManual: boolean;
	SellAmountExclTax: number;
}
