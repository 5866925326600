<p-toolbar>
	<ng-template pTemplate="left"></ng-template>
	<ng-template pTemplate="right">
		<div class="select-button-toolbar">
			<p-selectButton
				[options]="displayFilterOptions"
				(onChange)="changeFilterOption($event)"
				[(ngModel)]="selectedFilterOption"
				optionLabel="label"
				optionValue="value"
				data-testid="displayFilterOptionsSelectButton"></p-selectButton>
		</div>
	</ng-template>
</p-toolbar>
<p-table
	*ngIf="showList"
	[value]="invoices"
	dataKey="InvoiceId"
	[rowHover]="true"
	responsiveLayout="scroll"
	[showCurrentPageReport]="true"
	[loading]="loading"
	[filterDelay]="0"
	#invoiceListTable>
	<ng-template pTemplate="header">
		<tr>
			<th pSortableColumn="ReferenceName">
				<div class="table-header-label">
					Reference
					<p-sortIcon field="ReferenceName"></p-sortIcon>
					<p-columnFilter type="text" field="ReferenceName" display="menu"> </p-columnFilter>
				</div>
			</th>
			<th pSortableColumn="InvoiceNumber">
				<div class="table-header-label">
					Invoice #
					<p-sortIcon field="InvoiceNumber"></p-sortIcon>
					<p-columnFilter type="text" field="InvoiceNumber" display="menu"> </p-columnFilter>
				</div>
			</th>
			<th pSortableColumn="InvoiceRelatedEntryTypeName">
				<div class="table-header-label">
					Type
					<p-sortIcon field="InvoiceRelatedEntryTypeName"></p-sortIcon>
					<p-columnFilter type="text" field="InvoiceRelatedEntryTypeName" display="menu"> </p-columnFilter>
				</div>
			</th>
			<th pSortableColumn="FinancialEntityName">
				<div class="table-header-label">
					Contact
					<p-sortIcon field="FinancialEntityName"></p-sortIcon>
					<p-columnFilter type="text" field="FinancialEntityName" display="menu"> </p-columnFilter>
				</div>
			</th>
			<th pSortableColumn="StatusName">
				<div class="table-header-label">
					Status
					<p-sortIcon field="StatusName"></p-sortIcon>
					<p-columnFilter type="text" field="StatusName" display="menu"> </p-columnFilter>
				</div>
			</th>
			<th pSortableColumn="ReferenceNumber">
				<div class="table-header-label">
					Reference #
					<p-sortIcon field="ReferenceNumber"></p-sortIcon>
					<p-columnFilter type="text" field="ReferenceNumber" display="menu"> </p-columnFilter>
				</div>
			</th>

			<th pSortableColumn="DateEntryConfirmed">
				<div class="table-header-label">
					Confirmed
					<p-sortIcon field="DateEntryConfirmed"></p-sortIcon>
					<p-columnFilter type="date" field="DateEntryConfirmed" display="menu"> </p-columnFilter>
				</div>
			</th>

			<th pSortableColumn="ReceivableAmountInclTax">
				<div class="table-header-label">
					Amount {{ region.CurrencySymbol }}
					<p-sortIcon field="ReceivableAmountInclTax"></p-sortIcon>
					<p-columnFilter type="numeric" field="ReceivableAmountInclTax" display="menu">
						<ng-template pTemplate="filter" let-value let-filter="filterCallback">
							<p-inputNumber
								#number
								[ngModel]="value"
								[showButtons]="true"
								(onBlur)="currencyFilter(number.value, 'ReceivableAmountInclTax')"
								[locale]="region.RegionCode"
								[minFractionDigits]="region.CurrencyPrecision"
								[maxFractionDigits]="region.CurrencyPrecision"
								autocomplete="off"></p-inputNumber>
						</ng-template>
					</p-columnFilter>
				</div>
				<div class="title-label-small">(incl. tax)</div>
			</th>

			<th pSortableColumn="RemainingAmountInclTax">
				<div class="table-header-label">
					Balance {{ region.CurrencySymbol }}
					<p-sortIcon field="RemainingAmountInclTax"></p-sortIcon>
					<p-columnFilter type="numeric" field="RemainingAmountInclTax" display="menu">
						<ng-template pTemplate="filter" let-value let-filter="filterCallback">
							<p-inputNumber
								#number
								[ngModel]="value"
								[showButtons]="true"
								(onBlur)="currencyFilter(number.value, 'RemainingAmountInclTax')"
								[locale]="region.RegionCode"
								[minFractionDigits]="region.CurrencyPrecision"
								[maxFractionDigits]="region.CurrencyPrecision"
								autocomplete="off"></p-inputNumber>
						</ng-template>
					</p-columnFilter>
				</div>
				<div class="title-label-small">(incl. tax)</div>
			</th>
			<th style="width: 3rem" name="inline-edit-buttons"></th>
			<th style="width: 3rem" name="inline-edit-buttons"></th>
		</tr>
	</ng-template>
	<ng-template pTemplate="body" let-invoice let-ri="rowIndex" let-expanded="expanded">
		<tr data-testid="eventRow" ngClass="p-selectable-row" [pEditableRow]="invoice">
			<td [attr.data-testid]="'invoiceRefNumberTd' + invoice.ReferenceName">
				<span *ngIf="getReferenceRecordType(invoice)" class="is-completed-style">
					<i class="fa fa-circle dot-{{ getReferenceRecordType(invoice).toLowerCase() }}"></i>
				</span>
				{{ invoice.ReferenceName }}
			</td>
			<td [attr.data-testid]="'invoiceNumberTd' + invoice.InvoiceNumber">
				<span *ngIf="getInvoiceRecordType(invoice)" class="is-completed-style">
					<i class="fa fa-circle dot-{{ getInvoiceRecordType(invoice).toLowerCase() }}"></i>
				</span>
				{{ invoice.InvoiceNumber }}
			</td>
			<td>
				{{ invoice.InvoiceRelatedEntryTypeName }}
			</td>
			<td>
				{{ invoice.FinancialEntityName }}
			</td>

			<td>
				{{ invoice.StatusName }}
			</td>
			<td>
				{{ invoice.ReferenceNumber }}
			</td>
			<td>
				<span [innerHTML]="invoice.DateEntryConfirmed | formatDate : region.RegionCode : region.DateFormat"></span>
			</td>

			<td style="text-align: right">
				{{
					invoice.ReceivableAmountInclTax != null
						? (invoice.ReceivableAmountInclTax | formatNumber : '1.' + region.CurrencyPrecision + '-' + region.CurrencyPrecision : region.RegionCode)
						: '-'
				}}
			</td>
			<td style="text-align: right">
				{{
					invoice.RemainingAmountInclTax != null
						? (invoice.RemainingAmountInclTax | formatNumber : '1.' + region.CurrencyPrecision + '-' + region.CurrencyPrecision : region.RegionCode)
						: '-'
				}}
			</td>
			<td style="text-align: center; padding-left: 0px; padding-right: 0px">
				<button
					*ngIf="canNavigateToDetails(invoice)"
					type="button"
					pButton
					pRipple
					pTooltip="Expand For Items"
					tooltipPosition="bottom"
					(click)="toggleItems(invoice)"
					class="p-button-text p-button-rounded p-button-plain"
					[icon]="expanded ? 'pi pi-chevron-up' : 'pi pi-chevron-down'"></button>
			</td>
			<td style="text-align: center; padding-left: 0px; padding-right: 0px">
				<button
					*ngIf="canNavigateToDetails(invoice)"
					type="button"
					pButton
					pRipple
					pTooltip="Click For Details"
					tooltipPosition="bottom"
					(click)="handleRowClick(invoice)"
					class="p-button-text p-button-rounded p-button-plain"
					icon="pi pi-link"></button>
				<div
					*ngIf="!canNavigateToDetails(invoice)"
					class="p-element p-button-text p-button-rounded p-button-plain p-button p-component p-button-icon-only ng-star-inserted"></div>
			</td>
		</tr>
	</ng-template>
	<ng-template pTemplate="rowexpansion" let-invoice>
		<tr class="p-p-0 child-table-wrapper">
			<td colspan="12" class="p-p-0">
				<app-related-invoice-item-list #invoiceItemList [invoice]="invoice"></app-related-invoice-item-list>
			</td>
		</tr>
	</ng-template>
</p-table>

<div *ngIf="showList" class="custom-pagination" data-testid="eventsCustomPagination">
	<span *ngIf="invoices && invoices.length; then resultFoundPaginationText; else noResultFoundPaginationText"> </span>
	<ng-template #resultFoundPaginationText>Showing {{ invoices.length }} {{ invoices.length > 1 ? 'entries' : 'entry' }}</ng-template>
	<ng-template #noResultFoundPaginationText>No entries found</ng-template>
</div>
