<div
	class="layout-wrapper"
	(click)="onWrapperClick()"
	[ngClass]="{
		'layout-wrapper-static': app.layoutMode === 'static',
		'layout-wrapper-active': mobileMenuActive,
		'layout-menu-horizontal': app.layoutMode === 'horizontal',
		'layout-rtl': app.isRTL,
		'p-ripple-disabled': !app.ripple,
		'p-input-filled': app.inputStyle === 'filled',
		'table-overflow-page': appContext.HasTableBasedData
	}">
	<app-menu></app-menu>

	<div class="layout-main">
		<app-topbar [ngClass]="appContext.BasePageCSSClass"></app-topbar>

		<app-breadcrumb></app-breadcrumb>

		<app-blockable-div #content class="layout-content" [class]="appContext.BasePageCSSClass">
			<router-outlet></router-outlet>
		</app-blockable-div>

		<app-footer></app-footer>
		<p-toast styleClass="p-toast-clear" key="clearAll" preventOpenDuplicates="true">
			<ng-template let-message pTemplate="message">
				<div class="flex flex-column" style="flex: 1">
					<p-button data-test-id="clearAllButton" label="Clear All" styleClass="p-button-link" (onClick)="onClearAll()"></p-button>
				</div>
			</ng-template>
		</p-toast>
		<p-toast #toast data-test-id="toastMessage" styleClass="p-toast-custom" (onClose)="onMsgClose()"> </p-toast>
		<p-blockUI [blocked]="blocked">
			<p-progressSpinner [ngClass]="{ loader: pageLevelBlock }" strokeWidth="4"></p-progressSpinner>
		</p-blockUI>
		<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle" [class]="appContext.BasePageCSSClass"></p-confirmDialog>
		<div class="layout-main-mask" *ngIf="mobileMenuActive"></div>
	</div>
</div>
