import { Component, Input, OnDestroy } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { Constants } from 'src/app/shared/constants/constants.constant';
import { FORM_ITEM_DISPLAY_OPTION_LABEL, FormItemDisplayOption } from 'src/app/shared/enums';
import { FormMode } from 'src/app/shared/models/forms';
import { BreadcrumbItem } from 'src/app/shared/models/ui';
import { FieldIconConstants } from '../constants/field-icons.constants';
import { AppContextService, UtilService } from '../services';

@Component({
	template: '',
})
export abstract class BaseComponent implements OnDestroy {
	@Input() formMode: FormMode;
	formModes = FormMode;
	protected destroyed$: Subject<boolean> = new Subject<boolean>();
	public editMode = false;
	public isNew = false;
	public util: UtilService;

	public viewPrices = true;
	public priceTooltip: string;
	public priceIcon: string;
	public priceOverride: string;

	constructor(public appContext: AppContextService) {
		this.util = this.appContext.util;

		this.viewPrices = this.appContext.hasPermission(Constants.UserPermission.CommonViewPrices);
		if (this.viewPrices == false) {
			this.priceTooltip = FieldIconConstants.MissingPermission_Tooltip;
			this.priceIcon = FieldIconConstants.MissingPermission_Icon;
			this.priceOverride = FORM_ITEM_DISPLAY_OPTION_LABEL[FormItemDisplayOption.MISSING_PERMISSION];
		}
	}

	ngOnDestroy(): void {
		if (this.isPage()) {
			this.appContext.showBreadcrumb = false;
			this.appContext.breadcrumbItems = [];
			this.appContext.showFilter = false;
			this.appContext.HasTableBasedData = false;
		}

		this.destroyed$.next(true);
		this.destroyed$.complete();
	}

	isPage(): boolean {
		return false;
	}

	public newGuid() {
		return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
			const r = (Math.random() * 16) | 0,
				v = c == 'x' ? r : (r & 0x3) | 0x8;
			return v.toString(16);
		});
	}

	markGroupDirty(formGroup: UntypedFormGroup) {
		Object.keys(formGroup.controls).forEach(field => {
			const control = formGroup.get(field);
			if (control instanceof UntypedFormControl) {
				control.markAsTouched({ onlySelf: true });
				control.markAsDirty();
			} else if (control instanceof UntypedFormGroup) {
				this.markGroupDirty(control);
			}
		});
	}

	getUserRelationTypeTooltip(externalUserRelationTypeCode: string) {
		if (externalUserRelationTypeCode == Constants.UserRelationType.Client) return FieldIconConstants.ExternalUserType_Client_Tooltip;

		return null;
	}

	getUserRelationTypeIcon(externalUserRelationTypeCode: string) {
		if (externalUserRelationTypeCode == Constants.UserRelationType.Client) return FieldIconConstants.ExternalUserType_Client_Icon;

		return null;
	}

	setBreadcrumbForAddress(name: string, assetReference: string = null, street: string = null, suburb: string = null) {
		const items = [new BreadcrumbItem({ label: name })];

		if (assetReference && street && suburb) {
			items.push(new BreadcrumbItem({ label: `${assetReference} - ${street}, ${suburb}` }));
		} else if (street && suburb) {
			items.push(new BreadcrumbItem({ label: `${street}, ${suburb}` }));
		} else if (assetReference && (street || suburb)) {
			items.push(new BreadcrumbItem({ label: `${assetReference} - ${street ?? suburb}` }));
		} else if (assetReference) {
			items.push(new BreadcrumbItem({ label: `${assetReference}` }));
		}

		this.appContext.breadcrumbItems = items;
		this.appContext.showBreadcrumb = true;
	}

	setBreadcrumb(fields: string[]) {
		const items = fields.filter(x => x).map(x => new BreadcrumbItem({ label: x }));

		this.appContext.breadcrumbItems = items;
		this.appContext.showBreadcrumb = true;
	}
}
