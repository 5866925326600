export class BreadcrumbItem {
	label: string;
	url: string | null;
	constructor(properties?: any) {
		if (properties) {
			for (const key in properties) {
				this[key] = properties[key];
			}
		}
	}
}
