import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { FormMode, FormControl as FrmCntl } from 'src/app/shared/models/forms';
import { DynamicDialogParams } from 'src/app/core/models';

@Component({
	selector: 'app-paid-invoice-confirmation-dialog',
	templateUrl: './paid-invoice-confirmation-dialog.component.html',
})
export class PaidInvoiceConfirmationDialogComponent implements OnInit {
	private params: DynamicDialogParams;
	formMode: FormMode;
	formModes = FormMode;
	formControls = FrmCntl;
	title: string;
	icon: string;

	constructor(private fb: UntypedFormBuilder, public ref: DynamicDialogRef, public config: DynamicDialogConfig) {}

	confirmationForm = this.fb.group({
		datePaid: [null, Validators.required],
		referenceNumber: [null],
		comments: [null],
	});

	ngOnInit(): void {
		this.params = this.config.data as DynamicDialogParams;
		if (this.params.data != null) {
			this.title = this.params.data.title;
			this.icon = this.params.data.icon;
		}
		this.formMode = this.formModes.Create;
		this.markGroupDirty(this.confirmationForm);
	}

	submitDialog() {
		if (!this.confirmationForm.valid) return false;
		this.ref.close(this.confirmationForm.value);
	}

	closeDialog() {
		this.ref.close(null);
	}

	getHeader(): string {
		return this.params.header;
	}

	getTitle(): string {
		return this.title;
	}

	getIcon() {
		return this.icon;
	}

	markGroupDirty(formGroup: UntypedFormGroup) {
		Object.keys(formGroup.controls).forEach(field => {
			const control = formGroup.get(field);
			if (control instanceof UntypedFormControl) {
				control.markAsTouched({ onlySelf: true });
				control.markAsDirty();
			} else if (control instanceof UntypedFormGroup) {
				this.markGroupDirty(control);
			}
		});
	}
}
