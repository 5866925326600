import { StatusLookupValue } from './status-lookup-value.model';

export class TaxLookupValue extends StatusLookupValue {
	TaxCode: string;
	TaxName: string;
	TaxRate: number | null;

	CompanyId: string;
	CompanyName: string;
	ClientId: string;
	ClientName: string;
	BusinessEntityId: string;
	BusinessEntityName: string;
}
