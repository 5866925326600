export interface ActionResult<T = any> {
	IsSuccess: boolean;
	IsCancelled: boolean;
	Messages: ActionResultMessage[];
	Data: T;
}

export interface ActionResultMessage<T = any> {
	MessageType: ActionResultMessageType;
	Message: string;
	Detail: string;
	OwnerId: string;
	FieldName: string;
	Source: string;
	Data: T;
}

export enum ActionResultMessageType {
	Error = 0,
	Warning = 1,
	Notification = 5,
	UnauthorizedAccess = 3,
	Question = 4,
	Success = 2,
}
