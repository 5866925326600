export interface IROAccessObjectList {
	order: number;
	message: string;
}

export interface IROAccessObject {
	Object: string;
	Action: string;
	Field: string;
	Note: string;
	Reason?: string;
	Message?: string;
	Order?: number;
	System?: string;
	Type?: string;
}

export const TOOLTIP_MESSAGES_COMPONENT = {
	C_AREA: {
		object: 'Area',
		sheet: 'Configuration',
	},
	C_ASSET: {
		object: 'Asset',
		sheet: 'Configuration',
	},
	C_BUSINESS_ENTITY: {
		object: 'Contractor',
		sheet: 'Configuration',
	},
	C_BUSINESS_ENTITY_INSURANCE: {
		object: 'Contractor Insurance',
		sheet: 'Configuration',
	},
	C_BUSINESS_ENTITY_LICENCE: {
		object: 'Contractor Licence',
		sheet: 'Configuration',
	},
	C_CLIENT: {
		object: 'Client',
		sheet: 'Configuration',
	},
	C_COMPANY: {
		object: 'Company',
		sheet: 'Configuration',
	},
	C_DOMAIN_TYPE_VALUE: {
		object: 'Field',
		sheet: 'Configuration',
	},
	C_PROJECT: {
		object: 'Project',
		sheet: 'Configuration',
	},
	C_SOR: {
		object: 'Rate',
		sheet: 'Configuration',
	},
	C_SOR_PRICE: {
		object: 'Rate Price',
		sheet: 'Configuration',
	},
	C_SUBSCRIBER: {
		object: 'Subscriber',
		sheet: 'Configuration',
	},
	C_USER: {
		object: 'User',
		sheet: 'Configuration',
	},
	C_USER_ROLE: {
		object: 'User Role',
		sheet: 'Configuration',
	},
	C_USER_ROLE_PERMISSION: {
		object: 'User Role Permission',
		sheet: 'Configuration',
	},
	INTEXP: {
		object: 'Interface Export Event',
		sheet: 'System',
	},
	INTIMP: {
		object: 'Interface Import Event',
		sheet: 'System',
	},
	INVOICE: {
		object: 'Invoice',
		sheet: 'Invoice',
	},
	INVOICE_ISSUE: {
		object: 'Invoice Issue',
		sheet: 'Invoice',
	},
	INVOICE_ITEM: {
		object: 'Invoice Item',
		sheet: 'Invoice',
	},
	INVOICE_PAYMENT: {
		object: 'Invoice Payment',
		sheet: 'Invoice',
	},
	PURCHASE_ORDER: {
		object: 'Purchase Order',
		sheet: 'Work Order',
	},
	PURCHASE_ORDER_ITEM: {
		object: 'Purchase Order Item',
		sheet: 'Work Order',
	},
	PURCHASE_ORDER_ITEM_REVIEW: {
		object: 'Purchase Order Item Review',
		sheet: 'Work Order',
	},
	QUOTE_REQUEST: {
		object: 'Quote Request',
		sheet: 'Quote',
	},
	QUOTE_REQUEST_ITEM: {
		object: 'Quote Request Item',
		sheet: 'Quote',
	},
	QUOTE_REQUEST_ITEM_RESPONSE: {
		object: 'Quote Request Item Response',
		sheet: 'Quote',
	},
	QUOTE_REQUEST_RECIPIENT: {
		object: 'Quote Request Recipient',
		sheet: 'Quote',
	},
	S_ACTIVITY: {
		object: 'Activity',
		sheet: 'Shared',
	},
	S_ATTACHMENT: {
		object: 'Attachment',
		sheet: 'Shared',
	},
	S_ATTACHMENT_FILE: {
		object: 'Attachment File',
		sheet: 'Shared',
	},
	S_CONTACT: {
		object: 'Contact',
		sheet: 'Shared',
	},
	S_MAP: {
		object: 'Map',
		sheet: 'Shared',
	},
	S_NOTE: {
		object: 'Note',
		sheet: 'Shared',
	},
	SCOPE: {
		object: 'Scope',
		sheet: 'Scope',
	},
	SCOPE_ITEM: {
		object: 'Scope Item',
		sheet: 'Scope',
	},
	SRQ: {
		object: 'Service Request',
		sheet: 'Service Request',
	},
	SRQ_ITEM: {
		object: 'Service Request Item',
		sheet: 'Service Request',
	},
	WORK_ORDER: {
		object: 'Work Order',
		sheet: 'Work Order',
	},
	WORK_ORDER_ITEM: {
		object: 'Work Order Item',
		sheet: 'Work Order',
	},
	WORK_ORDER_ITEM_REVIEW: {
		object: 'Work Order Item Review',
		sheet: 'Work Order',
	},
};

export type tooltipMessagesComponentType = keyof typeof TOOLTIP_MESSAGES_COMPONENT;

export const TT_PERMISSION_TYPES = [
	'AllowAccept',
	'AllowAddCostPrice',
	'AllowAddCreditNote',
	'AllowAddInsurance',
	'AllowAddItem',
	'AllowAddLicence',
	'AllowAddPayment',
	'AllowAddPermission',
	'AllowAddRecipient',
	'AllowAllocated',
	'AllowAmend',
	'AllowApprove',
	'AllowBulkAllocateOrderItems',
	'AllowClose',
	'AllowCreated',
	'AllowDataEntryRequired',
	'AllowDefectWithdraw',
	'AllowEdit',
	'AllowFlagEdit',
	'AllowFlowToInvoice',
	'AllowFlowToQuote',
	'AllowFlowToScope',
	'AllowFlowToServiceRequest',
	'AllowFlowToWorkOrder',
	'AllowHold',
	'AllowInProgress',
	'AllowPaid',
	'AllowPaused',
	'AllowPrinting',
	'AllowReceived',
	'AllowRecreate',
	'AllowReject',
	'AllowRelease',
	'AllowRemove',
	'AllowReprocess',
	'AllowResend',
	'AllowReview',
	'AllowReviewApproved',
	'AllowReviewRequired',
	'AllowSend',
	'AllowSent',
	'AllowUnsuccessful',
	'AllowViewPlan',
	'AllowVoid',
	'AREA_UPDATE',
	'AREA_VIEW',
	'ASSET_UPDATE',
	'ASSET_VIEW',
	'BUSINESSENTITY_UPDATE',
	'BUSINESSENTITY_VIEW',
	'CLIENT_UPDATE',
	'CLIENT_VIEW',
	'COMMON_ACTIVITY_VIEW',
	'COMMON_ATTACH_CREATE',
	'COMMON_ATTACH_EDIT',
	'COMMON_ATTACH_REMOVE',
	'COMMON_CONTACTS_REMOVE',
	'COMMON_CONTACTS_UPDATE',
	'COMMON_MAPS_CREATE',
	'COMMON_MAPS_EDIT',
	'COMMON_MAPS_REMOVE',
	'COMMON_NOTES_CREATE',
	'COMMON_NOTES_EDIT',
	'COMMON_NOTES_REMOVE',
	'COMPANY_UPDATE',
	'COMPANY_VIEW',
	'DOMAINVALUE_UPDATE',
	'DOMAINVALUE_VIEW',
	'INTERFACE_VIEW',
	'INVOICE_CREATE',
	'INVOICE_VIEW',
	'ORDER_CREATE',
	'ORDER_VIEW',
	'PROJECT_UPDATE',
	'PROJECT_VIEW',
	'QUOTE_CREATE',
	'QUOTE_VIEW',
	'SCOPE_CREATE',
	'SCOPE_VIEW',
	'SOR_UPDATE',
	'SOR_VIEW',
	'SRQ_UPDATE',
	'SRQ_UPDATE_BASIC',
	'SRQ_VIEW',
	'SUBSCRIBER_VIEW',
	'USER_UPDATE',
	'USER_VIEW',
	'USERROLE_UPDATE',
	'USERROLE_VIEW',
] as const;
export type ttPermissionType = (typeof TT_PERMISSION_TYPES)[number];

export const TT_REASON_TYPES = [
	undefined,
	'BlockDefectedItemChanges',
	'BlockQrBarcodeEditing',
	'BlockQuotedItemChanges',
	'HasOverriddenPrice',
	'IncorrectChildStatus',
	'IncorrectChildType',
	'IncorrectConfig',
	'IncorrectEntityType',
	'IncorrectStatus',
	'IncorrectType',
	'IsChildInvoiced',
	'IsDefected',
	'IsDraft',
	'IsInvoiced',
	'IsNew',
	'IsNotAllocated',
	'IsQuoted',
	'IsRemoved',
	'IsReviewed',
	'IsSystem',
	'MissingCodes',
	'MissingDates',
	'MissingPermission',
] as const;
export type ttReasonType = (typeof TT_REASON_TYPES)[number];

export const REPLACEABLE_VALUES = [
	'actionName',
	'businessEntityRelationTypeName',
	'invoiceStatusName',
	'invoiceSubtypeName',
	'invoiceTypeName',
	'purchaseOrderStatusName',
	'purchaseOrderTypeName',
	'statusName',
	'workOrderStatusName',
	'workOrderTypeName',
] as const;
export type ttReplaceableValues = (typeof REPLACEABLE_VALUES)[number];

export type IReplaceableList = {
	[key in ttReplaceableValues]?: string;
};

export const TOOLTIP_MESSAGES = {
	'Work Order': [
		{
			Object: 'Work Order',
			Action: 'View List',
			Type: 'Permission',
			Field: 'ORDER_VIEW',
			Note: 'hide the sidebar menu / front page tile if missing the permission',
		},
		{
			Object: 'Work Order',
			Action: 'Create',
			Type: 'Permission',
			Field: 'ORDER_CREATE',
			Reason: 'MissingPermission',
			Message: 'You do not have permissions to perform this action.',
		},
		{
			Object: 'Work Order',
			Action: 'Add Item',
			Type: 'Access',
			Field: 'AllowAddItem',
			Order: 1,
			Reason: 'MissingPermission',
			Message: 'You do not have permissions to perform this action.',
		},
		{
			Object: 'Work Order',
			Action: 'Add Item',
			Type: 'Access',
			Field: 'AllowAddItem',
			Order: 2,
			Reason: 'IncorrectType',
			Message: 'Items cannot be added to ${workOrderTypeName} work orders.',
		},
		{
			Object: 'Work Order',
			Action: 'Add Item',
			Type: 'Access',
			Field: 'AllowAddItem',
			Order: 3,
			Reason: 'IncorrectStatus',
			Message: 'Items cannot be added when the work order is in ${statusName} status.',
		},
		{
			Object: 'Work Order',
			Action: 'Bulk Allocate Items',
			Type: 'Access',
			Field: 'AllowBulkAllocateOrderItems',
			Order: 1,
			Reason: 'MissingPermission',
			Message: 'You do not have permissions to perform this action.',
		},
		{
			Object: 'Work Order',
			Action: 'Bulk Allocate Items',
			Type: 'Access',
			Field: 'AllowBulkAllocateOrderItems',
			Order: 2,
			Reason: 'IncorrectType',
			Message: 'Items cannot be bulk allocated to ${workOrderTypeName} work orders.',
		},
		{
			Object: 'Work Order',
			Action: 'Bulk Allocate Items',
			Type: 'Access',
			Field: 'AllowBulkAllocateOrderItems',
			Order: 3,
			Reason: 'IncorrectStatus',
			Message: 'Items cannot be bulk allocated when the work order is in ${statusName} status.',
		},
		{
			Object: 'Work Order',
			Action: 'Cancel',
			Type: 'Access',
			Field: 'AllowRemove',
			Order: 1,
			Reason: 'MissingPermission',
			Message: 'You do not have permissions to perform this action.',
		},
		{
			Object: 'Work Order',
			Action: 'Cancel',
			Type: 'Access',
			Field: 'AllowRemove',
			Order: 2,
			Reason: 'IncorrectType',
			Message: '${workOrderTypeName} work orders cannot be cancelled.',
		},
		{
			Object: 'Work Order',
			Action: 'Cancel',
			Type: 'Access',
			Field: 'AllowRemove',
			Order: 3,
			Reason: 'IncorrectStatus',
			Message: 'Orders in ${statusName} status cannot be cancelled.',
		},
		{
			Object: 'Work Order',
			Action: 'Edit Work Order',
			Type: 'Access',
			Field: 'AllowEdit',
			Order: 1,
			Reason: 'MissingPermission',
			Message: 'You do not have permissions to perform this action.',
		},
		{
			Object: 'Work Order',
			Action: 'Edit Work Order',
			Type: 'Access',
			Field: 'AllowEdit',
			Order: 2,
			Reason: 'IncorrectType',
			Message: '${workOrderTypeName} work orders cannot be edited.',
		},
		{
			Object: 'Work Order',
			Action: 'Flow to Invoice',
			Type: 'Access',
			Field: 'AllowFlowToInvoice',
			Order: 1,
			Reason: 'MissingPermission',
			Message: 'You do not have permissions to perform this action.',
		},
		{
			Object: 'Work Order',
			Action: 'Send Changes',
			Type: 'Access',
			Field: 'AllowSend',
			Order: 1,
			Reason: 'MissingPermission',
			Message: 'You do not have permissions to perform this action.',
		},
		{
			Object: 'Work Order',
			Action: 'Send Changes',
			Type: 'Access',
			Field: 'AllowSend',
			Order: 2,
			Reason: 'IncorrectType',
			Message: 'Updated details cannot be sent for ${workOrderTypeName} work orders.',
		},
		{
			Object: 'Work Order',
			Action: 'Edit Flags',
			Type: 'Access',
			Field: 'AllowFlagEdit',
			Order: 1,
			Reason: 'MissingPermission',
			Message: 'You do not have permissions to perform this action.',
		},
		{
			Object: 'Work Order',
			Action: 'Edit Flags',
			Type: 'Access',
			Field: 'AllowFlagEdit',
			Order: 2,
			Reason: 'IncorrectType',
			Message: '${workOrderTypeName} work orders cannot be edited.',
		},
		{
			Object: 'Work Order',
			Action: 'View Work Plan',
			Type: 'Access',
			Field: 'AllowViewPlan',
			Order: 1,
			Reason: 'MissingPermission',
			Message: 'You do not have permissions to perform this action.',
		},
		{
			Object: 'Work Order',
			Action: 'View Work Plan',
			Type: 'Access',
			Field: 'AllowViewPlan',
			Order: 2,
			Reason: 'MissingDates',
			Message: 'The planner for this work order cannot be viewed as it does not have a scheduled start or due date.',
		},
		{
			Object: 'Work Order',
			Action: 'View Work Plan',
			Type: 'Access',
			Field: 'AllowViewPlan',
			Order: 3,
			Reason: 'IncorrectStatus',
			Message: 'The planner for this work order cannot be viewed as it is in ${statusName} status.',
		},
		{
			Object: 'Work Order',
			Action: 'Created',
			Type: 'Access',
			Field: 'AllowCreated',
			Order: 1,
			Reason: 'MissingPermission',
			Message: 'You do not have permissions to perform this action.',
		},
		{
			Object: 'Work Order',
			Action: 'Created',
			Type: 'Access',
			Field: 'AllowCreated',
			Order: 2,
			Reason: 'IncorrectType',
			Message: '${workOrderTypeName} work orders cannot be created.',
		},
		{
			Object: 'Work Order',
			Action: 'Created',
			Type: 'Access',
			Field: 'AllowCreated',
			Order: 3,
			Reason: 'IncorrectStatus',
			Message: 'Orders in ${statusName} status cannot be created.',
		},
		{
			Object: 'Work Order',
			Action: 'Allocated',
			Type: 'Access',
			Field: 'AllowAllocated',
			Order: 1,
			Reason: 'MissingPermission',
			Message: 'You do not have permissions to perform this action.',
		},
		{
			Object: 'Work Order',
			Action: 'Allocated',
			Type: 'Access',
			Field: 'AllowAllocated',
			Order: 2,
			Reason: 'IncorrectType',
			Message: '${workOrderTypeName} work orders cannot be allocated.',
		},
		{
			Object: 'Work Order',
			Action: 'Allocated',
			Type: 'Access',
			Field: 'AllowAllocated',
			Order: 3,
			Reason: 'IncorrectStatus',
			Message: 'Orders in ${statusName} status cannot be allocated.',
		},
		{
			Object: 'Work Order',
			Action: 'InProgress',
			Type: 'Access',
			Field: 'AllowInProgress',
			Order: 1,
			Reason: 'MissingPermission',
			Message: 'You do not have permissions to perform this action.',
		},
		{
			Object: 'Work Order',
			Action: 'InProgress',
			Type: 'Access',
			Field: 'AllowInProgress',
			Order: 2,
			Reason: 'IncorrectType',
			Message: '${workOrderTypeName} work orders cannot be in progress.',
		},
		{
			Object: 'Work Order',
			Action: 'InProgress',
			Type: 'Access',
			Field: 'AllowInProgress',
			Order: 3,
			Reason: 'IncorrectStatus',
			Message: 'Orders in ${statusName} status cannot be marked as in progress.',
		},
		{
			Object: 'Work Order',
			Action: 'ReviewRequired',
			Type: 'Access',
			Field: 'AllowReviewRequired',
			Order: 1,
			Reason: 'MissingPermission',
			Message: 'You do not have permissions to perform this action.',
		},
		{
			Object: 'Work Order',
			Action: 'ReviewRequired',
			Type: 'Access',
			Field: 'AllowReviewRequired',
			Order: 2,
			Reason: 'IncorrectType',
			Message: '${workOrderTypeName} work orders cannot be marked as review required.',
		},
		{
			Object: 'Work Order',
			Action: 'ReviewRequired',
			Type: 'Access',
			Field: 'AllowReviewRequired',
			Order: 3,
			Reason: 'IncorrectStatus',
			Message: 'Orders in ${statusName} status cannot be marked as review required.',
		},
		{
			Object: 'Work Order',
			Action: 'ReviewedApproved',
			Type: 'Access',
			Field: 'AllowReviewApproved',
			Order: 1,
			Reason: 'MissingPermission',
			Message: 'You do not have permissions to perform this action.',
		},
		{
			Object: 'Work Order',
			Action: 'ReviewedApproved',
			Type: 'Access',
			Field: 'AllowReviewApproved',
			Order: 2,
			Reason: 'IncorrectType',
			Message: '${workOrderTypeName} work orders cannot be marked as reviewed approved.',
		},
		{
			Object: 'Work Order',
			Action: 'ReviewedApproved',
			Type: 'Access',
			Field: 'AllowReviewApproved',
			Order: 3,
			Reason: 'IncorrectStatus',
			Message: 'Orders in ${statusName} status cannot be marked as reviewed approved.',
		},
		{
			Object: 'Work Order',
			Action: 'Paused',
			Type: 'Access',
			Field: 'AllowPaused',
			Order: 1,
			Reason: 'MissingPermission',
			Message: 'You do not have permissions to perform this action.',
		},
		{
			Object: 'Work Order',
			Action: 'Paused',
			Type: 'Access',
			Field: 'AllowPaused',
			Order: 2,
			Reason: 'IncorrectType',
			Message: '${workOrderTypeName} work orders cannot be paused.',
		},
		{
			Object: 'Work Order',
			Action: 'Paused',
			Type: 'Access',
			Field: 'AllowPaused',
			Order: 3,
			Reason: 'IncorrectStatus',
			Message: 'Orders in ${statusName} status cannot be paused.',
		},
		{
			Object: 'Work Order Item',
			Action: 'Add Review',
			Type: 'Access',
			Field: 'AllowReview',
			Order: 1,
			Reason: 'MissingPermission',
			Message: 'You do not have permissions to perform this action.',
		},
		{
			Object: 'Work Order Item',
			Action: 'Add Review',
			Type: 'Access',
			Field: 'AllowReview',
			Order: 2,
			Reason: 'IsRemoved',
			Message: 'Defected items cannot be reviewed.',
		},
		{
			Object: 'Work Order Item',
			Action: 'Add Review',
			Type: 'Access',
			Field: 'AllowReview',
			Order: 3,
			Reason: 'IncorrectType',
			Message: 'Items belonging to ${purchaseOrderTypeName} purchase orders on ${workOrderTypeName} work orders cannot be reviewed.',
		},
		{
			Object: 'Work Order Item',
			Action: 'Add Review',
			Type: 'Access',
			Field: 'AllowReview',
			Order: 4,
			Reason: 'IncorrectStatus',
			Message: 'Items cannot be reviewed when the work order is in ${workOrderStatusName} status.',
		},
		{
			Object: 'Work Order Item',
			Action: 'Add Review',
			Type: 'Access',
			Field: 'AllowReview',
			Order: 5,
			Reason: 'IsNotAllocated',
			Message: 'Unallocated items cannot be reviewed.',
		},
		{
			Object: 'Work Order Item',
			Action: 'Add Review',
			Type: 'Access',
			Field: 'AllowReview',
			Order: 6,
			Reason: 'IsNew',
			Message: 'Newly added items cannot be reviewed.',
		},
		{
			Object: 'Work Order Item',
			Action: 'Delete Item',
			Type: 'Access',
			Field: 'AllowRemove',
			Order: 1,
			Reason: 'MissingPermission',
			Message: 'You do not have permissions to perform this action.',
		},
		{
			Object: 'Work Order Item',
			Action: 'Delete Item',
			Type: 'Access',
			Field: 'AllowRemove',
			Order: 2,
			Reason: 'IsRemoved',
			Message: 'Deleted items cannot be deleted.',
		},
		{
			Object: 'Work Order Item',
			Action: 'Delete Item',
			Type: 'Access',
			Field: 'AllowRemove',
			Order: 3,
			Reason: 'IncorrectType',
			Message: 'Items cannot be deleted on ${workOrderTypeName} work orders.',
		},
		{
			Object: 'Work Order Item',
			Action: 'Delete Item',
			Type: 'Access',
			Field: 'AllowRemove',
			Order: 4,
			Reason: 'IncorrectStatus',
			Message: 'Items cannot be deleted when the work order is in ${workOrderStatusName} status.',
		},
		{
			Object: 'Work Order Item',
			Action: 'Delete Item',
			Type: 'Access',
			Field: 'AllowRemove',
			Order: 5,
			Reason: 'IsInvoiced',
			Message: 'Invoiced items cannot be deleted.',
		},
		{
			Object: 'Work Order Item',
			Action: 'Delete Item',
			Type: 'Access',
			Field: 'AllowRemove',
			Order: 6,
			Reason: 'IsDefected',
			Message: 'Defected items cannot be deleted.',
		},
		{
			Object: 'Work Order Item',
			Action: 'Delete Item',
			Type: 'Access',
			Field: 'AllowRemove',
			Order: 7,
			Reason: 'IncorrectChildType',
			Message: 'Items cannot be deleted on ${purchaseOrderTypeName} purchase orders.',
		},
		{
			Object: 'Work Order Item',
			Action: 'Delete Item',
			Type: 'Access',
			Field: 'AllowRemove',
			Order: 8,
			Reason: 'IncorrectChildStatus',
			Message: 'Items cannot be deleted when the purchase order is in ${purchaseOrderStatusName} status.',
		},
		{
			Object: 'Work Order Item',
			Action: 'Delete Item',
			Type: 'Access',
			Field: 'AllowRemove',
			Order: 9,
			Reason: 'IsChildInvoiced',
			Message: 'Invoiced purchase order items cannot be deleted.',
		},
		{
			Object: 'Work Order Item',
			Action: 'Edit Item',
			Type: 'Access',
			Field: 'AllowEdit',
			Order: 1,
			Reason: 'MissingPermission',
			Message: 'You do not have permissions to perform this action.',
		},
		{
			Object: 'Work Order Item',
			Action: 'Edit Item',
			Type: 'Access',
			Field: 'AllowEdit',
			Order: 2,
			Reason: 'IsRemoved',
			Message: 'Deleted items cannot be edited.',
		},
		{
			Object: 'Work Order Item',
			Action: 'Edit Item',
			Type: 'Access',
			Field: 'AllowEdit',
			Order: 3,
			Reason: 'IncorrectType',
			Message: 'Items cannot be edited on ${workOrderTypeName} work orders.',
		},
		{
			Object: 'Work Order Item',
			Action: 'Edit Item',
			Type: 'Access',
			Field: 'AllowEdit',
			Order: 4,
			Reason: 'IncorrectStatus',
			Message: 'Items cannot be edited when the work order is in ${workOrderStatusName} status.',
		},
		{
			Object: 'Work Order Item',
			Action: 'Edit Item',
			Type: 'Access',
			Field: 'AllowEdit',
			Order: 5,
			Reason: 'IsInvoiced',
			Message: 'Invoiced items cannot be edited.',
		},
		{
			Object: 'Work Order Item',
			Action: 'Edit Item',
			Type: 'Access',
			Field: 'AllowEdit',
			Order: 6,
			Reason: 'IsDefected',
			System: 'Mobile',
			Message: 'Defected items cannot be edited.',
		},
		{
			Object: 'Work Order Item',
			Action: 'Edit Item',
			Type: 'Access',
			Field: 'AllowEdit',
			Order: 7,
			Reason: 'IsQuoted',
			System: 'Mobile',
			Message: 'Quoted items cannot be edited.',
		},
		{
			Object: 'Work Order Item',
			Action: 'Edit Item',
			Type: 'Access',
			Field: 'AllowEdit',
			Order: 8,
			Reason: 'HasOverriddenPrice',
			Message: 'You do not have permissions to edit items with an overridden price.',
		},
		{
			Object: 'Work Order Item',
			Action: 'Edit Item',
			Type: 'Access',
			Field: 'AllowEdit',
			Order: 9,
			Reason: 'IncorrectChildType',
			Message: 'Items cannot be edited on ${purchaseOrderTypeName} purchase orders.',
		},
		{
			Object: 'Work Order Item',
			Action: 'Edit Item',
			Type: 'Access',
			Field: 'AllowEdit',
			Order: 10,
			Reason: 'IncorrectChildStatus',
			Message: 'Items cannot be edited when the purchase order is in ${purchaseOrderStatusName} status.',
		},
		{
			Object: 'Work Order Item',
			Action: 'Edit Item',
			Type: 'Access',
			Field: 'AllowEdit',
			Order: 11,
			Reason: 'IsChildInvoiced',
			Message: 'Invoiced purchase order items cannot be edited.',
		},
		{
			Object: 'Work Order Item',
			Action: 'Edit Item',
			Type: 'Flag',
			Field: 'AllowEdit',
			Order: 12,
			Reason: 'BlockDefectedItemChanges',
			System: 'Web',
			Message: `Edit Defected Item
Not all fields will be editable.`,
		},
		{
			Object: 'Work Order Item',
			Action: 'Edit Item',
			Type: 'Flag',
			Field: 'AllowEdit',
			Order: 13,
			Reason: 'BlockQuotedItemChanges',
			System: 'Web',
			Message: `Edit Quoted Item
Not all fields will be editable.`,
		},
		{
			Object: 'Work Order Item Review',
			Action: 'Delete Review',
			Type: 'Access',
			Field: 'AllowRemove',
			Order: 1,
			Reason: 'MissingPermission',
			Message: 'You do not have permissions to perform this action.',
		},
		{
			Object: 'Work Order Item Review',
			Action: 'Delete Review',
			Type: 'Access',
			Field: 'AllowRemove',
			Order: 2,
			Reason: 'IncorrectType',
			Message: 'Item reviews belonging to ${purchaseOrderTypeName} purchase orders on ${workOrderTypeName} work orders cannot be deleted.',
		},
		{
			Object: 'Work Order Item Review',
			Action: 'Delete Review',
			Type: 'Access',
			Field: 'AllowRemove',
			Order: 3,
			Reason: 'IncorrectStatus',
			Message: 'Item reviews cannot be deleted if they have been sent.',
		},
		{
			Object: 'Work Order Item Review',
			Action: 'Edit Review',
			Type: 'Access',
			Field: 'AllowEdit',
			Order: 1,
			Reason: 'MissingPermission',
			Message: 'You do not have permissions to perform this action.',
		},
		{
			Object: 'Work Order Item Review',
			Action: 'Edit Review',
			Type: 'Access',
			Field: 'AllowEdit',
			Order: 2,
			Reason: 'IncorrectType',
			Message: 'Item reviews belonging to ${purchaseOrderTypeName} purchase orders on ${workOrderTypeName} work orders cannot be edited.',
		},
		{
			Object: 'Work Order Item Review',
			Action: 'Edit Review',
			Type: 'Access',
			Field: 'AllowEdit',
			Order: 3,
			Reason: 'IncorrectStatus',
			Message: 'Item reviews cannot be edited if they have been sent.',
		},
		{
			Object: 'Work Order Item Review',
			Action: 'Withdraw Review',
			Type: 'Access',
			Field: 'AllowDefectWithdraw',
			Order: 1,
			Reason: 'MissingPermission',
			Message: 'You do not have permissions to perform this action.',
		},
		{
			Object: 'Work Order Item Review',
			Action: 'Withdraw Review',
			Type: 'Access',
			Field: 'AllowDefectWithdraw',
			Order: 2,
			Reason: 'IncorrectType',
			Message: 'Item reviews belonging to ${purchaseOrderTypeName} purchase orders on ${workOrderTypeName} work orders cannot be withdrawn.',
		},
		{
			Object: 'Work Order Item Review',
			Action: 'Withdraw Review',
			Type: 'Access',
			Field: 'AllowDefectWithdraw',
			Order: 3,
			Reason: 'IncorrectStatus',
			Message: 'Item reviews cannot be withdrawn if they have not been sent or already withdrawn.',
		},
		{
			Object: 'Purchase Order',
			Action: 'View List',
			Type: 'Permission',
			Field: 'ORDER_VIEW',
			Note: 'hide the sidebar menu / front page tile if missing the permission',
		},
		{
			Object: 'Purchase Order',
			Action: 'Create',
			Type: 'Permission',
			Field: 'ORDER_CREATE',
			Reason: 'MissingPermission',
			Message: 'You do not have permissions to perform this action.',
		},
		{
			Object: 'Purchase Order',
			Action: 'Add Item',
			Type: 'Access',
			Field: 'AllowAddItem',
			Order: 1,
			Reason: 'MissingPermission',
			Message: 'You do not have permissions to perform this action.',
		},
		{
			Object: 'Purchase Order',
			Action: 'Add Item',
			Type: 'Access',
			Field: 'AllowAddItem',
			Order: 2,
			Reason: 'IncorrectType',
			Message: 'Items cannot be added to ${purchaseOrderTypeName} purchase orders.',
		},
		{
			Object: 'Purchase Order',
			Action: 'Add Item',
			Type: 'Access',
			Field: 'AllowAddItem',
			Order: 3,
			Reason: 'IncorrectStatus',
			Message: 'Items cannot be added when the purchase order is in ${statusName} status.',
		},
		{
			Object: 'Purchase Order',
			Action: 'Cancel',
			Type: 'Access',
			Field: 'AllowRemove',
			Order: 1,
			Reason: 'MissingPermission',
			Message: 'You do not have permissions to perform this action.',
		},
		{
			Object: 'Purchase Order',
			Action: 'Cancel',
			Type: 'Access',
			Field: 'AllowRemove',
			Order: 2,
			Reason: 'IncorrectType',
			Message: '${purchaseOrderTypeName} purchase orders cannot be cancelled.',
		},
		{
			Object: 'Purchase Order',
			Action: 'Cancel',
			Type: 'Access',
			Field: 'AllowRemove',
			Order: 3,
			Reason: 'IncorrectStatus',
			Message: 'Orders in ${statusName} status cannot be cancelled.',
		},
		{
			Object: 'Purchase Order',
			Action: 'Edit Purchase Order',
			Type: 'Access',
			Field: 'AllowEdit',
			Order: 1,
			Reason: 'MissingPermission',
			Message: 'You do not have permissions to perform this action.',
		},
		{
			Object: 'Purchase Order',
			Action: 'Edit Purchase Order',
			Type: 'Access',
			Field: 'AllowEdit',
			Order: 2,
			Reason: 'IncorrectType',
			Message: '${purchaseOrderTypeName} purchase orders cannot be edited.',
		},
		{
			Object: 'Purchase Order',
			Action: 'Flow to Invoice',
			Type: 'Access',
			Field: 'AllowFlowToInvoice',
			Order: 1,
			Reason: 'MissingPermission',
			Message: 'You do not have permissions to perform this action.',
		},
		{
			Object: 'Purchase Order',
			Action: 'Flow to Invoice',
			Type: 'Access',
			Field: 'AllowFlowToInvoice',
			Order: 2,
			Reason: 'IncorrectEntityType',
			Message: 'Invoices cannot be created against purchase orders assigned to ${businessEntityRelationTypeName}(s).',
		},
		{
			Object: 'Purchase Order',
			Action: 'Send Changes',
			Type: 'Access',
			Field: 'AllowSend',
			Order: 1,
			Reason: 'MissingPermission',
			Message: 'You do not have permissions to perform this action.',
		},
		{
			Object: 'Purchase Order',
			Action: 'Send Changes',
			Type: 'Access',
			Field: 'AllowSend',
			Order: 2,
			Reason: 'IncorrectType',
			Message: 'Updated details cannot be sent for ${purchaseOrderTypeName} purchase orders.',
		},
		{
			Object: 'Purchase Order',
			Action: 'Edit Flags',
			Type: 'Access',
			Field: 'AllowFlagEdit',
			Order: 1,
			Reason: 'MissingPermission',
			Message: 'You do not have permissions to perform this action.',
		},
		{
			Object: 'Purchase Order',
			Action: 'Edit Flags',
			Type: 'Access',
			Field: 'AllowFlagEdit',
			Order: 2,
			Reason: 'IncorrectType',
			Message: '${purchaseOrderTypeName} purchase orders cannot be edited.',
		},
		{
			Object: 'Purchase Order',
			Action: 'Created',
			Type: 'Access',
			Field: 'AllowCreated',
			Order: 1,
			Reason: 'MissingPermission',
			Message: 'You do not have permissions to perform this action.',
		},
		{
			Object: 'Purchase Order',
			Action: 'Created',
			Type: 'Access',
			Field: 'AllowCreated',
			Order: 2,
			Reason: 'IncorrectType',
			Message: '${purchaseOrderTypeName} purchase orders cannot be created.',
		},
		{
			Object: 'Purchase Order',
			Action: 'Created',
			Type: 'Access',
			Field: 'AllowCreated',
			Order: 3,
			Reason: 'IncorrectStatus',
			Message: 'Orders in ${statusName} status cannot be created.',
		},
		{
			Object: 'Purchase Order',
			Action: 'Allocated',
			Type: 'Access',
			Field: 'AllowAllocated',
			Order: 1,
			Reason: 'MissingPermission',
			Message: 'You do not have permissions to perform this action.',
		},
		{
			Object: 'Purchase Order',
			Action: 'Allocated',
			Type: 'Access',
			Field: 'AllowAllocated',
			Order: 2,
			Reason: 'IncorrectType',
			Message: '${purchaseOrderTypeName} purchase orders cannot be allocated.',
		},
		{
			Object: 'Purchase Order',
			Action: 'Allocated',
			Type: 'Access',
			Field: 'AllowAllocated',
			Order: 3,
			Reason: 'IncorrectStatus',
			Message: 'Orders in ${statusName} status cannot be allocated.',
		},
		{
			Object: 'Purchase Order',
			Action: 'InProgress',
			Type: 'Access',
			Field: 'AllowInProgress',
			Order: 1,
			Reason: 'MissingPermission',
			Message: 'You do not have permissions to perform this action.',
		},
		{
			Object: 'Purchase Order',
			Action: 'InProgress',
			Type: 'Access',
			Field: 'AllowInProgress',
			Order: 2,
			Reason: 'IncorrectType',
			Message: '${purchaseOrderTypeName} purchase orders cannot be in progress.',
		},
		{
			Object: 'Purchase Order',
			Action: 'InProgress',
			Type: 'Access',
			Field: 'AllowInProgress',
			Order: 3,
			Reason: 'IncorrectStatus',
			Message: 'Orders in ${statusName} status cannot be marked as in progress.',
		},
		{
			Object: 'Purchase Order',
			Action: 'DataEntryRequired',
			Type: 'Access',
			Field: 'AllowDataEntryRequired',
			Order: 1,
			Reason: 'MissingPermission',
			Message: 'You do not have permissions to perform this action.',
		},
		{
			Object: 'Purchase Order',
			Action: 'DataEntryRequired',
			Type: 'Access',
			Field: 'AllowDataEntryRequired',
			Order: 2,
			Reason: 'IncorrectType',
			Message: '${purchaseOrderTypeName} purchase orders cannot be marked as data entry required.',
		},
		{
			Object: 'Purchase Order',
			Action: 'DataEntryRequired',
			Type: 'Access',
			Field: 'AllowDataEntryRequired',
			Order: 3,
			Reason: 'IncorrectStatus',
			Message: 'Orders in ${statusName} status cannot be marked as data entry required.',
		},
		{
			Object: 'Purchase Order',
			Action: 'ReviewRequired',
			Type: 'Access',
			Field: 'AllowReviewRequired',
			Order: 1,
			Reason: 'MissingPermission',
			Message: 'You do not have permissions to perform this action.',
		},
		{
			Object: 'Purchase Order',
			Action: 'ReviewRequired',
			Type: 'Access',
			Field: 'AllowReviewRequired',
			Order: 2,
			Reason: 'IncorrectType',
			Message: '${purchaseOrderTypeName} purchase orders cannot be marked as review required.',
		},
		{
			Object: 'Purchase Order',
			Action: 'ReviewRequired',
			Type: 'Access',
			Field: 'AllowReviewRequired',
			Order: 3,
			Reason: 'IncorrectStatus',
			Message: 'Orders in ${statusName} status cannot be marked as review required.',
		},
		{
			Object: 'Purchase Order',
			Action: 'ReviewApproved',
			Type: 'Access',
			Field: 'AllowReviewApproved',
			Order: 1,
			Reason: 'MissingPermission',
			Message: 'You do not have permissions to perform this action.',
		},
		{
			Object: 'Purchase Order',
			Action: 'ReviewApproved',
			Type: 'Access',
			Field: 'AllowReviewApproved',
			Order: 2,
			Reason: 'IncorrectType',
			Message: '${purchaseOrderTypeName} purchase orders cannot be marked as reviewed approved.',
		},
		{
			Object: 'Purchase Order',
			Action: 'ReviewApproved',
			Type: 'Access',
			Field: 'AllowReviewApproved',
			Order: 3,
			Reason: 'IncorrectStatus',
			Message: 'Orders in ${statusName} status cannot be marked as reviewed approved.',
		},
		{
			Object: 'Purchase Order',
			Action: 'Paused',
			Type: 'Access',
			Field: 'AllowPaused',
			Order: 1,
			Reason: 'MissingPermission',
			Message: 'You do not have permissions to perform this action.',
		},
		{
			Object: 'Purchase Order',
			Action: 'Paused',
			Type: 'Access',
			Field: 'AllowPaused',
			Order: 2,
			Reason: 'IncorrectType',
			Message: '${purchaseOrderTypeName} purchase orders cannot be paused.',
		},
		{
			Object: 'Purchase Order',
			Action: 'Paused',
			Type: 'Access',
			Field: 'AllowPaused',
			Order: 3,
			Reason: 'IncorrectStatus',
			Message: 'Orders in ${statusName} status cannot be paused.',
		},
		{
			Object: 'Purchase Order Item',
			Action: 'Add Review',
			Type: 'Access',
			Field: 'AllowReview',
			Order: 1,
			Reason: 'MissingPermission',
			Message: 'You do not have permissions to perform this action.',
		},
		{
			Object: 'Purchase Order Item',
			Action: 'Add Review',
			Type: 'Access',
			Field: 'AllowReview',
			Order: 2,
			Reason: 'IsRemoved',
			Message: 'Defected items cannot be reviewed.',
		},
		{
			Object: 'Purchase Order Item',
			Action: 'Add Review',
			Type: 'Access',
			Field: 'AllowReview',
			Order: 3,
			Reason: 'IncorrectType',
			Message: 'Items belonging to ${purchaseOrderTypeName} purchase orders cannot be reviewed.',
		},
		{
			Object: 'Purchase Order Item',
			Action: 'Add Review',
			Type: 'Access',
			Field: 'AllowReview',
			Order: 4,
			Reason: 'IncorrectStatus',
			Message: 'Items cannot be reviewed when the purchase order is in ${purchaseOrderStatusName} status.',
		},
		{
			Object: 'Purchase Order Item',
			Action: 'Add Review',
			Type: 'Access',
			Field: 'AllowReview',
			Order: 5,
			Reason: 'IsNotAllocated',
			Message: 'Unallocated items cannot be reviewed.',
		},
		{
			Object: 'Purchase Order Item',
			Action: 'Add Review',
			Type: 'Access',
			Field: 'AllowReview',
			Order: 6,
			Reason: 'IsDraft',
			Message: 'Items on unsent draft purchase orders cannot be reviewed.',
		},
		{
			Object: 'Purchase Order Item',
			Action: 'Add Review',
			Type: 'Access',
			Field: 'AllowReview',
			Order: 7,
			Reason: 'IsNew',
			Message: 'Newly added items cannot be reviewed.',
		},
		{
			Object: 'Purchase Order Item',
			Action: 'Delete Item',
			Type: 'Access',
			Field: 'AllowRemove',
			Order: 1,
			Reason: 'MissingPermission',
			Message: 'You do not have permissions to perform this action.',
		},
		{
			Object: 'Purchase Order Item',
			Action: 'Delete Item',
			Type: 'Access',
			Field: 'AllowRemove',
			Order: 2,
			Reason: 'IsRemoved',
			Message: 'Deleted items cannot be deleted.',
		},
		{
			Object: 'Purchase Order Item',
			Action: 'Delete Item',
			Type: 'Access',
			Field: 'AllowRemove',
			Order: 3,
			Reason: 'IncorrectType',
			Message: 'Items cannot be deleted on ${purchaseOrderTypeName} purchase orders.',
		},
		{
			Object: 'Purchase Order Item',
			Action: 'Delete Item',
			Type: 'Access',
			Field: 'AllowRemove',
			Order: 4,
			Reason: 'IncorrectStatus',
			Message: 'Items cannot be deleted when the purchase order is in ${purchaseOrderStatusName} status.',
		},
		{
			Object: 'Purchase Order Item',
			Action: 'Delete Item',
			Type: 'Access',
			Field: 'AllowRemove',
			Order: 5,
			Reason: 'IsInvoiced',
			Message: 'Invoiced items cannot be deleted.',
		},
		{
			Object: 'Purchase Order Item',
			Action: 'Delete Item',
			Type: 'Access',
			Field: 'AllowRemove',
			Order: 6,
			Reason: 'IsDefected',
			Message: 'Defected items cannot be deleted.',
		},
		{
			Object: 'Purchase Order Item',
			Action: 'Edit Item',
			Type: 'Access',
			Field: 'AllowEdit',
			Order: 1,
			Reason: 'MissingPermission',
			Message: 'You do not have permissions to perform this action.',
		},
		{
			Object: 'Purchase Order Item',
			Action: 'Edit Item',
			Type: 'Access',
			Field: 'AllowEdit',
			Order: 2,
			Reason: 'IsRemoved',
			Message: 'Deleted items cannot be edited.',
		},
		{
			Object: 'Purchase Order Item',
			Action: 'Edit Item',
			Type: 'Access',
			Field: 'AllowEdit',
			Order: 3,
			Reason: 'IncorrectType',
			Message: 'Items cannot be edited on ${purchaseOrderTypeName} purchase orders.',
		},
		{
			Object: 'Purchase Order Item',
			Action: 'Edit Item',
			Type: 'Access',
			Field: 'AllowEdit',
			Order: 4,
			Reason: 'IncorrectStatus',
			Message: 'Items cannot be edited when the purchase order is in ${purchaseOrderStatusName} status.',
		},
		{
			Object: 'Purchase Order Item',
			Action: 'Edit Item',
			Type: 'Access',
			Field: 'AllowEdit',
			Order: 5,
			Reason: 'IsInvoiced',
			Message: 'Invoiced items cannot be edited.',
		},
		{
			Object: 'Purchase Order Item',
			Action: 'Edit Item',
			Type: 'Access',
			Field: 'AllowEdit',
			Order: 6,
			Reason: 'IsDefected',
			System: 'Mobile',
			Message: 'Defected items cannot be edited.',
		},
		{
			Object: 'Purchase Order Item',
			Action: 'Edit Item',
			Type: 'Access',
			Field: 'AllowEdit',
			Order: 7,
			Reason: 'IsQuoted',
			System: 'Mobile',
			Message: 'Quoted items cannot be edited.',
		},
		{
			Object: 'Purchase Order Item',
			Action: 'Edit Item',
			Type: 'Access',
			Field: 'AllowEdit',
			Order: 8,
			Reason: 'HasOverriddenPrice',
			Message: 'You do not have permissions to edit items with an overridden price.',
		},
		{
			Object: 'Purchase Order Item',
			Action: 'Edit Item',
			Type: 'Flag',
			Field: 'AllowEdit',
			Order: 9,
			Reason: 'BlockDefectedItemChanges',
			System: 'Web',
			Message: `Edit Defected Item
Not all fields will be editable.`,
		},
		{
			Object: 'Purchase Order Item',
			Action: 'Edit Item',
			Type: 'Flag',
			Field: 'AllowEdit',
			Order: 10,
			Reason: 'BlockQuotedItemChanges',
			System: 'Web',
			Message: `Edit Quoted Item
Not all fields will be editable.`,
		},
		{
			Object: 'Purchase Order Item Review',
			Action: 'Delete Review',
			Type: 'Access',
			Field: 'AllowRemove',
			Order: 1,
			Reason: 'MissingPermission',
			Message: 'You do not have permissions to perform this action.',
		},
		{
			Object: 'Purchase Order Item Review',
			Action: 'Delete Review',
			Type: 'Access',
			Field: 'AllowRemove',
			Order: 2,
			Reason: 'IncorrectType',
			Message: 'Item reviews belonging to ${purchaseOrderTypeName} purchase orders cannot be deleted.',
		},
		{
			Object: 'Purchase Order Item Review',
			Action: 'Delete Review',
			Type: 'Access',
			Field: 'AllowRemove',
			Order: 3,
			Reason: 'IncorrectStatus',
			Message: 'Item reviews cannot be deleted if they have been sent.',
		},
		{
			Object: 'Purchase Order Item Review',
			Action: 'Edit Review',
			Type: 'Access',
			Field: 'AllowEdit',
			Order: 1,
			Reason: 'MissingPermission',
			Message: 'You do not have permissions to perform this action.',
		},
		{
			Object: 'Purchase Order Item Review',
			Action: 'Edit Review',
			Type: 'Access',
			Field: 'AllowEdit',
			Order: 2,
			Reason: 'IncorrectType',
			Message: 'Item reviews belonging to ${purchaseOrderTypeName} purchase orders cannot be edited.',
		},
		{
			Object: 'Purchase Order Item Review',
			Action: 'Edit Review',
			Type: 'Access',
			Field: 'AllowEdit',
			Order: 3,
			Reason: 'IncorrectStatus',
			Message: 'Item reviews cannot be edited if they have been sent.',
		},
		{
			Object: 'Purchase Order Item Review',
			Action: 'Withdraw Review',
			Type: 'Access',
			Field: 'AllowDefectWithdraw',
			Order: 1,
			Reason: 'MissingPermission',
			Message: 'You do not have permissions to perform this action.',
		},
		{
			Object: 'Purchase Order Item Review',
			Action: 'Withdraw Review',
			Type: 'Access',
			Field: 'AllowDefectWithdraw',
			Order: 2,
			Reason: 'IncorrectType',
			Message: 'Item reviews belonging to ${purchaseOrderTypeName} purchase orders cannot be withdrawn.',
		},
		{
			Object: 'Purchase Order Item Review',
			Action: 'Withdraw Review',
			Type: 'Access',
			Field: 'AllowDefectWithdraw',
			Order: 3,
			Reason: 'IncorrectStatus',
			Message: 'Item reviews cannot be withdrawn if they have not been sent or already withdrawn.',
		},
	],
	Scope: [
		{
			Object: 'Scope',
			Action: 'View List',
			Type: 'Permission',
			Field: 'SCOPE_VIEW',
			Note: 'hide the sidebar menu / front page tile if missing the permission',
		},
		{
			Object: 'Scope',
			Action: 'Create',
			Type: 'Permission',
			Field: 'SCOPE_CREATE',
			Reason: 'MissingPermission',
			Message: 'You do not have permissions to perform this action.',
		},
		{
			Object: 'Scope',
			Action: 'Cancel',
			Type: 'Access',
			Field: 'AllowRemove',
			Order: 1,
			Reason: 'MissingPermission',
			Message: 'You do not have permissions to perform this action.',
		},
		{
			Object: 'Scope',
			Action: 'Cancel',
			Type: 'Access',
			Field: 'AllowRemove',
			Order: 2,
			Reason: 'IncorrectStatus',
			Message: 'Cancelled scopes cannot be cancelled.',
		},
		{
			Object: 'Scope',
			Action: 'Flow to Quote',
			Type: 'Access',
			Field: 'AllowFlowToQuote',
			Order: 1,
			Reason: 'MissingPermission',
			Message: 'You do not have permissions to perform this action.',
		},
		{
			Object: 'Scope',
			Action: 'Flow to Quote',
			Type: 'Access',
			Field: 'AllowFlowToQuote',
			Order: 2,
			Reason: 'IncorrectStatus',
			Message: 'Cancelled scopes cannot be flowed through to a new quote request.',
		},
		{
			Object: 'Scope',
			Action: 'Flow to Work Order',
			Type: 'Access',
			Field: 'AllowFlowToWorkOrder',
			Order: 1,
			Reason: 'MissingPermission',
			Message: 'You do not have permissions to perform this action.',
		},
		{
			Object: 'Scope',
			Action: 'Flow to Work Order',
			Type: 'Access',
			Field: 'AllowFlowToWorkOrder',
			Order: 2,
			Reason: 'IncorrectStatus',
			Message: 'Cancelled scopes cannot be flowed through to a new work order.',
		},
		{
			Object: 'Scope',
			Action: 'Flow to Invoice',
			Type: 'Access',
			Field: 'AllowFlowToInvoice',
			Order: 1,
			Reason: 'MissingPermission',
			Message: 'You do not have permissions to perform this action.',
		},
		{
			Object: 'Scope',
			Action: 'Add Item',
			Type: 'Access',
			Field: 'AllowAddItem',
			Order: 1,
			Reason: 'MissingPermission',
			Message: 'You do not have permissions to perform this action.',
		},
		{
			Object: 'Scope',
			Action: 'Send Changes',
			Type: 'Access',
			Field: 'AllowSend',
			Order: 1,
			Reason: 'MissingPermission',
			Message: 'You do not have permissions to perform this action.',
		},
		{
			Object: 'Scope',
			Action: 'Edit',
			Type: 'Access',
			Field: 'AllowEdit',
			Order: 1,
			Reason: 'MissingPermission',
			Message: 'You do not have permissions to perform this action.',
		},
		{
			Object: 'Scope',
			Action: 'Edit Flags',
			Type: 'Access',
			Field: 'AllowFlagEdit',
			Order: 1,
			Reason: 'MissingPermission',
			Message: 'You do not have permissions to perform this action.',
		},
		{
			Object: 'Scope',
			Action: 'Review',
			Type: 'Access',
			Field: 'AllowReview',
			Order: 1,
			Reason: 'MissingPermission',
			System: 'Mobile',
			Message: 'You do not have permissions to perform this action.',
		},
		{
			Object: 'Scope',
			Action: 'View Work Plan',
			Type: 'Access',
			Field: 'AllowViewPlan',
			Order: 1,
			Reason: 'MissingPermission',
			Message: 'You do not have permissions to perform this action.',
		},
		{
			Object: 'Scope',
			Action: 'View Work Plan',
			Type: 'Access',
			Field: 'AllowViewPlan',
			Order: 2,
			Reason: 'MissingDates',
			Message: 'The planner for this scope cannot be viewed as it does not have a scheduled start or due date.',
		},
		{
			Object: 'Scope',
			Action: 'View Work Plan',
			Type: 'Access',
			Field: 'AllowViewPlan',
			Order: 3,
			Reason: 'IncorrectStatus',
			Message: 'The planner for this scope cannot be viewed as it is in ${statusName} status.',
		},
		{
			Object: 'Scope',
			Action: 'Created',
			Type: 'Access',
			Field: 'AllowCreated',
			Order: 1,
			Reason: 'MissingPermission',
			Message: 'You do not have permissions to perform this action.',
		},
		{
			Object: 'Scope',
			Action: 'Created',
			Type: 'Access',
			Field: 'AllowCreated',
			Order: 2,
			Reason: 'IncorrectStatus',
			Message: 'Scopes in ${statusName} status cannot be created.',
		},
		{
			Object: 'Scope',
			Action: 'Allocated',
			Type: 'Access',
			Field: 'AllowAllocated',
			Order: 1,
			Reason: 'MissingPermission',
			Message: 'You do not have permissions to perform this action.',
		},
		{
			Object: 'Scope',
			Action: 'Allocated',
			Type: 'Access',
			Field: 'AllowAllocated',
			Order: 2,
			Reason: 'IncorrectStatus',
			Message: 'Scopes in ${statusName} status cannot be allocated.',
		},
		{
			Object: 'Scope',
			Action: 'DataEntryRequired',
			Type: 'Access',
			Field: 'AllowDataEntryRequired',
			Order: 1,
			Reason: 'MissingPermission',
			Message: 'You do not have permissions to perform this action.',
		},
		{
			Object: 'Scope',
			Action: 'DataEntryRequired',
			Type: 'Access',
			Field: 'AllowDataEntryRequired',
			Order: 2,
			Reason: 'IncorrectStatus',
			Message: 'Scopes in ${statusName} status cannot be marked as data entry required.',
		},
		{
			Object: 'Scope',
			Action: 'ReviewRequired',
			Type: 'Access',
			Field: 'AllowReviewRequired',
			Order: 1,
			Reason: 'MissingPermission',
			Message: 'You do not have permissions to perform this action.',
		},
		{
			Object: 'Scope',
			Action: 'ReviewRequired',
			Type: 'Access',
			Field: 'AllowReviewRequired',
			Order: 2,
			Reason: 'IncorrectStatus',
			Message: 'Scopes in ${statusName} status cannot be marked as review required.',
		},
		{
			Object: 'Scope',
			Action: 'Paused',
			Type: 'Access',
			Field: 'AllowPaused',
			Order: 1,
			Reason: 'MissingPermission',
			Message: 'You do not have permissions to perform this action.',
		},
		{
			Object: 'Scope',
			Action: 'Paused',
			Type: 'Access',
			Field: 'AllowPaused',
			Order: 2,
			Reason: 'IncorrectStatus',
			Message: 'Scopes in ${statusName} status cannot be paused.',
		},
		{
			Object: 'Scope Item',
			Action: 'Edit',
			Type: 'Access',
			Field: 'AllowEdit',
			Order: 1,
			Reason: 'MissingPermission',
			Message: 'You do not have permissions to perform this action.',
		},
		{
			Object: 'Scope Item',
			Action: 'Edit',
			Type: 'Access',
			Field: 'AllowEdit',
			Order: 2,
			Reason: 'IsRemoved',
			Message: 'Deleted items cannot be edited.',
		},
		{
			Object: 'Scope Item',
			Action: 'Delete',
			Type: 'Access',
			Field: 'AllowRemove',
			Order: 1,
			Reason: 'MissingPermission',
			Message: 'You do not have permissions to perform this action.',
		},
		{
			Object: 'Scope Item',
			Action: 'Delete',
			Type: 'Access',
			Field: 'AllowRemove',
			Order: 2,
			Reason: 'IsRemoved',
			Message: 'Deleted items cannot be deleted.',
		},
		{
			Object: 'Scope Item',
			Action: 'Review',
			Type: 'Access',
			Field: 'AllowReview',
			Order: 1,
			Reason: 'MissingPermission',
			Message: 'You do not have permissions to perform this action.',
		},
		{
			Object: 'Scope Item',
			Action: 'Review',
			Type: 'Access',
			Field: 'AllowReview',
			Order: 2,
			Reason: 'IsRemoved',
			Message: 'Deleted items cannot be reviewed.',
		},
		{
			Object: 'Scope Item',
			Action: 'Review',
			Type: 'Access',
			Field: 'AllowReview',
			Order: 3,
			Reason: 'IsNew',
			Message: 'Newly added items cannot be reviewed.',
		},
	],
	Quote: [
		{
			Object: 'Quote Request',
			Action: 'View List',
			Type: 'Permission',
			Field: 'QUOTE_VIEW',
			Note: 'hide the sidebar menu / front page tile if missing the permission',
		},
		{
			Object: 'Quote Request',
			Action: 'Create',
			Type: 'Permission',
			Field: 'QUOTE_CREATE',
			Reason: 'MissingPermission',
			Message: 'You do not have permissions to perform this action.',
		},
		{
			Object: 'Quote Request',
			Action: 'Cancel',
			Type: 'Access',
			Field: 'AllowRemove',
			Order: 1,
			Reason: 'MissingPermission',
			Message: 'You do not have permissions to perform this action.',
		},
		{
			Object: 'Quote Request',
			Action: 'Cancel',
			Type: 'Access',
			Field: 'AllowRemove',
			Order: 2,
			Reason: 'IncorrectStatus',
			Message: 'Cancelled quote requests cannot be cancelled.',
		},
		{
			Object: 'Quote Request',
			Action: 'Flow to Work Order',
			Type: 'Access',
			Field: 'AllowFlowToWorkOrder',
			Order: 1,
			Reason: 'MissingPermission',
			Message: 'You do not have permissions to perform this action.',
		},
		{
			Object: 'Quote Request',
			Action: 'Flow to Work Order',
			Type: 'Access',
			Field: 'AllowFlowToWorkOrder',
			Order: 2,
			Reason: 'IncorrectStatus',
			Message: 'Cancelled quote requests cannot be flowed through to a new work order.',
		},
		{
			Object: 'Quote Request',
			Action: 'Flow to Invoice',
			Type: 'Access',
			Field: 'AllowFlowToInvoice',
			Order: 1,
			Reason: 'MissingPermission',
			Message: 'You do not have permissions to perform this action.',
		},
		{
			Object: 'Quote Request',
			Action: 'Add Item',
			Type: 'Access',
			Field: 'AllowAddItem',
			Order: 1,
			Reason: 'MissingPermission',
			Message: 'You do not have permissions to perform this action.',
		},
		{
			Object: 'Quote Request',
			Action: 'Send Changes',
			Type: 'Access',
			Field: 'AllowSend',
			Order: 1,
			Reason: 'MissingPermission',
			Message: 'You do not have permissions to perform this action.',
		},
		{
			Object: 'Quote Request',
			Action: 'Edit',
			Type: 'Access',
			Field: 'AllowEdit',
			Order: 1,
			Reason: 'MissingPermission',
			Message: 'You do not have permissions to perform this action.',
		},
		{
			Object: 'Quote Request',
			Action: 'Edit Flags',
			Type: 'Access',
			Field: 'AllowFlagEdit',
			Order: 1,
			Reason: 'MissingPermission',
			Message: 'You do not have permissions to perform this action.',
		},
		{
			Object: 'Quote Request',
			Action: 'Add Recipient',
			Type: 'Access',
			Field: 'AllowAddRecipient',
			Order: 1,
			Reason: 'MissingPermission',
			System: 'Web',
			Message: 'You do not have permissions to perform this action.',
		},
		{
			Object: 'Quote Request',
			Action: 'View Work Plan',
			Type: 'Access',
			Field: 'AllowViewPlan',
			Order: 1,
			Reason: 'MissingPermission',
			Message: 'You do not have permissions to perform this action.',
		},
		{
			Object: 'Quote Request',
			Action: 'View Work Plan',
			Type: 'Access',
			Field: 'AllowViewPlan',
			Order: 2,
			Reason: 'MissingDates',
			Message: 'The planner for this quote cannot be viewed as it does not have a scheduled start or due date.',
		},
		{
			Object: 'Quote Request',
			Action: 'View Work Plan',
			Type: 'Access',
			Field: 'AllowViewPlan',
			Order: 3,
			Reason: 'IncorrectStatus',
			Message: 'The planner for this quote cannot be viewed as it is in ${statusName} status.',
		},
		{
			Object: 'Quote Request',
			Action: 'Created',
			Type: 'Access',
			Field: 'AllowCreated',
			Order: 1,
			Reason: 'MissingPermission',
			Message: 'You do not have permissions to perform this action.',
		},
		{
			Object: 'Quote Request',
			Action: 'Created',
			Type: 'Access',
			Field: 'AllowCreated',
			Order: 2,
			Reason: 'IncorrectStatus',
			Message: 'Created quotes cannot be created.',
		},
		{
			Object: 'Quote Request',
			Action: 'Sent',
			Type: 'Access',
			Field: 'AllowSent',
			Order: 1,
			Reason: 'MissingPermission',
			Message: 'You do not have permissions to perform this action.',
		},
		{
			Object: 'Quote Request',
			Action: 'Sent',
			Type: 'Access',
			Field: 'AllowSent',
			Order: 2,
			Reason: 'IncorrectStatus',
			Message: 'Sent quotes cannot be sent.',
		},
		{
			Object: 'Quote Request',
			Action: 'Received',
			Type: 'Access',
			Field: 'AllowReceived',
			Order: 1,
			Reason: 'MissingPermission',
			Message: 'You do not have permissions to perform this action.',
		},
		{
			Object: 'Quote Request',
			Action: 'Received',
			Type: 'Access',
			Field: 'AllowReceived',
			Order: 2,
			Reason: 'IncorrectStatus',
			Message: 'Received quotes cannot be received.',
		},
		{
			Object: 'Quote Request',
			Action: 'ReviewRequired',
			Type: 'Access',
			Field: 'AllowReviewRequired',
			Order: 1,
			Reason: 'MissingPermission',
			Message: 'You do not have permissions to perform this action.',
		},
		{
			Object: 'Quote Request',
			Action: 'ReviewRequired',
			Type: 'Access',
			Field: 'AllowReviewRequired',
			Order: 2,
			Reason: 'IncorrectStatus',
			Message: 'Review required quotes cannot be marked as review required.',
		},
		{
			Object: 'Quote Request',
			Action: 'Paused',
			Type: 'Access',
			Field: 'AllowPaused',
			Order: 1,
			Reason: 'MissingPermission',
			Message: 'You do not have permissions to perform this action.',
		},
		{
			Object: 'Quote Request',
			Action: 'Paused',
			Type: 'Access',
			Field: 'AllowPaused',
			Order: 2,
			Reason: 'IncorrectStatus',
			Message: 'Paused quotes cannot be paused.',
		},
		{
			Object: 'Quote Request',
			Action: 'Unsuccessful',
			Type: 'Access',
			Field: 'AllowUnsuccessful',
			Order: 1,
			Reason: 'MissingPermission',
			Message: 'You do not have permissions to perform this action.',
		},
		{
			Object: 'Quote Request',
			Action: 'Unsuccessful',
			Type: 'Access',
			Field: 'AllowUnsuccessful',
			Order: 2,
			Reason: 'IncorrectStatus',
			Message: 'Unsuccessful quotes cannot be marked as unsuccessful.',
		},
		{
			Object: 'Quote Request Item',
			Action: 'Edit',
			Type: 'Access',
			Field: 'AllowEdit',
			Order: 1,
			Reason: 'MissingPermission',
			Message: 'You do not have permissions to perform this action.',
		},
		{
			Object: 'Quote Request Item',
			Action: 'Edit',
			Type: 'Access',
			Field: 'AllowEdit',
			Order: 2,
			Reason: 'IsRemoved',
			Message: 'Deleted items cannot be edited.',
		},
		{
			Object: 'Quote Request Item',
			Action: 'Delete',
			Type: 'Access',
			Field: 'AllowRemove',
			Order: 1,
			Reason: 'MissingPermission',
			Message: 'You do not have permissions to perform this action.',
		},
		{
			Object: 'Quote Request Item',
			Action: 'Delete',
			Type: 'Access',
			Field: 'AllowRemove',
			Order: 2,
			Reason: 'IsRemoved',
			Message: 'Deleted items cannot be deleted.',
		},
		{
			Object: 'Quote Request Item',
			Action: 'Add/Edit Cost Price',
			Type: 'Access',
			Field: 'AllowAddCostPrice',
			Order: 1,
			Reason: 'MissingPermission',
			System: 'Mobile',
			Message: 'You do not have permissions to perform this action.',
		},
		{
			Object: 'Quote Request Item',
			Action: 'Add/Edit Cost Price',
			Type: 'Access',
			Field: 'AllowAddCostPrice',
			Order: 2,
			Reason: 'IsRemoved',
			System: 'Mobile',
			Message: 'Cost prices cannot be added to deleted items.',
		},
		{
			Object: 'Quote Request Recipient',
			Action: 'Edit',
			Type: 'Access',
			Field: 'AllowEdit',
			Order: 1,
			Reason: 'MissingPermission',
			Message: 'You do not have permissions to perform this action.',
		},
		{
			Object: 'Quote Request Item Response',
			Action: 'Edit',
			Type: 'Access',
			Field: 'AllowEdit',
			Order: 1,
			Reason: 'MissingPermission',
			System: 'Mobile',
			Message: 'You do not have permissions to perform this action.',
		},
		{
			Object: 'Quote Request Item Response',
			Action: 'Edit',
			Type: 'Access',
			Field: 'AllowEdit',
			Order: 2,
			Reason: 'IsRemoved',
			System: 'Mobile',
			Message: 'Deleted cost prices cannot be edited.',
		},
	],
	Invoice: [
		{
			Object: 'Invoice',
			Action: 'View List',
			Type: 'Permission',
			Field: 'INVOICE_VIEW',
			Note: 'hide the sidebar menu / front page tile if missing the permission',
		},
		{
			Object: 'Invoice',
			Action: 'Create',
			Type: 'Permission',
			Field: 'INVOICE_CREATE',
			Reason: 'MissingPermission',
			Message: 'You do not have permissions to perform this action.',
		},
		{
			Object: 'Invoice',
			Action: 'Edit Invoice',
			Type: 'Access',
			Field: 'AllowEdit',
			Order: 1,
			Reason: 'MissingPermission',
			Message: 'You do not have permissions to perform this action.',
		},
		{
			Object: 'Invoice',
			Action: 'Edit Invoice',
			Type: 'Access',
			Field: 'AllowEdit',
			Order: 2,
			Reason: 'IncorrectStatus',
			Message: 'Invoices cannot be edited when the invoice is in status ${statusName}.',
		},
		{
			Object: 'Invoice',
			Action: 'Cancel',
			Type: 'Access',
			Field: 'AllowRemove',
			Order: 1,
			Reason: 'MissingPermission',
			Message: 'You do not have permissions to perform this action.',
		},
		{
			Object: 'Invoice',
			Action: 'Cancel',
			Type: 'Access',
			Field: 'AllowRemove',
			Order: 2,
			Reason: 'IncorrectStatus',
			Message: 'The ${actionName} action cannot be performed when the invoice is in status ${statusName}.',
			Note: 'hide from details page when status incorrect',
		},
		{
			Object: 'Invoice',
			Action: 'Approve',
			Type: 'Access',
			Field: 'AllowApprove',
			Order: 1,
			Reason: 'MissingPermission',
			Message: 'You do not have permissions to perform this action.',
		},
		{
			Object: 'Invoice',
			Action: 'Approve',
			Type: 'Access',
			Field: 'AllowApprove',
			Order: 2,
			Reason: 'IncorrectStatus',
			Message: 'The ${actionName} action cannot be performed when the invoice is in status ${statusName}.',
			Note: 'hide from details page when status incorrect',
		},
		{
			Object: 'Invoice',
			Action: 'Paid',
			Type: 'Access',
			Field: 'AllowPaid',
			Order: 1,
			Reason: 'MissingPermission',
			Message: 'You do not have permissions to perform this action.',
		},
		{
			Object: 'Invoice',
			Action: 'Paid',
			Type: 'Access',
			Field: 'AllowPaid',
			Order: 2,
			Reason: 'IncorrectStatus',
			Message: 'The ${actionName} action cannot be performed when the invoice is in status ${statusName}.',
			Note: 'hide from details page when status incorrect',
		},
		{
			Object: 'Invoice',
			Action: 'Paid',
			Type: 'Access',
			Field: 'AllowPaid',
			Order: 3,
			Reason: 'IncorrectType',
			Message: 'The ${actionName} action cannot be performed on credit notes.',
		},
		{
			Object: 'Invoice',
			Action: 'Paid',
			Type: 'Access',
			Field: 'AllowPaid',
			Order: 4,
			Reason: 'IncorrectConfig',
			Message: 'The current subscriber configuration does not allow ${actionName} action to be manually called.',
		},
		{
			Object: 'Invoice',
			Action: 'Hold',
			Type: 'Access',
			Field: 'AllowHold',
			Order: 1,
			Reason: 'MissingPermission',
			Message: 'You do not have permissions to perform this action.',
		},
		{
			Object: 'Invoice',
			Action: 'Hold',
			Type: 'Access',
			Field: 'AllowHold',
			Order: 2,
			Reason: 'IncorrectStatus',
			Message: 'The ${actionName} action cannot be performed when the invoice is in status ${statusName}.',
			Note: 'hide from details page when status incorrect',
		},
		{
			Object: 'Invoice',
			Action: 'Hold',
			Type: 'Access',
			Field: 'AllowHold',
			Order: 3,
			Reason: 'IncorrectType',
			Message: 'The ${actionName} action cannot be performed on credit notes.',
		},
		{
			Object: 'Invoice',
			Action: 'Release',
			Type: 'Access',
			Field: 'AllowRelease',
			Order: 1,
			Reason: 'MissingPermission',
			Message: 'You do not have permissions to perform this action.',
		},
		{
			Object: 'Invoice',
			Action: 'Release',
			Type: 'Access',
			Field: 'AllowRelease',
			Order: 2,
			Reason: 'IncorrectStatus',
			Message: 'The ${actionName} action cannot be performed when the invoice is in status ${statusName}.',
			Note: 'hide from details page when status incorrect',
		},
		{
			Object: 'Invoice',
			Action: 'Release',
			Type: 'Access',
			Field: 'AllowRelease',
			Order: 3,
			Reason: 'IncorrectType',
			Message: 'The ${actionName} action cannot be performed on credit notes.',
		},
		{
			Object: 'Invoice',
			Action: 'Add Credit Note',
			Type: 'Access',
			Field: 'AllowAddCreditNote',
			Order: 1,
			Reason: 'MissingPermission',
			Message: 'You do not have permissions to perform this action.',
		},
		{
			Object: 'Invoice',
			Action: 'Add Credit Note',
			Type: 'Access',
			Field: 'AllowAddCreditNote',
			Order: 2,
			Reason: 'IncorrectType',
			Message: 'Credit notes cannot be applied to credit notes.',
		},
		{
			Object: 'Invoice',
			Action: 'Add Credit Note',
			Type: 'Access',
			Field: 'AllowAddCreditNote',
			Order: 3,
			Reason: 'IncorrectStatus',
			Message: 'Credit notes cannot be applied against an invoice in ${statusName} status.',
		},
		{
			Object: 'Invoice',
			Action: 'Add Credit Note',
			Type: 'Access',
			Field: 'AllowAddCreditNote',
			Order: 4,
			Reason: 'IsInvoiced',
			Message: 'Credit note cannot be applied when the invoice had already been fully credited.',
		},
		{
			Object: 'Invoice',
			Action: 'Void',
			Type: 'Access',
			Field: 'AllowVoid',
			Order: 1,
			Reason: 'MissingPermission',
			Message: 'You do not have permissions to perform this action.',
		},
		{
			Object: 'Invoice',
			Action: 'Void',
			Type: 'Access',
			Field: 'AllowVoid',
			Order: 2,
			Reason: 'IncorrectType',
			Message: 'Only amount credit notes can be voided.',
		},
		{
			Object: 'Invoice',
			Action: 'Void',
			Type: 'Access',
			Field: 'AllowVoid',
			Order: 3,
			Reason: 'IncorrectStatus',
			Message: 'The Void action cannot be performed when the invoice is in status ${statusName}.',
		},
		{
			Object: 'Invoice',
			Action: 'Send',
			Type: 'Access',
			Field: 'AllowSend',
			Order: 1,
			Reason: 'MissingPermission',
			Message: 'You do not have permissions to perform this action.',
		},
		{
			Object: 'Invoice',
			Action: 'Send',
			Type: 'Access',
			Field: 'AllowSend',
			Order: 2,
			Reason: 'IncorrectStatus',
			Message: 'Invoices cannot be sent when the invoice is in status ${statusName}.',
		},
		{
			Object: 'Invoice',
			Action: 'Edit Flags',
			Type: 'Access',
			Field: 'AllowFlagEdit',
			Order: 1,
			Reason: 'MissingPermission',
			Message: 'You do not have permissions to perform this action.',
		},
		{
			Object: 'Invoice',
			Action: 'Edit Flags',
			Type: 'Access',
			Field: 'AllowFlagEdit',
			Order: 2,
			Reason: 'IncorrectStatus',
			Message: 'Invoices cannot be edited when the invoice is in status ${statusName}.',
		},
		{
			Object: 'Invoice',
			Action: 'Add Item',
			Type: 'Access',
			Field: 'AllowAddItem',
			Order: 1,
			Reason: 'MissingPermission',
			Message: 'You do not have permissions to perform this action.',
		},
		{
			Object: 'Invoice',
			Action: 'Add Item',
			Type: 'Access',
			Field: 'AllowAddItem',
			Order: 2,
			Reason: 'IncorrectType',
			Message: 'Items cannot be added to ${invoiceTypeName} ${invoiceSubtypeName} invoices.',
		},
		{
			Object: 'Invoice',
			Action: 'Add Item',
			Type: 'Access',
			Field: 'AllowAddItem',
			Order: 3,
			Reason: 'IncorrectStatus',
			Message: 'Items cannot be added when the invoice is in ${statusName} status.',
		},
		{
			Object: 'Invoice',
			Action: 'Add Payment',
			Type: 'Access',
			Field: 'AllowAddPayment',
			Order: 1,
			Reason: 'MissingPermission',
			Message: 'You do not have permissions to perform this action.',
		},
		{
			Object: 'Invoice',
			Action: 'Add Payment',
			Type: 'Access',
			Field: 'AllowAddPayment',
			Order: 2,
			Reason: 'IncorrectType',
			Message: 'Payments cannot be added to credit notes.',
		},
		{
			Object: 'Invoice',
			Action: 'Add Payment',
			Type: 'Access',
			Field: 'AllowAddPayment',
			Order: 3,
			Reason: 'IncorrectStatus',
			Message: 'Payments cannot be added when the invoice is in ${statusName} status.',
		},
		{
			Object: 'Invoice',
			Action: 'Add Payment',
			Type: 'Access',
			Field: 'AllowAddPayment',
			Order: 4,
			Reason: 'IncorrectConfig',
			Message: 'The current subscriber configuration does not allow payments to be manually added.',
		},
		{
			Object: 'Invoice Item',
			Action: 'Delete Item',
			Type: 'Access',
			Field: 'AllowRemove',
			Order: 1,
			Reason: 'MissingPermission',
			Message: 'You do not have permissions to perform this action.',
		},
		{
			Object: 'Invoice Item',
			Action: 'Delete Item',
			Type: 'Access',
			Field: 'AllowRemove',
			Order: 2,
			Reason: 'IsRemoved',
			Message: 'Deleted items cannot be deleted.',
		},
		{
			Object: 'Invoice Item',
			Action: 'Delete Item',
			Type: 'Access',
			Field: 'AllowRemove',
			Order: 3,
			Reason: 'IncorrectType',
			Message: 'Items cannot be deleted on ${invoiceTypeName} ${invoiceSubtypeName} invoices.',
		},
		{
			Object: 'Invoice Item',
			Action: 'Delete Item',
			Type: 'Access',
			Field: 'AllowRemove',
			Order: 4,
			Reason: 'IncorrectStatus',
			Message: 'Items cannot be deleted when the invoice is in ${invoiceStatusName} status.',
		},
		{
			Object: 'Invoice Item',
			Action: 'Edit Item',
			Type: 'Access',
			Field: 'AllowEdit',
			Order: 1,
			Reason: 'MissingPermission',
			Message: 'You do not have permissions to perform this action.',
		},
		{
			Object: 'Invoice Item',
			Action: 'Edit Item',
			Type: 'Access',
			Field: 'AllowEdit',
			Order: 2,
			Reason: 'IsRemoved',
			Message: 'Deleted items cannot be edited.',
		},
		{
			Object: 'Invoice Item',
			Action: 'Edit Item',
			Type: 'Access',
			Field: 'AllowEdit',
			Order: 3,
			Reason: 'IncorrectType',
			Message: 'Items cannot be edited on ${invoiceTypeName} ${invoiceSubtypeName} invoices.',
		},
		{
			Object: 'Invoice Item',
			Action: 'Edit Item',
			Type: 'Access',
			Field: 'AllowEdit',
			Order: 4,
			Reason: 'IncorrectStatus',
			Message: 'Items cannot be edited when the invoice is in ${invoiceStatusName} status.',
		},
		{
			Object: 'Invoice Payment',
			Action: 'Delete Payment',
			Type: 'Access',
			Field: 'AllowRemove',
			Order: 1,
			Reason: 'MissingPermission',
			Message: 'You do not have permissions to perform this action.',
		},
		{
			Object: 'Invoice Payment',
			Action: 'Delete Payment',
			Type: 'Access',
			Field: 'AllowRemove',
			Order: 2,
			Reason: 'IsRemoved',
			Message: 'Deleted payments cannot be deleted.',
		},
		{
			Object: 'Invoice Payment',
			Action: 'Delete Payment',
			Type: 'Access',
			Field: 'AllowRemove',
			Order: 3,
			Reason: 'IncorrectConfig',
			Message: 'The current subscriber configuration does not allow payments to be manually deleted.',
		},
		{
			Object: 'Invoice Issue',
			Action: 'Edit Issue',
			Type: 'Access',
			Field: 'AllowRemove',
			Order: 1,
			Reason: 'MissingPermission',
			Message: 'You do not have permissions to perform this action.',
		},
		{
			Object: 'Invoice Issue',
			Action: 'Edit Issue',
			Type: 'Access',
			Field: 'AllowRemove',
			Order: 2,
			Reason: 'IsRemoved',
			Message: 'Deleted issues cannot be edited.',
		},
	],
	Configuration: [
		{
			Object: 'Asset',
			Action: 'View List',
			Type: 'Permission',
			Field: 'ASSET_VIEW',
			Note: 'hide the sidebar menu / front page tile if missing the permission',
		},
		{
			Object: 'Asset',
			Action: 'Create',
			Type: 'Permission',
			Field: 'ASSET_UPDATE',
			Reason: 'MissingPermission',
			Message: 'You do not have permissions to perform this action.',
		},
		{
			Object: 'Asset',
			Action: 'Edit',
			Type: 'Access',
			Field: 'AllowEdit',
			Order: 1,
			Reason: 'MissingPermission',
			Message: 'You do not have permissions to perform this action.',
		},
		{
			Object: 'Asset',
			Action: 'Edit',
			Type: 'Access',
			Field: 'AllowEdit',
			Order: 2,
			Reason: 'IsRemoved',
			Message: 'Deleted assets cannot be edited.',
		},
		{
			Object: 'Asset',
			Action: 'Edit',
			Type: 'Flag',
			Field: 'AllowEdit',
			Order: 3,
			Reason: 'BlockQrBarcodeEditing',
			System: 'Web',
			Message: 'Editing of QR and barcodes is currently disabled.',
		},
		{
			Object: 'Asset',
			Action: 'Delete',
			Type: 'Access',
			Field: 'AllowRemove',
			Order: 1,
			Reason: 'MissingPermission',
			Message: 'You do not have permissions to perform this action.',
		},
		{
			Object: 'Asset',
			Action: 'Delete',
			Type: 'Access',
			Field: 'AllowRemove',
			Order: 2,
			Reason: 'IsRemoved',
			Message: 'Deleted assets cannot be deleted.',
		},
		{
			Object: 'Asset',
			Action: 'Flow to Scope',
			Type: 'Access',
			Field: 'AllowFlowToScope',
			Order: 1,
			Reason: 'MissingPermission',
			Message: 'You do not have permissions to perform this action.',
		},
		{
			Object: 'Asset',
			Action: 'Flow to Scope',
			Type: 'Access',
			Field: 'AllowFlowToScope',
			Order: 2,
			Reason: 'IsRemoved',
			Message: 'Scopes cannot be created on deleted assets.',
		},
		{
			Object: 'Asset',
			Action: 'Flow to Quote',
			Type: 'Access',
			Field: 'AllowFlowToQuote',
			Order: 1,
			Reason: 'MissingPermission',
			Message: 'You do not have permissions to perform this action.',
		},
		{
			Object: 'Asset',
			Action: 'Flow to Quote',
			Type: 'Access',
			Field: 'AllowFlowToQuote',
			Order: 2,
			Reason: 'IsRemoved',
			Message: 'Quotes cannot be created on deleted assets.',
		},
		{
			Object: 'Asset',
			Action: 'Flow to Work Order ',
			Type: 'Access',
			Field: 'AllowFlowToWorkOrder',
			Order: 1,
			Reason: 'MissingPermission',
			Message: 'You do not have permissions to perform this action.',
		},
		{
			Object: 'Asset',
			Action: 'Flow to Work Order',
			Type: 'Access',
			Field: 'AllowFlowToWorkOrder',
			Order: 2,
			Reason: 'IsRemoved',
			Message: 'Work Orders cannot be created on deleted assets.',
		},
		{
			Object: 'Asset',
			Action: 'Flow to Service Request',
			Type: 'Access',
			Field: 'AllowFlowToServiceRequest',
			Order: 1,
			Reason: 'MissingPermission',
			Message: 'You do not have permissions to perform this action.',
		},
		{
			Object: 'Asset',
			Action: 'Flow to Service Request',
			Type: 'Access',
			Field: 'AllowFlowToServiceRequest',
			Order: 2,
			Reason: 'IsRemoved',
			Message: 'Service Requests cannot be created on deleted assets.',
		},
		{
			Object: 'Asset',
			Action: 'View Work Plan',
			Type: 'Access',
			Field: 'AllowViewPlan',
			Order: 1,
			Reason: 'MissingPermission',
			Message: 'You do not have permissions to perform this action.',
		},
		{
			Object: 'Asset',
			Action: 'View Work Plan',
			Type: 'Access',
			Field: 'AllowViewPlan',
			Order: 2,
			Reason: 'IsRemoved',
			Message: 'The planner for this asset cannot be viewed as it has been deleted.',
		},
		{
			Object: 'Asset',
			Action: 'Bulk Print QR Codes',
			Type: 'Access',
			Field: 'AllowPrinting',
			Order: 1,
			Reason: 'MissingCodes',
			Message: 'This asset cannot be printed as it does not have a QR code.',
		},
		{
			Object: 'Rate',
			Action: 'View List',
			Type: 'Permission',
			Field: 'SOR_VIEW',
			Note: 'hide the sidebar menu / front page tile if missing the permission',
		},
		{
			Object: 'Rate',
			Action: 'Create',
			Type: 'Permission',
			Field: 'SOR_UPDATE',
			Reason: 'MissingPermission',
			Message: 'You do not have permissions to perform this action.',
		},
		{
			Object: 'Rate',
			Action: 'Edit',
			Type: 'Access',
			Field: 'AllowEdit',
			Order: 1,
			Reason: 'MissingPermission',
			Message: 'You do not have permissions to perform this action.',
		},
		{
			Object: 'Rate',
			Action: 'Edit',
			Type: 'Access',
			Field: 'AllowEdit',
			Order: 2,
			Reason: 'IsRemoved',
			Message: 'Deleted rates cannot be edited.',
		},
		{
			Object: 'Rate',
			Action: 'Delete',
			Type: 'Access',
			Field: 'AllowRemove',
			Order: 1,
			Reason: 'MissingPermission',
			Message: 'You do not have permissions to perform this action.',
		},
		{
			Object: 'Rate',
			Action: 'Delete',
			Type: 'Access',
			Field: 'AllowRemove',
			Order: 2,
			Reason: 'IsRemoved',
			Message: 'Deleted rates cannot be deleted.',
		},
		{
			Object: 'Rate',
			Action: 'Add Price',
			Type: 'Access',
			Field: 'AllowAddCostPrice',
			Order: 1,
			Reason: 'MissingPermission',
			Message: 'You do not have permissions to perform this action.',
		},
		{
			Object: 'Rate',
			Action: 'Add Price',
			Type: 'Access',
			Field: 'AllowAddCostPrice',
			Order: 2,
			Reason: 'IsRemoved',
			Message: 'Cost prices cannot be added to deleted rates.',
		},
		{
			Object: 'Rate Price',
			Action: 'Edit',
			Type: 'Access',
			Field: 'AllowEdit',
			Order: 1,
			Reason: 'MissingPermission',
			Message: 'You do not have permissions to perform this action.',
		},
		{
			Object: 'Rate Price',
			Action: 'Edit',
			Type: 'Access',
			Field: 'AllowEdit',
			Order: 2,
			Reason: 'IsRemoved',
			Message: 'Deleted cost prices cannot be edited.',
		},
		{
			Object: 'Rate Price',
			Action: 'Delete',
			Type: 'Access',
			Field: 'AllowRemove',
			Order: 1,
			Reason: 'MissingPermission',
			Message: 'You do not have permissions to perform this action.',
		},
		{
			Object: 'Rate Price',
			Action: 'Delete',
			Type: 'Access',
			Field: 'AllowRemove',
			Order: 2,
			Reason: 'IsRemoved',
			Message: 'Deleted cost prices cannot be deleted.',
		},
		{
			Object: 'Company',
			Action: 'View List',
			Type: 'Permission',
			Field: 'COMPANY_VIEW',
			Note: 'hide the sidebar menu / front page tile if missing the permission',
		},
		{
			Object: 'Company',
			Action: 'Create',
			Type: 'Permission',
			Field: 'COMPANY_UPDATE',
			Reason: 'MissingPermission',
			Message: 'You do not have permissions to perform this action.',
		},
		{
			Object: 'Company',
			Action: 'Edit',
			Type: 'Access',
			Field: 'AllowEdit',
			Order: 1,
			Reason: 'MissingPermission',
			Message: 'You do not have permissions to perform this action.',
		},
		{
			Object: 'Client',
			Action: 'View List',
			Type: 'Permission',
			Field: 'CLIENT_VIEW',
			Note: 'hide the sidebar menu / front page tile if missing the permission',
		},
		{
			Object: 'Client',
			Action: 'Create',
			Type: 'Permission',
			Field: 'CLIENT_UPDATE',
			Reason: 'MissingPermission',
			Message: 'You do not have permissions to perform this action.',
		},
		{
			Object: 'Client',
			Action: 'Edit',
			Type: 'Access',
			Field: 'AllowEdit',
			Order: 1,
			Reason: 'MissingPermission',
			Message: 'You do not have permissions to perform this action.',
		},
		{
			Object: 'Contractor',
			Action: 'View List',
			Type: 'Permission',
			Field: 'BUSINESSENTITY_VIEW',
			Note: 'hide the sidebar menu / front page tile if missing the permission',
		},
		{
			Object: 'Contractor',
			Action: 'Create',
			Type: 'Permission',
			Field: 'BUSINESSENTITY_UPDATE',
			Reason: 'MissingPermission',
			Message: 'You do not have permissions to perform this action.',
		},
		{
			Object: 'Contractor',
			Action: 'Edit',
			Type: 'Access',
			Field: 'AllowEdit',
			Order: 1,
			Reason: 'MissingPermission',
			Message: 'You do not have permissions to perform this action.',
		},
		{
			Object: 'Contractor',
			Action: 'Add Insurance',
			Type: 'Access',
			Field: 'AllowAddInsurance',
			Order: 1,
			Reason: 'MissingPermission',
			Message: 'You do not have permissions to perform this action.',
		},
		{
			Object: 'Contractor',
			Action: 'Add Licence',
			Type: 'Access',
			Field: 'AllowAddLicence',
			Order: 1,
			Reason: 'MissingPermission',
			Message: 'You do not have permissions to perform this action.',
		},
		{
			Object: 'Contractor Insurance',
			Action: 'Edit',
			Type: 'Access',
			Field: 'AllowEdit',
			Order: 1,
			Reason: 'MissingPermission',
			Message: 'You do not have permissions to perform this action.',
		},
		{
			Object: 'Contractor Insurance',
			Action: 'Edit',
			Type: 'Access',
			Field: 'AllowEdit',
			Order: 2,
			Reason: 'IsRemoved',
			Message: 'Deleted insurances cannot be edited.',
		},
		{
			Object: 'Contractor Insurance',
			Action: 'Delete',
			Type: 'Access',
			Field: 'AllowRemove',
			Order: 1,
			Reason: 'MissingPermission',
			Message: 'You do not have permissions to perform this action.',
		},
		{
			Object: 'Contractor Insurance',
			Action: 'Delete',
			Type: 'Access',
			Field: 'AllowRemove',
			Order: 2,
			Reason: 'IsRemoved',
			Message: 'Deleted insurances cannot be deleted.',
		},
		{
			Object: 'Contractor Licence',
			Action: 'Edit',
			Type: 'Access',
			Field: 'AllowEdit',
			Order: 1,
			Reason: 'MissingPermission',
			Message: 'You do not have permissions to perform this action.',
		},
		{
			Object: 'Contractor Licence',
			Action: 'Edit',
			Type: 'Access',
			Field: 'AllowEdit',
			Order: 2,
			Reason: 'IsRemoved',
			Message: 'Deleted licences cannot be edited.',
		},
		{
			Object: 'Contractor Licence',
			Action: 'Delete',
			Type: 'Access',
			Field: 'AllowRemove',
			Order: 1,
			Reason: 'MissingPermission',
			Message: 'You do not have permissions to perform this action.',
		},
		{
			Object: 'Contractor Licence',
			Action: 'Delete',
			Type: 'Access',
			Field: 'AllowRemove',
			Order: 2,
			Reason: 'IsRemoved',
			Message: 'Deleted licences cannot be deleted.',
		},
		{
			Object: 'Area',
			Action: 'View List',
			Type: 'Permission',
			Field: 'AREA_VIEW',
			Note: 'hide the sidebar menu / front page tile if missing the permission',
		},
		{
			Object: 'Area',
			Action: 'Create',
			Type: 'Permission',
			Field: 'AREA_UPDATE',
			Reason: 'MissingPermission',
			Message: 'You do not have permissions to perform this action.',
		},
		{
			Object: 'Area',
			Action: 'Edit',
			Type: 'Access',
			Field: 'AllowEdit',
			Order: 1,
			Reason: 'MissingPermission',
			Message: 'You do not have permissions to perform this action.',
		},
		{
			Object: 'Area',
			Action: 'Edit',
			Type: 'Access',
			Field: 'AllowEdit',
			Order: 2,
			Reason: 'IsRemoved',
			Message: 'Deleted areas cannot be edited.',
		},
		{
			Object: 'Area',
			Action: 'Delete',
			Type: 'Access',
			Field: 'AllowRemove',
			Order: 1,
			Reason: 'MissingPermission',
			Message: 'You do not have permissions to perform this action.',
		},
		{
			Object: 'Area',
			Action: 'Delete',
			Type: 'Access',
			Field: 'AllowRemove',
			Order: 2,
			Reason: 'IsRemoved',
			Message: 'Deleted areas cannot be deleted.',
		},
		{
			Object: 'Project',
			Action: 'View List',
			Type: 'Permission',
			Field: 'PROJECT_VIEW',
			Note: 'hide the sidebar menu / front page tile if missing the permission',
		},
		{
			Object: 'Project',
			Action: 'Create',
			Type: 'Permission',
			Field: 'PROJECT_UPDATE',
			Reason: 'MissingPermission',
			Message: 'You do not have permissions to perform this action.',
		},
		{
			Object: 'Project',
			Action: 'Edit',
			Type: 'Access',
			Field: 'AllowEdit',
			Order: 1,
			Reason: 'MissingPermission',
			Message: 'You do not have permissions to perform this action.',
		},
		{
			Object: 'Project',
			Action: 'Edit',
			Type: 'Access',
			Field: 'AllowEdit',
			Order: 2,
			Reason: 'IsRemoved',
			Message: 'Deleted projects cannot be edited.',
		},
		{
			Object: 'Project',
			Action: 'Delete',
			Type: 'Access',
			Field: 'AllowRemove',
			Order: 1,
			Reason: 'MissingPermission',
			Message: 'You do not have permissions to perform this action.',
		},
		{
			Object: 'Project',
			Action: 'Delete',
			Type: 'Access',
			Field: 'AllowRemove',
			Order: 2,
			Reason: 'IsRemoved',
			Message: 'Deleted projects cannot be deleted.',
		},
		{
			Object: 'Field',
			Action: 'View List',
			Type: 'Permission',
			Field: 'DOMAINVALUE_VIEW',
			Note: 'hide the sidebar menu / front page tile if missing the permission',
		},
		{
			Object: 'Field',
			Action: 'Create',
			Type: 'Permission',
			Field: 'DOMAINVALUE_UPDATE',
			Reason: 'MissingPermission',
			Message: 'You do not have permissions to perform this action.',
		},
		{
			Object: 'Field',
			Action: 'Edit',
			Type: 'Access',
			Field: 'AllowEdit',
			Order: 1,
			Reason: 'MissingPermission',
			Message: 'You do not have permissions to perform this action.',
		},
		{
			Object: 'Field',
			Action: 'Edit',
			Type: 'Access',
			Field: 'AllowEdit',
			Order: 2,
			Reason: 'IsRemoved',
			Message: 'Deleted fields cannot be edited.',
		},
		{
			Object: 'Field',
			Action: 'Edit',
			Type: 'Access',
			Field: 'AllowEdit',
			Order: 3,
			Reason: 'IsSystem',
			Message: 'System fields cannot be edited.',
		},
		{
			Object: 'Field',
			Action: 'Delete',
			Type: 'Access',
			Field: 'AllowRemove',
			Order: 1,
			Reason: 'MissingPermission',
			Message: 'You do not have permissions to perform this action.',
		},
		{
			Object: 'Field',
			Action: 'Delete',
			Type: 'Access',
			Field: 'AllowRemove',
			Order: 2,
			Reason: 'IsRemoved',
			Message: 'Deleted fields cannot be deleted.',
		},
		{
			Object: 'Field',
			Action: 'Delete',
			Type: 'Access',
			Field: 'AllowRemove',
			Order: 3,
			Reason: 'IsSystem',
			Message: 'System fields cannot be deleted.',
		},
		{
			Object: 'User',
			Action: 'View List',
			Type: 'Permission',
			Field: 'USER_VIEW',
			Note: 'hide the sidebar menu / front page tile if missing the permission',
		},
		{
			Object: 'User',
			Action: 'Create',
			Type: 'Permission',
			Field: 'USER_UPDATE',
			Reason: 'MissingPermission',
			Message: 'You do not have permissions to perform this action.',
		},
		{
			Object: 'User',
			Action: 'Edit',
			Type: 'Access',
			Field: 'AllowEdit',
			Order: 1,
			Reason: 'MissingPermission',
			Message: 'You do not have permissions to perform this action.',
		},
		{
			Object: 'User Role',
			Action: 'View List',
			Type: 'Permission',
			Field: 'USERROLE_VIEW',
			Note: 'hide the sidebar menu / front page tile if missing the permission',
		},
		{
			Object: 'User Role',
			Action: 'Create',
			Type: 'Permission',
			Field: 'USERROLE_UPDATE',
			Reason: 'MissingPermission',
			Message: 'You do not have permissions to perform this action.',
		},
		{
			Object: 'User Role',
			Action: 'Edit',
			Type: 'Access',
			Field: 'AllowEdit',
			Order: 1,
			Reason: 'MissingPermission',
			Message: 'You do not have permissions to perform this action.',
		},
		{
			Object: 'User Role',
			Action: 'Edit',
			Type: 'Access',
			Field: 'AllowEdit',
			Order: 2,
			Reason: 'IsRemoved',
			Message: 'Deleted roles cannot be edited.',
		},
		{
			Object: 'User Role',
			Action: 'Edit',
			Type: 'Access',
			Field: 'AllowEdit',
			Order: 3,
			Reason: 'IsSystem',
			Message: 'System roles cannot be edited.',
		},
		{
			Object: 'User Role',
			Action: 'Delete',
			Type: 'Access',
			Field: 'AllowRemove',
			Order: 1,
			Reason: 'MissingPermission',
			Message: 'You do not have permissions to perform this action.',
		},
		{
			Object: 'User Role',
			Action: 'Delete',
			Type: 'Access',
			Field: 'AllowRemove',
			Order: 2,
			Reason: 'IsRemoved',
			Message: 'Deleted roles cannot be deleted.',
		},
		{
			Object: 'User Role',
			Action: 'Delete',
			Type: 'Access',
			Field: 'AllowRemove',
			Order: 3,
			Reason: 'IsSystem',
			Message: 'System roles cannot be deleted.',
		},
		{
			Object: 'User Role',
			Action: 'Add Permission',
			Type: 'Access',
			Field: 'AllowAddPermission',
			Order: 1,
			Reason: 'MissingPermission',
			Message: 'You do not have permissions to perform this action.',
		},
		{
			Object: 'User Role',
			Action: 'Add Permission',
			Type: 'Access',
			Field: 'AllowAddPermission',
			Order: 2,
			Reason: 'IsRemoved',
			Message: 'Permissions cannot be added to deleted roles.',
		},
		{
			Object: 'User Role',
			Action: 'Add Permission',
			Type: 'Access',
			Field: 'AllowAddPermission',
			Order: 3,
			Reason: 'IsSystem',
			Message: 'Permissions cannot be added to system roles.',
		},
		{
			Object: 'User Role Permission',
			Action: 'Edit',
			Type: 'Access',
			Field: 'AllowEdit',
			Order: 1,
			Reason: 'MissingPermission',
			Message: 'You do not have permissions to perform this action.',
		},
		{
			Object: 'User Role Permission',
			Action: 'Edit',
			Type: 'Access',
			Field: 'AllowEdit',
			Order: 2,
			Reason: 'IsRemoved',
			Message: 'Deleted permissions cannot be edited.',
		},
		{
			Object: 'User Role Permission',
			Action: 'Edit',
			Type: 'Access',
			Field: 'AllowEdit',
			Order: 3,
			Reason: 'IsSystem',
			Message: 'Permissions cannot be edited on system roles.',
		},
		{
			Object: 'User Role Permission',
			Action: 'Delete',
			Type: 'Access',
			Field: 'AllowRemove',
			Order: 1,
			Reason: 'MissingPermission',
			Message: 'You do not have permissions to perform this action.',
		},
		{
			Object: 'User Role Permission',
			Action: 'Delete',
			Type: 'Access',
			Field: 'AllowRemove',
			Order: 2,
			Reason: 'IsRemoved',
			Message: 'Deleted permissions cannot be deleted.',
		},
		{
			Object: 'User Role Permission',
			Action: 'Delete',
			Type: 'Access',
			Field: 'AllowRemove',
			Order: 3,
			Reason: 'IsSystem',
			Message: 'Permissions cannot be deleted on system roles.',
		},
		{
			Object: 'Subscriber',
			Action: 'View',
			Type: 'Permission',
			Field: 'SUBSCRIBER_VIEW',
		},
		{
			Object: 'Subscriber',
			Action: 'Edit',
			Type: 'Access',
			Field: 'AllowEdit',
			Order: 1,
			Reason: 'MissingPermission',
			Message: 'You do not have permissions to perform this action.',
		},
	],
	Shared: [
		{
			Object: 'Attachment',
			Action: 'Create',
			Type: 'Permission',
			Field: 'COMMON_ATTACH_CREATE',
			Reason: 'MissingPermission',
			Message: 'You do not have permissions to perform this action.',
		},
		{
			Object: 'Attachment',
			Action: 'Edit',
			Type: 'Permission',
			Field: 'COMMON_ATTACH_EDIT',
			Reason: 'MissingPermission',
			Message: 'You do not have permissions to perform this action.',
		},
		{
			Object: 'Attachment',
			Action: 'Remove',
			Type: 'Permission',
			Field: 'COMMON_ATTACH_REMOVE',
			Reason: 'MissingPermission',
			Message: 'You do not have permissions to perform this action.',
		},
		{
			Object: 'Attachment File',
			Action: 'Create',
			Type: 'Permission',
			Field: 'COMMON_ATTACH_CREATE',
			Reason: 'MissingPermission',
			Message: 'You do not have permissions to perform this action.',
		},
		{
			Object: 'Attachment File',
			Action: 'Edit',
			Type: 'Permission',
			Field: 'COMMON_ATTACH_EDIT',
			Reason: 'MissingPermission',
			Message: 'You do not have permissions to perform this action.',
		},
		{
			Object: 'Attachment File',
			Action: 'Remove',
			Type: 'Permission',
			Field: 'COMMON_ATTACH_REMOVE',
			Reason: 'MissingPermission',
			Message: 'You do not have permissions to perform this action.',
		},
		{
			Object: 'Contact',
			Action: 'Create',
			Type: 'Permission',
			Field: 'COMMON_CONTACTS_UPDATE',
			Reason: 'MissingPermission',
			Message: 'You do not have permissions to perform this action.',
		},
		{
			Object: 'Contact',
			Action: 'Edit',
			Type: 'Permission',
			Field: 'COMMON_CONTACTS_UPDATE',
			Reason: 'MissingPermission',
			Message: 'You do not have permissions to perform this action.',
		},
		{
			Object: 'Contact',
			Action: 'Remove',
			Type: 'Permission',
			Field: 'COMMON_CONTACTS_REMOVE',
			Reason: 'MissingPermission',
			Message: 'You do not have permissions to perform this action.',
		},
		{
			Object: 'Note',
			Action: 'Create',
			Type: 'Permission',
			Field: 'COMMON_NOTES_CREATE',
			Reason: 'MissingPermission',
			Message: 'You do not have permissions to perform this action.',
		},
		{
			Object: 'Note',
			Action: 'Edit',
			Type: 'Permission',
			Field: 'COMMON_NOTES_EDIT',
			Reason: 'MissingPermission',
			Message: 'You do not have permissions to perform this action.',
		},
		{
			Object: 'Note',
			Action: 'Remove',
			Type: 'Permission',
			Field: 'COMMON_NOTES_REMOVE',
			Reason: 'MissingPermission',
			Message: 'You do not have permissions to perform this action.',
		},
		{
			Object: 'Map',
			Action: 'Create',
			Type: 'Permission',
			Field: 'COMMON_MAPS_CREATE',
			Reason: 'MissingPermission',
			Message: 'You do not have permissions to perform this action.',
		},
		{
			Object: 'Map',
			Action: 'Edit',
			Type: 'Permission',
			Field: 'COMMON_MAPS_EDIT',
			Reason: 'MissingPermission',
			Message: 'You do not have permissions to perform this action.',
		},
		{
			Object: 'Map',
			Action: 'Remove',
			Type: 'Permission',
			Field: 'COMMON_MAPS_REMOVE',
			Reason: 'MissingPermission',
			Message: 'You do not have permissions to perform this action.',
		},
		{
			Object: 'Activity',
			Action: 'View',
			Type: 'Permission',
			Field: 'COMMON_ACTIVITY_VIEW',
			Note: 'Hide the tab if missing permission',
		},
	],
	'Service Request': [
		{
			Object: 'Service Request',
			Action: 'View List',
			Type: 'Permission',
			Field: 'SRQ_VIEW',
			Note: 'hide the sidebar menu / front page tile if missing the permission',
		},
		{
			Object: 'Service Request',
			Action: 'Create',
			Type: 'Permission',
			Field: 'SRQ_UPDATE',
			Reason: 'MissingPermission',
			Message: 'You do not have permissions to perform this action.',
		},
		{
			Object: 'Service Request',
			Action: 'Create',
			Type: 'Permission',
			Field: 'SRQ_UPDATE_BASIC',
			Reason: 'MissingPermission',
			Message: 'You do not have permissions to perform this action.',
		},
		{
			Object: 'Service Request',
			Action: 'Edit',
			Type: 'Access',
			Field: 'AllowEdit',
			Order: 1,
			Reason: 'MissingPermission',
			Message: 'You do not have permissions to perform this action.',
		},
		{
			Object: 'Service Request',
			Action: 'Edit',
			Type: 'Access',
			Field: 'AllowEdit',
			Order: 2,
			Reason: 'IncorrectStatus',
			Message: 'You do not have permission to edit SRQs in ${statusName} status.',
		},
		{
			Object: 'Service Request',
			Action: 'Amend',
			Type: 'Access',
			Field: 'AllowAmend',
			Order: 1,
			Reason: 'MissingPermission',
			Message: 'You do not have permissions to perform this action.',
		},
		{
			Object: 'Service Request',
			Action: 'Amend',
			Type: 'Access',
			Field: 'AllowAmend',
			Order: 2,
			Reason: 'IncorrectStatus',
			Message: 'SRQs cannot be amended while in ${statusName} status.',
		},
		{
			Object: 'Service Request',
			Action: 'Cancel',
			Type: 'Access',
			Field: 'AllowRemove',
			Order: 1,
			Reason: 'MissingPermission',
			Message: 'You do not have permissions to perform this action.',
		},
		{
			Object: 'Service Request',
			Action: 'Cancel',
			Type: 'Access',
			Field: 'AllowRemove',
			Order: 2,
			Reason: 'IncorrectStatus',
			Message: 'SRQs in ${statusName} status cannot be cancelled.',
		},
		{
			Object: 'Service Request',
			Action: 'Review',
			Type: 'Access',
			Field: 'AllowReview',
			Order: 1,
			Reason: 'MissingPermission',
			System: 'Mobile',
			Message: 'You do not have permissions to perform this action.',
		},
		{
			Object: 'Service Request',
			Action: 'Flow to Scope',
			Type: 'Access',
			Field: 'AllowFlowToScope',
			Order: 1,
			Reason: 'MissingPermission',
			Message: 'You do not have permissions to perform this action.',
		},
		{
			Object: 'Service Request',
			Action: 'Flow to Scope',
			Type: 'Access',
			Field: 'AllowFlowToScope',
			Order: 2,
			Reason: 'IncorrectStatus',
			Message: 'Cancelled SRQs cannot be flowed through to a new scope.',
		},
		{
			Object: 'Service Request',
			Action: 'Flow to Quote',
			Type: 'Access',
			Field: 'AllowFlowToQuote',
			Order: 1,
			Reason: 'MissingPermission',
			Message: 'You do not have permissions to perform this action.',
		},
		{
			Object: 'Service Request',
			Action: 'Flow to Quote',
			Type: 'Access',
			Field: 'AllowFlowToQuote',
			Order: 2,
			Reason: 'IncorrectStatus',
			Message: 'Cancelled SRQs cannot be flowed through to a new quote request.',
		},
		{
			Object: 'Service Request',
			Action: 'Flow to Work Order',
			Type: 'Access',
			Field: 'AllowFlowToWorkOrder',
			Order: 1,
			Reason: 'MissingPermission',
			Message: 'You do not have permissions to perform this action.',
		},
		{
			Object: 'Service Request',
			Action: 'Flow to Work Order',
			Type: 'Access',
			Field: 'AllowFlowToWorkOrder',
			Order: 2,
			Reason: 'IncorrectStatus',
			Message: 'Cancelled SRQs cannot be flowed through to a new work order.',
		},
		{
			Object: 'Service Request',
			Action: 'Flow to Invoice',
			Type: 'Access',
			Field: 'AllowFlowToInvoice',
			Order: 1,
			Reason: 'MissingPermission',
			Message: 'You do not have permissions to perform this action.',
		},
		{
			Object: 'Service Request',
			Action: 'Add Item',
			Type: 'Access',
			Field: 'AllowAddItem',
			Order: 1,
			Reason: 'MissingPermission',
			Message: 'You do not have permissions to perform this action.',
		},
		{
			Object: 'Service Request',
			Action: 'Add Item',
			Type: 'Access',
			Field: 'AllowAddItem',
			Order: 2,
			Reason: 'IncorrectStatus',
			Message: 'You can only add items when the SRQ is in Created status.',
		},
		{
			Object: 'Service Request',
			Action: 'Send Changes',
			Type: 'Access',
			Field: 'AllowSend',
			Order: 1,
			Reason: 'MissingPermission',
			Message: 'You do not have permissions to perform this action.',
		},
		{
			Object: 'Service Request',
			Action: 'Edit Flags',
			Type: 'Access',
			Field: 'AllowFlagEdit',
			Order: 1,
			Reason: 'MissingPermission',
			Message: 'You do not have permissions to perform this action.',
		},
		{
			Object: 'Service Request',
			Action: 'Edit Flags',
			Type: 'Access',
			Field: 'AllowFlagEdit',
			Order: 2,
			Reason: 'IncorrectStatus',
			Message: 'You do not have permission to edit SRQs in ${statusName} status.',
		},
		{
			Object: 'Service Request',
			Action: 'View Work Plan',
			Type: 'Access',
			Field: 'AllowViewPlan',
			Order: 1,
			Reason: 'MissingPermission',
			Message: 'You do not have permissions to perform this action.',
		},
		{
			Object: 'Service Request',
			Action: 'View Work Plan',
			Type: 'Access',
			Field: 'AllowViewPlan',
			Order: 2,
			Reason: 'MissingDates',
			Message: 'The planner for this SRQ cannot be viewed as it does not have a scheduled start or due date.',
		},
		{
			Object: 'Service Request',
			Action: 'View Work Plan',
			Type: 'Access',
			Field: 'AllowViewPlan',
			Order: 3,
			Reason: 'IncorrectStatus',
			Message: 'The planner for this SRQ cannot be viewed as it is in ${statusName} status.',
		},
		{
			Object: 'Service Request',
			Action: 'Close',
			Type: 'Access',
			Field: 'AllowClose',
			Order: 1,
			Reason: 'MissingPermission',
			Message: 'You do not have permissions to perform this action.',
		},
		{
			Object: 'Service Request',
			Action: 'Close',
			Type: 'Access',
			Field: 'AllowClose',
			Order: 2,
			Reason: 'IncorrectStatus',
			Message: 'SRQs in ${statusName} status cannot be closed.',
		},
		{
			Object: 'Service Request',
			Action: 'Accept',
			Type: 'Access',
			Field: 'AllowAccept',
			Order: 1,
			Reason: 'MissingPermission',
			Message: 'You do not have permissions to perform this action.',
		},
		{
			Object: 'Service Request',
			Action: 'Accept',
			Type: 'Access',
			Field: 'AllowAccept',
			Order: 2,
			Reason: 'IncorrectStatus',
			Message: 'SRQs in ${statusName} status cannot be accepted.',
		},
		{
			Object: 'Service Request',
			Action: 'Reject',
			Type: 'Access',
			Field: 'AllowReject',
			Order: 1,
			Reason: 'MissingPermission',
			Message: 'You do not have permissions to perform this action.',
		},
		{
			Object: 'Service Request',
			Action: 'Reject',
			Type: 'Access',
			Field: 'AllowReject',
			Order: 2,
			Reason: 'IncorrectStatus',
			Message: 'SRQs in ${statusName} status cannot be rejected.',
		},
		{
			Object: 'Service Request Item',
			Action: 'Edit',
			Type: 'Access',
			Field: 'AllowEdit',
			Order: 1,
			Reason: 'MissingPermission',
			Message: 'You do not have permissions to perform this action.',
		},
		{
			Object: 'Service Request Item',
			Action: 'Edit',
			Type: 'Access',
			Field: 'AllowEdit',
			Order: 2,
			Reason: 'IncorrectStatus',
			Message: 'You can only edit problems when the SRQ is in Created status.',
		},
		{
			Object: 'Service Request Item',
			Action: 'Edit',
			Type: 'Access',
			Field: 'AllowEdit',
			Order: 3,
			Reason: 'IsReviewed',
			Message: 'The review result must be cleared before editing a reviewed problem.',
		},
		{
			Object: 'Service Request Item',
			Action: 'Edit',
			Type: 'Access',
			Field: 'AllowEdit',
			Order: 4,
			Reason: 'IsRemoved',
			Message: 'Deleted problems cannot be edited.',
		},
		{
			Object: 'Service Request Item',
			Action: 'Delete',
			Type: 'Access',
			Field: 'AllowRemove',
			Order: 1,
			Reason: 'MissingPermission',
			Message: 'You do not have permissions to perform this action.',
		},
		{
			Object: 'Service Request Item',
			Action: 'Delete',
			Type: 'Access',
			Field: 'AllowRemove',
			Order: 2,
			Reason: 'IncorrectStatus',
			Message: 'You can only delete problems when the SRQ is in Created status.',
		},
		{
			Object: 'Service Request Item',
			Action: 'Delete',
			Type: 'Access',
			Field: 'AllowRemove',
			Order: 3,
			Reason: 'IsReviewed',
			Message: 'The review result must be cleared before deleting a reviewed problem.',
		},
		{
			Object: 'Service Request Item',
			Action: 'Delete',
			Type: 'Access',
			Field: 'AllowRemove',
			Order: 4,
			Reason: 'IsRemoved',
			Message: 'Deleted problems cannot be deleted.',
		},
		{
			Object: 'Service Request Item',
			Action: 'Review',
			Type: 'Access',
			Field: 'AllowReview',
			Order: 1,
			Reason: 'MissingPermission',
			Message: 'You do not have permissions to perform this action.',
		},
		{
			Object: 'Service Request Item',
			Action: 'Review',
			Type: 'Access',
			Field: 'AllowReview',
			Order: 2,
			Reason: 'IsRemoved',
			Message: 'Deleted problems cannot be reviewed.',
		},
		{
			Object: 'Service Request Item',
			Action: 'Review',
			Type: 'Access',
			Field: 'AllowReview',
			Order: 3,
			Reason: 'IsNew',
			Message: 'Newly added problems cannot be reviewed.',
		},
	],
	System: [
		{
			Object: 'Interface Export Event',
			Action: 'View List',
			Type: 'Permission',
			Field: 'INTERFACE_VIEW',
		},
		{
			Object: 'Interface Export Event',
			Action: 'Resend',
			Type: 'Access',
			Field: 'AllowResend',
			Order: 1,
			Reason: 'MissingPermission',
			Message: 'You do not have permissions to perform this action.',
		},
		{
			Object: 'Interface Export Event',
			Action: 'Resend',
			Type: 'Access',
			Field: 'AllowResend',
			Order: 2,
			Reason: 'IncorrectStatus',
			Message: 'Export events can only be resent when in Failed or Cancelled status.',
		},
		{
			Object: 'Interface Export Event',
			Action: 'Cancel',
			Type: 'Access',
			Field: 'AllowRemove',
			Order: 1,
			Reason: 'MissingPermission',
			Message: 'You do not have permissions to perform this action.',
		},
		{
			Object: 'Interface Export Event',
			Action: 'Cancel',
			Type: 'Access',
			Field: 'AllowRemove',
			Order: 2,
			Reason: 'IncorrectStatus',
			Message: 'Export events can only be cancelled when in Created or Failed status.',
		},
		{
			Object: 'Interface Export Event',
			Action: 'Refresh and Resend',
			Type: 'Access',
			Field: 'AllowRecreate',
			Order: 1,
			Reason: 'MissingPermission',
			Message: 'You do not have permissions to perform this action.',
		},
		{
			Object: 'Interface Export Event',
			Action: 'Refresh and Resend',
			Type: 'Access',
			Field: 'AllowRecreate',
			Order: 2,
			Reason: 'IncorrectStatus',
			Message: 'Export events can only be refreshed and resent when in Failed or Cancelled status.',
		},
		{
			Object: 'Interface Import Event',
			Action: 'View List',
			Type: 'Permission',
			Field: 'INTERFACE_VIEW',
		},
		{
			Object: 'Interface Import Event',
			Action: 'Reprocess',
			Type: 'Access',
			Field: 'AllowReprocess',
			Order: 1,
			Reason: 'MissingPermission',
			Message: 'You do not have permissions to perform this action.',
		},
		{
			Object: 'Interface Import Event',
			Action: 'Reprocess',
			Type: 'Access',
			Field: 'AllowReprocess',
			Order: 2,
			Reason: 'IncorrectStatus',
			Message: 'Import events can only be reprocessed when in Failed or Cancelled status.',
		},
		{
			Object: 'Interface Import Event',
			Action: 'Cancel',
			Type: 'Access',
			Field: 'AllowRemove',
			Order: 1,
			Reason: 'MissingPermission',
			Message: 'You do not have permissions to perform this action.',
		},
		{
			Object: 'Interface Import Event',
			Action: 'Cancel',
			Type: 'Access',
			Field: 'AllowRemove',
			Order: 2,
			Reason: 'IncorrectStatus',
			Message: 'Import events can only be cancelled when in Created or Failed status.',
		},
	],
};
