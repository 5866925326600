import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ActionResult } from 'src/app/shared/models/api';
import { BusinessEntityLicence } from 'src/app/shared/models/business-entities';
import { ApiService } from 'src/app/core/services';

@Injectable({
	providedIn: 'root',
})
export class EntityLicenceApiService {
	constructor(private apiService: ApiService) {
		//
	}

	getBusinessEntityLicencesForBusinessEntity(businessEntityId: string): Observable<BusinessEntityLicence[]> {
		return this.apiService.get<BusinessEntityLicence[]>(`/BusinessEntity/GetBusinessEntityLicencesForBusinessEntity/${businessEntityId}`);
	}

	saveBusinessEntityLicence(item: BusinessEntityLicence) {
		return this.apiService.post<ActionResult>('/BusinessEntity/SaveBusinessEntityLicence', item);
	}

	disableBusinessEntityLicence(businessEntityLicenceId: string) {
		return this.apiService.get<ActionResult>(`/BusinessEntity/DisableBusinessEntityLicence/${businessEntityLicenceId}`);
	}
}
