<p-overlayPanel #filterOP [appendTo]="'body'" (onHide)="onHideFilter()">
	<div *ngFor="let filterConfig of filterConfigs" [ngSwitch]="filterConfig.DisplayType">
		<div *ngIf="!filterConfig.IsHidden" class="p-grid">
			<div class="p-grid p-p-1 p-mt-1">
				<div *ngSwitchCase="'guidValue'" class="p-col-12 align-items-center">
					<div class="p-grid">
						<div class="p-col-12 p-float-label p-mt-4">
							<input name="{{ filterConfig.Name }}" style="width: 100%" type="text" autocomplete="off" [(ngModel)]="filterConfig.TextValue" pInputText />
							<label for="{{ filterConfig.Name }}">{{ filterConfig.DisplayName }}</label>
						</div>
					</div>
				</div>
				<div *ngSwitchCase="'textValue'" class="p-col-12 align-items-center">
					<div class="p-grid">
						<div class="p-col-12 p-float-label p-mt-4">
							<input name="{{ filterConfig.Name }}" style="width: 100%" type="text" autocomplete="off" [(ngModel)]="filterConfig.TextValue" pInputText />
							<label for="{{ filterConfig.Name }}">{{ filterConfig.DisplayName }}</label>
						</div>
					</div>
				</div>
				<div *ngSwitchCase="'multiValue'" class="p-col-12 align-items-center">
					<div class="p-grid">
						<div class="p-col-12 p-float-label p-mt-4">
							<input name="{{ filterConfig.Name }}" style="width: 100%" type="text" autocomplete="off" [(ngModel)]="filterConfig.Values" pInputText />
							<label for="{{ filterConfig.Name }}">{{ filterConfig.DisplayName }}</label>
						</div>
					</div>
				</div>
				<div *ngSwitchCase="'selectBtn'" class="p-col-12 align-items-center">
					<div *ngIf="filterConfig.Options && filterConfig.Options.length !== 0">
						<div>{{ filterConfig.DisplayName }}</div>
						<p-selectButton [options]="filterConfig.Options" [(ngModel)]="filterConfig.Values" multiple="multiple" optionLabel="NameValue"></p-selectButton>
					</div>
				</div>
				<div *ngSwitchCase="'dropdown'" class="p-col-12 p-float-label p-mt-4">
					<div *ngIf="filterConfig.Options && filterConfig.Options.length !== 0">
						<p-autoComplete
							name="{{ filterConfig.Name }}"
							appAutoComplete
							[(ngModel)]="filterConfig.Values"
							[suggestions]="filterConfig.Options"
							[forceSelection]="true"
							[dropdown]="true"
							field="NameValue"
							filterBy="NameValue, CodeValue, Description"
							[multiple]="true">
						</p-autoComplete>
						<label>{{ filterConfig.DisplayName }}</label>
					</div>
				</div>
				<div *ngSwitchCase="'numberValue'" class="p-col-12 align-items-center">
					<div class="p-grid">
						<div class="p-col-12 p-float-label p-mt-4">
							<input
								name="{{ filterConfig.Name }}"
								style="width: 100%"
								type="text"
								[(ngModel)]="filterConfig.NumberValue"
								pInputText
								pKeyFilter="money"
								autocomplete="off"
								format="currency" />
							<label for="{{ filterConfig.Name }}">{{ filterConfig.DisplayName }}</label>
						</div>
					</div>
				</div>
				<div *ngSwitchCase="'startNumberValue'" class="p-col-12 align-items-center">
					<div class="p-grid">
						<div class="p-col-12 p-float-label p-mt-4">
							<input
								name="{{ filterConfig.Name }}"
								style="width: 100%"
								type="text"
								[(ngModel)]="filterConfig.NumberValue"
								pInputText
								pKeyFilter="money"
								autocomplete="off"
								format="currency" />
							<label for="{{ filterConfig.Name }}">{{ filterConfig.DisplayName }}</label>
						</div>
					</div>
				</div>
				<div *ngSwitchCase="'endNumberValue'" class="p-col-12 align-items-center">
					<div class="p-grid">
						<div class="p-col-12 p-float-label p-mt-4">
							<input
								name="{{ filterConfig.Name }}"
								style="width: 100%"
								type="text"
								[(ngModel)]="filterConfig.NumberValue"
								pInputText
								pKeyFilter="money"
								autocomplete="off"
								format="currency" />
							<label for="{{ filterConfig.Name }}">{{ filterConfig.DisplayName }}</label>
						</div>
					</div>
				</div>
				<div *ngSwitchCase="'numberRange'" class="p-col-12 align-items-center">
					<div>{{ filterConfig.DisplayName }}</div>
					<div class="p-grid">
						<div class="p-col-12 p-float-label p-mt-4">
							<input
								name="{{ filterConfig.Name }}_StartNumber"
								style="width: 100%"
								type="text"
								[(ngModel)]="filterConfig.StartNumber"
								pInputText
								pKeyFilter="money"
								autocomplete="off"
								format="currency" />
							<label for="{{ filterConfig.Name }}_StartNumber"><b>From</b></label>
						</div>
						<div class="p-col-12 p-float-label p-mt-4">
							<input
								name="{{ filterConfig.Name }}_EndNumber"
								style="width: 100%"
								type="text"
								[(ngModel)]="filterConfig.EndNumber"
								pInputText
								pKeyFilter="money"
								autocomplete="off"
								format="currency" />
							<label for="{{ filterConfig.Name }}_EndNumber"><b>To</b></label>
						</div>
					</div>
				</div>
				<div *ngSwitchCase="'dateValue'" class="p-col-12 align-items-center">
					<div class="p-grid">
						<div class="p-col-12 p-float-label p-mt-4">
							<p-calendar
								name="{{ filterConfig.Name }}"
								[(ngModel)]="filterConfig.DateValue"
								showIcon="true"
								showButtonBar="true"
								hideOnDateTimeSelect="true"
								dateFormat="dd/mm/yy">
							</p-calendar>
							<label for="{{ filterConfig.Name }}">{{ filterConfig.DisplayName }}</label>
						</div>
					</div>
				</div>
				<div *ngSwitchCase="'startDateValue'" class="p-col-12 align-items-center">
					<div class="p-grid">
						<div class="p-col-12 p-float-label p-mt-4">
							<p-calendar
								name="{{ filterConfig.Name }}"
								[(ngModel)]="filterConfig.DateValue"
								showIcon="true"
								showButtonBar="true"
								hideOnDateTimeSelect="true"
								dateFormat="dd/mm/yy">
							</p-calendar>
							<label for="{{ filterConfig.Name }}">{{ filterConfig.DisplayName }}</label>
						</div>
					</div>
				</div>
				<div *ngSwitchCase="'endDateValue'" class="p-col-12 align-items-center">
					<div class="p-grid">
						<div class="p-col-12 p-float-label p-mt-4">
							<p-calendar
								name="{{ filterConfig.Name }}"
								[(ngModel)]="filterConfig.DateValue"
								showIcon="true"
								showButtonBar="true"
								hideOnDateTimeSelect="true"
								dateFormat="dd/mm/yy">
							</p-calendar>
							<label for="{{ filterConfig.Name }}">{{ filterConfig.DisplayName }}</label>
						</div>
					</div>
				</div>
				<div *ngSwitchCase="'dateRange'" class="p-col-12 align-items-center">
					<div>{{ filterConfig.DisplayName }}</div>
					<div class="p-grid">
						<div class="p-col-12 p-float-label p-mt-4">
							<p-calendar
								name="{{ filterConfig.Name }}_start"
								[(ngModel)]="filterConfig.StartDate"
								showIcon="true"
								showButtonBar="true"
								hideOnDateTimeSelect="true"
								dateFormat="dd/mm/yy">
							</p-calendar>
							<label>Start date</label>
						</div>
						<div class="p-col-12 p-float-label p-mt-4">
							<p-calendar
								name="{{ filterConfig.Name }}_end"
								[(ngModel)]="filterConfig.EndDate"
								showIcon="true"
								showButtonBar="true"
								hideOnDateTimeSelect="true"
								dateFormat="dd/mm/yy">
							</p-calendar>
							<label>End date</label>
						</div>
					</div>
				</div>
				<div *ngSwitchCase="'boolValue'" class="p-col-12 align-items-center">
					<p-checkbox name="{{ filterConfig.Name }}" [(ngModel)]="filterConfig.BoolValue" label="{{ filterConfig.DisplayName }}" binary="true"></p-checkbox>
				</div>
				<div *ngSwitchCase="'checkbox'" class="p-col-12 align-items-center">
					<p-checkbox name="{{ filterConfig.Name }}" [(ngModel)]="filterConfig.BoolValue" label="{{ filterConfig.DisplayName }}" binary="true"></p-checkbox>
				</div>
			</div>
		</div>
	</div>
	<div class="p-mt-2 text-right p-d-flex p-jc-end">
		<button type="button" pButton class="p-mb-1 p-mr-2" (click)="advancedSearchUpdated()">Ok</button>
		<button type="button" pButton class="p-mb-1 p-mr-2" (click)="clear()">Clear</button>
	</div>
</p-overlayPanel>
