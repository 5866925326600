import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { DynamicDialogParams } from 'src/app/core/models';
import { UtilService } from 'src/app/core/services';

@Component({
	selector: 'app-message-dialog',
	templateUrl: './message-dialog.component.html',
	styleUrls: ['./message-dialog.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class MessageDialogComponent implements OnInit {
	private params: DynamicDialogParams;
	messageObject: any;
	ObjectName: any;
	ObjectPropertyName_Number: any;
	title: string;
	icon: string;

	constructor(private util: UtilService, public ref: DynamicDialogRef, public config: DynamicDialogConfig) {}
	ngOnInit(): void {
		this.params = this.config.data as DynamicDialogParams;
		if (this.params.data != null) {
			this.title = this.params.data.title;
			this.icon = this.params.data.icon;
			this.messageObject = this.params.data.messageObject;
			this.ObjectName = this.params.data.ObjectName;
			this.ObjectPropertyName_Number = this.params.data.ObjectPropertyName_Number;
		}
	}

	closeDialog() {
		this.ref.close(null);
	}

	getHeader(): string {
		return this.params.header;
	}

	getTitle(): string {
		return this.title;
	}

	getIcon() {
		return this.icon;
	}

	getObjectName() {
		return this.ObjectName;
	}

	getObjectNumber() {
		if (this.messageObject) return this.messageObject.Details[this.ObjectPropertyName_Number];

		return '';
	}

	getActionName() {
		if (this.messageObject) return this.messageObject.Action;
		return '';
	}

	copyMessage() {
		if (this.messageObject) this.util.copyContent(this.messageObject.Messages.map((x: { Message: any }) => x.Message).join(', '));
		return;
	}
}
