import { Directive } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';

@Directive({
	selector: '[validatePercentage]',
	providers: [
		{
			provide: NG_VALIDATORS,
			useExisting: PercentageValidatorDirective,
			multi: true,
		},
	],
})
export class PercentageValidatorDirective implements Validator {
	validate(control: AbstractControl): ValidationErrors | null {
		if (control.value == null || control.value === '') {
			return null;
		}
		// Convert the value to a number
		const numericValue = parseFloat(control.value);

		return isNaN(numericValue) || numericValue < 0 || numericValue > 100 ? { percentage: 'not matched' } : null;
	}
}
