import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Table } from 'primeng/table';
import { InvoiceRelatedEntry } from 'src/app/features/invoice/models';
import { InvoiceApiService } from 'src/app/features/invoice/services';
import { FormMode } from 'src/app/shared/models/forms';
import { RegionSettings } from 'src/app/shared/models/settings';
import { BaseComponent } from 'src/app/core/components/base.component';
import { AppContextService } from 'src/app/core/services';
import { Constants } from '../../constants/constants.constant';
import { RELATED_INVOICE_DISPLAY_FILTER_LIST, RelatedInvoiceDisplayFilterType } from '../../enums';

@Component({
	selector: 'app-related-invoice-table',
	templateUrl: './related-invoice-table.component.html',
	styleUrls: ['./related-invoice-table.component.scss'],
})
export class RelatedInvoiceTableComponent extends BaseComponent implements OnInit {
	@Input() parentReferenceTypeCode: string;
	@Input() parentReferenceId: string;
	@Input() referenceTypeCode: string;
	@Input() referenceId: string;
	@Input() invoiceId: string;
	@Input() workAssetId: string;
	@ViewChild('invoiceListTable') invoiceListTable: Table;

	invoices: InvoiceRelatedEntry[] = [];
	showList = true;
	formMode = FormMode.View;
	region: RegionSettings;
	currentRefID: string;

	displayFilterOptions = RELATED_INVOICE_DISPLAY_FILTER_LIST;
	displayFilterTypes = RelatedInvoiceDisplayFilterType;
	selectedFilterOption = this.displayFilterTypes[this.displayFilterTypes.SHOW_ACTIVE];

	constructor(appContext: AppContextService, private activatedRoute: ActivatedRoute, private invoiceService: InvoiceApiService, private router: Router) {
		super(appContext);
	}

	ngOnInit(): void {
		this.currentRefID = this.activatedRoute?.snapshot?.paramMap?.get('id');
		try {
			this.formMode = this.formModes.View;
			this.region = this.appContext.getRegion();
		} catch (ex) {
			this.util.hideWait();
			console.error(ex);
		}
	}

	public refreshData(): void {
		const isAllInvoiceEnabled = this.selectedFilterOption == this.displayFilterTypes[this.displayFilterTypes.SHOW_ALL];
		this.showList = false;
		this.appContext.HasTableBasedData = false;

		if (this.invoiceListTable) this.invoiceListTable.editingRowKeys = {};
		this.invoiceService
			.getRelatedInvoiceEntries(
				this.parentReferenceTypeCode,
				this.parentReferenceId,
				this.referenceTypeCode,
				this.referenceId,
				this.invoiceId,
				this.workAssetId,
				isAllInvoiceEnabled
			)
			.subscribe(result => {
				this.invoices = [...result];
				this.showList = true;
				this.appContext.HasTableBasedData = true;
			});
	}

	canNavigateToDetails(invoice: InvoiceRelatedEntry) {
		const isPayable = ['purchasecredit', 'payable'].includes(invoice.InvoiceTypeCode.toLowerCase());
		const isReceivable = ['salescredit', 'receivable'].includes(invoice.InvoiceTypeCode.toLowerCase());
		const isSameInvoice = this.currentRefID === invoice.InvoiceId;
		return !invoice?.InvoicePaymentId && (isPayable || isReceivable) && !isSameInvoice;
	}

	handleRowClick(invoice: InvoiceRelatedEntry) {
		const selectedLinkInvoiceId = invoice.InvoiceId;
		const isPayable = ['purchasecredit', 'payable'].includes(invoice.InvoiceTypeCode.toLowerCase());
		const isReceivable = ['salescredit', 'receivable'].includes(invoice.InvoiceTypeCode.toLowerCase());
		if (isPayable || isReceivable) {
			const URL = `/invoice/${isPayable ? 'payable' : 'receivable'}`;
			try {
				// TO-DO Find alternative for same page reload
				this.router.routeReuseStrategy.shouldReuseRoute = () => false;
			} catch (err) {
				console.log(err);
			}
			this.router.navigate([URL, selectedLinkInvoiceId]);
		}
	}

	toggleItems(invoice: InvoiceRelatedEntry) {
		this.invoiceListTable.expandedRowKeys[invoice.InvoiceId] = !this.invoiceListTable.expandedRowKeys[invoice.InvoiceId];
	}

	currencyFilter(value, id) {
		if (value) {
			this.invoiceListTable.filters[id][0].value = value.toString();
		}
	}

	changeFilterOption() {
		this.refreshData();
	}

	getInvoiceRecordType(invoice: InvoiceRelatedEntry) {
		return this.util.getRecordTypeCodeForReferenceType(Constants.ReferenceType.Invoice, null, null, null, invoice.InvoiceTypeCode);
	}

	getReferenceRecordType(invoice: InvoiceRelatedEntry) {
		return invoice.RecordTypeCode;
	}
}
