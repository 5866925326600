import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Constants } from 'src/app/shared/constants/constants.constant';
import { EntityInsuranceConstants } from 'src/app/shared/constants/entity-insurance.constant';
import { BusinessEntityInsurance } from 'src/app/shared/models/business-entities';
import { FormMode, FormControl as FrmCntl } from 'src/app/shared/models/forms';
import { LookupValue } from 'src/app/shared/models/lookups';
import { RegionSettings } from 'src/app/shared/models/settings';
import { BaseComponent } from 'src/app/core/components/base.component';
import { AppContextService, TooltipMessageService } from 'src/app/core/services';
import { CustomValidators } from 'src/app/core/utils';
import { EntityInsuranceApiService } from '../../services';

@Component({
	selector: 'app-entity-insurance-detail',
	templateUrl: './entity-insurance-detail.component.html',
})
export class EntityInsuranceDetailComponent extends BaseComponent implements OnInit {
	@Input() formMode = FormMode.View;
	@Input() record: BusinessEntityInsurance = new BusinessEntityInsurance();
	@Output() editFinished = new EventEmitter();
	@Output() detailsClosed = new EventEmitter();

	formControls = FrmCntl;
	insuranceTypes: LookupValue[] = [];
	initialValues: any;
	region: RegionSettings;

	recordForm = this.fb.group({
		insuranceType: [null, [Validators.required]],
		policyNumber: [null],
		policyNumberFieldVisibilityCode: [null],
		coverAmount: [null],
		coverAmountFieldVisibilityCode: [null],
		expiryDate: [null],
		expiryDateFieldVisibilityCode: [null],
		expiryDateNotApplicable: [null],
		comments: [null],
		dateAdded: [null],
		dateUpdated: [null],
		dateRemoved: [null],
		updatedBy: [null],
		addedBy: [null],
	});

	constructor(
		appContext: AppContextService,
		private entityInsuranceService: EntityInsuranceApiService,
		private fb: UntypedFormBuilder,
		private activatedRoute: ActivatedRoute,
		private tooltipMessageService: TooltipMessageService
	) {
		super(appContext);
	}

	ngOnInit(): void {
		this.activatedRoute.data.subscribe(data => {
			this.initLookups(data);

			if (this.record.BusinessEntityInsuranceId) {
				this.patchForm();
				this.initialValues = this.recordForm.value;
			} else {
				this.formMode = this.formModes.Create;
				this.patchForm();
			}
		});
	}

	initLookups(data) {
		this.region = this.appContext.getRegion();
		this.insuranceTypes = data.pageData.insuranceTypes.slice();

		const inactiveInsuranceType = this.util.addRecordValueIntoLookup(this.record.InsuranceTypeCode, this.record.InsuranceTypeName, this.insuranceTypes);
		if (inactiveInsuranceType != null) {
			inactiveInsuranceType.AdditionalDetails = {
				PolicyNumberFieldVisibilityCode: this.record.PolicyNumberFieldVisibilityCode,
				CoverAmountFieldVisibilityCode: this.record.CoverAmountFieldVisibilityCode,
				ExpiryDateFieldVisibilityCode: this.record.ExpiryDateFieldVisibilityCode,
				IsRequiredInsurance: this.record.IsRequiredInsurance,
			};
		}
	}

	patchForm() {
		const insuranceType = this.insuranceTypes.find(x => x.CodeValue === this.record.InsuranceTypeCode);

		this.recordForm.patchValue({
			insuranceType: insuranceType,
			policyNumber: this.record.PolicyNumber,
			policyNumberFieldVisibilityCode: this.record.PolicyNumberFieldVisibilityCode,
			coverAmount: this.record.CoverAmount,
			coverAmountFieldVisibilityCode: this.record.CoverAmountFieldVisibilityCode,
			expiryDate: this.record.ExpiryDate,
			expiryDateNotApplicable: this.record.ExpiryDateNotApplicable,
			expiryDateFieldVisibilityCode: this.record.ExpiryDateFieldVisibilityCode,
			comments: this.record.Comments,
			dateAdded: this.record.DateAdded,
			dateUpdated: this.record.DateUpdated,
			dateRemoved: this.record.DateRemoved,
			updatedBy: this.record.UpdatedBy,
			addedBy: this.record.AddedBy,
		});

		this.updateValidators();

		if (this.formMode != this.formModes.Create) this.markGroupDirty(this.recordForm);
	}

	updateValidators() {
		const isEditable = this.formMode == this.formModes.Create || this.formMode == this.formModes.Edit;

		if (isEditable && this.recordForm.value.policyNumberFieldVisibilityCode == Constants.FieldVisibility.Mandatory)
			this.recordForm.controls['policyNumber'].addValidators(Validators.required);
		else this.recordForm.controls['policyNumber'].removeValidators(Validators.required);

		if (isEditable && this.recordForm.value.coverAmountFieldVisibilityCode == Constants.FieldVisibility.Mandatory)
			this.recordForm.controls['coverAmount'].addValidators(Validators.required);
		else this.recordForm.controls['coverAmount'].removeValidators(Validators.required);

		if (isEditable && this.recordForm.value.expiryDateFieldVisibilityCode == Constants.FieldVisibility.Mandatory) {
			this.recordForm.controls['expiryDate'].addValidators(CustomValidators.expiryDateOrNotApplicableRequired);
			this.recordForm.controls['expiryDateNotApplicable'].addValidators(CustomValidators.expiryDateOrNotApplicableRequired);
		} else {
			this.recordForm.controls['expiryDate'].removeValidators(CustomValidators.expiryDateOrNotApplicableRequired);
			this.recordForm.controls['expiryDateNotApplicable'].removeValidators(CustomValidators.expiryDateOrNotApplicableRequired);
		}

		this.recordForm.controls['policyNumber'].updateValueAndValidity();
		this.recordForm.controls['coverAmount'].updateValueAndValidity();
		this.recordForm.controls['expiryDate'].updateValueAndValidity();
		this.recordForm.controls['expiryDateNotApplicable'].updateValueAndValidity();
	}

	insuranceTypeChanged(insuranceType: LookupValue) {
		this.recordForm.patchValue({
			policyNumberFieldVisibilityCode: insuranceType?.AdditionalDetails?.PolicyNumberFieldVisibilityCode,
			coverAmountFieldVisibilityCode: insuranceType?.AdditionalDetails?.CoverAmountFieldVisibilityCode,
			expiryDateFieldVisibilityCode: insuranceType?.AdditionalDetails?.ExpiryDateFieldVisibilityCode,
		});

		this.updateValidators();
	}

	showPolicyNumberField() {
		const policyNumberFieldVisibilityCode = this.recordForm.value.policyNumberFieldVisibilityCode;
		return policyNumberFieldVisibilityCode == Constants.FieldVisibility.Mandatory || policyNumberFieldVisibilityCode == Constants.FieldVisibility.Optional;
	}

	showCoverAmountField() {
		const coverAmountFieldVisibilityCode = this.recordForm.value.coverAmountFieldVisibilityCode;
		return coverAmountFieldVisibilityCode == Constants.FieldVisibility.Mandatory || coverAmountFieldVisibilityCode == Constants.FieldVisibility.Optional;
	}

	showExpiryDateField() {
		const expiryDateFieldVisibilityCode = this.recordForm.value.expiryDateFieldVisibilityCode;
		return expiryDateFieldVisibilityCode == Constants.FieldVisibility.Mandatory || expiryDateFieldVisibilityCode == Constants.FieldVisibility.Optional;
	}

	fieldsShown(): number {
		let fieldsShown = 0;

		if (this.showPolicyNumberField()) fieldsShown++;
		if (this.showCoverAmountField()) fieldsShown++;
		if (this.showExpiryDateField()) fieldsShown += 2;

		return fieldsShown;
	}

	expiryChanged() {
		this.recordForm.controls['expiryDate'].updateValueAndValidity();
		this.recordForm.controls['expiryDateNotApplicable'].updateValueAndValidity();
	}

	editRecord() {
		this.recordForm.reset();
		this.patchForm();
		this.initialValues = this.recordForm.value;
		this.formMode = this.formModes.Edit;
		this.updateValidators();
	}

	cancelRecordEdit() {
		this.util.clearMessages();
		this.recordForm.reset(this.initialValues);
		this.formMode = this.formModes.View;
		this.updateValidators();
	}

	backToList() {
		if (this.formMode != this.formModes.View) {
			this.cancelRecordEdit();
			this.detailsClosed.emit();
		} else {
			this.detailsClosed.emit();
		}
	}

	saveRecord() {
		this.util.clearMessages();
		this.appContext.loaderService.block();

		if (this.recordForm.valid) {
			this.record.InsuranceTypeCode = this.recordForm.value.insuranceType?.CodeValue;
			this.record.InsuranceTypeName = this.recordForm.value.insuranceType?.NameValue;
			this.record.PolicyNumber = this.showPolicyNumberField() ? this.recordForm.value.policyNumber : null;
			this.record.CoverAmount = this.showCoverAmountField() ? this.recordForm.value.coverAmount : null;
			this.record.ExpiryDate = this.showExpiryDateField() ? this.recordForm.value.expiryDate : null;
			this.record.ExpiryDateNotApplicable = this.showExpiryDateField() && this.recordForm.value.expiryDateNotApplicable === true ? true : null;
			this.record.Comments = this.recordForm.value.comments;
			this.record.IsRequiredInsurance = this.record.IsRequiredInsurance ?? false;

			this.record.ExpiryDateWithoutTimezone = this.util.formatAsDate(this.record.ExpiryDate); // workaround

			this.entityInsuranceService.saveBusinessEntityInsurance(this.record).subscribe(result => {
				if (result.IsSuccess) {
					this.record = this.util.deepClone(result.Data);

					this.recordForm.reset();
					this.patchForm();
					this.formMode = this.formModes.View;

					this.editFinished.emit(result.Data);
				}
				this.appContext.loaderService.unblock();
			});
		} else {
			this.appContext.loaderService.unblock();
			this.markGroupDirty(this.recordForm);
		}
	}

	deleteRecord() {
		this.util.confirmDelete('Do you want to delete this insurance?').then(result => {
			if (result) {
				this.util.clearMessages();
				this.appContext.loaderService.block();

				this.entityInsuranceService.disableBusinessEntityInsurance(this.record.BusinessEntityInsuranceId).subscribe(result => {
					this.appContext.loaderService.unblock();
					if (result.IsSuccess) {
						this.editFinished.emit(result.Data);
						this.backToList();
					}
				});
			}
		});
	}

	showEdit(): boolean {
		return this.record.ObjectAccess?.AllowEdit?.Hide != true;
	}

	allowEdit(): boolean {
		return this.record?.ObjectAccess?.AllowEdit?.IsAllowed;
	}

	getEditTooltip(): string {
		return (
			this.tooltipMessageService.getTooltipMessage('C_BUSINESS_ENTITY_INSURANCE', 'AllowEdit', this.record.ObjectAccess?.AllowEdit, this.record) ??
			EntityInsuranceConstants.Tab_detail_EditInsurance
		);
	}

	showDelete(): boolean {
		return this.record.ObjectAccess?.AllowRemove?.Hide != true;
	}

	allowDelete(): boolean {
		return this.record?.ObjectAccess?.AllowRemove?.IsAllowed;
	}

	getDeleteTooltip(): string {
		return (
			this.tooltipMessageService.getTooltipMessage('C_BUSINESS_ENTITY_INSURANCE', 'AllowRemove', this.record.ObjectAccess?.AllowRemove, this.record) ??
			EntityInsuranceConstants.Tab_detail_DeleteInsurance
		);
	}
}
