export class AttachmentDetail {
	AttachmentId: string;
	ExternalReferenceNumber: string;

	ReferenceTypeCode: string;
	ReferenceId: string;
	ReferenceName: string;

	ParentReferenceTypeCode: string;
	ParentReferenceId: string;
	ParentReferenceName: string;

	AttachmentTypeCode: string;
	AttachmentTypeName: string;
	AttachmentTitle: string;

	TimeZoneCode: string;
	TimeZoneName: string;
	AddedById: string;
	AddedBy: string;
	UpdatedById: string;
	UpdatedBy: string;
	DateAdded: Date;
	DateUpdated: Date;
	DateRemoved: Date;
	AddedByExternalUserRelationTypeCode: string;
	UpdatedByExternalUserRelationTypeCode: string;

	constructor(properties?: any) {
		if (properties) {
			for (const key in properties) {
				this[key] = properties[key];
			}
		}
	}
}
