import { CodeValue } from '../lookups';

export class Recurrence {
	RecordTypeCode: string;
	RecordId: string;
	RecordName: string;
	//for ui
	StyleClass: string;

	// time
	StartTime: string | null;
	Duration: string | null;

	// pattern
	RecurrencePatternCode: string;
	RecurrencePatternName: string;

	Daily: DailyRecurrence | null;
	Weekly: WeeklyRecurrence | null;
	Monthly: MonthlyRecurrence | null;
	Yearly: YearlyRecurrence | null;

	// range
	StartDate: Date | null;
	EndDate: Date | null;
	AfterXRecurrences: number | null;

	TimeZoneCode: string | null;
	TimeZoneName: string | null;

	constructor(properties?: any) {
		if (properties) {
			for (const key in properties) {
				this[key] = properties[key];
			}
		}
	}
}

export class DailyRecurrence {
	EveryXDays: number | null;
	EveryWeekday: boolean | null;

	constructor(properties?: any) {
		if (properties) {
			for (const key in properties) {
				this[key] = properties[key];
			}
		}
	}
}

export class WeeklyRecurrence {
	EveryXWeeks: number | null;
	ApplicableDays: CodeValue[] | null;

	constructor(properties?: any) {
		if (properties) {
			for (const key in properties) {
				this[key] = properties[key];
			}
		}
	}
}

export class MonthlyRecurrence {
	DayXOfMonth: number | null;
	WeeklyRecurrenceCode: string | null; // first, second, third, fourth, last
	WeeklyRecurrenceName: string | null;
	DayofWeekCode: string | null;
	DayofWeekName: string | null;

	EveryXMonths: number | null;

	constructor(properties?: any) {
		if (properties) {
			for (const key in properties) {
				this[key] = properties[key];
			}
		}
	}
}

export class YearlyRecurrence {
	EveryXYears: number | null;
	DayXOfMonth: number | null;

	WeeklyRecurrenceCode: string | null; // first, second, third, fourth, last
	WeeklyRecurrenceName: string | null;
	DayofWeekCode: string | null;
	DayofWeekName: string | null;

	OnXMonth: number | null;
	constructor(properties?: any) {
		if (properties) {
			for (const key in properties) {
				this[key] = properties[key];
			}
		}
	}
}
