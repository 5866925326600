import { Injectable } from '@angular/core';
import { IconConstants } from '../constants/icon.constants';

@Injectable({
	providedIn: 'root',
})
export class IconService {
	constructor() {
		//
	}
	getIcons(markerCode: string): string {
		return IconConstants.ICONS_LIST.filter(x => x.value === markerCode)[0].icon;
	}
}
